import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  Card,
  Flex,
  Heading,
  ThemeProvider,
  Theme,
} from "@aws-amplify/ui-react";
import Chart from "chart.js/auto";
import "src/styles/card.scss";
import { AuthorityContext } from "../../App";
//api
import { SiteApi } from "../../api/site";
import { DashboardApi } from "../../api/dashboard"

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";

import { IDashboard, IDashResponse, IDashData } from "types/IDashboard";
import { getDateRange } from "../../utils/Date";

const theme: Theme = {
  name: "card-theme",
  tokens: {
    components: {
      card: {
        backgroundColor: { value: "#fff" },
        borderRadius: { value: "10px" },
        padding: { value: "20px" },
        boxShadow: { value: "#000 0px 2px 6px -2px" },
      },
    },
  },
};

type SiteSelect = {
  value: string;
  label: string;
};

type EquKeyValuePair ={
  key: string;
  value: string;
};


export default function DashboardPage() {
  const { site, settings, company}: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const siteApi = SiteApi();
  const dashboardApi = DashboardApi();
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(true);
  const [siteIdSelected, setSiteIdSelected] = useState(localStorage.getItem("currentSiteId") ?? "0");
  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);
  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "", label: "ダッシュボード" },
  ];
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";

  const [dashboardData, setDashboardData] = useState<IDashData | null>(null);
  const [dateList, setDateList] = useState<string[]>([]);
  const [inSiteEquipments, setInSiteEquipments] = useState<number[]>([]);
  const [inUseEquipments, setInUseEquipments] = useState<number[]>([]);
  const [term1Category1, setTerm1Category1] = useState("");
  const [term1Category2, setTerm1Category2] = useState("");
  const [term1Category3, setTerm1Category3] = useState("");
  const [topAxisLine, setTopAxisLine] = useState<number>();
  const [tickStep, setTickStep] = useState<number>();

  const chartContainer = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart>();
  const chartContainerBar = useRef<HTMLCanvasElement>(null);
  const chartInstanceBar = useRef<Chart>();

  const findValueByKey = (list: EquKeyValuePair[], targetKey: string): string  => {
    const foundItem = list.find(item => item.key === targetKey);
    return foundItem ? foundItem.value : "0";
  }

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteIdSelected(value);
    console.log(value);
  };

  const getMaxValue = (numbers: number[]): number | null=> {
    if(numbers.length === 0) return null;
    return Math.max(...numbers)
  }

  const calculateTopAxisLine =(number: number | null) =>{
    if(number !== null){
      if(number < 50){
        setTopAxisLine(50);
        setTickStep(10);
      }else if(number < 100){
        setTopAxisLine(100);
        setTickStep(20);
      }else if(number < 200){
        setTopAxisLine(200);
        setTickStep(50);
      }else if(number < 300){
        setTopAxisLine(300);
        setTickStep(50);
      }else{
        setTopAxisLine(100*(Math.floor(number/100)+1));
        setTickStep(100);
      }
    };
  };

  const getData = async (site_id: string) => {
    const payload = {
      "site_id": site_id
    };

    setLoader(true);

    try {
      const data:any = await dashboardApi.getDashboard(payload);
      
      if (data?.data?.success) {
        setDashboardData(data?.data?.data);
        setErrorMessage('');
      } else {
        setErrorMessage(data?.error?.message || 'Error: Unsuccessful response');
      }
    } catch (error) {
      setErrorMessage(`Error: `);
    } finally {
      setLoader(false);
    }
  };  

  //api call
  useEffect (()=> {
    getData(`${siteIdSelected}`);
    const dates = getDateRange(3, 3);
    setDateList(dates);
  },[siteIdSelected]);

  //calculate api data
  useEffect(()=>{
    if(dashboardData !== null){
      const dataList: EquKeyValuePair[] = dashboardData?.total_eachCategory_equipments;
      //card's value setting
      const term1Count = Number(findValueByKey(dataList, "11001"))+ Number(findValueByKey(dataList, "11002"))+ Number(findValueByKey(dataList, "11003"))+Number(findValueByKey(dataList, "11004"))+Number(findValueByKey(dataList, "11005"));
      setTerm1Category1(`${term1Count}`);
      setTerm1Category2(findValueByKey(dataList, "12003"));
      setTerm1Category3(findValueByKey(dataList, "12001"));
      //line chart's top axis line value setting
      calculateTopAxisLine(getMaxValue([...dashboardData?.in_site_equipment_count_per_day,...dashboardData?.in_use_equipment_count_per_day]))
      //line chart's value setting
      setInSiteEquipments(dashboardData?.in_site_equipment_count_per_day)
      setInUseEquipments(dashboardData?.in_use_equipment_count_per_day)
    };
  },[dashboardData])

  //chart generate
  useEffect(() => {
    if(inSiteEquipments.length !==0 && inUseEquipments.length !== 0 && topAxisLine){
      // line chart
      if (chartContainer && chartContainer.current) {
        const ctx = chartContainer.current.getContext("2d");

        if (ctx) {
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }

          const data = {
            labels: dateList,
            datasets: [
              {
                label: "",
                data: inSiteEquipments,
                borderColor: "#050505",
                backgroundColor: "rgba(234,234,234,.25)",
                tension: 0.1,
                pointBackgroundColor: "#050505",
                pointBorderColor: "#e4e4e4",
                pointRadius: 6,
                pointBorderWidth: 4,
                cubicInterpolationMode: "monotone",
                fill: true,
              },
              {
                label: "",
                data: inUseEquipments,
                borderColor: "#ababab",
                backgroundColor: "rgba(234,234,234,.25)",
                tension: 0.1,
                pointBackgroundColor: "#ababab",
                pointBorderColor: "#eaeaea",
                pointRadius: 6,
                pointBorderWidth: 4,
                cubicInterpolationMode: "monotone",
                fill: true,
              },
            ],
          } as any;
          chartInstance.current = new Chart(ctx, {
            type: "line",
            data: data,
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  min: 0,
                  max: topAxisLine,
                  ticks: {
                    stepSize: tickStep,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          });
        }
      }

      // bar chart
      if (chartContainerBar && chartContainerBar.current) {
        const ctx = chartContainerBar.current.getContext("2d");
        if (ctx) {
          if (chartInstanceBar.current) {
            chartInstanceBar.current.destroy();
          }

          const labels = [
            "高所作業機械",
            "発電機",
            "コンプレッサー",
            "照明",
            "高所作業台",
            "水中ポンプ",
            "冷暖房・扇風機",
            "荷役・運搬",
            "掘削・整地",
            "フォークリフト",
            "測量・測定",
            "溶接機",
          ];
          const formattedLabels = labels.map((label) => label.split(""));
          const data = {
            labels: formattedLabels,
            datasets: [
              {
                label: "",
                data: [95, 60, 50, 90, 25, 25, 70, 65, 52, 95, 85, 15],
                borderColor: "#E0E0E0",
                backgroundColor: "#E0E0E0",
                tension: 0.1,
                pointBackgroundColor: "#050505",
                pointBorderColor: "#e4e4e4",
                pointRadius: 6,
                pointBorderWidth: 4,
                cubicInterpolationMode: "monotone",
                fill: true,
              },
            ],
          } as any;
          chartInstanceBar.current = new Chart(ctx, {
            type: "bar",
            data: data,
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 20,
                  },
                  grid: {
                    display: false,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          });
        }
      }

          }
  }, [inSiteEquipments, inUseEquipments, topAxisLine]);

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );
          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            {/*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/}
            setSiteIdSelected(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteIdSelected(mappedSiteList[0].value);
            setSiteSelections(mappedSiteList);
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });

      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId  && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        setSiteIdSelected(initialSelectedSiteId);
        setSiteSelections(mappedSiteList);        
      } else {
        setSiteIdSelected(mappedSiteList[0].value);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteSelections(mappedSiteList);
      }
    }
  }, [site, company]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
      showSelectField
      selectFieldOptions={siteSelections}
      initialValue={siteIdSelected}
      changeEvent={setSiteForFilter}
    >
      <ThemeProvider theme={theme} colorMode="light">
        <Flex marginTop={25} gap={30} marginBottom={40} className="top-page-cards">
          <Card className="card-dashboard" textAlign="center" padding="20px 0">
            <Text
              as="span"
              marginBottom="10px"
              color="rgba(0,0,0,.6)"
              display="block"
              fontWeight="500"
              fontSize="18px"
              lineHeight="21.6px"
              className="card-text"
            >
              高所作業車
            </Text>
            <Text
              as="span"
              color="rgba(0,0,0,.87)"
              display="block"
              fontWeight="700"
              fontSize="36px"
              lineHeight="36px"
              className="card-text"
            >
              {term1Category1}
            </Text>
          </Card>
          <Card className="card-dashboard" textAlign="center" padding="20px 0">
            <Text
              as="span"
              marginBottom="10px"
              color="rgba(0,0,0,.6)"
              display="block"
              fontWeight="500"
              fontSize="18px"
              lineHeight="21.6px"
              className="card-text"
            >
              作業台
            </Text>
            <Text
              as="span"
              color="rgba(0,0,0,.87)"
              display="block"
              fontWeight="700"
              fontSize="36px"
              lineHeight="36px"
              className="card-text"
            >
              {term1Category2}
            </Text>
          </Card>
          <Card className="card-dashboard" textAlign="center" padding="20px 0">

            <Text
              as="span"
              marginBottom="10px"
              color="rgba(0,0,0,.6)"
              display="block"
              fontWeight="500"
              fontSize="18px"
              lineHeight="21.6px"
              className="card-text"
            >
              台車
            </Text>
            <Text
              as="span"
              color="rgba(0,0,0,.87)"
              display="block"
              fontWeight="700"
              fontSize="36px"
              lineHeight="36px"
              className="card-text"
            >
              {term1Category3}
            </Text>
          </Card>
        </Flex>
        <Flex
          marginTop={25}
          gap={30}
          marginBottom={40}
          className="unflex-on-print top-page-graphs"
        >
          <Card
            as="div"
            className="card-dashboard"
            textAlign="center"
            padding="30px 20px"
            width="65%"
          >
            <Heading
              fontSize="24px"
              lineHeight="28px"
              textAlign="left"
              fontWeight={700}
              marginBottom="20px"
            >
              高所作業車推移
            </Heading>
            <canvas ref={chartContainer} />
          </Card>
          <Card
            as="div"
            className="card-dashboard"
            padding="30px 20px"
            flex={1}
          >
            <Heading
              fontSize="24px"
              lineHeight="28px"
              textAlign="left"
              fontWeight={700}
              marginBottom="20px"
            >
              <Flex justifyContent={'space-between'} gap={'5px'} width={'100%'}>
                <View as="span">使用業者</View>
                <View as="span" fontSize={'16px'}>※高所作業車のみ</View>
              </Flex>
            </Heading>
            <Flex alignItems="flex-end" gap="10px">
              <Text
                as="span"
                marginBottom="10px"
                color="rgba(0,0,0,.87)"
                display="block"
                fontWeight="500"
                fontSize="16px"
                lineHeight="24px"
                className="card-text"
              >
                使用業者総数
              </Text>
              <Text
                as="span"
                marginBottom="10px"
                color="rgba(0,0,0,.87)"
                display="block"
                fontSize="36px"
                lineHeight="36px"
                fontWeight="700"
              >
                {dashboardData?.use_company.length}
              </Text>
            </Flex>
            <View as="div">
              <Flex
                className="card-list-item"
                justifyContent="space-between"
                margin="15px 0 8px"
                paddingBottom="15px"
              >
                <Text
                  as="span"
                  color="rgba(0,0,0,.87)"
                  display="block"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="16px"
                  className="card-text"
                >
                  会社名
                </Text>
                <Text
                  as="span"
                  color="rgba(0,0,0,.87)"
                  display="block"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="16px"
                  className="card-text"
                >
                  使用中台数
                </Text>
              </Flex>
              {dashboardData && dashboardData.use_company.length > 0 ? (
              <ul>
                {dashboardData.use_company.map((company, index) => (
                  <li key={index}>
                    <Flex
                      className="card-list-item"
                      justifyContent="space-between"
                      margin="8px 0"
                      paddingBottom="8px"
                    >
                      <Text
                        as="span"
                        color="rgba(0,0,0,.87)"
                        display="block"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="20px"
                        className="card-text"
                      >
                      {company.company_name}
                      </Text>
                      <Text
                        as="span"
                        color="rgba(0,0,0,.87)"
                        display="block"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="20px"
                        className="card-text"
                      >
                      {company.equipment_count}
                      </Text>
                    </Flex>
                  </li>
                ))}
              </ul>
                ) : (
                  <div>使用中の機材はありません</div>
                )}
            </View>
          </Card>
        </Flex>
        {/* <Flex
          marginTop={25}
          gap={30}
          marginBottom={40}
          className="unflex-on-print"
        >
          <Card
            as="div"
            className="card-dashboard"
            textAlign="center"
            padding="30px 20px"
            width="74%"
          >
            <Heading
              fontSize="24px"
              lineHeight="28px"
              textAlign="left"
              fontWeight={700}
              marginBottom="20px"
            >
              カテゴリー別レンタル中機材数
            </Heading>
            <canvas ref={chartContainerBar} />
          </Card>

          <View as="div" flex={1}>
            <Card as="div" className="card-dashboard" padding="30px 20px">
              <Heading
                fontSize="24px"
                lineHeight="28px"
                textAlign="left"
                fontWeight={700}
                marginBottom="20px"
              >
                操作にお困りの方へ
              </Heading>
              <Flex justifyContent="center">
                <CustomButton
                  {...defaultButtonProps}
                  text="操作マニュアル"
                  iconName="arrow"
                  iconPosition="right"
                  size="large"
                  click={() => handleOperationManual()}
                />
              </Flex>
            </Card>
          </View>
        </Flex> */}
      </ThemeProvider>
    </PageContent>
  );
}
