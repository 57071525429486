import React from "react";

type Props = {
  color: string,
  opacity: number,
  size?: string | number,
};

export default function PenIcon(props : Props) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.248535 12.3761V15.5011H4.31104L16.2927 6.28444L12.2302 3.15944L0.248535 12.3761ZM19.4344 3.86777C19.8569 3.54277 19.8569 3.01777 19.4344 2.69277L16.8994 0.742773C16.4769 0.417773 15.7944 0.417773 15.3719 0.742773L13.3894 2.26777L17.4519 5.39277L19.4344 3.86777Z"
        fill={props.color}
        fillOpacity={props.opacity}
      />
    </svg>
  )
}
