import React, { useEffect, useState } from "react";
// amplify ui components
import { Button, Text } from "@aws-amplify/ui-react";

// types
import { IButtons } from "../types/IButtons";

// icons
import ButtonIcons from "./icons/button-icons";
import getDeviceType from "../utils/getDeviceType";

export default function CustomButton(props: IButtons) {
  const isMobile = getDeviceType() === 'mobile'
  const [textPadding, setTextPadding]: any = useState("");
  const [buttonType, setButtonType]: any = useState("");
  const [buttonTextColor, setButtonTextColor]: any = useState("");
  const [buttonSize, setButtonSize]: any = useState("");
  const [buttonWidth, setButtonWidth]: any = useState("");
  const [buttonShadow, setButtonShadow]: any = useState("");
  const [buttonBorder, setButtonBorder]: any = useState("");
  const [buttonBorderRadius, setButtonBorderRadius]: any = useState("");
  const paddingLeft: React.CSSProperties = { paddingLeft: "10px" };
  const paddingRight: React.CSSProperties = { paddingRight: "10px" };
  const noPadding: React.CSSProperties = { padding: "0" };

  useEffect(() => {
    const btnPadding = () => {
      switch (props.iconPosition) {
        case "left":
          return paddingLeft;
        case "right":
          return paddingRight;
        default:
          return 0;
      }
    };

    const colorTheme = () => {
      switch (props.type) {
        case "primary":
          return "#4365DE";
        case "secondary":
          return "#9C27B0";
        case "warning":
          return "#EF6C00";
        case "lightblue":
          return "#F3F6FC";
        case "gray":
          return "#929292";
        case "light-gray":
          return "#E0E0E0";
        case "darkblue":
          return "#393A94";
        case "icon":
          return "";
        case "bordered-white":
          return "#ffffff";
        case "bordered-transparent":
          return "#fff";
        case "bordered-gray":
          return "#fff";
        case "bordered-black":
          return "#fff";
        case "transparent":
            return "transparent";
        default:
          return "#fff";
      }
    };

    const fontColor = () => {
      switch (props.type) {
        case "primary":
          return "#ffffff";
        case "secondary":
          return "#ffffff";
        case "warning":
          return "#ffffff";
        case "darkblue":
          return "#ffffff";
        case "gray":
          return "#000";
        case "bordered-white":
          return "#2196F3";
        case "bordered-transparent":
          return "#4365DE";
        case "bordered-black":
          return "#0D1A26";
        case "transparent":
          return "#4365DE";
        default:
          return "#000000";
      }
    };

    const buttonSize = () => {
      switch (props.size) {
        case "small":
          return "3px 7px";
        case "xs":
          return "8px 16px";
        case "medium":
          return "15px 16px";
        case "large":
          return isMobile ? "9px 14px" : "9px 22px";
        default:
          return "";
      }
    };

    const buttonWidth = () => {
      switch (props.width) {
        case "full":
          return "100%";
        case "large":
          return "144px";
        case "medium":
          return "355px";
        case "small":
          return "124px";
        case "xsmall":
          return "74px";
        default:
          return "";
      }
    };

    const buttonShadow = () => {
      switch (props.type) {
        case "icon":
          return "0";
        case "primary":
          return "4px 8px 24px 0px #246BFD40";
        case "large":
          return "0";
        case "secondary":
          return "4px 8px 24px 0px #F3F6FC";
        case "bordered-transparent":
          return "0";
        default:
          return "0";
      }
    };

    const buttonBorder = () => {
      switch (props.type) {
        case "bordered-white":
          return "1px solid #2196F3";
        case "bordered-transparent":
          return "1px solid #fff";
        case "bordered-gray":
          return "1px solid #AEB3B7";
        case "bordered-black":
          return "1px solid #0D1A26";
        default:
          return "0";
      }
    };

    const buttonBorderRadius = () => {
      switch (props.borderRadius) {
        case "large":
          return "50px";
        case "small":
          return "4px";
        default:
          return "10px";
      }
    };

    setButtonShadow(buttonShadow());
    setTextPadding(btnPadding);
    setButtonType(colorTheme());
    setButtonTextColor(fontColor());
    setButtonSize(buttonSize());
    setButtonWidth(buttonWidth());
    setButtonBorder(buttonBorder);
    setButtonBorderRadius(buttonBorderRadius);

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* @ts-ignore */}
      <Button
        size={props.size}
        width={buttonWidth}
        colorTheme={props.type}
        iconname={props.iconName}
        isDisabled={props.disabled}
        boxShadow={buttonShadow}
        backgroundColor={buttonType}
        color={buttonTextColor}
        padding={buttonSize}
        border={buttonBorder}
        borderRadius={buttonBorderRadius}
        onClick={props.click}
        className={`${props.className} custom-button`}
      >
        {props.iconPosition === "left" ? (
          <ButtonIcons icon={props.iconName} />
        ) : null}
        {props.text !== "" ? (
          <Text
            as="span"
            display="inline-block"
            color="inherit"
            style={props.iconName !== "" ? textPadding : noPadding}
            marginBottom={'0px'}
          >
            {props.text}
          </Text>
        ) : null}
        {props.iconPosition === "right" ? (
          <ButtonIcons icon={props.iconName} />
        ) : null}
      </Button>
    </>
  );
}
