import React, { useContext, useEffect, useState } from "react";
import PageContent from "../../../components/pageContent";
import "../../../styles/calendar.scss";

import CustomCalendar from "./component/calendar-week";
import { AuthorityContext } from "../../../App";

import { SiteApi } from "../../../api/site";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: ``, label: "機材管理" },
  { href: "", label: "一括登録" },
];

type SiteSelect = {
  value: string;
  label: string;
};

export default function Index() {
  const siteApi = SiteApi();
  const { company, settings, site }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";
  const [siteIdSelected, setSiteIdSelected] = useState(initialSelectedSiteId);
  const setSiteForFilter = async (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteIdSelected(value);
  };

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );
          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            {/*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/}
            setSiteSelections(mappedSiteList);
            setSiteIdSelected(initialSelectedSiteId);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteIdSelected(mappedSiteList[0].value);
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });

      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        {/*setSiteSelections([
          ...mappedSiteList?.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList?.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/}
        setSiteSelections(mappedSiteList);
        setSiteIdSelected(initialSelectedSiteId);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteIdSelected(mappedSiteList[0].value);
      }
    }
  }, [site, company]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title="一括登録"
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue={siteIdSelected}
      changeEvent={setSiteForFilter}
    >
      <CustomCalendar siteIdSelected={siteIdSelected} />
    </PageContent>
  );
}
