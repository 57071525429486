// Amplify Auth
import { signOut } from "aws-amplify/auth";

// const API_BASE_URL = "https://jsonplaceholder.typicode.com";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const headers = {
  "Content-type": "application/json; charset=UTF-8",
};

export default function FetchHeader(
  url: string,
  method: string,
  body?: {} | []
) {
  const user = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_ID}.LastAuthUser`
  );
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_ID}.${user}.idToken`
  );
  
  async function handleSignOut() {
    try {
      await signOut();
      localStorage.setItem("username", String(null));
      window.location.href = '/login'
    } catch (error) {
      return error;
    }
  }

  if (method === "GET") {
    return fetch(API_BASE_URL + url, {
      method: method,
      headers: {
        ...headers,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if(response.status === 401) handleSignOut()
        return response.json().then((data) => ({
          data: data,
          status: response.status,
        }));
      })
      .catch((err) => {
        return err;
      });
  } else {
    return fetch(API_BASE_URL + url, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if(response.status === 401) handleSignOut()
        return response.json().then((data) => ({
          data: data,
          status: response.status,
        }));
      })
      .catch((err) => {
        return err;
      });
  }
}
