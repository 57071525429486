import FetchHeader from "../../utils/fetchHeader";

interface ForgotPassword {
  email: string
}

interface ConfirmForgotPassword {
  username: string,
  code: string,
  password: string,
}

export const PasswordApi = () => {
  const doForgotPassword = async (payload: ForgotPassword) => {
    const response = await FetchHeader(`/auth/user/forgotpassword`, "POST", payload);
    return response;
  };

  const doConfirmPassword = async (payload: ConfirmForgotPassword) => {
    const response = await FetchHeader(`/auth/user/confirmforgotpassword`, "POST", payload);
    return response;
  };

  return {
    doForgotPassword,
    doConfirmPassword
  };
};
