import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  Text,
  Loader
} from "@aws-amplify/ui-react";

import { IEqDelivery } from "../../types/IEq";
// api
import { EqApi } from "../../api/eq";
import { UserApi } from "../../api/user";
// utils
import formatDate from "../../utils/formatDate";
import convertStatus from "../../utils/geStatus";
import getBadgeColor from "../../utils/getBadgeColor";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function EqResultReturnPage() {
  const api = EqApi();
  const userApi = UserApi();
  // const pageTitle = localStorage.getItem('currentPage') ?? ''
  const navigate = useNavigate();
  const { equipmentId } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [eqName, setEqName] = useState('')
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const userName = localStorage.getItem("username") || "null";
  const [userDisplayName, setUserDisplayName] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasFormError, setHasFormError] = useState(false)
  const [hasSchedule, setHasSchedule] = useState(false)
  const defaultDate = new Date()
  // const [hasUser, setHasUser] = useState(false)
  const [payload, setPayload] = useState<IEqDelivery>({
    schedule: '',
    schedule_time: '',
    user_id: '',
    equipment_common_name: '',
    equipment_name: ''
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/eq/list', label: '機材一覧' },
    { href: '', label: '機材詳細' },
    { href: '', label: '返却登録' },
  ]

  // const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
  //     setPayload({
  //        ...payload,
  //         acceptance_date: value,
  //         acceptance_time: value,
  //         acceptance_person: value
  //     });
  //   }
  // }

  // const formatDate = (p0: null, dateString: string): string => {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  const openModal = () => {
    setIsModalOpen(true)
  };

  const timeToSeconds = (time: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: NumberConstructor): [any, any, any]; new(): any; }; }; }) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  
  const compareTimes = (time1: any, time2: any) => {
    return timeToSeconds(time1) <= timeToSeconds(time2);
  }

  const handleChange = (item:any, value: any) => {
    // if (item === 'schedule_time') {
    //   const isSelectedGreaterThanCurrent = compareTimes(payload.schedule_time, value);
    //   if (isSelectedGreaterThanCurrent) return
    // }
    setPayload({ ...payload, [item]: value })
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const handleRemoveBodyHidden = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  }

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    const createPayload = {
      result_return_user_id: Number(userName),
      result_return_schedule: payload.schedule,
      result_return_schedule_time: payload.schedule_time,
    };

    try {
      // @ts-ignore
      const { data } = await api.updateEq(equipmentId, createPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        // navigate(`/eq/${ equipmentId }`) 
        openModal()
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }

      // @ts-ignore
      handleRemoveBodyHidden()
      // document.querySelector(".main-body").style.overflow = 'auto';
    } catch(err) {
      console.log(err)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleCancel = () => {
    handleRemoveBodyHidden()
    setIsModalOpen(false);
    navigate(`/eq/schedule/${equipmentId}`)
  };

  const getDetail = async () => {
    setLoader(true);
    // @ts-ignore
    const { data } = await api.getDetail(equipmentId);
    const userDetail = await userApi.getDetail(Number(userName));
    if(userDetail?.data?.success){
      setUserDisplayName(userDetail?.data?.data?.user_name?? '');
    }
    if (data?.success) {
      setLoader(false);
      setFetchError(false);
      const convert = defaultDate.toLocaleDateString('ja-JP')
      const format = convert?.split('/')
      const formattedTime = defaultDate.toLocaleTimeString('en-US');

      const checkMonth = format[1].length === 1 ? '0' + format[1] : format[1]
      const checkDate = format[2].length === 1 ? '0' + format[2] : format[2]
      setPayload({
        schedule: formatDate(null, data?.data?.result_return_schedule) || format[0] + '-' + checkMonth + '-' + checkDate,
        schedule_time: formatDate('time', data?.data?.result_return_schedule) || formatDate('time', format[0] + '-' + checkMonth + '-' + checkDate + ' ' + formattedTime),
        user_id: data?.data?.result_return_user_id,
        equipment_common_name: data?.data?.equipment_common_name,
        equipment_name: data?.data?.equipment_name
      });
      setCategory(data?.data?.equipment_category1_name)
      // setStatus(data?.data?.status)
      setEqName(data?.data?.equipment_name)
      setHasSchedule(data?.data?.result_return_schedule === null || data?.data?.result_return_schedule === '' ? false : true)
      // setHasUser(data?.data?.result_delivery_user_id === null || data?.data?.result_delivery_user_id === '' ? false : true)
    } else {
      setFetchError(true);
    }
  };

  const getStatus = async () => {
    const { data } = await api.getEqStatus(equipmentId ?? '')
    try {
      if(data?.success) {
        const stat = convertStatus(data?.data?.status)
        setStatus(stat)
      } else {
        const stat = convertStatus('in_use')
        setStatus(stat)
      }
    } catch(err) {
      return err
    }   
  }

  useEffect(() => {
    getDetail()
    getStatus()
  // eslint-disable-next-line
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={payload.equipment_common_name || payload.equipment_name}
    >
      <View className="main-content">
        <View
          fontSize={14}
          fontWeight={500}
          color={'#EF6C00'}
          marginBottom={30}
        >
          ※この操作はレンタルを終了する際に行う操作です。
        </View>
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { errorMessage !== '' || hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          {!loader ? (
            <>
              <Flex
                justifyContent={"space-between"}
                alignItems={"end"}
              >
                <View>
                  <Flex wrap="wrap">
                    <View
                      border={`1px solid #757575`}
                      padding={"5px 20px"}
                      width={"fit"}
                      fontWeight={500}
                      fontSize={14}
                      lineHeight="20px"
                      letterSpacing="0.17px"
                    >
                      { category }
                    </View>
                    <View
                      fontWeight={700}
                      fontSize={24}
                      lineHeight="29px"
                    >
                      { eqName }
                    </View>
                    <View
                      fontWeight={700}
                      fontSize={24}
                      lineHeight="29px"
                    >
                      <Badge
                        backgroundColor={getBadgeColor(status).bg}
                        color={getBadgeColor(status).color}
                      >
                        { status }
                      </Badge>
                    </View>
                  </Flex>
                </View>
              </Flex>
              <Divider orientation="horizontal"  marginBottom={20} marginTop={15} />
              <Flex>
                <TextField
                  label="返却日"
                  width={"50%"}
                  type="date"
                  value={payload.schedule}
                  //onChange={(event) => handleChange('schedule', event.target.value)}
                  disabled
                  // max={payload.schedule}
                />
                <TextField
                  label="返却時間"
                  width={"50%"}
                  type="time"
                  disabled
                  value={payload.schedule_time}
                  step={1}
                  //onChange={(event) => handleChange('schedule_time', event.target.value)}
                />
              </Flex>
              <TextField
                label="返却担当者"
                //value={payload.user_id}
                value={userDisplayName}
                disabled
                //onChange={(event) => handleChange('user_id', event.target.value)}
              >
              </TextField>
              <Flex
                  justifyContent="flex-end"
                  marginTop="1rem"
                  alignItems={`center`}
                  direction={{
                    base: 'column',
                    medium: 'row'
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text='返却登録'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  iconName={ disableButton ? 'loader' : '' }
                  iconPosition='right'
                  disabled={ disableButton }
                  click={() => handleSubmit()}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text='キャンセル'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
                <CommonModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  icon="icon"
                  textHeading="機材返却を確定しました"
                  textsmall="機材の使用状況を「返却済」に更新しました。"
                  periodUse={`返却日時: ${ payload.schedule } ${ payload.schedule_time }`}
                  //user={`返却担当者: ${ payload.user_id }`}
                  user={`受入担当者: ${ userDisplayName }`}
                >
                  <View margin={`0 auto`}>
                    <CustomButton
                      { ...defaultButtonProps }
                      text='機材情報を確認'
                      size='medium'
                      width='medium'
                      borderRadius='large'
                      click={() => {
                        handleRemoveBodyHidden()
                        navigate(`/eq/${equipmentId}`)
                      }}
                    />
                  </View>
                </CommonModal>

                {/* cancel modal */}
                <CommonModal
                  isOpen={isCancelModal}
                  textHeading="入力内容を破棄してスケジュールに
                  戻りますか？"
                >
                  <Flex justifyContent="center" margin={`0 auto`}>
                    <CustomButton
                      { ...defaultButtonProps }
                      text='編集へ戻る'
                      type='bordered-transparent'
                      click={showCancelModal}
                    />
                    <CustomButton
                      { ...defaultButtonProps }
                      text='破棄'
                      size='xs'
                      width='small'
                      click={handleCancel}
                    />
                  </Flex>
                </CommonModal>
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
      </View>
    </PageContent>
  );
}
