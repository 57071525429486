export default function checkFeature(feature: string) {
  switch (feature) {
    case 'authority_groups':
      return '権限グループ'
    case 'companies':
      return '会社'
    case 'company_categories':
      return '会社カテゴリー'
    case 'csvlists':
      return 'CSVリスト'
    case 'equipment_categories':
      return '機器カテゴリー'
    case 'equipment_images':
      return '設備イメージ'
    case 'equipment_inventories':
      return '機器の在庫'
    case 'equipment_manage_delivery_schedule':
      return '機器管理 配送 スケジュール'
    case 'equipment_manage_result_datetime':
      return '機器管理結果日時'
    case 'equipments':
      return '設備'
    case 'equipments_categories_identification':
      return '機器カテゴリの識別'
    case 'equipments_character_identification':
      return '装備キャラクター識別'
    case 'equipments_mst':
      return '設備 mst'
    case 'equipments_name_identification':
      return '機器名の識別'
    case 'site_affiliated_companies':
      return 'サイトアフィリエイト'
    case 'site_construction_locations':
      return '現場建設場所'
    case 'site_inventory_locations':
      return 'サイトの在庫場所'
    case 'site_other_companies':
      return '他社サイト'
    case 'site_prime_companies':
      return 'サイトプライム企業'
    case 'site_rental_companies':
      return 'サイトレンタル会社'
    case 'sites':
      return 'サイト'
    case 'user_affiliated_authority_groups':
      return 'ユーザー関連の権限グループ'
    case 'user_affiliated_companies':
      return 'ユーザー関連会社'
    case 'user_affiliated_sites':
      return 'ユーザーアフィリエイトサイト'
    case 'user_notifications':
      return 'ユーザーへの通知'
    case 'users':
      return 'ユーザー'
  }
}