import React from "react";
//! In preparation for integration,
//! some routes have been temporarily disabled (commented out)
//! and will be refactored for production use after integration is complete.
import { Route, Navigate } from "react-router-dom";
// import TopPage from "./pages/top/top";
import TopPage from "./pages/top";
import LoginPage from "./pages/login";
import AgreementPage from "./pages/agreement";
import UserListPage from "./pages/user/list";
import UserCreatePage from "./pages/user/create";
import UserEditPage from "./pages/user/edit";
import AuthListPage from "./pages/auth/list";
import AuthCreatePage from "./pages/auth/create";
import AuthEditPage from "./pages/auth/edit";
import AuthEditPermissions from "./pages/auth/permissions";
import EquipmentPage from "./pages/eq/schedule/index";
import EqListPage from "./pages/eq/list";
import EqCSVUploadPage from "./pages/eq/csv-upload";
import EqCreatePage from "./pages/eq/create";
import EqEditPage from "./pages/eq/edit";
//! This is a duplicate routes
import EqSchedulePage from "./pages/eq/schedule/index";
import EqDeliverySchedulePage from "./pages/eq/delivery/schedule";
import EqDeliveryRefactorPage from "./pages/eq/delivery/index-refactor";
import EqDeliveryPage from "./pages/eq/delivery";
import EqRegistInventoryPage from "./pages/eq/regist-inventory";
import EqScheduleIndexRefactorPage from "./pages/eq/schedule/id-refactor";
import EqScheduleIndexPage from "./pages/eq/schedule/id";
import EqUseStartPage from "./pages/eq/use/start";
import EqUseEndPage from "./pages/eq/use/end";
import CategoriesListPage from "./pages/categories/list";
import CompanyListPage from "./pages/company/list";
import CompanyEditPage from "./pages/company/edit";
import CompanyCreatePage from "./pages/company/create";
import SiteCreatePage from "./pages/site/create";
import SiteEditPage from "./pages/site/edit";
import SiteListPage from "./pages/site/list";
import SiteAssignPage from "./pages/site/assign/user";
import SiteAssignListPage from "./pages/site/assign";
//! Delete this After Integration
import EqScheduleRegisterPage from "./pages/eq/schedule/regist";
import EqScheduleBulkRegisterPage from "./pages/eq/schedule/regist-bulk";
import EqScheduleRegisterRefactorPage from "./pages/eq/schedule/register-refactor";
import EqScheduleEditPage from "./pages/eq/schedule/edit";
import EqUseRegisterPage from "./pages/eq/use-regist";
//! Delete this After Integration
import EqResultReturnPage from "./pages/eq/result-return";
import EqResultReturnRefactorPage from "./pages/eq/result-return-refactor";
import EqScheduleOnDatePage from "./pages/eq/schedule/ondate";
import TermsOfUsePage from "./pages/terms-of-use";
import PrivacyPolicyPage from "./pages/privacy-policy";
import PasswordResetPage from "./pages/password/reset";
import QrCodePage from "./pages/qrcode";
import QrCodeAlertPage from "./pages/qrcode/alert";
import QrCodeScannerPage from "./pages/qrcode/scanner";
import DashboardPage from "./pages/dashboard";
import LogsPage from "./pages/logs";
import NoticePage from "./pages/notice";
import AccountPage from "./pages/account";
import AccountEditEmailPage from "./pages/account/edit-email";
import HelpPage from "./pages/help";
import UserPage from "./pages/user";
//* New UI Route
// import EqPage from "./pages/eq/index-refactor";
import EqPage from "./pages/eq";
import CompanyPage from "./pages/company";
import PasswordForgetPage from "./pages/password/forget";
import LoginIntroduction from "./pages/login/introduction";
import UpdateAccount from "./pages/login/update-account";
import SitePage from "./pages/site";
// New routes 8/22/24
import InventoryBulk from "./pages/eq/schedule/invetory-bulk";
import ReturnBulk from "./pages/eq/schedule/return-bulk";

import SitemapPage from "./pages/list";
import PrivateRoute from "./privateRoute";
import GetApi from "./pages/test-page/get-api";
import PageNotFound from "./pages/404";
import PageForbidden from "./pages/403";

const username = localStorage.getItem("username");

export const routes = (
  <>
    <Route
      path="/"
      pageId="top-01"
      name="ホーム"
      nameEn="Top Page"
      element={
        <PrivateRoute username={username} path="/v2" pageId="home">
          <TopPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/login"
      pageId="login-01"
      name="ログイン"
      nameEn="Login"
      ui="Done"
      apiIntegration="Done"
      element={<LoginPage />}
    />
    <Route
      path="/agreement"
      pageId="login-01-04"
      name="プラポリ/利用確認画面"
      nameEn="Policy/Usage Confirmation Screen"
      element={
        // <PrivateRoute username={username}>
        <AgreementPage />
        // </PrivateRoute>
      }
    />
    <Route
      path="/user/list"
      code="users_service"
      pageId="login-02"
      name="ユーザ管理"
      nameEn="User List Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="users_service">
          <UserListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/user/create"
      code="user_regist"
      pageId="login-04-01"
      name="新規ユーザー登録"
      nameEn="New User Information Entry Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="user_regist">
          <UserCreatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/user/:userId"
      pageId="login-03"
      name="A山 A太郎"
      nameEn="User Details Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="user-index">
          <UserPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/user/edit/:userId"
      code="user_edit"
      pageId="login-06"
      name="A山 A太郎"
      nameEn="User Information Edit Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="user_edit">
          <UserEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/auth/list"
      code="auth_service"
      pageId="auth-01"
      name="権限グループ一覧"
      nameEn="Authorization Grp List Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="auth_service">
          <AuthListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/auth/create"
      pageId="auth-02"
      name="新規権限グループ登録"
      nameEn="New Authorization Grp Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="auth-create">
          <AuthCreatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/auth/edit/:groupId"
      pageId="auth-02-02"
      name="権限グループ編集"
      nameEn="Authorization Grp name edit screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="auth-edit">
          <AuthEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/auth/settings"
      code="edit_permission_group"
      pageId="auth-05"
      name="権限編集"
      nameEn="Edit Permissions"
      element={
        <PrivateRoute username={username} pageId="edit_permission_group">
          <AuthEditPermissions />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/list"
      pageId="ce-manage-01"
      name="機材一覧"
      nameEn="Equipment list screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-list">
          <EqListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/csv/upload"
      code="equipment_csv_regist"
      pageId="ce-manage-02-01"
      name="CSV登録・更新"
      nameEn="Equipment registration screen (csv)"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="equipment_csv_regist">
          <EqCSVUploadPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/create"
      code="equipment_regist"
      pageId="ce-manage-02-02"
      name="新規機材登録"
      nameEn="Equipment Registration (Web Entry)"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="equipment_regist">
          <EqCreatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/:equipmentId"
      pageId="ce-manage-05"
      name="機材スケジュール"
      nameEn="Equipment Details Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-index">
          <EqPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/schedule"
      pageId="use-04-01"
      name="機材スケジュール"
      nameEn="Equipment Schedule"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="schedule">
          <EquipmentPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/edit/:equipmentId"
      code="equipment_edit"
      pageId="ce-manage-06"
      name="油圧式ショベルカー"
      nameEn="Edit Equipment Information Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="equipment_edit">
          <EqEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/categories/list"
      pageId="ce-manage-09"
      name="機材カテゴリー"
      nameEn="List by Category Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="category-list">
          <CategoriesListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/company/create"
      pageId="org-manage-01"
      name="新規法人登録"
      nameEn="New Company Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="company-create">
          <CompanyCreatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/company/edit/:companyId"
      pageId="org-manage-04"
      name="法人情報編集画面"
      nameEn="Edit Company Information Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="company-edit">
          <CompanyEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/company/list"
      code="companies_service"
      pageId="org-manage-10"
      name="法人一覧"
      nameEn="Company List Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="companies_service">
          <CompanyListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/company/:companyId"
      pageId="org-manage-11"
      name="油圧式ショベルカー"
      nameEn="Company Details Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="company-index">
          <CompanyPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/list"
      code="sites_service"
      pageId="on-site-manage-01"
      name="現場一覧"
      nameEn="Site List Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="sites_service">
          <SiteListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/create"
      pageId="on-site-manage-02"
      name="新規現場登録"
      nameEn="New Site Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="site-create">
          <SiteCreatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/:siteId"
      pageId="on-site-manage-05"
      name="〇〇区〇〇プロジェクト"
      nameEn="Site Details Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="site-index">
          <SitePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/edit/:siteId"
      pageId="on-site-manage-06-01"
      name="現場情報編集画面"
      nameEn="Site Information Edit Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="site-edit">
          <SiteEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/assign/"
      pageId="on-site-manage-12"
      name="〇〇区〇〇プロジェクト"
      nameEn="List of Assigned Users"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="site-assign">
          <SiteAssignListPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/site/assign/user/:siteId"
      pageId="on-site-manage-09"
      name="〇〇区〇〇プロジェクト"
      nameEn="Assigned User Selection Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="site-assign-user">
          <SiteAssignPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/regist-refactor/:equipmentId"
      pageId="use-01"
      name="油圧式ショベルカー"
      nameEn="Planned Equipment Usage Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-schedule-regist">
          <EqScheduleRegisterRefactorPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/regist/:equipmentId"
      pageId="use-01"
      name="油圧式ショベルカー"
      nameEn="Planned Equipment Usage Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-schedule-regist">
          <EqScheduleRegisterPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/regist-bulk"
      pageId="use-01"
      name="使用予定登録ページ"
      nameEn="Planned Equipment Usage Bulk Registration Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-schedule-regist">
          <EqScheduleBulkRegisterPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/:equipmentId"
      pageId="use-04"
      name="機材利用予定一覧画面(個別機材)"
      nameEn="Planned Equipment Usage List (Individual Equipment)"
      element={
        <PrivateRoute username={username} pageId="eq-schedule">
          <EqScheduleIndexPage />
        </PrivateRoute>
      }
    />
    {/* Refactor Calendar */}
    <Route
      path="/eq/schedule-refactor/:equipmentId"
      pageId="use-04"
      name="機材利用予定一覧画面(個別機材)"
      nameEn="Planned Equipment Usage List (Individual Equipment)"
      element={
        <PrivateRoute username={username} pageId="eq-schedule">
          <EqScheduleIndexRefactorPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/use/start/:equipmentId"
      code="start_use_regist"
      pageId="use-04"
      name="油圧式ショベルカー"
      nameEn="Eq Use Start"
      element={
        <PrivateRoute username={username} pageId="start_use_regist">
          <EqUseStartPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/use/end/:equipmentId"
      code="end_use_regist"
      pageId="use-04"
      name="油圧式ショベルカー"
      nameEn="Eq Use End"
      element={
        <PrivateRoute username={username} pageId="end_use_regist">
          <EqUseEndPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule"
      code="equ_service"
      pageId="use-04-01"
      name="機材利用予定一覧画面(機材一覧)"
      nameEn="Scheduled Equipment Usage List (List of Equipment)"
      element={
        <PrivateRoute username={username} pageId="equ_service">
          <EqSchedulePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/edit/:equipmentId"
      code="person_in_charge_who_is_scheduled_to_use"
      pageId="use-05"
      name="油圧式ショベルカー"
      nameEn="Edit Scheduled Use of Equipment"
      ui="Done"
      element={
        <PrivateRoute
          username={username}
          pageId="person_in_charge_who_is_scheduled_to_use"
        >
          <EqScheduleEditPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/use/regist/:equipmentId"
      pageId="Sp-use-10"
      name="油圧式ショベルカー"
      nameEn="Register to start using a particular piece of equipment"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-use-regist">
          <EqUseRegisterPage />
        </PrivateRoute>
      }
    />
    {/* *! A refactor route for 'Equipment Delivery' */}
    <Route
      path="/eq/delivery-refactor/:equipmentId"
      code="delivery_schedule_regist"
      pageId="use-01"
      name="油圧式ショベルカー"
      nameEn="Equipment Delivery Schedule"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="delivery_schedule_regist">
          <EqDeliveryRefactorPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/delivery-schedule/:equipmentId"
      code="delivery_schedule_regist"
      pageId="use-01"
      name="油圧式ショベルカー"
      nameEn="Equipment Delivery Schedule"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="delivery_schedule_regist">
          <EqDeliverySchedulePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/delivery/:equipmentId"
      pageId="ce-manage-11"
      name="油圧式ショベルカー"
      nameEn="Equipment Delivery"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="accept_regist">
          <EqDeliveryPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/regist/inventory/:equipmentId"
      code="inventory_regist"
      pageId="stock-ctrl01"
      name="油圧式ショベルカー"
      nameEn="Equipment Register Inventory"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="inventory_regist">
          <EqRegistInventoryPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/inventory-bulk"
      code="inventory_bulk"
      pageId="stock-ctrl01"
      name="Inventory Bulk"
      nameEn="Inventory Bulk"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="inventory_bulk">
          <InventoryBulk />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/return-bulk"
      code="return_bulk"
      pageId="stock-ctrl01"
      name="返却登録"
      nameEn="Return Bulk"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="return_bulk">
          <ReturnBulk />
        </PrivateRoute>
      }
    />
    {/* //! This is a refactored route for EqResultReturnPage */}
    <Route
      path="/eq/result-return-refactor/:equipmentId"
      code="return_regist"
      pageId="return-02"
      name="油圧式ショベルカー"
      nameEn="Equipment Result Return"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="return_regist">
          <EqResultReturnRefactorPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/result-return/:equipmentId"
      code="return_regist"
      pageId="return-02"
      name="油圧式ショベルカー"
      nameEn="Equipment Result Return"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="return_regist">
          <EqResultReturnPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/eq/schedule/ondate/edit/:equipmentId"
      pageId="use-01"
      name="油圧式ショベルカー"
      nameEn="Equipment Result Return"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="eq-schedule-ondate">
          <EqScheduleOnDatePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/terms-of-use"
      pageId="static-02"
      name="利用規約"
      nameEn="Terms of Use Confirmation Screen"
      element={
        <PrivateRoute username={username} pageId="terms-of-use">
          <TermsOfUsePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/privacy-policy"
      pageId="static-01"
      name="プライバシーポリシー"
      nameEn="Privacy Policy Confirmation Screen"
      element={
        <PrivateRoute username={username} pageId="privacy-policy">
          <PrivacyPolicyPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/password/reset"
      pageId="utilty-01"
      name="パスワード設定画面"
      nameEn="Password Setup Screen"
      element={
        // <PrivateRoute username={username}>
        <PasswordResetPage />
        // </PrivateRoute>
      }
    />
    <Route
      path="/qrcode/alert"
      pageId="utilty-02"
      name="QRコード読み取り"
      nameEn="QR Code Alert Screen"
      ui="Done"
      element={
        <PrivateRoute username={username} pageId="qr-code-alert">
          <QrCodeAlertPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/qrcode/scanner"
      pageId="SP_utility-camera"
      name="QRコード読み取り"
      nameEn="QR Scanner"
      element={
        <PrivateRoute username={username} pageId="qr-code-scanner">
          <QrCodeScannerPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/qrcode"
      pageId="utilty-02_01"
      name="QRコード読込画面"
      nameEn="QR code loading screen"
      element={
        <PrivateRoute username={username} pageId="qrcode">
          <QrCodePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard"
      code="dashboard"
      pageId="utilty-03"
      name="ダッシュボード"
      nameEn="Dashboard screen"
      element={
        <PrivateRoute username={username} pageId="dashboard">
          <DashboardPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/logs"
      code="logs"
      pageId="utilty-04"
      name="サービスログ確認画面"
      nameEn="Service log confirmation screen"
      element={
        <PrivateRoute username={username} pageId="logs">
          <LogsPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/notice"
      pageId="utilty-05"
      name="通知一覧画面"
      nameEn="Notification list screen"
      element={
        <PrivateRoute username={username} pageId="notice">
          <NoticePage />
        </PrivateRoute>
      }
    />
    <Route
      path="/account/:userName"
      pageId="utility-06"
      name="アカウント"
      nameEn="Account information confirmation screen"
      element={
        <PrivateRoute username={username} pageId="account">
          <AccountPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/account/edit/email/:userId"
      pageId="utility-08"
      name="アカウント"
      nameEn="E-mail address change input screen"
      element={
        <PrivateRoute username={username} pageId="account-edit">
          <AccountEditEmailPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/help"
      pageId="static-03"
      name="操作マニュアル"
      nameEn="Help (manual)"
      element={
        <PrivateRoute username={username} pageId="help">
          <HelpPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/password/forget"
      pageId="login-01_02"
      name="パスワードを忘れた方へ"
      nameEn="Forgot Password Screen"
      ui="Done"
      element={<PasswordForgetPage />}
    />
    <Route
      path="/login/introduction"
      pageId="login-01-04"
      name="はじめに"
      nameEn="Login introduction"
      ui="Done"
      element={<LoginIntroduction />}
    />
    <Route
      path="/login/update-account"
      pageId="login-01_a"
      name="アカウント情報・初期パスワード変更"
      nameEn="Update account"
      ui="Done"
      element={<UpdateAccount />}
    />
    <Route
      path="/list"
      pageId="-"
      name="全ページリスト"
      nameEn="Sitemap"
      element={<SitemapPage />}
    />
    <Route
      path="/get-api"
      pageId="login-01"
      name="ログイン画面"
      nameEn="Login Screen"
      element={<GetApi />}
    />
    <Route
      path="404-not-found"
      name="404 Page"
      nameEn="404 Page"
      element={<PageNotFound />}
    />
    <Route
      path="403-forbidden"
      name="403 Page"
      nameEn="403 Page"
      element={<PageForbidden />}
    />
    <Route path="*" element={<Navigate replace to="/404-not-found" />} />
  </>
);
