import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

// amplify ui
import {
  View,
  DropZone,
  VisuallyHidden,
  Flex,
  Text,
  Heading,
  Loader,
} from "@aws-amplify/ui-react";

// icons
import UploadSmallIcon from "../../components/icons/upload-small-icon";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// css
import "../../styles/eq.scss";

// custom utilities
import { upload } from "../../utils/s3UploadClient";
import { EqApi } from "../../api/eq/index";
import { CompanyApi } from "../../api/company/index";

const acceptedFileTypes = [".csv"];

export default function EqCSVUploadPage() {
  const hiddenInput = useRef(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [initialState, setInitialState] = useState(true)
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "/eq/list", label: "機材一覧" },
    { href: "", label: "機材詳細" },
  ];

  const navigate = useNavigate();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const [isModalOpen, setIsModalOpen] = useState(false);

  //成功した時のモーダル表示のための関数
  const openModal = () => {
    setIsModalOpen(true);
    return {};
  };

  //失敗した時のエラー表示のための関数
  const Error = (message: string = "") => {
    setShowError(true);
    setLoader(false);
    setFile(null);
    setErrorMessage(message);
    return {};
  };

  const [file, setFile] = useState<File | null>(null);

  //ここのIf文でうまくErrorが処理されていたら、成功したらmodalに、失敗したらErrorにいくはず
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setShowError(false);
    setLoader(true);
    e.preventDefault();
    if (file) {
      if (!checkExtensionIsValid(file.name)) {
        Error("ファイル拡張子はcsvにしてください。"); //失敗した時のエラー表示
        return;
      }

      if (file?.size && file?.size > 3 * 1024 * 1024) {
        Error("ファイルサイズは3MBまでにしてください。"); //失敗した時のエラー表示
        return;
      }
      const fileName = file?.name?.split('_')
      const companyId = removeExtension(fileName[2])
      try {
          const { data } = await CompanyApi().getDetail(companyId)
          const detail = data?.data
          if(!data?.success) {
            setShowError(true);
            setErrorMessage('レンタル会社の法人IDを使用してください');
            setLoader(false);
            return
          }
          if(detail?.company_category_id !== '200') {
            setShowError(true)
            setErrorMessage('レンタル会社の法人IDを使用してください');
            setLoader(false);
            return
          }
          if(detail?.company_category_id === '200') {
            setShowError(false)
          }
        } catch(err) {
          console.log(err)
      }

      const renamedFile = new File(
        [file],
        `${removeExtension(file.name)}_${getFormattedDateTime()}.csv`,
        {
          type: file.type,
        }
      );
      try {
        const data = await EqApi().createCsv({
          user_id: Number(
            localStorage.getItem(
              `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_ID}.LastAuthUser`
            )
          ),
          csv_name: renamedFile.name,
        });
        if (data?.status === 200) {
          await upload(
            renamedFile,
            String(process.env.REACT_APP_CSVIMPORT_S3BUCKET),
            "equipment_import/"
          );
          openModal(); //登録がうまく行った時の表示モーダル
          setInitialState(true)
        } else {
          Error(data?.data?.message ?? ""); //失敗した時のエラー表示
        }
      } catch (e) {
        Error(); //失敗した時のエラー表示
        console.log(e);
      }
    }
    setFile(null);
    setLoader(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setShowError(false);
    setInitialState(false)
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleOpenFileSelectModal = () =>
    (document.querySelector('input[type="file"]') as HTMLInputElement)?.click();



  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <View className="main-content">
        {showError ? (
          <View
            className="dropzone-error"
            backgroundColor={"#D32F2F"}
            color={"#ffffff"}
            padding={"20px"}
            marginBottom={"20px"}
          >
            <Flex>
              <ErrorSmallIcon fill="white" />
              <View>
                <Heading color={"#ffffff"} level={6} fontWeight={500}>
                  CSV登録エラー
                </Heading>
                <Text color={"#ffffff"} fontSize={"14px"}>
                  ファイルが正しくありません。確認後、再登録を行なってください。
                </Text>
                <Text color={"#ffffff"} fontSize={"14px"}>
                  {errorMessage}
                </Text>
              </View>
            </Flex>
          </View>
        ) : null}

        <Heading level={6} fontWeight={500} marginBottom={"20px"}>
          規定のファイル名に設定のうえ、アップロードをお願いします。
        </Heading>
        <DropZone
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          acceptedFileTypes={acceptedFileTypes}
        >
          <Flex direction="column" alignItems="center">
            <UploadSmallIcon />
            {file ? (
              <Text>{file.name}</Text>
            ) : (
              <Text>
                {/*<Link to={""} onClick={handleOpenFileSelectModal}>
                  クリックしてファイル参照
            </Link>*/}
                <Text as="span">ドラッグ＆ドロップでアップロード</Text>
              </Text>
            )}
            <Text>CSV形式 (max. 3MB)</Text>
          </Flex>
          <VisuallyHidden>
            <input
              type="file"
              tabIndex={-1}
              ref={hiddenInput}
              multiple={true}
              accept={acceptedFileTypes.join(",")}
            />
          </VisuallyHidden>
        </DropZone>
        <form onSubmit={handleSubmit}>
          <View textAlign={"center"} marginTop={"50px"}>
            <input
              type="file"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
            {loader ? (
              <View className="table-loader">
                <Loader width="5rem" height="5rem" filledColor="#2196F3" />
              </View>
            ) : (
              <CustomButton
                {...defaultButtonProps}
                text="CSV登録"
                disabled={initialState || showError}
                // click={() => hiddenInput.current.click()}
                click={handleSubmit}
              />
            )}
          </View>
        </form>
      </View>

      {/*成功した時のモーダル表示*/}
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setFile(null);
        }}
        textHeading="CSVを登録しました"
        textsmall="取込処理の結果は別途メールでお知らせします。"
      >
        <Flex justifyContent="center" margin={`0 auto`}>
          <CustomButton
            {...defaultButtonProps}
            text="機材一覧に戻る"
            iconName="arrow"
            iconPosition="right"
            size="medium"
            click={() => navigate("/eq/list")}
          />
        </Flex>
      </CommonModal>
    </PageContent>
  );
}

function getFormattedDateTime() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // 月は0-indexedなので+1する
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

function removeExtension(fileName: string) {
  // 最後のピリオドの位置を検索して拡張子を除去
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex === -1) {
    // ピリオドが見つからない場合はそのまま返す
    return fileName;
  } else {
    // ピリオドが見つかった場合は拡張子を除去した部分のみを返す
    return fileName.substring(0, lastIndex);
  }
}

function checkExtensionIsValid(fileName: string): boolean {
  // 最後のピリオドの位置を検索して拡張子を取得
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex === -1) {
    // ピリオドが見つからない場合はfalse
    return false;
  } else {
    // ピリオドが見つかった場合は拡張子を除去した部分のみを返す
    return fileName.substring(lastIndex + 1) === "csv";
  }
}
