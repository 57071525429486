import React from "react";
import { useNavigate } from "react-router-dom";
import '../../styles/login-board.scss'

// icons
import ArrowNext from "../../components/icons/arrow-next";

//custom components
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

import getDeviceType from "../../utils/getDeviceType";

import { 
  Card,
  Divider,
  Flex,
  Heading, 
  Text, 
  View,
  Image 
} from "@aws-amplify/ui-react";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: (): void => {}
}

export default function LoginIntroduction() {
  const isMobile = getDeviceType() === 'mobile'
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <View className='board-container'>
      <View className='board--header' >
        <Heading level={1}>はじめに</Heading>
        <Divider size="small" />
      </View>
      {isMobile ? (
        <Image src="/images/introduction_SP.png" alt="introduction" width={'100%'} />
      ) : <Image src="/images/introduction_PC.png" alt="introduction" width={'100%'} />
      /*(
        <Flex
          className='board--progress'
          alignItems={'center'}
          gap={1}
        >
          <Card>
            <Heading level={6}>初回ログイン</Heading>
          </Card>
          <ArrowNext />
          <Card className='card--current'>
            <Heading level={6}>
              プライバシーポリシー <br />
              利用規約同意
            </Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>同意完了</Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>
              パスワード <br />
              アカウント情報更新
            </Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>再ログイン</Heading>
          </Card>
        </Flex>)*/
        }
      <View className='board--contents'>
        <Heading level={3}>はじめてご利用いただく方へ</Heading>
        <Text className='board--paragraph'>
          当システムでは、初めてご利用いただく際にご利用規約とプライバシーポリシーへの同意が必要です。 <br />
          また、初回ログイン時にご入力いただいたパスワードは仮パスワードとなります。次の画面にて、新しいパスワードへの再設定をお願いします。
        </Text>
        <Text className='board--paragraph'>
          ※このページは初回ご利用時にのみ表示されます。
        </Text>

        <Heading level={3}>利用規約とプライバシーポリシーへの同意</Heading>
        <Text className='board--paragraph'>
          以下のご利用規約とプライバシーポリシーをお読みいただき、ご同意ただいけれる場合は「同意する」ボタンをクリックしてください。 <br />
          同意いただけない場合は、当サービスはご利用いただくことができません。
        </Text>

        <Heading level={4}>ご利用規約</Heading>
        <Card className='scrollable-card'>
          <Text　style={{ textAlign: 'center' }}><strong>機材管理サービス”ConPath-D” サービス利用規約</strong></Text>
          <Text>本ユーザー利用規約（以下「本規約」）は、株式会社ダイヤモンド建機（以下「当社」）が「ConPath-D」の名称で提供する建設現場の機材管理サービス（以下「本サービス」）の提供条件及び本サービスをご利用いただくお客様と当社との間の権利義務関係を定めるものです。本サービスのご利用に際しては、本規約の全文をお読みください。<br />
                お客様は、本規約に承諾の上、本サービスの利用を申し込むものとし、本サービスの申込をした場合、本規約を承諾したものとみなします。</Text>
          <Text></Text>
          <Text><strong>第1条（定義）</strong></Text>
          <Text>本規約において、以下各号の用語は別途定義されている場合を除き、以下の意味を有するものとします。</Text>
          <Text>(1) 「サービス利用契約」とは、法人、団体、組合及び個人事業主等が本規約に同意の上、所定の手続きにより当社の間で成立する、本規約を契約の内容とする本サービスの利用に係る契約をいいます。</Text>
          <Text>(2) 「登録申請者」とは、サービス利用契約を締結している法人、団体、組合及び個人事業主等に属する者が、本サービスの提供を受けることを目的とし、ユーザー登録を申請する者をいいます。</Text>
          <Text>(3) 「ユーザー」とは、登録申請者の内、当社の判断により本サービスの提供を受けることができる者をいいます。</Text>
          <Text></Text>
          <Text><strong>第2条（適用）</strong></Text>
          <Text>1. 本規約は、ユーザーと当社との間の本サービス利用に係る全ての関係に適用されます。</Text>
          <Text>2. 本サービスを利用した場合、ユーザーは本規約の内容を承諾したものとみなされます。</Text>
          <Text>3. 当社が当社ウェブサイトやアプリケーション上に記載する本サービスに係る説明、ルール、規定は本規約の一部を構成するものとします。</Text>
          <Text></Text>
          <Text><strong>第3条（本規約等の変更）</strong></Text>
          <Text>1. 当社は、本規約及び本サービスの内容を変更できるものとします。</Text>
          <Text>2. 当社が前項の規定により本規約を変更する場合には、変更の内容及び効力発生時期を、効力発生日の相当期間前に、変更後の本規約の内容をユーザーに通知するものとします。</Text>
          <Text></Text>
          <Text><strong>第4条（利用申込）</strong></Text>
          <Text>1. 本サービスへの登録を希望する者は、本規約の内容を承諾の上、当社が定める一定の情報（以下「登録情報」）を正確に入力し、当社に提供することにより、本サービスの登録申請をすることができます。なお、登録申請者は、当社ウェブサイト又は個別契約等において本規約に同意したときに、本規約の個別の条項についても同意したものとみなされます。</Text>
          <Text>2. 当社は、本サービスへの登録申請者より前項に基づく登録の申請があった場合、当社の基準に従って、本サービスへの登録可否を判断し、登録可否の決定を当該登録申請者に通知します。本サービスへの登録可能との通知を以て、ユーザー登録は完了し、本サービスのユーザーとして本サービスを利用できます。</Text>
          <Text>3. 当社は次の各号に該当する場合、本サービスの登録申請を承諾しないことがあります。</Text>
          <Text>　(1) 登録申請者が登録申請に際し、登録情報に虚偽の事実を記載した場合。</Text>
          <Text>　(2) 登録申請者が登録申請の際に正確性、完全性に欠け、または最新のものではない登録情報を提供したと当社に合理的に認められる場合。 </Text>
          <Text>　(3) 登録申請者が登録申請に係る本規約の義務を怠るおそれがあると当社が判断した場合。 </Text>
          <Text>　(4) 登録申請者が当社または本サービスの信用を毀損するおそれがある態様で本サービスを利用するおそれがあると当社が判断した場合。</Text>
          <Text>　(5) 登録申請者が本規約の第8条に該当する禁止行為を行ったことがある場合、または行うおそれがあると当社が判断した場合。</Text>
          <Text>　(6) 登録申請を承諾することが、技術上または本サービスの提供の他、当社の業務遂行上著しい支障があると当社が判断した場合。</Text>
          <Text>　(7) 登録情報の内容に、第三者の会社名、組織名、商標、登録商標等が含まれており、第三者の権利侵害になると判断した場合。</Text>
          <Text>　(8) 登録申請を承諾することにより、反社会団体による利用のおそれがある場合、もしくは反社会行為、その他犯罪行為に利用されるおそれがあると当社が判断した場合。 </Text>
          <Text>　(9) 前各号のほか、当社がサービス利用契約の締結を適当でないと判断した場合。</Text>
          <Text></Text>
          <Text><strong>第5条（ユーザーID・パスワードの管理）</strong></Text>
          <Text>1. ユーザーは、付与されるユーザーID及びパスワードを、自己の責任において管理及び保管するものとし、ユーザーID及びパスワードを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</Text>
          <Text>2. ユーザーは、ユーザーID 及びパスワードの盗難・失念・第三者の利用等があった場合、直ちに当社にその旨を連絡するとともに、当社からの指示がある場合にはこれに従うものとします。</Text>
          <Text>3. 前項に定める義務違反、ユーザーID 及びパスワードの管理不十分、使用上の過誤、第三者の利用、パスワード変更義務を怠ったこと等による損害等の責任はすべてユーザーが負うものとし、当社は一切の責任を負わないものとします。</Text>
          <Text></Text>
          <Text><strong>第6条（登録情報の変更）</strong></Text>
          <Text>1. ユーザーは、本サービスの登録情報の内容に変更があったとき、速やかにその旨を当社所定の方法により当社に届け出るものとします。</Text>
          <Text>2. ユーザーが前項の届け出を怠った事による損害等について、当社は一切の責任を負わないものとします</Text>
          <Text></Text>
          <Text><strong>第7条（利用停止）</strong></Text>
          <Text>当社は、合理的な理由が存する場合には、当社の判断に基づき、事前に通知することなく、当該ユーザーについて本サービスの利用を一時的に停止、又はユーザーとしての登録を取り消すことができます。なお、これに係る損害について当社は一切の責任を負いません。</Text>
          <Text></Text>
          <Text><strong>第8条（禁止事項）</strong></Text>
          <Text>1. ユーザーは、本サービスの利用にあたって以下の各号のいずれかに該当する行為をしてはなりません。</Text>
          <Text>　(1) 当社、他のユーザー、又は第三者の著作権、商標権、その他の権利、名誉を侵害する行為。</Text>
          <Text>　(2) 当社、他のユーザー、又は第三者の財産もしくはプライバシーを侵害する行為、または侵害する恐れのある行為。</Text>
          <Text>　(3) 公序良俗に反する行為もしくはその恐れのある行為。</Text>
          <Text>　(4) 犯罪的行為もしくは犯罪的行為に結びつく行為、またはその恐れのある行為。 </Text>
          <Text>　(5) 当社の事業や本サービスの運営を妨げる行為。</Text>
          <Text>　(6) ユーザーID 及びパスワードを不正に使用する行為。</Text>
          <Text>　(7) コンピュータウィルスその他の有害なプログラムを本サービスに関連して使用もしくは提供する行為。 </Text>
          <Text>　(8) 本サービスに登録された個人情報及び個人データの目的外の加工、複写又は複製を行う行為。</Text>
          <Text>　(9) その他、本規約や法令に違反する行為及び当社が不適切と判断する行為。 </Text>
          <Text>2. 当社が、ユーザーが前項各号のいずれかに該当すると判断した場合、事前に通知することなく、当該ユーザーによる本サービスの利用停止、登録取消等の措置を講じることができるものとします。尚、これに係る損害について当社は一切の責任を負いません。</Text>
          <Text>3. ユーザーは、第１項の規程に違反したことにより当社が被った損害を賠償しなければなりません。</Text>
          <Text></Text>
          <Text><strong>第9条（本サービスの中止等）</strong></Text>
          <Text>1. 当社は、次の場合に自らの判断で本サービスを中止することがあります。</Text>
          <Text>　(1) 本サービスに係るシステムの保守を定期的または緊急に行う場合。</Text>
          <Text>　(2) 不可抗力、天災、事変、その他の非常事態が発生し、もしくは発生する恐れがある場合。</Text>
          <Text>　(3) システム、コンピューター、通信回線等が事故により停止し、本サービスを利用できなくなった場合。</Text>
          <Text>　(4) 本サービスの提供に必要な設備の障害等により本サービスの提供が困難となった場合。</Text>
          <Text>　(5) 本サービスの利用料の支払いが確認できない場合。</Text>
          <Text>　(6) その他、当社が停止又は中断を必要と判断した場合。</Text>
          <Text>2. 当社は、当社都合により、本サービスの提供を終了することができるものとします。</Text>
          <Text>3. 当社は、本条に基づき当社が行った措置によってユーザー又は第三者に生じた損害について一切の責任を負わないものとします。</Text>
          <Text></Text>
          <Text><strong>第10条（個人情報その他の情報の取り扱い）</strong></Text>
          <Text>当社は、ユーザーの情報を、別途定める本サービスのプライバシーポリシーに従って適切に取り扱います。</Text>
          <Text></Text>
          <Text><strong>第11条（知的財産権）</strong></Text>
          <Text>本サービスを構成するソフトウェア、プログラム、データベース、画像、映像、文章、その他の有形又は無形の資産に関する特許権（特許を受ける権利を含みます）、実用新案権（実用新案登録を受ける権利を含みます）、意匠権（意匠登録を受ける権利を含みます）、商標権、著作権、その他の知的財産権は、当社又は当該権利を有する第三者に帰属します。ユーザーは、本サービスについて、リバースエンジニアリング、その他、本サービスの解析を目的とする行為を行わないものとします。</Text>
          <Text></Text>
          <Text><strong>第12条（反社会的勢力の排除）</strong></Text>
          <Text>1. ユーザーは、自己及びその代表者、役員、その他、実質的に経営を支配する者が、以下のいずれにも該当しないことを保証し、将来においても該当しないことを確約します。</Text>
          <Text>　(1) 暴力団、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団、それらの構成員又はこれに準ずる者（以下｢反社会的勢力｣）</Text>
          <Text>　(2) 反社会的勢力でなくなったときから5年を経過しない者</Text>
          <Text>　(3) 反社会的勢力に対する資金提供、便宜の供給、その他密接な関わりを有する者</Text>
          <Text>2. 当社は、ユーザーが上記保証又は確約に違反した場合、事前の催告なく、直ちに本サービスの利用を停止することができます。本項に係る行使はユーザーに対する損害賠償請求を妨げないものとします。なお、本サービスの利用の停止によりユーザーに生じた損害について賠償する責任を負わないものとします。</Text>
          <Text></Text>
          <Text><strong>第13条（損害賠償）</strong></Text>
          <Text>1. ユーザーが本規約に違反することによって、当社、他のユーザー又は第三者に損害等を与えた場合、当該ユーザーは自己の費用と責任において当該損害等を賠償しなければならず、当社は一切の責任を負いません。</Text>
          <Text>2. ユーザーが本規約に違反することによって、他のユーザー又は第三者との間で紛争が生じた場合、当該ユーザーは自己の費用と責任において当該紛争を解決するものとし、当社は一切の責任を負いません。</Text>
          <Text>3. ユーザーが本規約に違反することによって、当社とユーザーとの間で紛争が生じた場合、当社及びユーザー間で協議することにより解決するものとします。当社は当該紛争によりユーザーに生じた損害について一切の責任を負いません。</Text>
          <Text></Text>
          <Text><strong>第14条（譲渡）</strong></Text>
          <Text>ユーザーは、法の作用その他にかかわらず、当社の書面による承諾なく、取引上発生する債権、債務、権利、義務又は契約上の地位を譲渡又は移転することはできません。当社は、ユーザーの同意なく、当社の関連会社に対し、合併、分割、株式交換、株式移転、買収、事業譲渡、その他の企業再編に関連して、本規約上の債権、債務、権利、義務又は規約上の地位を譲渡することができます。</Text>
          <Text></Text>
          <Text><strong>第15条（分離可能性）</strong></Text>
          <Text>本規約のいずれかの条項が何らかの理由により無効又は執行不能であっても、本規約の他の条項が無効又は執行不能となるものではありません。裁判所において本規約のある規定が無効又は執行不能とされた場合、当該規定は有効かつ執行可能となるために必要最小限度の修正がなされた内容として解釈されるものとします。</Text>
          <Text></Text>
          <Text><strong>第16条（存続条項）</strong></Text>
          <Text>本規約の終了後も存続することが予定されている各条項は、本サービスが存続する限り有効とします。</Text>
          <Text></Text>
          <Text><strong>第17条（準拠法及び管轄裁判所）</strong></Text>
          <Text>本規約の準拠法は日本法とします。本規約に関する紛争については、乙の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。</Text>
          <Text></Text>
          <Text><strong>第18条（誠実協議）</strong></Text>
          <Text>本規約の内容に関する疑義、又は本規約に定めのない事項については、当社とユーザーの間で誠意をもって協議の上、解決するものとします。</Text>
          <Text　style={{ textAlign: 'right' }}>以上　　</Text>

        
        
        </Card>

        <Heading level={4}>プライバシーポリシー</Heading>
        <Card className='scrollable-card'>

          <Text　style={{ textAlign: 'center' }}><strong>機材管理サービス“ConPath-D” プライバシーポリシー</strong></Text>
          <Text>株式会社ダイヤモンド建機は、個人情報保護の重要性に鑑み、個人情報の保護に関する法令及び行政手続における特定の個人を識別するための番号の利用等に関する法令を遵守し、個人情報、匿名加工情報（特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたものをいい、加工方法等の情報を併せたものをいいます。）および個人関連情報（以下「個人情報等」）を以下の方針に基づいて適切に取り扱います。</Text>
          <Text><strong>1. 個人情報保護体制の確立と継続的改善</strong></Text>
          <Text>当社は、全社員に個人情報保護の重要性を認識させ、個人情報を適切に保護するための個人情報保護体制を確立し、継続的に改善します。</Text>
          <Text><strong>2. 個人情報の取得・利用</strong></Text>
          <Text>当社は、個人情報保護に係る社内規程を制定し、これに従って個人情報を適正かつ適切に取得、利用します。また、本人に通知もしくは公表した利用目的の達成に必要な範囲内に限定して個人情報を取り扱います。</Text>
          <Text><strong>3. 本人への対応</strong></Text>
          <Text>当社は、個人情報の開示、訂正等のお問い合わせに対し、法令に従い、速やかに対応します。</Text>
          <Text><strong>4. 個人情報の正確性・安全性の確保</strong></Text>
          <Text>当社は、個人情報の正確性および安全性を確保するため、安全対策を講じて、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏えい等の防止に努めます。</Text>
          <Text><strong>5. 第三者提供に関する措置</strong></Text>
          <Text>個人情報等を第三者に提供する際には、法令に従い必要な措置を講じます。</Text>
          <Text><strong>6. 管理体制の維持・向上</strong></Text>
          <Text>社内教育・啓発活動を実施して個人情報等の適正な取扱いについて周知徹底に努めるとともに、管理体制を継続的に見直します。</Text>
          <Text>株式会社ダイヤモンド建機<br />
                代表取締役社長　宮沢 達朗<br />
                〒105-0003 東京都港区西新橋一丁目4番14号 物産ビル6階<br />
          </Text>
          <Text><strong>個人情報の利用目的について</strong></Text>
          <Text>当社は、他の方法でお知らせする場合を除き、ご提供いただいた個人情報を次の目的の達成に必要な範囲で取扱います。また、個人番号に関しては、法令に定める個人番号関係事務の範囲を超えて取扱うことはありません。
          </Text>
          <Text>(1) お客様に機材管理サービス”ConPath-D”を提供するため。</Text>
          <Text>(2) サービス利用料金の徴収のため。</Text>
          <Text>(3) お客様の本人確認を行うため。</Text>
          <Text>(4) 各種お問い合わせ、資料請求等に対応するため。</Text>
          <Text>(5) お客様に、当社の商品、サービス等に関する情報を提供するため。</Text>
          <Text>(6) サービスの改善、新規サービスの開発・マーケティングのため。</Text>
          <Text>(7) 市場調査・顧客動向分析等、経営上必要な分析を行うための基礎データおよび統計的データの作成、または匿名加工情報の解析をするため。</Text>
          <Text>(8) 新サービスに関する各種情報の連絡のため。</Text>
          <Text>(9) 利用規約等で禁じている各種行為の調査とそれに基づく詳細確認のため。</Text>
          <Text>(10) 以上の各事項に付随する業務</Text>

          <Text>当社は、個別にご了解を得た場合を除き、上記の利用目的の範囲を超えてお客様の個人情報を利用いたしません。利用目的が変更された場合には、速やかに通知、もしくは公表いたします。</Text>
          <Text>なお、当社では上記利用目的を達成するため、提供いただいた個人情報を業務委託先に委託する場合がございます。その場合には、委託された個人情報等の安全管理が図られるよう、業務委託先に対する必要かつ適切な監督を行います。また、法令等に基づき公的機関より提供を求められた場合には、当該公的機関に提供することがございます。</Text>

          <Text><strong>【個人情報の取り扱いに関するお問合せ】</strong></Text>
          <Text>株式会社ダイヤモンド建機　個人情報保護担当<br />
                電話：03-6205-7886<br />
                メール：info@diace.co.jp</Text>

          <Text><strong>個人情報の第三者提供について</strong></Text>
          <Text>当社は、取得した個人情報を、個人情報保護関連法令等で定める場合を除き、本人の同意なく第三者に提供することはありません。ただし、本人の同意を得た場合は、取得した個人情報を、第三者（外国にある第三者を含む）に提供する場合があります。</Text>
          <Text>その際、個人情報の第三者提供にあたっては、個人情報保護法の定めに従い、その記録と保存を行います。個人情報を外国にある第三者に提供する場合には、同法の定めに従い、あらかじめ本人の同意を得たうえで提供します。</Text>

          <Text><strong>「開示等の求め」に応じる手続きについて</strong></Text>
          <Text>当社では「個人情報の保護に関する法律」に基づき、保有個人データに関する開示、訂正、利用停止等の求めに以下の通り対応させて頂きます。</Text>

          <Text><strong>1. 対象範囲</strong></Text>
          <Text>ご本人の住所、氏名の他、特定の個人を識別できる個人情報であって、「個人情報の保護に関する法律」に基づく保有個人データに含まれ、当社が収集し、保有しているもののみとします。</Text>

          <Text><strong>2. 開示等のご請求先</strong></Text>


          <Text>開示等のご請求は所定の請求書に必要書類および開示手数料（開示のご請求の場合のみ必要であり、訂正等・利用停止等のご請求の場合は不要）を同封の上、郵送にてお願いいたします。なお、郵送の際は封筒に朱書きで「開示等請求書類在中」とお書きください。</Text>

          <Text>【郵送先】
                〒105-0003 東京都港区西新橋一丁目4番14号 物産ビル6階<br />
                株式会社ダイヤモンド建機　個人情報保護担当<br />
                【電話】　　03-6205-7886<br />
                【E-Mail】　info@diace.co.jp<br />
                【受付時間】9:00～17:00（ただし、土日祝祭日は除く）<br />
                ※直接ご来社頂いてのご請求はお受け致しかねますので、ご了承願います。<br />
                ※郵送料等につきましては、ご本人負担とさせていただきます。<br />
                </Text>

          <Text><strong>3. 開示等のご請求に際してご提出頂く書面（様式）等</strong></Text>
          <Text>開示等のご請求を行う場合は、当社所定の請求書と本人確認のための書類が必要になります。当社所定の請求書（A）に所定の事項を全てご記入の上、本人確認のための書類（B）を同封しご郵送下さい。</Text>
          <Text>
            A) 当社所定の請求書<br />
            　・開示請求書<br />
            　・訂正等請求書<br />
            　・利用停止等請求書<br />
            <br />
            B) 本人確認のための書類<br />
            　運転免許証、パスポート、保険証、年金手帳、住民票等の現住所が確認できる公的書類のコピー（現住所がわかるようにコピーをお願いします）１通<br />
            ※当社所定の請求書は当社よりお送り致しますので、ご希望の請求書を明らかにした上、上記連絡先にご一報下さい。<br />
            ※当社所定の申請書類以外では一切お受けできませんので、あらかじめご了承願います。<br />
            ※本人確認用書類として、運転免許証や住民票等を利用される場合は、当該「本籍地」を黒で塗りつぶすなどして読み取り不可の状態でご送付ください。<br />
          </Text>

          <Text><strong>4. 代理人による開示等のご請求</strong></Text>
          <Text>開示等のご請求をする方が、本人の法定代理人、または本人が委任した代理人である場合は、前項の書類に加えて以下の書類をご同封願います。</Text>

          <Text>A) 法定代理人の場合<br />
          　・法定代理人があることの理由を記載した書面（様式自由）１通<br />
          　・法定代理権があることを確認するための書類（本人の戸籍抄本）１通</Text>

          <Text>代理人本人であることを確認するための書類　１通 （代理人の運転免許証、パスポート、保険証、年金手帳、住民票などの現住所が確認できる公的書類のコピー）</Text>

          <Text>B) 委任による代理人の場合<br />
          　・捺印済みの委任状　１通<br />
          　・本人の印鑑証明書　１通<br />
          　・代理人本人であることを確認するための書類　１通 （代理人の運転免許証、パスポート、保険証、年金手帳、住民票などの現住所が確認できる公的書類のコピー）</Text>

          <Text><strong>5. 開示等のご請求に関する手数料およびそのお支払い方法</strong></Text>
          <Text>一回のご請求毎に、440円（簡易書留郵便を利用し書面にてご回答する実費）を頂戴いたしますので、440円分の郵便切手を申請書類に同封して下さい。なお、手数料は開示のご請求の場合のみ必要であり、訂正等・利用停止等のご請求の場合、手数料は不要です。</Text>

          <Text><strong>6. 開示等のご請求に対するご回答方法</strong></Text>
          <Text>請求書記載住所宛に、簡易書留により書面でご回答申し上げます。なお、開示等のご請求に際してご提出頂く全ての書面等が到着し開示手続きが開始された時からご回答までは、少なくとも10営業日はお時間を頂きます。</Text>

          <Text><strong>7. 「保有個人データ」の不開示について</strong></Text>

          <Text>次に定める場合は不開示とさせて頂きます。不開示を決定した場合は、その旨理由を付記してご通知申し上げます。なお、不開示の場合についても所定の手数料を頂きます。</Text>
          <Text>　・請求書に記載されている住所、本人確認のための書類に記載されている住所、当社の登録住所が一致しない場合等、ご本人からの請求であることが確認できない場合</Text>
          <Text>　・代理人による申請に際して、代理権が確認できない場合</Text>
          <Text>　・ご提出頂いた申請書類に不備があった場合</Text>
          <Text>　・開示等のご請求の対象が「保有個人データ」に該当しない場合</Text>
          <Text>　・本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</Text>
          <Text>　・当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</Text>
          <Text>　・他の法令に違反することとなる場合</Text>

          <Text><strong>8. 開示等のご請求に関して取得した個人情報の利用目的</strong></Text>

          <Text>開示等のご請求に際してご提出頂いた請求書、本人確認書類等の個人情報は、開示等のご請求への対応に必要な範囲のみで取扱います。ご提出頂いた書類は、開示等のご請求に対するご回答が終了した後、一定期間保存しその後破棄させて頂きます。</Text>

          <Text><strong>匿名加工情報の利用について</strong></Text>
          <Text>当社は、匿名加工情報を取扱う場合、法令等に定めに従い、適切に取扱います。また、当社は、保有している個人情報から匿名加工情報を作成した場合、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。</Text>

          <Text>また、当社は、匿名加工情報を第三者に提供する場合、提供しようとする匿名加工情報に含まれる個人に関する情報の項目と提供の方法を公表するとともに、提供先となる第三者に対して、提供する情報が匿名加工情報であることを明示します。</Text>

        </Card>
        <Flex className='board--footer'>
          <CustomButton 
            {...defaultButtonProps}
            text='同意しない'
            type='default'
            click={() => navigate('/login')}
          />
          <CustomButton 
            {...defaultButtonProps}
            text='同意する'
            click={() => setIsModalOpen(true)}
          />
        </Flex>
      </View>
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        textHeading="同意しました"
        textOperationAlign="left"
        textOperation={`利用規約とプライバシーポリシーに同意いただきありがとうございました。 \n初回ログインの方は、セキュリティ強化のため \n次の画面でパスワードの設定変更をお願いします。`}
      >
        <CustomButton
          { ...defaultButtonProps }
          text='パスワード変更へ進む'
          type='primary'
          click={() => navigate('/login/update-account')}
        />
      </CommonModal>
    </View>
  )
}