import React from "react";
import "../../styles/login.scss";
export default function AccountIcon2() {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      className="login-icon forgot-password-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2086_47330)">
        <path
          d="M34.9999 8.2351L34.9957 8.23929C34.9948 10.3454 34.1803 12.4507 32.5517 14.0541C30.92 15.6605 28.7799 16.4642 26.6416 16.4642C24.5033 16.4642 22.3635 15.6605 20.7313 14.0538C19.9384 13.2732 19.3362 12.3646 18.9258 11.3889C18.4977 10.3727 18.2839 9.29835 18.2839 8.23569L18.2776 5.40024H21.4815L21.4858 8.23629C21.4854 8.90686 21.6139 9.56964 21.8709 10.1794C22.1194 10.7708 22.4945 11.3322 22.9953 11.8251C24.0025 12.8167 25.3233 13.3125 26.6422 13.3122C27.9609 13.3122 29.2811 12.8167 30.288 11.8257C31.297 10.8322 31.8012 9.53277 31.803 8.2354C31.803 8.2354 31.7945 8.2354 31.7987 8.2354C31.803 8.2354 35.0002 8.2354 35.0002 8.2354L34.9999 8.2351Z"
          fill="#B5C9FB"
        />
        <path
          d="M26.6355 34L26.6313 33.9958C24.4921 33.9949 22.3538 33.193 20.7252 31.5896C19.0932 29.9832 18.2772 27.8762 18.2772 25.7712C18.2772 23.666 19.0935 21.5593 20.7255 19.9522C21.5183 19.1716 22.4412 18.5787 23.4322 18.1746C24.4644 17.7532 25.5556 17.5427 26.6349 17.5427L29.5149 17.5364V20.6908L26.6343 20.695C25.9532 20.6947 25.28 20.8212 24.6607 21.0742C24.06 21.3188 23.4897 21.6882 22.9892 22.1813C21.982 23.1729 21.4784 24.4732 21.4787 25.7718C21.4787 27.0701 21.982 28.3699 22.9886 29.3612C23.9976 30.3546 25.3175 30.851 26.6352 30.8528C26.6352 30.8528 26.6352 30.8444 26.6352 30.8486C26.6352 30.8528 26.6352 34.0006 26.6352 34.0006L26.6355 34Z"
          fill="#B5C9FB"
        />
        <path
          d="M2.86102e-05 25.7649L0.00429153 25.7607C0.00520515 23.6546 0.819661 21.5494 2.44827 19.9459C4.07992 18.3395 6.22004 17.5358 8.35833 17.5358C10.4966 17.5358 12.6364 18.3395 14.2687 19.9462C15.0615 20.7268 15.6638 21.6354 16.0742 22.6111C16.5023 23.6273 16.716 24.7017 16.716 25.7643L16.7224 28.5998H13.5185L13.5142 25.7637C13.5145 25.0934 13.386 24.4304 13.1291 23.8207C12.8806 23.2292 12.5055 22.6678 12.0047 22.175C10.9975 21.1833 9.67668 20.6875 8.35772 20.6878C7.03906 20.6878 5.71888 21.1833 4.712 22.1744C3.70299 23.1678 3.19879 24.4672 3.19696 25.7646C3.19696 25.7646 3.20548 25.7646 3.20122 25.7646C3.19696 25.7646 -0.000276566 25.7646 -0.000276566 25.7646L2.86102e-05 25.7649Z"
          fill="#B5C9FB"
        />
        <path
          d="M8.43198 -5.41293e-06L8.43625 0.00419127C10.5754 0.00509056 12.7137 0.806956 14.3423 2.41039C15.9743 4.01682 16.7903 6.12385 16.7903 8.22908C16.7903 10.3343 15.974 12.4411 14.342 14.0481C13.5492 14.8287 12.6264 15.4216 11.6353 15.8257C10.6032 16.2471 9.51194 16.4576 8.43259 16.4576L5.55262 16.4639V13.3095L8.4332 13.3053C9.1143 13.3056 9.78748 13.1791 10.4068 12.9261C11.0075 12.6815 11.5778 12.3122 12.0783 11.819C13.0855 10.8274 13.5891 9.52706 13.5888 8.22849C13.5888 6.93021 13.0855 5.63044 12.0789 4.63912C11.0699 3.64571 9.75003 3.1493 8.43229 3.1475C8.43229 3.1475 8.43229 3.1559 8.43229 3.1517C8.43229 3.1475 8.43229 -0.000305176 8.43229 -0.000305176L8.43198 -5.41293e-06Z"
          fill="#B5C9FB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2086_47330">
          <rect
            width="35"
            height="34"
            fill="white"
            transform="matrix(-1 0 0 1 35 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
