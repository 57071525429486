import React, { useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

import {
  TextField,
  View,
  Flex,
  Badge,
  SelectField
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

import { IAuthEdit } from "../../types/IAuth";
import getBadgeColor from "../../utils/getBadgeColor";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function EqUseRegisterPage() {
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isCancelModal, setIsCancelModal] = useState(false);
  const [payload, setPayload] = useState<IAuthEdit>({
    authority_groups_name: 'グループA｜'
  })

  const openModal = () => {
    setIsModalOpen(true)
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const handleSubmit = () => {
    console.log(payload)
    navigate('/eq/:equipmentId')
    return payload
  };

  const showCancelModal = () => {
    navigate('/eq/:equipmentId')
  }

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: `/eq/list`, label: '機材一覧' },
    { href: '', label: '機材スケジュール' },
  ]

  return (
    <View marginTop={{base: '20px', medium:'0px'}}>
      <PageContent
        title={pageTitle}
        breadcrumbs={breadCrumbsItems}
      >
        <View
          fontSize={16}
          fontWeight={500}
          lineHeight="24px"
          letterSpacing={"0.15px"}
          marginBottom={0}
        >
          機材使用の予定登録を行う場合は「機材詳細」から使用登録を行ってください。
        </View>
        <CustomButton
          {...defaultButtonProps}
          text='機材詳細を確認'
          type="default"
          width='full'
        />
        <View
          fontSize={16}
          fontWeight={500}
          lineHeight="24px"
          letterSpacing={"0.15px"}
          marginTop={10}
          marginBottom={10}
        >
          今から機材を使用する場合のみ、以下の「機材を使用」ボタンをクリックしてください。
        </View>
        <View className="main-content">
          <View
            as='form'
            onSubmit={handleSubmit}
            className='custom-form'
          >
            <View
              border={`1px solid #757575`}
              padding={"5px 20px"}
              width={"fit-content"}
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
            >
              掘削・整地
            </View>
            <Flex marginBottom={40}  marginTop={5}>
              <View
                fontWeight={700}
                fontSize={20}
                lineHeight="24px"
              >
                高所-1
              </View>
              <View
                fontWeight={700}
                fontSize={24}
                lineHeight="29px"
              >
                <Badge
                  backgroundColor={getBadgeColor('使用中').bg}
                  color={getBadgeColor('使用中').color}
                >
                  使用可能
                </Badge>
              </View>
            </Flex>
            <TextField
              label="使用開始日"
              placeholder=""
              type="date"
              value={payload.authority_groups_name}
              onChange={(event) => handleChange('groupName', event.target.value)}
            />
            <TextField
              label="使用終了日（予定）"
              placeholder=""
              type="date"
              value={payload.authority_groups_name}
              onChange={(event) => handleChange('groupName', event.target.value)}
            />
            <SelectField
              label="使用業者"
              onChange={(event) => handleChange('category', event.target.value)}
              icon={<SelectIcon />}
            >
              <option value="XIndustry">Z工業</option>
              <option value="XIndustry">Z工業</option>
            </SelectField>
            <SelectField
              label="使用担当者"
              onChange={(event) => handleChange('category', event.target.value)}
              icon={<SelectIcon />}
            >
              <option value="mountainTaro">建設　太郎</option>
              <option value="mountainTaro">建設　太郎</option>
            </SelectField>
            <SelectField
              label="使用場所"
              onChange={(event) => handleChange('category', event.target.value)}
              icon={<SelectIcon />}
            >
              <option value="notUsed">東館１F</option>
              <option value="notUsed">東館１F</option>
            </SelectField>
            <View marginTop="1rem">
              <View marginBottom={"20px"}>
                <CustomButton
                  {...defaultButtonProps}
                  text='機材を使用'
                  click={openModal}
                  width='full'
                />
              </View>
              <Flex justifyContent={"center"}>
                <CustomButton
                  {...defaultButtonProps}
                  text='キャンセル'
                  type=''
                  click={showCancelModal}
                />
              </Flex>
              <CommonModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                textHeading="機材使用を確定しました"
                textsmall="機材の使用状況を「使用中」に更新しました。"
                periodUse="使用期間：2024.01.01 - 2024.01.01"
                user="使用業者：Z工業"
                users="使用担当者：建設　太郎"
                location="場所：東館１F"
              >
                <CustomButton
                  { ...defaultButtonProps }
                  text='機材スケジュールを確認'
                  iconName='arrow'
                  iconPosition='right'
                  size='medium'
                  width="full"
                  click={handleSubmit}
                />
              </CommonModal>
            </View>
          </View>
        </View>
      </PageContent>
    </View>
  );
}
