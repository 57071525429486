import React from "react";

import "../../../styles/custom-icon.scss";

type Props = {
  hasNotification: boolean;
};

export default function NotificationIcon(props: Props) {
  const hasNotification = props.hasNotification ? "has-notif" : "";
  return (
    <div className={hasNotification}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6.44043V9.77043" stroke="#DCE4E8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M20.5894 15.17C21.3194 16.39 20.7394 17.97 19.3894 18.42C14.6094 20.01 9.43944 20.01 4.65944 18.42C3.21944 17.94 2.66944 16.48 3.45944 15.17L4.72944 13.05C5.07944 12.47 5.35944 11.44 5.35944 10.77V8.67C5.35944 4.98 8.33944 2 12.0194 2C15.6794 2 18.6794 5 18.6794 8.66V10.76C18.6794 10.94 18.6994 11.14 18.7294 11.35" stroke="#DCE4E8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M15.3299 18.8203C15.3299 20.6503 13.8299 22.1503 11.9999 22.1503C11.0899 22.1503 10.2499 21.7703 9.64992 21.1703C9.04992 20.5703 8.66992 19.7303 8.66992 18.8203" stroke="#DCE4E8" strokeWidth="1.5" strokeMiterlimit="10"/>
      </svg>
    </div>
  );
}
