import React from "react";

export default function DuplicateIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7367 2.41016V9.24673H3.50098" stroke="#6C7278" />
      <rect
        x="0.922852"
        y="0.628906"
        width="8.23574"
        height="5.83657"
        stroke="#6C7278"
      />
    </svg>
  );
}
