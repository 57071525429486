import React from "react";
import { Text, Heading } from "@aws-amplify/ui-react";
import "../../../styles/privacy-policy.scss";
import { disclosureProceduresData } from "./constants";

export default function DisclosureProcedures() {
  return (
    <>
      <Heading level={5} className="heading-title" textDecoration={"underline"}>
        {disclosureProceduresData.title}
      </Heading>
      <Text className="content">{disclosureProceduresData.content}</Text>
      {disclosureProceduresData.subContent?.map(
        (
          {
            title,
            content,
            otherContent,
            subContent,
            otherSubContent,
          }: SubContent,
          index: number
        ) => (
          <div className="sub-content-list" key={index}>
            <Text className="list-title">{title}</Text>
            <Text className="content" marginBottom={40}>
              {content}
            </Text>
            {otherContent?.map((data, idx) => (
              <Text
                key={idx}
                className={
                  idx === otherContent.length - 1
                    ? "last-content"
                    : "other-content"
                }
              >
                {data}
              </Text>
            ))}
            {otherSubContent &&
              otherSubContent.map((data, i) => (
                <Text
                  key={i}
                  className={
                    i === otherSubContent.length - 1
                      ? "last-content other-sub-content"
                      : "other-sub-content other-content"
                  }
                  paddingLeft={20}
                >
                  {data}
                </Text>
              ))}
            {subContent?.map((subItem: any, subIndex: number) => (
              <div className="sub-content-list" key={subIndex}>
                <Text className="list-title" fontWeight={200}>
                  {subItem.title}
                </Text>
                <Text
                // className="sub-content" marginBottom={40}
                >
                  {subItem.content}
                </Text>
                {subItem.otherContent?.map((subData: any, subIdx: number) => (
                  <Text
                    key={subIdx}
                    className={
                      subIdx === subItem.otherContent.length - 1
                        ? " last-content"
                        : "other-content"
                    }
                    paddingLeft={20}
                  >
                    {subData}
                  </Text>
                ))}
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
}
