import React, { memo, useMemo, useCallback } from "react";
import { Flex, Table, View } from "@aws-amplify/ui-react";
import "../../../../../styles/custom-calendar.scss";
import CalendarBody, { CalendarBodySP } from "./calendar-body";
import CalendarHeader, { CalendarHeaderSP } from "./calendar-header";
import CalendarToolbar from "./calendar-toolbar";

export const CalendarMonth: React.FC<MonthComponentProps> = ({
  monthDays,
  setCurrentDate,
  toolbar = false,
  eventList,
  handleEventClick,
}: {
  toolbar: boolean;
  setCurrentDate: (args: any) => any;
  handleEventClick: (
    hasEvent: boolean,
    defaultDate: string,
    company_name: any,
    company_id: any
  ) => any;
  monthDays: MonthData;
  eventList: any[];
}) => {
  const currentMonth = useMemo(
    () => Number(monthDays.month) - 1,
    [monthDays.month]
  );
  const currentYear = useMemo(() => Number(monthDays.year), [monthDays.year]);

  const weeks = useMemo(() => {
    const tempWeeks = [];
    for (let i = 0; i < monthDays.dates.length; i += 7) {
      tempWeeks.push(monthDays.dates.slice(i, i + 7));
    }
    return tempWeeks;
  }, [monthDays.dates]);

  const goToBack = useCallback(() => {
    if (currentMonth >= 1) {
      setCurrentDate({
        ...monthDays,
        month: (currentMonth - 1).toString(),
      });
    } else {
      setCurrentDate({
        ...monthDays,
        year: (currentYear - 1).toString(),
        month: "11",
      });
    }
  }, [currentMonth, currentYear, monthDays, setCurrentDate]);

  const goToNext = useCallback(() => {
    if (currentMonth <= 10) {
      setCurrentDate({
        ...monthDays,
        month: (currentMonth + 1).toString(),
      });
    } else {
      setCurrentDate({
        ...monthDays,
        year: (currentYear + 1).toString(),
        month: "0",
      });
    }
  }, [currentMonth, currentYear, monthDays, setCurrentDate]);

  return (
    <Flex className="calendar" direction={"column"}>
      <CalendarToolbar
        goToBack={goToBack}
        goToNext={goToNext}
        label={`${monthDays.year} - ${monthDays.month}`}
      />

      <Table className="calendar-table-wrapper">
        <View className="pc-view">
          <CalendarHeader />
          <CalendarBody
            handleEventClick={handleEventClick}
            weeks={weeks}
            eventList={eventList}
          />
        </View>
        {/* <View className="sp-view">
          <CalendarHeaderSP />
          <CalendarBodySP
            handleEventClick={handleEventClick}
            weeks={weeks}
            eventList={eventList}
          />
        </View> */}
      </Table>
    </Flex>
  );
};

export default memo(CalendarMonth);
