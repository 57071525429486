import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthorityContext } from "../../../../App";
import {
  CheckboxField,
  Flex,
  View,
  SelectField,
  Loader,
  Text,
  TextField,
  ScrollView,
  Button,
  Heading,
  Divider,
} from "@aws-amplify/ui-react";
import moment from "moment";
import _ from "lodash";
import "../../../../styles/calendar.scss";
import CustomButton from "../../../../components/custom-button";
import SelectIcon from "../../../../components/icons/select-icon";
import { useNavigate } from "react-router-dom";
//api
import { EqApi } from "../../../../api/eq";
import { CompanyApi } from "../../../../api/company";
import { SiteApi } from "../../../../api/site";
import DateIcon from "../../../../components/icons/date-icon";

//type
import { IEqCategoryList } from "../../../../types/IEq";
import ArrowRightIcon from "../../../../components/icons/arrow-right";
import ArrowLeftIcon from "../../../../components/icons/arrow-left";
import e from "cors";

//utils
import convertStatus from "../../../../utils/geStatus";
import getDeviceType from "../../../../utils/getDeviceType";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};
function getThisWeeksDates() {
  const today = moment();
  const startOfWeek = today.startOf("week"); // Get the beginning of the current week

  const datesThisWeek: string[] = [];
  for (let i = 0; i < 7; i++) {
    const date = startOfWeek.clone().add(i, "days"); // Create a copy and add days
    datesThisWeek.push(date.format("YYYY-MM-DD")); // Format the date string (adjust format as needed)
  }

  return datesThisWeek;
}

function getNextTargetDateStr(baseDate: string, next: boolean = true) {
  const baseDateMoment = moment(baseDate, "YYYY-MM-DD");
  if (next) {
    return baseDateMoment.clone().add(7, "days").format("YYYY-MM-DD");
  } else {
    return baseDateMoment.clone().subtract(7, "days").format("YYYY-MM-DD");
  }
}

function getWeeksDatesWithStartDate(startDateString: string) {
  const today = moment(startDateString, "YYYY-MM-DD");
  const startOfWeek = today.startOf("week"); // Get the beginning of the current week

  const datesTargetWeek: string[] = [];
  for (let i = 0; i < 7; i++) {
    const date = startOfWeek.clone().add(i, "days"); // Create a copy and add days
    datesTargetWeek.push(date.format("YYYY-MM-DD")); // Format the date string (adjust format as needed)
  }

  return datesTargetWeek;
}

type Props = {
  siteIdSelected: string;
};
//https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CustomCalendar: React.FC<Props> = ({ siteIdSelected }) => {
  const { company, settings, site }: any = useContext(AuthorityContext);
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(getDeviceType() === "mobile");
  const api = EqApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const [displayWeek, SetDisplayWeek] = useState<string[]>(getThisWeeksDates());
  const [displayTableData, SetDisplayTableData] = useState<any[]>([]);
  const [checkboxPayload, SetCheckboxPayload] = useState<any>({});
  //const [displayTableData, SetDisplayTableData]= useState<{}>(staticEquipmentList);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  //const [siteIdSelected, setSiteIdSelected] = useState("");
  const [rentalCompanyDropdown, setRentalCompanyDropdown] = useState([] as any);
  const [useCompanyDropdown, setUseCompanyDropdown] = useState([] as any);
  const [categoryDataList, setCategoryDataList] = useState([] as any);
  const [locationList, setLocationList] = useState([] as any);
  const [selectedCategory1Name, setSelectedCategory1Name] = useState("");
  const [secondaryCategoryList, setSecondaryCategoryList] = useState([] as any);
  const [filterPayload, setFilterPayload] = useState<{ [key: string]: string }>(
    {
      type: "advance",
      company: "",
      rental_company: "",
      use_company: "",
      use_location: "",
      status: "",
      delivery_schedule_start_date: "",
      delivery_schedule_end_date: "",
      result_delivery_schedule_start_date: "",
      result_delivery_schedule_end_date: "",
      return_schedule_start_date: "",
      return_schedule_end_date: "",
      result_return_schedule_start_date: "",
      result_return_schedule_end_date: "",
      site_id: `${siteIdSelected}`,
      equipment_category1_name: "",
      equipment_category2_name: "",
      last_use_actual_use_location: "",
      status_management: "",
    }
  );

  const statusDropdown = [
    {
      value: "before_delivery",
      key: "filter_option_before_delivery",
      label: convertStatus("before_delivery"),
    },
    {
      value: "delivered",
      key: "filter_option_delivered",
      label: convertStatus("delivered"),
    },
    {
      value:"in_reserved",
      key:"filter_option_in_reserved",
      label:convertStatus("in_reserved")
    },
    {
      value: "in_use",
      key: "filter_option_in_use",
      label: convertStatus("in_use"),
    },
//返却済みはそもそも取得しない
//    {
//      value: "return",
//      key: "filter_option_return",
//      label: convertStatus("return"),
//    },
  ];

  const filterElement = () => {
    return (
      <View
        as="div"
        border={`1px solid #757575`}
        borderRadius={8}
        padding={`25px`}
        marginBottom={20}
        fontWeight={`700`}
        minWidth={{'base': 'auto', 'medium': '880px'}}
        style={{'overflowX': 'scroll'}}
      >
        <View as="div" minWidth={{'base': 'auto', 'medium': '1230px'}}>
          <Flex gap={{'base': '0', 'medium': '25px'}} direction={{'base': 'column', 'medium': 'row'}}>
            <View as="div" className="left-filter">
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>カテゴリー（大）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category1_name"]}
                  onChange={(e) => {
                    handleSelectChange(e, "equipment_category1_name");
                    setSelectedCategory1Name(e.target.value);
                  }}
                >
                  <option></option>
                  {categoryDataList?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.equipment_category1_name}
                        key={`${item?.categoryId}`}
                      >
                        {item?.equipment_category1_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>使用状況</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload.status_management}
                  onChange={(e) => handleSelectChange(e, "status_management")}
                >
                  <option></option>
                  {statusDropdown.map((item: any, index: any) => {
                    return (
                      <option value={item?.value} key={`${item?.key}`}>
                        {item?.label}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>使用業者</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["use_company"]}
                  onChange={(e) => handleSelectChange(e, "use_company")}
                >
                  <option></option>
                  {useCompanyDropdown?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.company_id}
                        key={`use_company_${item?.company_id ?? index}-${
                          item?.company_name ?? index
                        }`}
                      >
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
            </View>
            <View as="div" className="right-filter">
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>カテゴリー（中）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category2_name"]}
                  onChange={(e) =>
                    handleSelectChange(e, "equipment_category2_name")
                  }
                >
                  <option></option>
                  {secondaryCategoryList?.map(
                    (equipment_category2_name_item: any, index: any) => {
                      return (
                        <option
                          value={`${equipment_category2_name_item}`}
                          key={`${equipment_category2_name_item}`}
                        >
                          {equipment_category2_name_item}
                        </option>
                      );
                    }
                  )}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>最終使用場所</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["last_use_actual_use_location"]}
                  onChange={(e) =>
                    handleSelectChange(e, "last_use_actual_use_location")
                  }
                >
                  <option></option>
                  {locationList?.map((item: any, index: any) => {
                    return (
                      <option value={item?.name} key={index}>
                        {item?.name.length > 0 ? item?.name : "-"}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>レンタル会社</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["rental_company"]}
                  onChange={(e) => handleSelectChange(e, "rental_company")}
                >
                  <option></option>
                  {rentalCompanyDropdown?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.company_id}
                        key={`rental_company_${item?.company_id ?? index}-${
                          item?.company_name ?? index
                        }`}
                      >
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
            </View>
          </Flex>
          {/* date */}
          <Flex gap={'20px'} direction={{'base': 'column', 'medium': 'row'}}>
            <Flex
              gap={{ base: "10px", medium: "20px" }}
              alignItems={{ base: "flex-start", medium: "center" }}
              direction={{ base: "column", medium: "row" }}
            >
              <Flex
                justifyContent={`center`}
                alignItems={{ base: "flex-start", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
              >
                <View width={'112px'}>受入予定日</View>
                <Flex justifyContent={`center`} alignItems={`center`} direction={{ base: "column", medium: "row" }}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="delivery_schedule_start_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "delivery_schedule_start_date")
                      }
                      value={filterPayload.delivery_schedule_start_date}
                      onChange={(event) =>
                        handleDateChange(event, "delivery_schedule_start_date")
                      }
                      width={{ base: "180px", medium: "220px" }}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
              <View fontSize={16} lineHeight={`24px`}>
                〜
              </View>
              <Flex
                justifyContent={`center`}
                alignItems={`center`}
              >
                <View display={`flex`} position={`relative`}>
                  <TextField
                    label=""
                    id="delivery_schedule_end_date"
                    placeholder=""
                    type="date"
                    onBlur={(event) =>
                      handleBlur(event, "delivery_schedule_end_date")
                    }
                    value={filterPayload.delivery_schedule_end_date}
                    onChange={(event) =>
                      handleDateChange(event, "delivery_schedule_end_date")
                    }
                    width={{ base: "180px", medium: "220px" }}
                  />
                  <View
                    position={`absolute`}
                    right={`21px`}
                    top={`36%`}
                    className="dateIcon"
                  >
                    <DateIcon />
                  </View>
                </View>
              </Flex>
            </Flex>
            <Flex
              gap={{ base: "0", medium: "20px" }}
              alignItems={{ base: "", medium: "center" }}
              direction={{ base: "column", medium: "row" }}
            >
              <Flex
                gap={{ base: "10px", medium: "20px" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
              >
                <Flex
                  justifyContent={`center`}
                  alignItems={{ base: "flex-start", medium: "center" }}
                  direction={{ base: "column", medium: "row" }}
                >
                 <View>返却予定日</View>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="return_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "return_schedule_start_date")
                        }
                        value={filterPayload.return_schedule_start_date}
                        onChange={(event) =>
                          handleDateChange(event, "return_schedule_start_date")
                        }
                        width={{ base: "180px", medium: "220px" }}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex
                  justifyContent={`center`}
                  alignItems={`center`}
                  className="eq-filter-field"
                >
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="return_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "return_schedule_end_date")
                      }
                      value={filterPayload.return_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(event, "return_schedule_end_date")
                      }
                      width={{ base: "180px", medium: "220px" }}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent={`center`} marginTop={20}>
            <CustomButton
              {...defaultButtonProps}
              text="上記で絞り込む"
              type="secondary"
              size="medium"
              click={() => {
                //setCurrentPageIndex(1);
                handleFilterSubmit();
              }}
            />
          </Flex>
        </View>
      </View>
    );
  };


  const getLocationList = async (id: any) => {
    // setLoader(true);
    try {
      const { data } = await siteApi.getDetail(id);
      if (data?.success) {
        setLocationList([...data?.data?.site_construction_locations]);
      } else {
        setLocationList([]);
      }
    } catch (err) {
      return err;
    }
  };


  const handleCheckboxChange = (key: string, flag: boolean) => {
    SetCheckboxPayload({ ...checkboxPayload, [key]: flag });
  };

  const checkedEquipments = useMemo(() => {
    if (checkboxPayload && displayTableData) {
      return displayTableData.filter((item: any) => {
        return checkboxPayload[item.equipment_id] ?? false;
      });
    } else {
      return [];
    }
    
  }, [checkboxPayload, displayTableData]);

  const isInventoryDisable = useMemo(() => {
    if (checkedEquipments && checkedEquipments.length > 0) {
      // 1つでも `status_management` が `'before_delivery'` であれば true
      return checkedEquipments.some(item => item.status_management === 'before_delivery');
    }
    return false; // `checkedEquipments` が空または存在しない場合は false を返す
  }, [checkedEquipments]);

  const IsReturnDisable = useMemo(() => {
    if (checkedEquipments && checkedEquipments.length > 0) {
      // 1つでも `status_management` が `'before_delivery'` であれば true
      return checkedEquipments.some(item => item.status_management === 'before_delivery' || item.status_management === 'in_use');
    }
    return false; // `checkedEquipments` が空または存在しない場合は false を返す
  }, [checkedEquipments]);

  function handleSetNextWeek() {
    SetDisplayWeek(
      getWeeksDatesWithStartDate(getNextTargetDateStr(displayWeek[0], true))
    );
  }
  function handleSetPrevWeek() {
    SetDisplayWeek(
      getWeeksDatesWithStartDate(getNextTargetDateStr(displayWeek[0], false))
    );
  }

  useEffect(() => {
    setLoader(true);
    if (!siteIdSelected || !displayWeek) {
      return;
    }
    const getSchedule = async () => {
      try {
        const { data } = await api.getEqSchedule(
          siteIdSelected,
          displayWeek[0] + " 00:00:00",
          displayWeek.slice(-1)[0] + " 23:59:59",
          filterPayload ?? {}
        );
        if (data?.success) {
          SetDisplayTableData(data?.data);
          const payload: any = {};
          data?.data?.forEach((item: any) => {
            payload[item.equipment_id] = false;
          });
          SetCheckboxPayload(payload);
        } else if (data?.error?.code === 404) {
          SetDisplayTableData([]);
          SetCheckboxPayload({});
          setLoader(false);
          setFetchError(false);
          setShowDropdown(false);
        } else {
          setLoader(false);
          setFetchError(true);
          setShowDropdown(false);
        }
        setLoader(false);
      } catch (err) {
        console.log(err);
        //      setErrorMessage([
        //        "予期しないエラーが発生しました。システム管理者にお問い合わせください。",
        //      ]);
        //      setDisableButton(false);
        //      setIsEditModalOpen(false);
      }
    };
    getSchedule();
    getLocationList(siteIdSelected);
  }, [siteIdSelected, displayWeek]);

  const handleFilterClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFilterSubmit = async () => {
    //setLoader(true);

    setLoader(true);
    const { data } = await api.getEqSchedule(
      siteIdSelected,
      displayWeek[0] + " 00:00:00",
      displayWeek.slice(-1)[0] + " 23:59:59",
      filterPayload
    );
    //   const { data } = await api.searchEqAdvance(
    //     filterPayload.type,
    //     filterPayload
    //   );
    if (data?.success) {
      //const mappedList = mapEqlist(data);
      //setDataList(mappedList);
      //setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      SetDisplayTableData(data?.data);
      const payload: any = {};
      data?.data?.forEach((item: any) => {
        payload[item.equipment_id] = false;
      });
      SetCheckboxPayload(payload);
      setLoader(false);
      setFetchError(false);
      setShowDropdown(false);
    } else if (data?.error?.code === 404) {
      SetDisplayTableData([]);
      SetCheckboxPayload({});
      setLoader(false);
      setFetchError(false);
      setShowDropdown(false);
    } else {
      setLoader(false);
      setFetchError(true);
      setShowDropdown(false);
    }
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = event.target;
    setFilterPayload((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string
  ) => {
    // const { relatedTarget } = event;
    // if (!relatedTarget || !relatedTarget.id.startsWith('date')) {
    //   if (!filterPayload[field]) {
    //     setFilterPayload(prevState => ({
    //       ...prevState,
    //       [field]: '',
    //     }));
    //   }
    // }
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    setFilterPayload((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  //  const getSiteList = async () => {
  //    try {
  //      const { data, status } = await siteApi.getList();
  //      if (status === 200) {
  //        setSiteDropdown([...data?.data]);
  //      }
  //    } catch (e) {
  //      console.error(e);
  //    }
  //  };

  const getCategoryList = async () => {
    setLoader(true);
    const { data } = await api.getCategoryList();
    if (data?.success) {
      const mappedList: IEqCategoryList = data?.data?.map((item: any) => {
        return {
          equipment_category1_name: item.equipment_category1_name,
          equipment_category2_name: Object.values(
            item.secondary_equipment_categories
          ),
          quantity: item.secondary_equipment_categories.length,
          categoryId: item.equipment_category_id,
        };
      });
      setCategoryDataList(mappedList);
      setLoader(false);
      setFetchError(false);
    } else {
      setFetchError(true);
    }
  };

  useEffect(() => {
    const category1 = categoryDataList?.filter(
      (categ: any) => categ.equipment_category1_name === selectedCategory1Name
    );
    if (category1.length > 0) {
      setSecondaryCategoryList(category1[0].equipment_category2_name);
      setFilterPayload((prevState) => ({
        ...prevState,
        equipment_category2_name: "",
      }));
    } else {
      setSecondaryCategoryList([]);
    }

    //setSecondaryCategories(
    //categoryDataList.filter((item:any)=>item.equipment_category1_name === selectedCategory1)
    //)
  }, [categoryDataList, selectedCategory1Name]);

  useEffect(() => {
    //Filter
    const getCompanyList = async () => {
      try {
        const { data, status } = await companyApi.getList();
        if (status === 200) {
          if (
            (company && company.admin_flag === true) ||
            (settings &&
            settings[siteIdSelected] &&
            settings[siteIdSelected][
              "record_control-equipments_manage"
            ])
          ) {
            const filteredCompanies = data?.data?.filter((e: any) => {
              return(
                e.company_category_id === "200" &&
                (company.admin_flag === true ||
                settings[siteIdSelected][
                  "record_control-equipments_manage"
                ].includes(e.company_id))
              );
            });
            setRentalCompanyDropdown(filteredCompanies);
          } else {
            setRentalCompanyDropdown([]);
          };
          if (
            (company && company.admin_flag === true) ||
            (settings &&
              settings[siteIdSelected] &&
              settings[siteIdSelected]["record_control-equipments_schedule_manage"])
          ) {
            const filteredCompanies = data?.data?.filter((e: any) => {
              return (
                e.company_category_id !== "200" &&
                (company.admin_flag === true ||
                settings[siteIdSelected][
                  "record_control-equipments_schedule_manage"
                ].includes(e.company_id))
              );
            });
            setUseCompanyDropdown(filteredCompanies);
          } else {
            setUseCompanyDropdown([]);
          };

        }
      } catch (e) {
        console.error(e);
      }
    };

    getCompanyList();
  }, [settings, siteIdSelected, company]);

  useEffect(() => {
    // filter
    getCategoryList();
    //getSiteList();

    // eslint-disable-next-line
  }, []);

//  useEffect(() => { console.log(displayTableData) }, [displayTableData])

  return (
    <Flex className="calendar-container">
      <Flex direction={"column"} className="pc-view">
        <Flex direction={"row"}></Flex>
        <Flex marginBottom="10px">
          <CustomButton
            {...defaultButtonProps}
            text="絞り込み"
            type="darkblue"
            width="medium"
            click={() => handleFilterClick()}
          />
        </Flex>
        {showDropdown && filterElement()}
        {/*showDropdown && (
          <View
            border={`1px solid #757575`}
            borderRadius={8}
            padding={`25px`}
            marginBottom={20}
            fontWeight={`700`}
            position={`sticky`}
          >
            <Flex gap={20}>
              {/*<Flex justifyContent={`center`} alignItems={`center`}>
                    <View>契約会社</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["company"]}
                      onChange={(e) => handleSelectChange(e, "company")}
                    >
                      <option></option>
                      {companyDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.company_id}
                            key={`${item?.company_id}-${item?.company_name}`}
                          >
                            {item?.company_name}
                          </option>
                        );
                      })}
                    </SelectField>
                    </Flex>*'/}

              <Flex justifyContent={`center`} alignItems={`center`}>
                <View>カテゴリー（大）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category1_name"]}
                  onChange={(e) => {
                    handleSelectChange(e, "equipment_category1_name");
                    setSelectedCategory1Name(e.target.value);
                  }}
                >
                  <option></option>
                  {categoryDataList?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.equipment_category1_name}
                        key={`${item?.categoryId}`}
                      >
                        {item?.equipment_category1_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex justifyContent={`center`} alignItems={`center`}>
                <View>カテゴリー（中）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category2_name"]}
                  onChange={(e) =>
                    handleSelectChange(e, "equipment_category2_name")
                  }
                >
                  <option></option>
                  {secondaryCategoryList?.map(
                    (equipment_category2_name_item: any, index: any) => {
                      return (
                        <option
                          value={`${equipment_category2_name_item}`}
                          key={`${equipment_category2_name_item}`}
                        >
                          {equipment_category2_name_item}
                        </option>
                      );
                    }
                  )}
                </SelectField>
              </Flex>
            </Flex>

            <Flex gap={20}>
              {/*<Flex justifyContent={`center`} alignItems={`center`}>
                    <View>契約会社</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["company"]}
                      onChange={(e) => handleSelectChange(e, "company")}
                    >
                      <option></option>
                      {companyDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.company_id}
                            key={`${item?.company_id}-${item?.company_name}`}
                          >
                            {item?.company_name}
                          </option>
                        );
                      })}
                    </SelectField>
                    </Flex>*'/}

              <Flex justifyContent={`center`} alignItems={`center`}>
                <View>レンタル会社</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["rental_company"]}
                  onChange={(e) => handleSelectChange(e, "rental_company")}
                >
                  <option></option>
                  {companyDropdown?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.company_id}
                        key={`2${item?.company_id}-${item?.company_name}`}
                      >
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              {/* <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>最終使用業者</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                    >
                      <option></option>
                      <option value="EndUser">最終使用業者</option>
                    </SelectField>
                  </Flex> *'/}
            </Flex>
            {/* <Flex gap={20} marginTop={10}>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>使用場所</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["use_location"]}
                      onChange={(e) => handleSelectChange(e, "use_location")}
                    >
                      <option></option>
                      {siteDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.site_id}
                            key={`${item?.site_id}-${item?.site_name}`}
                          >
                            {item?.site_name}
                          </option>
                        );
                      })}
                    </SelectField>
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>使用状況</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["status"]}
                      onChange={(e) => handleSelectChange(e, "status")}
                    >
                      <option></option>
                      {statusDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.value}
                            key={`${item.value}-${item.name}`}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </SelectField>
                  </Flex>
                </Flex> *'/}
            <Flex gap={20} marginTop={10} alignItems={`center`} wrap={`wrap`}>
              <Flex gap={20} marginTop={10} alignItems={`center`}>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>受入予定日</View>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="delivery_schedule_start_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "delivery_schedule_start_date")
                      }
                      value={filterPayload.delivery_schedule_start_date}
                      onChange={(event) =>
                        handleDateChange(event, "delivery_schedule_start_date")
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="delivery_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "delivery_schedule_end_date")
                      }
                      value={filterPayload.delivery_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(event, "delivery_schedule_end_date")
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
              <Flex gap={20} marginTop={10} alignItems={`center`}>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>受入日</View>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="result_delivery_schedule_start_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "result_delivery_schedule_start_date")
                      }
                      value={filterPayload.result_delivery_schedule_start_date}
                      onChange={(event) =>
                        handleDateChange(
                          event,
                          "result_delivery_schedule_start_date"
                        )
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="result_delivery_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "result_delivery_schedule_end_date")
                      }
                      value={filterPayload.result_delivery_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(
                          event,
                          "result_delivery_schedule_end_date"
                        )
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap={20} marginTop={10} alignItems={`center`} wrap={`wrap`}>
              <Flex gap={20} marginTop={10} alignItems={`center`}>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>返却予定日</View>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="return_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "return_schedule_start_date")
                        }
                        value={filterPayload.return_schedule_start_date}
                        onChange={(event) =>
                          handleDateChange(event, "return_schedule_start_date")
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="return_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "return_schedule_end_date")
                      }
                      value={filterPayload.return_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(event, "return_schedule_end_date")
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
              <Flex gap={20} marginTop={10} alignItems={`center`}>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>返却日</View>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="result_return_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "result_return_schedule_start_date")
                        }
                        value={filterPayload.result_return_schedule_start_date}
                        onChange={(event) =>
                          handleDateChange(
                            event,
                            "result_return_schedule_start_date"
                          )
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="result_return_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "result_return_schedule_end_date")
                      }
                      value={filterPayload.result_return_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(
                          event,
                          "result_return_schedule_end_date"
                        )
                      }
                      width={180}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent={`center`} marginTop={20}>
              <CustomButton
                {...defaultButtonProps}
                text="上記で絞り込む"
                type="secondary"
                size="medium"
                click={() => handleFilterSubmit()}
              />
            </Flex>
          </View>
        )*/}
      </Flex>
      <View className="sp-view">
        <Flex
          flex={1}
          marginBottom="30px"
          justifyContent={"end"}
          direction={{ base: "column", medium: "row" }}
        >
          <CustomButton
            {...defaultButtonProps}
            text="絞り込み"
            type="darkblue"
            width="medium"
            click={() => handleFilterClick()}
          />
          {showDropdown && filterElement()}
          {/*showDropdown && (
            <View
              border={`1px solid #757575`}
              borderRadius={8}
              padding={`25px`}
              marginBottom={20}
              fontWeight={`700`}
              position={`sticky`}
            >
              <Flex gap={20} display={{ base: "block" }}>
                {/*<Flex justifyContent={`center`} alignItems={`center`}>
                    <View>契約会社</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["company"]}
                      onChange={(e) => handleSelectChange(e, "company")}
                    >
                      <option></option>
                      {companyDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.company_id}
                            key={`${item?.company_id}-${item?.company_name}`}
                          >
                            {item?.company_name}
                          </option>
                        );
                      })}
                    </SelectField>
                    </Flex>*'/}

                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>カテゴリー（大）</View>
                  <SelectField
                    label=""
                    width={`220px`}
                    icon={<SelectIcon />}
                    value={filterPayload["equipment_category1_name"]}
                    onChange={(e) => {
                      handleSelectChange(e, "equipment_category1_name");
                      setSelectedCategory1Name(e.target.value);
                    }}
                  >
                    <option></option>
                    {categoryDataList?.map((item: any, index: any) => {
                      return (
                        <option
                          value={item?.equipment_category1_name}
                          key={`${item?.categoryId}`}
                        >
                          {item?.equipment_category1_name}
                        </option>
                      );
                    })}
                  </SelectField>
                </Flex>
                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>カテゴリー（中）</View>
                  <SelectField
                    label=""
                    width={`220px`}
                    icon={<SelectIcon />}
                    value={filterPayload["equipment_category2_name"]}
                    onChange={(e) =>
                      handleSelectChange(e, "equipment_category2_name")
                    }
                  >
                    <option></option>
                    {secondaryCategoryList?.map(
                      (equipment_category2_name_item: any, index: any) => {
                        return (
                          <option
                            value={`${equipment_category2_name_item}`}
                            key={`${equipment_category2_name_item}`}
                          >
                            {equipment_category2_name_item}
                          </option>
                        );
                      }
                    )}
                  </SelectField>
                </Flex>
                {/* <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>最終使用業者</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                    >
                      <option></option>
                      <option value="EndUser">最終使用業者</option>
                    </SelectField>
                  </Flex> *'/}
              </Flex>

              <Flex gap={20}>
                {/*<Flex justifyContent={`center`} alignItems={`center`}>
                    <View>契約会社</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["company"]}
                      onChange={(e) => handleSelectChange(e, "company")}
                    >
                      <option></option>
                      {companyDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.company_id}
                            key={`${item?.company_id}-${item?.company_name}`}
                          >
                            {item?.company_name}
                          </option>
                        );
                      })}
                    </SelectField>
                    </Flex>*'/}

                <Flex justifyContent={`center`} alignItems={`center`}>
                  <View>レンタル会社</View>
                  <SelectField
                    label=""
                    width={`220px`}
                    icon={<SelectIcon />}
                    value={filterPayload["rental_company"]}
                    onChange={(e) => handleSelectChange(e, "rental_company")}
                  >
                    <option></option>
                    {companyDropdown?.map((item: any, index: any) => {
                      return (
                        <option
                          value={item?.company_id}
                          key={`2${item?.company_id}-${item?.company_name}`}
                        >
                          {item?.company_name}
                        </option>
                      );
                    })}
                  </SelectField>
                </Flex>
                {/* <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>最終使用業者</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                    >
                      <option></option>
                      <option value="EndUser">最終使用業者</option>
                    </SelectField>
                  </Flex> *'/}
              </Flex>
              {/* <Flex gap={20} marginTop={10}>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>使用場所</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["use_location"]}
                      onChange={(e) => handleSelectChange(e, "use_location")}
                    >
                      <option></option>
                      {siteDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item?.site_id}
                            key={`${item?.site_id}-${item?.site_name}`}
                          >
                            {item?.site_name}
                          </option>
                        );
                      })}
                    </SelectField>
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View>使用状況</View>
                    <SelectField
                      label=""
                      width={`220px`}
                      icon={<SelectIcon />}
                      value={filterPayload["status"]}
                      onChange={(e) => handleSelectChange(e, "status")}
                    >
                      <option></option>
                      {statusDropdown?.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.value}
                            key={`${item.value}-${item.name}`}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </SelectField>
                  </Flex>
                </Flex> *'/}
              <Flex gap={20} marginTop={10} alignItems={`center`} wrap={`wrap`}>
                <Flex
                  gap={20}
                  marginTop={10}
                  alignItems={`center`}
                  display={{ base: "block" }}
                >
                  <Flex
                    justifyContent={`center`}
                    alignItems={`center`}
                    display={{ base: "block" }}
                  >
                    <View>受入予定日</View>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="delivery_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "delivery_schedule_start_date")
                        }
                        value={filterPayload.delivery_schedule_start_date}
                        onChange={(event) =>
                          handleDateChange(
                            event,
                            "delivery_schedule_start_date"
                          )
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                  <Flex
                    fontSize={16}
                    lineHeight={`24px`}
                    display={{ base: "flex" }}
                    justifyContent={`center`}
                  >
                    〜
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="delivery_schedule_end_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "delivery_schedule_end_date")
                        }
                        value={filterPayload.delivery_schedule_end_date}
                        onChange={(event) =>
                          handleDateChange(event, "delivery_schedule_end_date")
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <Flex
                  gap={20}
                  marginTop={10}
                  alignItems={`center`}
                  display={{ base: "block" }}
                >
                  <Flex
                    justifyContent={`center`}
                    alignItems={`center`}
                    display={{ base: "block" }}
                  >
                    <View>受入日</View>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="result_delivery_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(
                            event,
                            "result_delivery_schedule_start_date"
                          )
                        }
                        value={
                          filterPayload.result_delivery_schedule_start_date
                        }
                        onChange={(event) =>
                          handleDateChange(
                            event,
                            "result_delivery_schedule_start_date"
                          )
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                  <Flex
                    fontSize={16}
                    lineHeight={`24px`}
                    display={{ base: "flex" }}
                    justifyContent={`center`}
                  >
                    〜
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="result_delivery_schedule_end_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "result_delivery_schedule_end_date")
                        }
                        value={filterPayload.result_delivery_schedule_end_date}
                        onChange={(event) =>
                          handleDateChange(
                            event,
                            "result_delivery_schedule_end_date"
                          )
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={20} marginTop={10} alignItems={`center`} wrap={`wrap`}>
                <Flex
                  gap={20}
                  marginTop={10}
                  alignItems={`center`}
                  display={{ base: "block" }}
                >
                  <Flex
                    justifyContent={`center`}
                    alignItems={`center`}
                    display={{ base: "block" }}
                  >
                    <View>返却予定日</View>
                    <Flex justifyContent={`center`} alignItems={`center`}>
                      <View display={`flex`} position={`relative`}>
                        <TextField
                          label=""
                          id="return_schedule_start_date"
                          placeholder=""
                          type="date"
                          onBlur={(event) =>
                            handleBlur(event, "return_schedule_start_date")
                          }
                          value={filterPayload.return_schedule_start_date}
                          onChange={(event) =>
                            handleDateChange(
                              event,
                              "return_schedule_start_date"
                            )
                          }
                          width={180}
                        />
                        <View
                          position={`absolute`}
                          right={`21px`}
                          top={`36%`}
                          className="dateIcon"
                        >
                          <DateIcon />
                        </View>
                      </View>
                    </Flex>
                  </Flex>
                  <Flex
                    fontSize={16}
                    lineHeight={`24px`}
                    display={{ base: "flex" }}
                    justifyContent={`center`}
                  >
                    〜
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="return_schedule_end_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "return_schedule_end_date")
                        }
                        value={filterPayload.return_schedule_end_date}
                        onChange={(event) =>
                          handleDateChange(event, "return_schedule_end_date")
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <Flex
                  gap={20}
                  marginTop={10}
                  alignItems={`center`}
                  display={{ base: "block" }}
                >
                  <Flex
                    justifyContent={`center`}
                    alignItems={`center`}
                    display={{ base: "block" }}
                  >
                    <View>返却日</View>
                    <Flex justifyContent={`center`} alignItems={`center`}>
                      <View display={`flex`} position={`relative`}>
                        <TextField
                          label=""
                          id="result_return_schedule_start_date"
                          placeholder=""
                          type="date"
                          onBlur={(event) =>
                            handleBlur(
                              event,
                              "result_return_schedule_start_date"
                            )
                          }
                          value={
                            filterPayload.result_return_schedule_start_date
                          }
                          onChange={(event) =>
                            handleDateChange(
                              event,
                              "result_return_schedule_start_date"
                            )
                          }
                          width={180}
                        />
                        <View
                          position={`absolute`}
                          right={`21px`}
                          top={`36%`}
                          className="dateIcon"
                        >
                          <DateIcon />
                        </View>
                      </View>
                    </Flex>
                  </Flex>
                  <Flex
                    fontSize={16}
                    lineHeight={`24px`}
                    display={{ base: "flex" }}
                    justifyContent={`center`}
                  >
                    〜
                  </Flex>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="result_return_schedule_end_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "result_return_schedule_end_date")
                        }
                        value={filterPayload.result_return_schedule_end_date}
                        onChange={(event) =>
                          handleDateChange(
                            event,
                            "result_return_schedule_end_date"
                          )
                        }
                        width={180}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justifyContent={`center`} marginTop={20}>
                <CustomButton
                  {...defaultButtonProps}
                  text="上記で絞り込む"
                  type="secondary"
                  size="medium"
                  click={() => handleFilterSubmit()}
                />
              </Flex>
            </View>
          )*/}
        </Flex>
      </View>
      <Flex justifyContent={"flex-start"} marginBottom={20}>
          <Flex marginTop="10px" direction={{base: 'column', medium: 'row'}} width='100%'>
            <CustomButton
              {...defaultButtonProps}
              text="選択機材の使用登録"
              type="primary"
              width={isMobile ? 'full' : 'medium'}
              className={`calendar-actions ${checkedEquipments?.length === 0 ? 'disabled' : ''}`}
              disabled={checkedEquipments?.length === 0}
              click={() =>
                navigate("/eq/schedule/regist-bulk", {
                  state: {
                    pageTitle: "使用予定登録",
                    siteId: siteIdSelected,
                    equipmentList: checkedEquipments,
                    defaultDateStart: displayWeek[0],
                    defaultDateEnd: displayWeek.slice(-1)[0],
                  },
                })
              }
            />
            {(company?.admin_flag === true ||
          (settings &&
            settings[siteIdSelected]?.page_control?.includes(
              "inventory_regist"
            ))) && (
            <CustomButton
              {...defaultButtonProps}
              text="選択機材の棚卸登録"
              type="primary"
              width={isMobile ? 'full' : 'medium'}
              className={`calendar-actions ${checkedEquipments?.length === 0 || isInventoryDisable ? 'disabled' : ''}`}
              disabled={checkedEquipments?.length === 0 || isInventoryDisable}
              click={() =>
                navigate("/eq/schedule/inventory-bulk", {
                  state: {
                    pageTitle: "棚卸登録",
                    siteId: siteIdSelected,
                    equipmentList: checkedEquipments,
                    defaultDateStart: displayWeek[0],
                    defaultDateEnd: displayWeek.slice(-1)[0],
                  },
                })
              }
            />)}
            {(company?.admin_flag === true ||
          (settings &&
            settings[siteIdSelected]?.page_control?.includes(
              "return_regist"
            ))) && (
            <CustomButton
              {...defaultButtonProps}
              text="選択機材の返却登録"
              type="primary"
              width={isMobile ? 'full' : 'medium'}
              className={`calendar-actions ${checkedEquipments?.length === 0 || IsReturnDisable ? 'disabled' : ''}`}
              disabled={checkedEquipments?.length === 0 || IsReturnDisable}
              click={() =>
                navigate("/eq/schedule/return-bulk", {
                  state: {
                    pageTitle: "返却登録",
                    siteId: siteIdSelected,
                    equipmentList: checkedEquipments,
                    defaultDateStart: displayWeek[0],
                    defaultDateEnd: displayWeek.slice(-1)[0],
                  },
                })
              }
            />)}
          </Flex>
      </Flex>
      <Flex justifyContent={`center`} alignItems={`center`} gap={`50px`}>
        <Flex justifyContent={`center`} alignItems={`center`}>
          <Button
            style={{ border: "none" }}
            gap={`10px`}
            onClick={() => {
              handleSetPrevWeek();
            }}
          >
            <ArrowLeftIcon />
            <Text>前の1週間</Text>
          </Button>
        </Flex>
        <Flex justifyContent={`center`} alignItems={`center`}>
          <Button
            style={{ border: "none" }}
            gap={`10px`}
            onClick={() => {
              handleSetNextWeek();
            }}
          >
            <Text>次の1週間</Text>
            <ArrowRightIcon />
          </Button>
        </Flex>
      </Flex>
      {loader && (
        <View className="table-loader">
          <Loader width="5rem" height="5rem" filledColor="#2196F3" />
        </View>
      )}
      <Flex maxHeight={`${height}px`} className="calendar-week-wrapper">
        {!loader && displayTableData.length === 0 && (
          <Flex justifyContent={`center`} alignItems={`center`}>
            <Text>No data found.</Text>
          </Flex>
        )}
        {!loader &&
          displayTableData.map((item: any, i: any) => {
            //const { date, company_name }: any = staticEquipmentList[item];
            const {
              equipment_id,
              equipment_name,
              equipment_common_name,
              equipment_status,
              equipment_category1_name,
              onsite_manage_no,
            }: any = item;
            //console.log(item.length - 1, i);
            //const hasSchedule = (d: any) => {
            //  if (moment(d).format("YYYY-MM-DD") === date) return true;
            //  if (
            //    _.isArray(date) &&
            //    date.find((cdate: any) => cdate === moment(d).format("YYYY-MM-DD"))
            //  )
            //    return true;
            //  else return false;
            //};
            //console.log(equipment_status)

            return (
              <>
                <Divider className="form-divider" margin={"0px !important"} />
                <Flex
                  direction={"column"}
                  className={
                    item.length === i
                      ? "calendar-week-main border-bottom"
                      : "calendar-week-main"
                  }
                  key={"parent_flex_" + equipment_id}
                >
                  <Flex direction={"row"}>
                    <Flex
                      direction={"column"}
                      key={
                        equipment_id +
                        "-" +
                        equipment_common_name || equipment_name +
                        "-" +
                        equipment_category1_name
                      }
                    >
                      <div className="action bold-label">
                        <CheckboxField
                          label={equipment_common_name || equipment_name}
                          name={equipment_id}
                          checked={checkboxPayload[equipment_id] ?? false}
                          onChange={(e) =>
                            handleCheckboxChange(equipment_id, e.target.checked)
                          }
                          className="checkbox"
                        />
                      </div>
                      <div
                        className="company-details"
                        key={"company" + onsite_manage_no || equipment_category1_name}
                      >
                        {onsite_manage_no != null ? "現場管理No. " + onsite_manage_no : "現場管理No. -" || equipment_category1_name}
                      </div>
                    </Flex>
                  </Flex>

                  <Flex className="week-detail-wrapper">
                    {displayWeek.map((date: string) => {
                      if (!equipment_status[date.replaceAll("-", "")]) {
                        return <></>;
                      }
                      const isScheduled =
                        equipment_status[date.replaceAll("-", "")].status;
                      //const isScheduled = hasSchedule(date);
                      return (
                        <Flex className="calendar-week-details">
                          <View
                            className={isScheduled ? "scheduled" : "week-day"}
                          >
                            {moment(date).format("M/D")}
                          </View>
                          {isScheduled && (
                            <Text className="company-name">
                              {
                                equipment_status[date.replaceAll("-", "")]
                                  ?.company_name
                              }
                            </Text>
                          )}
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </>
            );
          })}
      </Flex>
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
    </Flex>
  );
};
export default CustomCalendar;
