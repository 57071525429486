import React from "react";
import PageContent from "../../../components/pageContent";
import DeliveryForm from "../components/delivery-form";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "", label: "機材管理 " },
  { href: "", label: "機材一覧" },
  { href: "", label: "使用予定編集" },
];
export default function EquipmentDeliveryPage() {
  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={"油圧式ショベルカー"}
      //  title={detail.equipment_common_name || detail.equipment_name}
    >
      <DeliveryForm />
    </PageContent>
  );
}
