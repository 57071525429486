import { Flex, TextField, View, PasswordField } from "@aws-amplify/ui-react";
import CustomButton from "../../components/custom-button";
import Footer from "../../components/footer";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import "../../styles/login.scss";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "aws-amplify/auth";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: (): void => {},
};

export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasChangedPass, setHasChangedPass] = useState("false");
  const [signInError, setSignInError] = useState(false);
  const checkInfo = localStorage.getItem("check_info");

  const navigate = useNavigate();

  const handleForgotPass = () => {
    navigate("/password/forget");
  };

  async function handleSignIn(username: any, password: any) {
    try {
      const res = await signIn({
        username,
        password,
      }).then((res) => {
        return res;
      });

      if (res.isSignedIn) localStorage.setItem("username", username);
      const signInStep = res?.nextStep?.signInStep;
      if (signInStep === "DONE") {
          localStorage.setItem("justAfterLogin", "true");
          window.location.href = "/"; // It is needed to refresh the app so that context values are updated.
      } else {
        // window.location.href = '/login/introduction'
        navigate("/login/introduction");
      }
      setSignInError(false);
    } catch (error) {
      console.log("error signing in", error);
      setSignInError(true);
    }
  }
  useEffect(() => {
    if (checkInfo === "true" || checkInfo) {
      setHasChangedPass(checkInfo);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <View as="form" className="login-form">
        {hasChangedPass === "true" ? (
          <View className="signin-error-success">
            パスワードを変更しました。 <br />
            新しいパスワードで再度ログインを行なってください。
          </View>
        ) : null}
        {signInError ? (
          <Flex className="signin-error-message">
            <View className="error-icon">
              <ErrorSmallIcon fill="#D32F2F" />
            </View>
            <View>ユーザーID、またはパスワードが間違っています</View>
          </Flex>
        ) : null}
        <TextField
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
          label="ユーザーID"
          className="border-rounded"
        />

        <PasswordField
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
          label="パスワード"
          className="border-rounded-password"
        />
        <CustomButton
          {...defaultButtonProps}
          text="ログイン"
          click={() => handleSignIn(username, password)}
        />

        <Flex className="login-actions">
          <CustomButton
            {...defaultButtonProps}
            type="bordered-transparent"
            text="ログインIDを忘れた方"
            click={() => setIsModalOpen(true)}
          />
          <CustomButton
            {...defaultButtonProps}
            type="bordered-transparent"
            text="パスワードを忘れた方"
            click={handleForgotPass}
          />
        </Flex>
        <Footer
          handleShowMenu={() => {}}
          handleSetUsername={() => {}}
          isAuthenticated={false}
          showMenu={true}
        />
      </View>
      {isModalOpen && (
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading="ユーザーIDを忘れた方へ"
          textOperation={`アカウント作成時に受信したメールを再度ご確認いただくか、\n自社の管理者または招待者にご確認をお願いいたします。`}
        >
          <CustomButton
            {...defaultButtonProps}
            text="戻る"
            type="default"
            iconName="close"
            iconPosition="left"
            click={() => setIsModalOpen(false)}
          />
        </CommonModal>
      )}
    </>
  );
}
