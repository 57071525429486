/* eslint-disable no-sparse-arrays */
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthorityContext } from "../../../App";
import { getEquipScheduleDetails } from "../../../api/eq";
import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import CustomCalendar from "./component/calendar";
import moment from "moment";
import EquipHeaderDetails from "../components/details-form/equip-header";
import EquipTabMenu, {
  EquipTabMenuSP,
} from "../components/details-form/equip-tab-menu";
import { Flex, Loader, Text, View } from "@aws-amplify/ui-react";
import ScheduleDateModal from "./component/schedule-date-modal";
import "../../../styles/eq-schedule-details.scss";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";
import { EquipScheduleDateRefactor } from "../components/details-form/equip-schedule-date";
import DuplicateIcon from "../../../components/icons/duplicate-icon";
import formatDate from "../../../utils/formatDate";

const now = new Date();

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: `/eq/list`, label: "機材一覧" },
  { href: "", label: "機材スケジュール" },
];

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EqScheduleIndexRefactorPage() {
  const { settings, company }: any = useContext(AuthorityContext);
  //   const api = EqApi();
  //   const navigate = useNavigate();
  const { equipmentId } = useParams();
  const navigate = useNavigate();

  const pageTitle = localStorage.getItem("currentPage") ?? "";

  const [detail, setDetail] = useState({
    detailData: {
      equipment_category1_name: "",
      equipment_name: "",
      site_id: "",
      onsite_manage_no: "",
      delivery_schedule: "",
      result_delivery_schedule: "",
      return_schedule: "",
      result_return_schedule: "",
      inventory_datetime: "",
    },
    equipments_status: [],
    status: "",
  });
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [isUneditableModalOpen, setIsUneditableModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDatesOpen, setIsModalDatesOpen] = useState(false);
  const [typeOfSchedule, setTypeOfSchedule] = useState("");

  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    id: "",
  });

  const fetchData = async () => {
    setLoader(true);

    const currentDate = `${moment(now).format("YYYY")}${moment(now).format(
      "MM"
    )}`;
    try {
      const { success, message, stat, equipments_status, detailData }: any =
        await getEquipScheduleDetails(equipmentId ?? "", currentDate);
      const pretty_equipments_status = [...Object.values(equipments_status)];
      if (success) {
        setLoader(false);
        setErrorMessage("");

        setDetail({
          ...detail,
          detailData,
          status: stat,
          equipments_status: pretty_equipments_status as [],
        });
      } else {
        setLoader(false);

        setHasError(true);
        setErrorMessage(message);
      }
    } catch (error) {
      setLoader(false);

      setHasError(true);
    }
  };

  const handleEventClick = useCallback(
    (
      hasEvent: boolean,
      defaultDate: string,
      company_name: string,
      company_id: string
    ) => {
      setCompanyDetails({
        ...companyDetails,
        name: company_name,
        id: company_id,
      });
      if (hasEvent) {
        if (
          company?.admin_flag === true ||
          (settings &&
            settings[detail?.detailData?.site_id] &&
            settings[detail?.detailData?.site_id][
              "record_control-equipments_schedule_manage"
            ] &&
            settings[detail?.detailData?.site_id][
              "record_control-equipments_schedule_manage"
            ].includes(company_id))
        ) {
          setIsModalOpen(true);
        } else setIsUneditableModalOpen(true);
      } else {
        navigate(`/eq/schedule/regist/${equipmentId}`, {
          state: {
            pageTitle: detail?.detailData?.equipment_name,
            category: detail?.detailData?.equipment_category1_name,
            defaultDate,
            onsite_manage_no: detail?.detailData?.onsite_manage_no,
            siteId: detail?.detailData?.site_id,
          },
        });
      }
    },
    [
      companyDetails,
      company?.admin_flag,
      settings,
      detail?.detailData?.site_id,
      detail?.detailData?.equipment_name,
      detail?.detailData?.equipment_category1_name,
      detail?.detailData?.onsite_manage_no,
      navigate,
      equipmentId,
    ]
  );

  const openDatesModal = (typeOfSchedule: string) => {
    setTypeOfSchedule(typeOfSchedule);
    setIsModalDatesOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const detailData = [
    {
      header: "受入予定日",
      icon: false,
      value: formatDate("dot", detail?.detailData?.delivery_schedule),
    },
    {
      header: "受入日",
      icon: false,
      value: formatDate("dot", detail?.detailData?.delivery_schedule),
    },
    ,
    {
      header: "返却予定日",
      icon: false,
      value: formatDate("dot", detail?.detailData?.delivery_schedule),
    },
    {
      header: "返却日",
      icon: false,
      value: formatDate("dot", detail?.detailData?.delivery_schedule),
    },
    {
      header: "最終棚卸日",
      icon: (
        <View className="nav-icon">
          <DuplicateIcon />
        </View>
      ),
      value: formatDate("dot", detail?.detailData?.delivery_schedule),
      onClick: () => openDatesModal("inventories"),
    },
  ];
  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      {hasError ? (
        <Flex className="signin-error-message">
          <View className="error-icon">
            <ErrorSmallIcon fill="#D32F2F" />
          </View>
          <Text>{errorMessage}</Text>
        </Flex>
      ) : null}
      {!loader ? (
        <>
          <EquipScheduleDateRefactor detailData={detailData} />
          <View marginTop={80} className="pc-view">
            <Flex direction={"row"} className="schedule-details">
              <View className="pc-view">
                <EquipHeaderDetails
                  status={detail.status}
                  category={detail?.detailData?.equipment_category1_name ?? ""}
                  name={detail?.detailData?.equipment_name ?? ""}
                />
              </View>
              <EquipTabMenu />
            </Flex>
            <CustomCalendar
              type="calendar-month"
              handleEventClick={handleEventClick}
              eventList={detail.equipments_status}
            />
          </View>
          <Flex className="pc-view form-actions" style={{display:'flex',justifyContent:'end'}}>
            <CustomButton
              {...defaultButtonProps}
              text="機材使用登録"
              size="medium"
              width="small"
              type="primary"
              borderRadius="large"
              //   click={() => navigate(`/eq/${equipmentId}`)}
            />
            <CustomButton
              {...defaultButtonProps}
              text="棚卸登録"
              size="medium"
              width="medium"
              borderRadius="large"
              type="secondary"
              iconName="calendar"
              iconPosition="left"
              //   click={() => navigate(`/eq/${equipmentId}`)}
            />
          </Flex>

          <Flex className="sp-view">
            <Flex direction={"column"} className="eq-header-details">
              <Flex
                direction={"row"}
                justifyContent={"space-between"}
                gap={"20%"}
              >
                <Text className="category" flex={1}>
                  {detail?.detailData?.equipment_category1_name ?? ""}
                </Text>
                <Text className="status" flex={1}>
                  {detail.status}
                </Text>
              </Flex>
              <Text className="name">
                {detail?.detailData?.equipment_name ?? ""}
              </Text>
            </Flex>

            <Flex className="form-actions">
              <CustomButton
                {...defaultButtonProps}
                text="機材使用登録"
                size="medium"
                width="small"
                type="primary"
                borderRadius="large"
                //   click={() => navigate(`/eq/${equipmentId}`)}
              />
              <CustomButton
                {...defaultButtonProps}
                text="棚卸登録"
                size="medium"
                width="medium"
                borderRadius="large"
                type="secondary"
                iconName="calendar"
                iconPosition="left"
                //   click={() => navigate(`/eq/${equipmentId}`)}
              />
            </Flex>
            <EquipTabMenuSP />
            <CustomCalendar
              type="calendar-month"
              handleEventClick={handleEventClick}
              eventList={detail.equipments_status}
            />
          </Flex>
        </>
      ) : (
        <View className="table-loader">
          <Loader width="5rem" height="5rem" filledColor="#2196F3" />
        </View>
      )}

      {isUneditableModalOpen && (
        <CommonModal
          isOpen={isUneditableModalOpen}
          onClose={() => setIsUneditableModalOpen(false)}
          textHeading={`${companyDetails.id}の使用予定があります。`}
        >
          <View
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="閉じる"
              type="default"
              iconName="close"
              iconPosition="left"
              size="large"
              click={() => setIsUneditableModalOpen(false)}
            />
          </View>
        </CommonModal>
      )}
      {isModalOpen && (
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading="自社使用予定"
          textOperation={`${companyDetails?.name}が使用しました`}
        >
          <CustomButton
            {...defaultButtonProps}
            text="閉じる"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={() => setIsModalOpen(false)}
          />
          <CustomButton
            {...defaultButtonProps}
            text="使用予定編集"
            type="primary"
            iconName="pen"
            iconPosition="left"
            size="large"
            click={() => navigate(`/eq/schedule/edit/${equipmentId}`)}
          />
        </CommonModal>
      )}
      {isModalDatesOpen && (
        <ScheduleDateModal
          isModalDatesOpen={isModalDatesOpen}
          openDatesModal={setIsModalDatesOpen}
          typeOfSchedule={typeOfSchedule}
          detailData={detail?.detailData}
        />
      )}
    </PageContent>
  );
}
