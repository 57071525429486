import React from "react";

export default function CircleCheckIcon() {
  return (
    <svg width="131" height="146" viewBox="0 0 131 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="131" height="131" rx="65.5" fill="#D7DFF1" fillOpacity="0.4"/>
      <g filter="url(#filter0_d_790_9000)">
      <rect x="33" y="33" width="65" height="65" rx="32.5" fill="#0C186A" shapeRendering="crispEdges"/>
      <g filter="url(#filter1_d_790_9000)">
      <path d="M65.5 48.833C56.3167 48.833 48.8334 56.3163 48.8334 65.4997C48.8334 74.683 56.3167 82.1663 65.5 82.1663C74.6834 82.1663 82.1667 74.683 82.1667 65.4997C82.1667 56.3163 74.6834 48.833 65.5 48.833ZM73.4667 61.6663L64.0167 71.1163C63.7834 71.3497 63.4667 71.483 63.1334 71.483C62.8 71.483 62.4834 71.3497 62.25 71.1163L57.5334 66.3997C57.05 65.9163 57.05 65.1163 57.5334 64.633C58.0167 64.1497 58.8167 64.1497 59.3 64.633L63.1334 68.4663L71.7 59.8997C72.1834 59.4163 72.9834 59.4163 73.4667 59.8997C73.95 60.383 73.95 61.1663 73.4667 61.6663Z" fill="white"/>
      </g>
      </g>
      <defs>
      <filter id="filter0_d_790_9000" x="9" y="33" width="113" height="113" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="10" operator="erode" in="SourceAlpha" result="effect1_dropShadow_790_9000"/>
      <feOffset dy="24"/>
      <feGaussianBlur stdDeviation="17"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.227451 0 0 0 0 0.54902 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_790_9000"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_790_9000" result="shape"/>
      </filter>
      <filter id="filter1_d_790_9000" x="35.5" y="45.5" width="60" height="62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="12"/>
      <feGaussianBlur stdDeviation="5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_790_9000"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_790_9000" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}