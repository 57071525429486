import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmSignIn, signOut } from 'aws-amplify/auth';
import '../../styles/login-board.scss'

// icons
import ArrowNext from "../../components/icons/arrow-next";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

//custom components
import CustomButton from "../../components/custom-button";

import getDeviceType from "../../utils/getDeviceType";

// types
import { IAccount } from "../../types/ILogin";

import {
  Image,
  Card,
  Divider,
  Flex,
  Heading, 
  Text, 
  TextField, 
  View 
} from "@aws-amplify/ui-react";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: (): void => {}
}

export default function UpdateAccount() {
  const isMobile = getDeviceType() === 'mobile'
  const navigate = useNavigate()  
  const passwordValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]{8,}$/
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [mismatchError, setMismatchError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [hasError, setHasError] = useState(false)
  // @ts-ignore
  const [payload, setPayload] = useState<IAccount>({
    password: '',
    confirmPassword: ''
  })

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const signInPayload = {
    challengeResponse: payload.confirmPassword
  }

  async function handleSignOut() {
    try {
      localStorage.setItem("lastUserVisit", '/');
      await signOut();
      localStorage.setItem("username", String(null));
      navigate('/login')
      window.location.reload()
    } catch (error) {
      return error;
    }
  }

  async function handleConfirmSignIn({challengeResponse} : any) {
    try {
      const res = await confirmSignIn({challengeResponse});
      const signInStep = res?.nextStep?.signInStep
      if(signInStep === 'DONE') {
        handleSignOut()
        navigate('/login')
        localStorage.setItem('check_info', 'true')
      }
      setHasError(false)
      console.log(res, 'jm')
      return res
    } catch (err) {
      setHasError(true)
      // @ts-ignore
      setErrorMessage(err.toString())
    }
  }

  const handleSubmit = () => {
    setPasswordError(() => {
      return payload.password === '' ? true : false
    })
    setConfirmPasswordError(() => {
      return payload.confirmPassword === '' ? true : false
    })
    if(!passwordValidation.test(payload.password)){
      setValidationError(true);
      return
    }
    
    if(payload.password !== '' &&
      payload.confirmPassword !== '') {
        if(payload.password === payload.confirmPassword) {
          setMismatchError(false)
          handleConfirmSignIn(signInPayload)
          return payload
        } else{
          setMismatchError(true)
        }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return null
    }
  };

  return (
    <View className='board-container'>
      <View className='board--header'>
        <Heading level={1}>初期パスワード変更</Heading>
        <Divider size="small" />
      </View>
      {isMobile ? (
        <Image src="/images/updatePassword_SP.png" alt="update-account" width={'100%'} />
      ) : (<Image src="/images/updatePassword_PC.png" alt="update-account" width={'100%'} />)
       /* <Flex
          className='board--progress'
          alignItems={'center'}
          gap={1}
        >
          <Card>
            <Heading level={6}>初回ログイン</Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>
              プライバシーポリシー <br />
              利用規約同意
            </Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>同意完了</Heading>
          </Card>
          <ArrowNext />
          <Card className='card--current'>
            <Heading level={6}>
              パスワード <br />
              アカウント情報更新
            </Heading>
          </Card>
          <ArrowNext />
          <Card>
            <Heading level={6}>再ログイン</Heading>
          </Card>
        </Flex> */
      }
      <View className='board--contents'>
        <Heading level={3}>パスワードを更新してください</Heading>
        <Text className='board--paragraph'>
          以下のフォームよりパスワードを更新し、再度ログイン画面よりログインを行なってください。
        </Text>
      </View>
      <View className="main-content">
        <View 
          as='form'
          className='custom-form'
        >
          { mismatchError ? 
            (
              <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
                <View>
                パスワードが一致しませんでした。
                </View>
              </Flex>
            ) : null
          }
          { validationError ? 
            (
              <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
                <View>
                パスワードポリシーを確認して正しく入力してください。
                </View>
              </Flex>
            ) : null
          }
          { hasError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <View>{ errorMessage }</View>
              </Flex>
            ) : null
          }
          <TextField
            label="パスワード"
            placeholder="8文字以上 半角英数字記号"
            value={payload.password}
            onChange={(event) => handleChange('password', event.target.value)}
            type="password"
            isRequired
            className={ passwordError ? `required input-error` : 'required' }
          />
          <Heading level={6} className="form-note">英大文字と英小文字と数字を混ぜた８文字以上の文字列で入力してください</Heading>
          <TextField
            label="パスワード確認"
            placeholder="再度パスワードを入力してください"
            value={payload.confirmPassword}
            onChange={(event) => handleChange('confirmPassword', event.target.value)}
            type="password"
            isRequired
            className={ confirmPasswordError ? `required input-error` : 'required' }
          />
          <Flex className='board--footer'>
            <CustomButton 
              {...defaultButtonProps}
              text='パスワードを更新'
              click={() => handleSubmit()}
            />
          </Flex>
        </View>
      </View>
    </View>
  )
}