import { ITermsOfService } from "types/ITermOfUse";

export const termOfUseData: ITermsOfService = {
  title: "機材管理サービス”ConPath-D” サービス利用規約",
  otherContent: [
    "本ユーザー利用規約（以下「本規約」）は、株式会社ダイヤモンド建機（以下「当社」）が「ConPath-D」の名称で提供する建設現場の機材管理サービス（以下「本サービス」）の提供条件及び本サービスをご利用いただくお客様と当社との間の権利義務関係を定めるものです。本サービスのご利用に際しては、本規約の全文をお読みください。",
    "お客様は、本規約に承諾の上、本サービスの利用を申し込むものとし、本サービスの申込をした場合、本規約を承諾したものとみなします。",
  ],
  sections: [
    {
      title: "第1条（定義）",
      subContent: [
        "本規約において、以下各号の用語は別途定義されている場合を除き、以下の意味を有するものとします。",
        "(1) 「サービス利用契約」とは、法人、団体、組合及び個人事業主等が本規約に同意の上、所定の手続きにより当社の間で成立する、本規約を契約の内容とする本サービスの利用に係る契約をいいます。",
        "(2) 「登録申請者」とは、サービス利用契約を締結している法人、団体、組合及び個人事業主等に属する者が、本サービスの提供を受けることを目的とし、ユーザー登録を申請する者をいいます。",
        "(3) 「ユーザー」とは、登録申請者の内、当社の判断により本サービスの提供を受けることができる者をいいます。",
      ],
    },
    {
      title: "第2条（適用）",
      sections: [
        "1. 本規約は、ユーザーと当社との間の本サービス利用に係る全ての関係に適用されます。",
        "2. 本サービスを利用した場合、ユーザーは本規約の内容を承諾したものとみなされます。",
        "3. 当社が当社ウェブサイトやアプリケーション上に記載する本サービスに係る説明、ルール、規定は本規約の一部を構成するものとします。",
      ],
    },
    {
      title: "第3条（本規約等の変更）",
      sections: [
        "1. 当社は、本規約及び本サービスの内容を変更できるものとします。",
        "2. 当社が前項の規定により本規約を変更する場合には、変更の内容及び効力発生時期を、効力発生日の相当期間前に、変更後の本規約の内容をユーザーに通知するものとします。",
      ],
    },
    {
      title: "第4条（利用申込）",
      sections: [
        "1. 本サービスへの登録を希望する者は、本規約の内容を承諾の上、当社が定める一定の情報（以下「登録情報」）を正確に入力し、当社に提供することにより、本サービスの登録申請をすることができます。なお、登録申請者は、当社ウェブサイト又は個別契約等において本規約に同意したときに、本規約の個別の条項についても同意したものとみなされます。",

        "2. 当社は、本サービスへの登録申請者より前項に基づく登録の申請があった場合、当社の基準に従って、本サービスへの登録可否を判断し、登録可否の決定を当該登録申請者に通知します。本サービスへの登録可能との通知を以て、ユーザー登録は完了し、本サービスのユーザーとして本サービスを利用できます。",
        {
          title:
            "3. 当社は次の各号に該当する場合、本サービスの登録申請を承諾しないことがあります。",
          subSections: [
            "(1) 登録申請者が登録申請に際し、登録情報に虚偽の事実を記載した場合。",
            "(2) 登録申請者が登録申請の際に正確性、完全性に欠け、または最新のものではない登録情報を提供したと当社に合理的に認められる場合。",
            "(3) 登録申請者が登録申請に係る本規約の義務を怠るおそれがあると当社が判断した場合。",
            "(4) 登録申請者が当社または本サービスの信用を毀損するおそれがある態様で本サービスを利用するおそれがあると当社が判断した場合。",
            "(5) 登録申請者が本規約の第8条に該当する禁止行為を行ったことがある場合、または行うおそれがあると当社が判断した場合。",
            "(6) 登録申請を承諾することが、技術上または本サービスの提供の他、当社の業務遂行上著しい支障があると当社が判断した場合。",
            "(7) 登録情報の内容に、第三者の会社名、組織名、商標、登録商標等が含まれており、第三者の権利侵害になると判断した場合。",
            "(8) 登録申請を承諾することにより、反社会団体による利用のおそれがある場合、もしくは反社会行為、その他犯罪行為に利用されるおそれがあると当社が判断した場合。",
            "(9) 前各号のほか、当社がサービス利用契約の締結を適当でないと判断した場合。",
          ],
        },
      ],
    },
    {
      title: "第5条（ユーザーID・パスワードの管理）",
      sections: [
        "1. ユーザーは、付与されるユーザーID及びパスワードを、自己の責任において管理及び保管するものとし、ユーザーID及びパスワードを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。",
        "2. ユーザーは、ユーザーID及びパスワードの盗難・失念・第三者の利用等があった場合、直ちに当社にその旨を連絡するとともに、当社からの指示がある場合にはこれに従うものとします。",
        "3. 前項に定める義務違反、ユーザーID及びパスワードの管理不十分、使用上の過誤、第三者の利用、パスワード変更義務を怠ったこと等による損害等の責任はすべてユーザーが負うものとし、当社は一切の責任を負わないものとします。",
      ],
    },
    {
      title: "第6条（登録情報の変更）",
      sections: [
        "1. ユーザーは、本サービスの登録情報の内容に変更があったとき、速やかにその旨を当社所定の方法により当社に届け出るものとします。",
        "2. ユーザーが前項の届け出を怠った事による損害等について、当社は一切の責任を負わないものとします。",
      ],
    },
    {
      title: "第7条（利用停止）",
      content:
        "当社は、合理的な理由が存する場合には、当社の判断に基づき、事前に通知することなく、当該ユーザーについて本サービスの利用を一時的に停止、又はユーザーとしての登録を取り消すことができます。なお、これに係る損害について当社は一切の責任を負いません。",
    },
    {
      title: "第8条（禁止事項）",
      sections: [
        {
          title:
            "1.   ユーザーは、本サービスの利用にあたって以下の各号のいずれかに該当する行為をしてはなりません。",
          subSections: [
            "(1)   当社、他のユーザー、又は第三者の著作権、商標権、その他の権利、名誉を侵害する行為。",
            "(2)   当社、他のユーザー、又は第三者の財産もしくはプライバシーを侵害する行為、または侵害する恐れのある行為。",
            "(3)   公序良俗に反する行為もしくはその恐れのある行為。",
            "(4)   犯罪的行為もしくは犯罪的行為に結びつく行為、またはその恐れのある行為。",
            "(5)   当社の事業や本サービスの運営を妨げる行為。",
            "(6)   ユーザーID及びパスワードを不正に使用する行為。",
            "(7)   コンピュータウィルスその他の有害なプログラムを本サービスに関連して使用もしくは提供する行為。",
            "(8)   本サービスに登録された個人情報及び個人データの目的外の加工、複写又は複製を行う行為。",
            "(9)   その他、本規約や法令に違反する行為及び当社が不適切と判断する行為。",
          ],
        },
        "2.   当社が、ユーザーが前項各号のいずれかに該当すると判断した場合、事前に通知することなく、当該ユーザーによる本サービスの利用停止、登録取消等の措置を講じることができるものとします。尚、これに係る損害について当社は一切の責任を負いません。",
        "3.   ユーザーは、第１項の規程に違反したことにより当社が被った損害を賠償しなければなりません。",
      ],
    },
    {
      title: "第9条（本サービスの中止等）",
      sections: [
        {
          title:
            "1. 当社は、次の場合に自らの判断で本サービスを中止することがあります。",
          subSections: [
            "(1) 本サービスに係るシステムの保守を定期的または緊急に行う場合。",
            "(2) 不可抗力、天災、事変、その他の非常事態が発生し、もしくは発生する恐れがある場合。",
            "(3) システム、コンピューター、通信回線等が事故により停止し、本サービスを利用できなくなった場合。",
            "(4) 本サービスの提供に必要な設備の障害等により本サービスの提供が困難となった場合。",
            "(5) 本サービスの利用料の支払いが確認できない場合。",
            "(6) その他、当社が停止又は中断を必要と判断した場合。",
          ],
        },
        "2.   当社は、当社都合により、本サービスの提供を終了することができるものとします。",
        "3.   当社は、本条に基づき当社が行った措置によってユーザー又は第三者に生じた損害について一切の責任を負わないものとします。",
      ],
    },
    {
      title: "第10条（個人情報その他の情報の取り扱い）",
      content:
        "当社は、ユーザーの情報を、別途定める本サービスのプライバシーポリシーに従って適切に取り扱います。",
    },
    {
      title: "第11条（知的財産権）",
      content:
        "本サービスを構成するソフトウェア、プログラム、データベース、画像、映像、文章、その他の有形又は無形の資産に関する特許権（特許を受ける権利を含みます）、実用新案権（実用新案登録を受ける権利を含みます）、意匠権（意匠登録を受ける権利を含みます）、商標権、著作権、その他の知的財産権は、当社又は当該権利を有する第三者に帰属します。ユーザーは、本サービスについて、リバースエンジニアリング、その他、本サービスの解析を目的とする行為を行わないものとします。",
    },
    {
      title: "第12条（反社会的勢力の排除）",
      sections: [
        {
          title:
            "1. ユーザーは、自己及びその代表者、役員、その他、実質的に経営を支配する者が、以下のいずれにも該当しないことを保証し、将来においても該当しないことを確約します。",
          subSections: [
            "(1) 暴力団、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団、それらの構成員又はこれに準ずる者（以下｢反社会的勢力｣）",
            "(2) 反社会的勢力でなくなったときから5年を経過しない者",
            "(3) 反社会的勢力に対する資金提供、便宜の供給、その他密接な関わりを有する者",
          ],
        },
        "2. 当社は、ユーザーが上記保証又は確約に違反した場合、事前の催告なく、直ちに本サービスの利用を停止することができます。本項に係る行使はユーザーに対する損害賠償請求を妨げないものとします。なお、本サービスの利用の停止によりユーザーに生じた損害について賠償する責任を負わないものとします。",
      ],
    },
    {
      title: "第13条（損害賠償）",
      sections: [
        "1. ユーザーが本規約に違反することによって、当社、他のユーザー又は第三者に損害等を与えた場合、当該ユーザーは自己の費用と責任において当該損害等を賠償しなければならず、当社は一切の責任を負いません。",
        "2. ユーザーが本規約に違反することによって、他のユーザー又は第三者との間で紛争が生じた場合、当該ユーザーは自己の費用と責任において当該紛争を解決するものとし、当社は一切の責任を負いません。",
        "3. ユーザーが本規約に違反することによって、当社とユーザーとの間で紛争が生じた場合、当社及びユーザー間で協議することにより解決するものとします。当社は当該紛争によりユーザーに生じた損害について一切の責任を負いません。",
      ],
    },
    {
      title: "第14条（譲渡）",
      content:
        "ユーザーは、法の作用その他にかかわらず、当社の書面による承諾なく、取引上発生する債権、債務、権利、義務又は契約上の地位を譲渡又は移転することはできません。当社は、ユーザーの同意なく、当社の関連会社に対し、合併、分割、株式交換、株式移転、買収、事業譲渡、その他の企業再編に関連して、本規約上の債権、債務、権利、義務又は規約上の地位を譲渡することができます。",
    },
    {
      title: "第15条（分離可能性）",
      content:
        "本規約のいずれかの条項が何らかの理由により無効又は執行不能であっても、本規約の他の条項が無効又は執行不能となるものではありません。裁判所において本規約のある規定が無効又は執行不能とされた場合、当該規定は有効かつ執行可能となるために必要最小限度の修正がなされた内容として解釈されるものとします。",
    },
    {
      title: "第16条（存続条項）[KM(TW1] ",
      content:
        "本規約の終了後も存続することが予定されている各条項は、本サービスが存続する限り有効とします。",
    },
    {
      title: "第17条（準拠法及び管轄裁判所）",
      content:
        "本規約の準拠法は日本法とします。本規約に関する紛争については、乙の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。",
    },
    {
      title: "第18条（誠実協議）",
      content:
        "本規約の内容に関する疑義、又は本規約に定めのない事項については、当社とユーザーの間で誠意をもって協議の上、解決するものとします。",
    },
  ],
};
