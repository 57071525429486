import React from "react";
import { Text, Heading } from "@aws-amplify/ui-react";
import "../../../styles/privacy-policy.scss";
import { PrivacyPolicyData } from "./constants";

//* First Section
export default function PrivacyPolicy() {
  return (
    <>
      <Heading level={1} className="section-title">
        {PrivacyPolicyData.title}
      </Heading>
      <Text className="content">{PrivacyPolicyData.content}</Text>
      {PrivacyPolicyData.subContent?.map((data) => (
        <>
          {data?.title ? (
            <Heading level={5} className="sub-content-title">
              {data?.title}
            </Heading>
          ) : null}
          <Text className="content">{data.content}</Text>
        </>
      ))}
      {PrivacyPolicyData.otherContent?.map((data: string, index: number) => (
        <Text
          className={
            index === PrivacyPolicyData.otherContent.length - 1
              ? "last-content"
              : "other-content"
          }
        >
          {data}
        </Text>
      ))}
    </>
  );
}
