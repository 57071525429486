import React, { useEffect, useState,useContext } from "react";
import PageContent from "../../components/pageContent";
// amplify ui components
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  View,
  Loader,
  Text,
  Flex,
  IconsProvider,
  Pagination,
  Badge,
} from "@aws-amplify/ui-react";

// icons
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";

import { ILogs } from "../../types/ILogs";
import { NoticeApi } from "../../api/notice";
import { SiteApi } from "../../api/site";
import formatDate from "../../utils/formatDate";
import checkFeature from "../../utils/checkFeature";
import { AuthorityContext } from "../../App";
import '../../styles/notice.scss'

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "", label: "更新履歴" },
];

type SiteSelect = {
  value: string;
  label: string;
};

const logsList = [
  {}
]


export default function LogsPage() {
  const api = NoticeApi();
  const siteApi = SiteApi();
  const { site, company }: any = useContext(AuthorityContext);
  const [dataList, setDataList] = useState<ILogs[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [siteId, setSiteId] = useState("");
  const itemsLimit = 20;
  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)
  const currentDate = new Date().toISOString().slice(0, 10)
  const [siteSelections, setSiteSelections] = useState<
  SiteSelect[] | undefined
>([]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    getList(itemsLimit, currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    getList(itemsLimit, currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const isJSON = (value: string) => {
    try {
      return JSON.parse(value) && !!value;
    } catch (e) {
      return false;
    }
  };

  const checkNote = (value: string, toSearch: string) => {
    //const translated = checkFeature(toSearch) as string;
    //いつかAPI側でログの取り方を修正できたらここら辺変わる
    return value.replace(/sites のエントリ/, '現場情報');
  };

  const getList = async (
    limit: number = itemsLimit,
    page: number = currentPageIndex
  ) => {
    setLoader(true);
    console.log(currentDate)
    try {
      const { data } = await api.getList(limit, page, +siteId);
      if (data?.success) {
        const mappedList: ILogs[] = data?.data?.map((item: any) => {
          return {
            date: formatDate(null, item.created_at),
            type: item.type,
            feature: isJSON(item.description)
              ? checkFeature(JSON.parse(item.description).table)
              : item.description,
            note: isJSON(item.description)
              ? checkNote(
                //いつかAPI側でログの取り方を修正できたらここら辺変わる
                  JSON.parse(item.description).after[0].status ? 
                  JSON.parse(item.description).note != 'sites のエントリが更新されました' ? '現場情報が登録されました' : JSON.parse(item.description).note
                   : '現場がクローズされました',
                  JSON.parse(item.description).table
                )
              : item.description,
            userId: item.user_id,
          };
        });

        setDataList(mappedList);
        setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
        setTotalCount(data?.data?.totalCount || data?.meta?.totalCount);
        setLoader(false);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteId(value);
  };

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            /*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/
            setSiteId(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteId(mappedSiteList[0].value)
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        /*setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/
        setSiteId(initialSelectedSiteId)
        setSiteSelections(mappedSiteList);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteId(mappedSiteList[0].value);
      }
    };
 
    // eslint-disable-next-line
  }, [site, company]);

  useEffect(() => {
    if(siteId !== ""){
      getList();
    }
    // eslint-disable-next-line
  }, [siteId]);

  useEffect(() => {}, [dataList]);

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={"通知一覧"}
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue={siteId}
      changeEvent={setSiteForFilter}
    >
      <View className="custom-table">
        {!loader ? (
          <>
            <Table highlightOnHover={true}>
              <TableBody>
                {dataList?.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="notice-cell" data-status='NEW'>
                        <View as="span" className="notice-date">{item.date} </View>
                        { item.date === currentDate && (
                          <Badge className="notice-status">NEW</Badge>
                        ) }
                        <View as="span" className="notice-note">{item.note}</View>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              lineHeight="20px"
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className={`custom-pagination ${
                    currentPageIndex === totalPages ? "disabled" : ""
                  }`}
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
      </View>
    </PageContent>
  );
}
