import { View, Flex, Heading } from "@aws-amplify/ui-react";
import React from "react";
import "../../styles/forgot-password.scss";
import ForgotPasswordForm from "./forgot-password-form";
import LoginIcon from "../../components/icons/login-icon";

function index() {
  return (
    <Flex className="forgot-password-container">
      <View className="forgot-password-main">
        <View className="forgot-password-headline">
          <Heading level={1} color={"white"}>
            パスワードの リセット
          </Heading>
          <Heading level={5} color={"white"}>
            Enter your email account to reset password
          </Heading>
        </View>
        <LoginIcon />
      </View>

      <ForgotPasswordForm />
    </Flex>
  );
}

export default index;
