import React from "react";
import { Text, Heading } from "@aws-amplify/ui-react";
import "../../../styles/privacy-policy.scss";
import { anonymizedInfoUsageData } from "./constants";

export default function AnonymizedInfoUsage() {
  return (
    <>
      <Heading level={5} className="heading-title" textDecoration={"underline"}>
        {anonymizedInfoUsageData.title}
      </Heading>
      {anonymizedInfoUsageData.otherContent.map((data, index) => (
        <Text
          className={
            index === anonymizedInfoUsageData.otherContent.length - 1
              ? "last-content"
              : "other-content"
          }
        >
          {data}
        </Text>
      ))}
    </>
  );
}
