import React, { useEffect, useState, useContext } from "react";
import PageContent from "../../components/pageContent";
// Amplify UI
import {
  Text,
  View,
  Card,
  Flex,
  Heading,
  ThemeProvider,
  Theme,
  Loader,
  Button
} from "@aws-amplify/ui-react";
import { IEqCategoryList } from "../../types/IEq";
import { EqApi } from "../../api/eq";
import { SiteApi } from "../../api/site";
import PlusIcon from "../../components/icons/plus-icon.tsx";
import { AuthorityContext } from "../../App";
// import ErrorSmallIcon from "../../components/icons/error-small-icon";
// import { Link } from 'react-router-dom';
// header custom css
import "src/styles/card.scss";
import { useNavigate } from "react-router-dom";

const sortedCategoryList = [//ここの表示順になる & arrayで区切り位置が決まる
  { equipment_category1_name: '高所作業車', equipment_category1_name_array: ['高所作業車'], category_units: '32' },
  { equipment_category1_name: '仮設作業台(仮設作業材)', equipment_category1_name_array: ['仮設作業台','(仮設作業材)'], category_units: '0' },
  { equipment_category1_name: '荷役・揚重', equipment_category1_name_array: ['荷役・揚重'], category_units: '60' },
  { equipment_category1_name: '照明', equipment_category1_name_array: ['照明'], category_units: '4' },
  { equipment_category1_name: '発電機・溶接機', equipment_category1_name_array: ['発電機・溶接機'], category_units: '160' },
  { equipment_category1_name: 'コンプレッサ・エア機器', equipment_category1_name_array: ['コンプレッサ','エア機器'], category_units: '2' },
  { equipment_category1_name: '通信・計測・測量・測定機器', equipment_category1_name_array: ['通信・計測','測量・測定機器'], category_units: '14' },
  { equipment_category1_name: '電動工具・汎用機器', equipment_category1_name_array: ['電動工具','汎用機器'], category_units: '88' },
  { equipment_category1_name: 'コンクリート機器・打設機器', equipment_category1_name_array: ['コンクリート機器','打設機器'], category_units: '36' },
  { equipment_category1_name: '水中ポンプ・水処理機械', equipment_category1_name_array: ['水中ポンプ','水処理機械'], category_units: '184' },
  { equipment_category1_name: '保安用品', equipment_category1_name_array: ['保安用品'], category_units: '8' },
  { equipment_category1_name: 'レンタカー車両', equipment_category1_name_array: ['レンタカー車両'], category_units: '2' },
  { equipment_category1_name: '土木用・道路用機械', equipment_category1_name_array: ['土木用','道路用機械'], category_units: '0' },
  { equipment_category1_name: 'ハウス・トイレ・備品・イベント', equipment_category1_name_array: ['ハウス・トイレ','備品・イベント'], category_units: '116' },
  { equipment_category1_name: '基礎・地盤改良機', equipment_category1_name_array: ['基礎・地盤改良機'], category_units: '0' },
  { equipment_category1_name: 'ICT関連機器', equipment_category1_name_array: ['ICT関連機器'], category_units: '0' },
  { equipment_category1_name: 'トンネル機械', equipment_category1_name_array: ['トンネル機械'], category_units: '0' },
  { equipment_category1_name: 'その他', equipment_category1_name_array: ['その他'], category_units: '148' },
]

const theme: Theme = {
  name: 'card-theme',
  tokens: {
    components: {
      card: {
        backgroundColor: { value: '#fff' },
        borderRadius: { value: '10px' },
        padding: { value: '20px' },
        boxShadow: { value: "#000 0px 2px 6px -2px"}
      },
      button: {
        borderWidth: '0',
        outlined: {
          info: {
            borderColor: 'transparent',
          },
        },
        _hover: {
          backgroundColor: 'transparent',
          borderColor: 'transparent'
        },
        _focus: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none'
        },
        _active: {
          backgroundColor: 'transparent',
          borderColor: 'transparent'
        }
      }
    },
  },
};

type SiteSelect = {
  value: string;
  label: string;
};

export default function CategoriesListPage() {
  const api = EqApi();
  const siteApi = SiteApi();
  const { company, settings, site }: any = useContext(AuthorityContext);
  const navigate = useNavigate()
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [dataList, setDataList] = useState([] as any);
  const [activeButton, setActiveButton] = useState(-1);
  const [data, setData] = useState(null);
  const [siteSelections, setSiteSelections] = useState<
  SiteSelect[] | undefined
>([]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";
  const [siteId, setSiteId] = useState(initialSelectedSiteId) ?? "";
  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '', label: '機材管理' },
    { href: '', label: 'カテゴリー' },
  ]


  const getList = async (siteId:string) => {
    setLoader(true)
    const { data } = await api.getCategoryList(siteId);
    if (data?.success) {
      const mappedList: IEqCategoryList = data?.data?.map((item: any) => {
        return {
          equipment_category1_name: item.equipment_category1_name,
          //equipment_category2_name: item.secondary_equipment_categories[0],
          //quantity: item.secondary_equipment_categories.length, ◁ そもそもこれじゃあ仕様を満たしていない
          categories: item.secondary_equipment_categories,
          total_quantity: Number(item.equipment_total_quantity) > 999 ? 999 : item.equipment_total_quantity,
          unit:  Number(item.equipment_total_quantity) > 999 ? "+" : "台",
          //categoryId: item.equipment_category_id
        };
      });
      console.log(mappedList);
      setDataList(mappedList);
      setLoader(false)
      setFetchError(false)
    } else {
      setFetchError(true)
    }
  };

  const handleButtonClick = (index: number) => {
    if (activeButton === index) {
      setActiveButton(-1)
    } else {
      setActiveButton(index)
    }
  };

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteId(value);
  };

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            /*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/
            setSiteId(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteId(mappedSiteList[0].value)
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        /*setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/
        setSiteId(initialSelectedSiteId)
        setSiteSelections(mappedSiteList);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteId(mappedSiteList[0].value);
      }
    };
 
    // eslint-disable-next-line
  }, [site, company]);

  useEffect(() => {
    if(siteId != ""){
      getList(siteId);
    }
  // eslint-disable-next-line
  }, [siteId])

  return (
    <PageContent
        breadcrumbs={breadCrumbsItems}
        title={pageTitle}
        showSelectField={true}
        selectFieldOptions={siteSelections}
        initialValue={siteId}
        changeEvent={setSiteForFilter}
    >
      <View as="div">
        <Text
          textAlign={"left"}
          fontSize={20}
          fontWeight={400}
          lineHeight="30px"
          marginBottom={20}
        >
          カテゴリー
        </Text>
        <Text
          textAlign={"left"}
          fontSize={12}
          fontWeight={500}
          lineHeight="16px"
          letterSpacing="0.15px"
        >
          カテゴリーをクリックすると該当する機材の一覧を表示します。
        </Text>
      </View>
      {!loader ? (
         <>
          <ThemeProvider theme={theme} colorMode="light">
            <Flex
              marginTop={25}
              gap={'16px'}
              wrap="wrap"
              marginLeft={{
                base: '0',
                medium: '-15px',
              }}
            >
              { sortedCategoryList.map((sorted_item: any, index: number) => {
                const item = dataList?.filter((e: any) => e.equipment_category1_name.replaceAll('・','') === sorted_item.equipment_category1_name.replaceAll('・',''))[0];
                if (!item){
                  return <></>
                }
                return (
                  <View as="div" className="card-dropdown-holder" key={index}>
                    <Button className="card-btn" position={"relative"} paddingLeft={{'base': '0'}} paddingRight={{'base': '0'}} onClick={() => handleButtonClick(index)}>
                      <Card className="card" paddingRight={'10px'}>
                        <Flex alignItems="flex-start" justifyContent="space-between">
                          <View className="category-name" flex={1}>
                            {/*<Heading level={4} className="category-name-list">{ item.equipment_category1_name }</Heading>*/}
                            {sorted_item.equipment_category1_name_array.map((name: string) => <Heading level={4} className="category-name-list" minWidth={'auto'} padding={'0 0 0 20px'}>{name}</Heading>)}
                            {/*<Text as="span" className="card-text">{ item.equipment_category2_name }</Text>*/}
                          </View>
                          <View color={'#4063DE'} fontSize={'1.7rem'} lineHeight={'1.2rem'} fontWeight={'bold'} paddingTop={'3px'}>
                            {item.total_quantity}<View as="span" fontSize={'1rem'}>{item.unit}</View>
                          </View>
                        </Flex>
                        <View
                          position={"absolute"}
                          bottom={{
                            base: '-6px'
                          }}
                          right={{
                            base: '0'
                          }}
                        >
                          <PlusIcon />
                        </View>
                      </Card>
                    </Button>
                    { activeButton === index && (
                      <View as="ul" className="card-dropdown">
                        {Object.entries(item?.categories).map(([key, value]) => {
                          return (
                            <View as="li" key={`category-${key}_${index}`} className="card-dropdown-item" 
                              onClick={() => navigate('/eq/list', { state: { type: 'category', categoryId: key }})}>
                              {/*@ts-ignore */}  
                                {value}
                            </View>)
                        }) }
                      </View>
                    )}
                  </View>
                )
              })}
            </Flex>
          </ThemeProvider>
         </>
      ) : (
        <View className="table-loader">
          <Loader width="5rem" height="5rem" filledColor="#2196F3" />
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
    </PageContent>
  );
}