import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// amplify ui
import { Flex, Text, View } from "@aws-amplify/ui-react";
import CommonModal from "../../components/common-modal";
import CustomButton from "../../components/custom-button";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../components/qr-frame";

import { EqApi } from "../../api/eq";
import { CompanyApi } from "../../api/company";
import { AuthorityContext } from "../../App";

import '../../styles/qrstyles.scss'
import { IEqQrParams } from "types/IEq";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: 'left',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function QrCodeAlertPage() {
  const api = EqApi()
  const companyApi = CompanyApi();
  const { company, settings, site }: any = useContext(AuthorityContext);
  const navigate = useNavigate()
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [scannedResult, setScannedResult] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyList, setCompanyList] = useState([] as any)
  const [hasFormError, setHasFormError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Success
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    const res = result?.data.split(' ')
    if(res?.length > 2) {
      setScannedResult(res.slice(0, -1).filter((r)=>r.trim()!==""));
    } else {
      setScannedResult(res);
    }
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    // console.log(err);
  };

  const siteId = localStorage.getItem("currentSiteId") ?? "";
  const getCompanyList = async () => {
    // company_category_id
    try {
    const { data } = await companyApi.getList()
    let list: any = []
      if(data?.success) {
        // eslint-disable-next-line
        if(
          (company && company.admin_flag === true) ||
          (settings && settings[siteId] && settings[siteId]["record_control-equipments_manage"])
        ){
          const filteredCompanies = data?.data?.filter((e: any) => {
            return(
              e.company_category_id === "200" &&
              (company.admin_flag === true ||
                settings[siteId]["record_control-equipments_manage"].includes(e.company_id))
            )
          });
          setCompanyList(filteredCompanies);
        }else{
          setCompanyList(list)
        }
      }
    } catch (err) {
      return err
    }
  }

  const processQrCode = async (id: string) => {
    setErrorMessage('')
    setHasFormError(false)
    if(!siteId) {
        setHasFormError(true)
        setErrorMessage('現場プルダウンの値が不正です。再度選び直してお試しください')
        setTimeout(() => {
          setHasFormError(false)
          setErrorMessage('')
        }, 3000)
        return;
    }
    const params: IEqQrParams = {
      site_id: siteId,
      company_id: id,
      type: scannedResult?.length > 1 ? 'array' : 'string',
      identification_tag: scannedResult?.length > 1 ? scannedResult.join(':') : scannedResult,
    }
    const { data } = await api.getQrCode(params)

    try {
      if(data?.success) {
        setHasFormError(false)
        setErrorMessage('')
        navigate(`/eq/${ data?.data?.equipment_id }`)
      } else {
        setHasFormError(true)
        setErrorMessage(data?.error?.message)
        setTimeout(() => {
          setHasFormError(false)
          setErrorMessage('')
        }, 3000)
      }
    } catch(err) {
      return err
    }
  }

  useEffect(() => {
    getCompanyList()
    
    if (!qrOn) alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");

    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
    
    return () => {
      // eslint-disable-next-line
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  // eslint-disable-next-line
  }, []);


  useEffect(() => {}, [scannedResult])

  return (
    <View className="qr-reader">
      {/* QR */}
      <video ref={videoEl}></video>
      <View ref={qrBoxEl} className="qr-box">
        <View className="qr-frame">
          <QrFrame />
        </View>
      </View>

      {/* Show Data Result if scan is success */}
      {scannedResult?.length !== 0 && (
        <>
          <View className="qr-result" onClick={() => setIsModalOpen(true)}>
            WebサイトQRコード <br />
            { scannedResult?.map((item: any, index: number) => {
              return (
                <span key={ index } className="scanned-codes">{ item }{index + 1 === scannedResult.length ? '' : ':'}</span>
              )
            }) }
          </View>

          <CommonModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            textHeading={`QRコードの種類を選択してください`}
          >
            <Flex direction={'column'} width={'100%'} display={'flex'} alignItems={'center'}>

              { hasFormError && (
                  <Flex className="signin-error-message">
                    <View className="error-icon">
                      <ErrorSmallIcon fill='#D32F2F' />
                    </View>
                    <Text>{ errorMessage }</Text>
                  </Flex>
                )
              }
              <View className="qr-code">
                { scannedResult?.map((item: any, index: number) => {
                  return (
                    <div key={ index } className="scanned-codes">{ item }</div>
                  )
                }) }
              </View>
              <Flex direction={'column'} height={'250px'} style={{'overflowY':'scroll'}}>
                {
                  companyList?.map((item: any, index: any) => {
                    return (
                      <CustomButton
                        { ...defaultButtonProps }
                        key={index}
                        text={item.company_name}
                        type='primary'
                        size='large'
                        width='medium'
                        borderRadius='large'
                        click={() => processQrCode(item?.company_id)}
                      />
                    )
                  })
                }
              </Flex>
              <Flex height={'50px'} >
                <CustomButton
                  { ...defaultButtonProps }
                  text='キャンセル'
                  type='icon'
                  size='large'
                  width='medium'
                  borderRadius='large'
                  click={() => setIsModalOpen(false)}
                />  
              </Flex>
            </Flex>
          </CommonModal>
        </>
      )}
    </View>
  );
}
