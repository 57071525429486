import React, { useContext, useEffect, useState } from "react";

import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  SelectField,
  Text,
} from "@aws-amplify/ui-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SelectIcon from "../../../components/icons/select-icon";

import { IEqEditResult } from "../../../types/IEq";
import convertStatus from "../../../utils/geStatus";
import getBadgeColor from "../../../utils/getBadgeColor";
// api
import { EqApi } from "../../../api/eq";
import { CompanyApi } from "../../../api/company";
import { SiteApi } from "../../../api/site";
import { UserApi } from "../../../api/user";

//contexts
import { AuthorityContext } from "../../../App";
import { create } from "lodash";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EqScheduleOnDatePage() {
  const api = EqApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const userApi = UserApi();
  const { company, settings }: any = useContext(AuthorityContext);
  const navigate = useNavigate();
  // const { equipmentId } = useParams();
  const location = useLocation();
  // const { company }: any = useContext(AuthorityContext);
  const { state } = location;
  const pageTitle = state?.pageTitle ?? localStorage.getItem("currentPage") ?? "";
  const userName = localStorage.getItem("username") || "null";
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  //const stateDefaultDate = state?.defaultDate;
  const [status, setStatus] = useState("");
  const [locationList, setLocationList] = useState([] as any);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState([] as any);
  const { equipmentId } = useParams();
  const [settingsList, setSettingsList] = useState([] as string[]);
  const [companyList, setCompanyList] = useState([] as any);
  const [filteredCompanyList, setFilteredCompanyList] = useState([] as any);
  const [userList, setUserList] = useState([] as any);
  const [filteredUserList, setFilteredUserList] = useState([] as any);
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    null as string | null
  );
  const args: any = state?.scheduleDataToNextPage;

  const [payload, setPayload] = useState<IEqEditResult>({
    schedule_id: args?.schedule_id || -1,
    delivery_schedule: args?.delivery_schedule,
    return_schedule: args?.return_schedule,
    actual_delivery: args?.actual_delivery,
    actual_return: args?.actual_return,
    user_id: args?.user_id,
    user_name: args?.user_name,
    use_location: args?.use_location,
    company_id: args?.company_id,
    company_name: args?.company_name,
    actual_user_id: args?.actual_user_id,
    actual_user_name: args?.actual_user_name,
    actual_use_location: args?.actual_use_location,
    actual_company_id: args?.actual_company_id,
    actual_company_name: args?.actual_company_name,
    actual_delivery_register_user_id: args?.actual_delivery_register_user_id,
    actual_return_register_user_id: args?.actual_return_register_user_id
  });

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "/eq/list", label: "機材一覧" },
    { href: "", label: "使用スケジュール編集" },
  ];

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      //  setPayload({ ...payload, utilization_factor: value });
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  //id (schedule_id)
  //delivery_schedule
  //return_schedule
  //actual_delivery
  //actual_return
  //company_id
  //user_id
  //use_location
  //status_management

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };
  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
  };

  const handleChangeAddMapToPayload = (mapToAdd: any) => {
    setPayload({ ...payload, ...mapToAdd });
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    setDisableButton(true);
    setErrorMessage([]);
    //    setIsModalOpen(false);
    const createPayload = {
      id: args?.schedule_id,
    };
    try {
      // @ts-ignore
      const { data } = await api.dateTimeDelete(equipmentId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsEditModalOpen(false);
        setErrorMessage([]);
        navigate(`/eq/schedule/${equipmentId}`);
      } else {
        setDisableButton(false);
        setIsEditModalOpen(false);

        const errors = data?.error?.errors;
        const toArray = Object.keys(errors).map((key) => [key, errors[key]]);
        setErrorMessage([...toArray]);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage([
        "予期しないエラーが発生しました。システム管理者にお問い合わせください。",
      ]);
      setDisableButton(false);
      setIsEditModalOpen(false);
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage([]);

    const createPayload: any = {
      schedule_id: payload.schedule_id,
      equipment_id: equipmentId,
      actual_company_id: payload.actual_company_id,
      actual_user_id: payload.actual_user_id,
      actual_use_location: payload.actual_use_location,
    };
    //if (payload.actual_return) {
    //  createPayload["actual_return"] = payload.actual_return;
    //}
    //local validation
    if (
      !createPayload.actual_user_id ||
      !createPayload.actual_company_id ||
      !createPayload.actual_use_location
    ) {
      let errorMessage = "";
      //errorMessage += createPayload.delivery_schedule ? "" : "使用開始日、";
      //errorMessage += createPayload.return_schedule ? "" : "使用終了日、";
      errorMessage += createPayload.actual_user_id ? "" : "使用担当者、";
      errorMessage += createPayload.actual_company_id ? "" : "使用業者、";
      errorMessage += createPayload.actual_use_location ? "" : "使用場所、";
      errorMessage = errorMessage.slice(0, -1);
      errorMessage += "を入力してください";
      setErrorMessage([errorMessage]);
      setDisableButton(false);
      setIsEditModalOpen(false);
      return;
    }
    //if (
    //  new Date(createPayload.return_schedule) <
    //  new Date(createPayload.delivery_schedule)
    //) {
    //  setErrorMessage(["使用開始日は使用終了日以前の日付を設定してください"]);
    //  setDisableButton(false);
    //  setIsEditModalOpen(false);
    //  return;
    //}

    try {
      // @ts-ignore
      const { data } = await api.dateTimeEdit(equipmentId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsEditModalOpen(false);
        setErrorMessage([]);
        navigate(`/eq/schedule/${equipmentId}`);
      } else {
        setDisableButton(false);
        setIsEditModalOpen(false);
        const errors = data?.error?.errors;
        const toArray = Object.keys(errors).map((key) => [key, errors[key]]);
        setErrorMessage([...toArray]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsEditModalOpen(false);
    navigate(`/eq/schedule/${equipmentId}`);
  };

  const getCompanyList = async () => {
    const { data, status } = await companyApi.getList();
    if (status === 200) {
      setCompanyList([...data?.data]);
    }
  };
  const getLocationList = async () => {
    const { data } = await siteApi.getDetail(state?.siteId);
    try {
      if (data?.success) {
        const fetchedLocations = [
          ...new Set(data?.data?.site_construction_locations),
        ];

        setLocationList([
          fetchedLocations.filter((e: any) => e.name === args?.actual_use_location)[0],
          ...fetchedLocations.filter((e: any) => e.name !== args?.actual_use_location),
        ]);
      } else {
        setLocationList([]);
      }
    } catch (err) {
      return err;
    }
  };

  const getUserList = async () => {
    const { data, status } = await userApi.getList(1, false, 10000, state?.siteId);
    if (status === 200) {
      const mappedList = data?.data?.users?.map((item: any) => {
        return {
          user_id: item?.user_id,
          user_name: item?.user_name,
          company_id: item?.company_id,
        };
      });
      setUserList(mappedList);
    } else {
      console.log("fetch error: usersList");
    }
  };

  useEffect(() => {
    if (!args || args?.keys?.length) {
      // navigate(`/eq/schedule/${equipmentId}`);
    }
    //TODO get from state
    //    const convert = stateDefaultDate
    //      ?.replace("年", "/")
    //      .replace("月", "/")
    //      .replace("日", "");
    //    const format = convert?.split("/");
    //    const checkMonth = format[1].length === 1 ? "0" + format[1] : format[1];
    //    const checkDate = format[2].length === 1 ? "0" + format[2] : format[2];
    //    setPayload({
    //      ...payload,
    //      delivery_schedule: format[0] + "-" + checkMonth + "-" + checkDate,
    //      return_schedule: format[0] + "-" + checkMonth + "-" + checkDate,
    //    });
    getCompanyList();
    getLocationList();
    getUserList();
    setSelectedCompanyId(payload.actual_company_id);
    // eslint-disable-next-line
  }, []);

  //auth for delete schedule
  useEffect(() => {
    const retSettingsList: string[] = [];
    const getAuthInfoBySiteId = async () => {
      try {
        const { data } = await api.getDetail(equipmentId!.toString());
        if (data?.success) {
          if (data?.data?.site_id) {
            if (
              settings &&
              settings[data?.data?.site_id] &&
              settings[data?.data?.site_id].page_control
            ) {
              retSettingsList.push(
                ...settings[data?.data?.site_id].page_control
              );
            }
          }

          setSettingsList(retSettingsList);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getAuthInfoBySiteId();
  }, [settings]);//[settings, api]から修正

  useEffect(() => {
    let filteredCompanyListToSet: any[] = [];
    companyList?.forEach((element: any) => {
      if (
        company?.admin_flag === true ||
        (settings &&
          state?.siteId &&
          settings[state?.siteId] &&
          settings[state.siteId]["record_control-equipments_schedule_manage"] &&
          settings[state.siteId][
            "record_control-equipments_schedule_manage"
          ].includes(element.company_id))
      ) {
        filteredCompanyListToSet.push(element);
      }
    });
    filteredCompanyListToSet = [
      ...filteredCompanyListToSet.filter(
        (e) => e.company_id === args?.company_id
      ),
      ...filteredCompanyListToSet.filter(
        (e) => e.company_id !== args?.company_id
      ),
    ];
    setFilteredCompanyList(filteredCompanyListToSet);
    if (!filteredCompanyListToSet.length) {
      handleChangeAddMapToPayload({ company_id: null, company_name: null });
    } else {
      handleChangeAddMapToPayload({
        company_id: filteredCompanyListToSet[0].company_id,
        company_name: filteredCompanyListToSet[0].company_name,
      });
    }
  }, [companyList, settings, company]);

  useEffect(() => {
    let filteredUserListToSet: any[] = [];
    userList?.forEach((element: any) => {
      if (element.company_id === selectedCompanyId ?? payload.company_id) {
        filteredUserListToSet.push(element);
      }
    });
    filteredUserListToSet = [
      ...filteredUserListToSet.filter((e) => e.user_id === args?.actual_user_id),
      ...filteredUserListToSet.filter((e) => e.user_id !== args?.actual_user_id),
    ];
    setFilteredUserList(filteredUserListToSet);
    if (!filteredUserListToSet.length) {
      handleChangeAddMapToPayload({ user_id: null, user_name: null });
    } else {
      handleChangeAddMapToPayload({
        user_id: filteredUserListToSet[0].user_id,
        user_name: filteredUserListToSet[0].user_name,
      });
    }
  }, [userList, args, selectedCompanyId]);

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <View className="main-content">
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          {errorMessage.length ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              {errorMessage.map((message: string) => (
                <Text>{message}</Text>
              ))}
            </Flex>
          ) : null}

          <Flex justifyContent={"space-between"}>
            <View>
              <Flex>
                <View
                  border={`1px solid #757575`}
                  padding={"5px 20px"}
                  width={"fit"}
                  fontWeight={500}
                  fontSize={14}
                  lineHeight="20px"
                  letterSpacing="0.17px"
                >
                  {state?.equipment_category1_name}
                </View>
                <View fontWeight={700} fontSize={24} lineHeight="29px">
                  {state?.model}
                </View>
                <View fontWeight={700} fontSize={24} lineHeight="29px">
                  <Badge
                    backgroundColor={getBadgeColor(convertStatus(args?.status_management)).bg}
                    color={getBadgeColor(convertStatus(args?.status_management)).color}
                  >
                    {convertStatus(args?.status_management)}
                  </Badge>
                </View>
              </Flex>
            </View>
            <View
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
            >
              現場管理No.：{state?.onsite_manage_no}
            </View>
          </Flex>
          <Divider orientation="horizontal" marginBottom={20} marginTop={15} />
          <Flex>
            <TextField
              disabled
              label="使用開始日（予定）"
              width={"50%"}
              type="date"
              value={payload?.delivery_schedule?.split(" ")[0]}
              onChange={(event) =>{}}
              //onChange={(event) =>
              //  handleChange("delivery_schedule", event.target.value)
              //}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
            <TextField
              disabled
              label="使用終了日（予定）"
              width={"50%"}
              type="date"
              value={payload?.return_schedule?.split(" ")[0]}
              onChange={(event) =>{}}
              //onChange={(event) =>
              //  handleChange("return_schedule", event.target.value)
              //}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
          </Flex>
          <SelectField
            label="使用業者（予定）"
            disabled
            onChange={(event) => {}}
            //icon={<SelectIcon />}
            //value={payload.company_id}
            value={payload.company_id}
          >
             <option value={payload.company_name} key={payload.company_name}>
              {payload.company_name}
              </option>
          </SelectField>
          <SelectField
            label="使用担当者（予定）"
            disabled
            value={payload.user_id}
            onChange={(event) => {}}
            //icon={<SelectIcon />}
          >
             <option value={payload.user_id} key={payload.user_id}>
               {payload.user_name}
             </option>
          </SelectField>

          <SelectField
            label="使用場所（予定）"
            value={payload.use_location}
            disabled
            onChange={(event) =>{}}
            //icon={<SelectIcon color={"#929292"} />}
          >
             <option value={payload.use_location} key={payload.use_location}>
               {payload.use_location}
             </option>
          </SelectField>
          <Divider orientation="horizontal" marginBottom={20} marginTop={15} />
          <Flex>
            <TextField
              disabled
              label="使用開始日（実績）"
              width={"50%"}
              type="date"
              value={payload.actual_delivery?.split(" ")[0]}
              onChange={(event) =>{}}
              //onChange={(event) =>
              //  handleChange("actual_delivery", event.target.value)
              //}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
            <TextField
              disabled
              label="使用終了日（実績）"
              width={"50%"}
              type="date"
              value={payload.actual_return?.split(" ")[0]}
              onChange={(event) =>{}}
              //onChange={(event) =>
              //  handleChange("actual_return", event.target.value)
              //}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
          </Flex>
          <Flex>
            <TextField
              disabled
              label="使用開始登録者"
              width={"50%"}
              value={userList.filter((e: any)=>{return e.user_id === payload.actual_delivery_register_user_id})[0]?.user_name}
              onChange={(event) =>{}}
            />
            <TextField
              disabled
              label="使用終了登録者"
              width={"50%"}
              value={userList.filter((e : any)=>{return e.user_id === payload.actual_return_register_user_id})[0]?.user_name ?? (payload.actual_return ? '※システムにより終了': '' ) }
              onChange={(event) =>{}}
              //onChange={(event) =>
              //  handleChange("actual_return", event.target.value)
              //}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
          </Flex>
          <SelectField
            label="使用業者（実績）"
            placeholder={
              filteredCompanyList.length ? "" : "業者が存在しません"
            }
            disabled={filteredCompanyList.length ? false : true}
            onChange={(event) => {
              handleChangeAddMapToPayload({
                actual_company_id: event.target.value,
                actual_company_name: event.target.selectedOptions[0].label,
              });
              setSelectedCompanyId(event.target.value);
            }}
            icon={<SelectIcon />}
            //value={payload.company_id}
            value={payload.actual_company_id}
          >
            {filteredCompanyList?.map((item: any, index: any) => {
              return (
                <option value={item?.company_id} key={index}>
                  {item?.company_name}
                </option>
              );
            })}
          </SelectField>
          <SelectField
            label="使用担当者（実績）"
            placeholder={
              filteredUserList.length ? "" : "ユーザーが存在しません"
            }
            disabled={filteredUserList.length ? false : true}
            value={payload.actual_user_id}
            onChange={(event) => {
              handleChangeAddMapToPayload({
                actual_user_id: event.target.value,
                actual_user_name: event.target.selectedOptions[0].label,
              });
            }}
            icon={<SelectIcon />}
          >
            {filteredUserList?.map((item: any, index: any) => {
              return (
                <option value={item?.user_id} key={index}>
                  {item?.user_name}
                </option>
              );
            })}
          </SelectField>
          <SelectField
            label="使用場所（実績）"
            value={payload.actual_use_location}
            onChange={(event) =>
              handleChange("actual_use_location", event.target.value)
            }
            icon={<SelectIcon color={"#929292"} />}
          >
            {locationList?.map((item: any, index: any) => {
              return (
                <option value={item?.name} key={index}>
                  {item?.name}
                </option>
              );
            })}
          </SelectField>
          <Flex marginTop="20px" justifyContent={"flex-end"} direction={{'base': 'column', 'medium': 'row'}}  className="button-group">
            <Flex justifyContent="space-between" direction={{'base': 'column', 'medium': 'row'}} alignItems={{'base': 'center'}}>
              {(company?.admin_flag === true ||
                (settings &&
                  settings[state?.siteId]?.page_control?.includes(
                    "end_use_regist"
                  ))) && (
                <>
                  {args?.status_management === "in_use" && (
                    <View as="div" maxWidth={{'medium': '200px'}}>
                      <CustomButton
                        {...defaultButtonProps}
                        text="使用終了登録"
                        size="medium"
                        width="medium"
                        borderRadius="large"
                        click={() =>
                          navigate(`/eq/use/end/${equipmentId}`, {
                            state: {
                              pageTitle: pageTitle,
                            },
                          })
                        }
                      />
                    </View>
                  )}
                </>
              )}

              <CustomButton
                {...defaultButtonProps}
                text="使用実績を更新"
                size="medium"
                width="medium"
                borderRadius="large"
                click={openEditModal}
              />

              <CustomButton
                {...defaultButtonProps}
                text="キャンセル"
                type="bordered-transparent"
                click={showCancelModal}
              />
            </Flex>

            <CommonModal
              isOpen={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              textHeading="機材使用実績を更新します。よろしいですか？"
            >
              <View
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text="機材スケジュールを更新"
                  disabled={disableButton}
                  iconName={disableButton ? "loader" : "arrow"}
                  iconPosition="right"
                  size="medium"
                  click={handleSubmit}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄してスケジュールに 戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="xs"
                  width="small"
                  click={handleCancel}
                />
              </Flex>
            </CommonModal>

            <CommonModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              textHeading={`機材「${equipmentId}」の使用予定を削除します`}
              //textsmall={`機材「${equipmentId}」`}
              textOperation="この操作は取り消せません。本当に削除しますか？"
            >
              <CustomButton
                {...defaultButtonProps}
                text="キャンセル"
                type="default"
                iconName="close"
                iconPosition="left"
                size="large"
                click={handleDeleteCancel}
              />
              <CustomButton
                {...defaultButtonProps}
                text="使用予定情報を削除"
                type="warning"
                disabled={disableButton}
                iconName={disableButton ? "loader" : ""}
                size="large"
                click={handleDelete}
              />
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
