import { S3 } from "aws-sdk";
const s3 = new S3({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export const upload = async (
  file: File,
  bucketName: string,
  folderName: string
) => {
  try {
    const params: any = {
      Bucket: bucketName,
      Key: folderName + file?.name,
      ContentType: file?.type,
      Body: file as Blob,
    };
    const data = await s3.upload(params).promise();
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
