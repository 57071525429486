import React from "react";

import HomeIcon from "./v2/home-icon";
import DashboardIcon from "./v2/dashboard-icon";
import EquipementIcon from "./v2/equipment-icon";
import LocationIcon from "./v2/location-icon";
import CorpIcon from "./v2/corp-icon";
import SettingsIcon from "./v2/settings-icon";
import UsersIcon from "./v2/users-icon";
import TimeIcon from "./v2/time-icon";
import AccountIcon2 from "./v2/account2-icon";

type Props = {
  icon: string;
};

export default function SidebarIcons(props: Props) {
  switch (props.icon) {
    case "home":
      return <HomeIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "equipment":
      return <EquipementIcon />;
    case "onsite-management":
      return <LocationIcon />;
    case "corp-management":
      return <CorpIcon />;
    case "auth-management":
      return <SettingsIcon />;
    case "user-management":
      return <UsersIcon />;
    case "history":
      return <TimeIcon />;
    case "account":
      return <AccountIcon2 />;
    default:
      <HomeIcon />;
  }
}
