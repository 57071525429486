import React from "react";
import PageContent from "../../components/pageContent";
// amplify ui components
import "../../styles/privacy-policy.scss";
import {
  PrivacyPolicy,
  InfoUsagePurpose,
  ThirdPartyInfoProvision,
  DisclosureProcedures,
  AnonymizedInfoUsage,
} from "./section";

export default function LogsPage() {
  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "", label: "プライバシーポリシー" },
  ];
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <PrivacyPolicy />
      <InfoUsagePurpose />
      <ThirdPartyInfoProvision />
      <DisclosureProcedures />
      <AnonymizedInfoUsage />
    </PageContent>
  );
}
