import React from "react";

export default function ArrowCircleIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3717 6L8.96167 7.41L13.5417 12L8.96167 16.59L10.3717 18L16.3717 12L10.3717 6Z"
        fill="white"
      />
    </svg>
  );
}
