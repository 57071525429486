import React from "react";
import {
  Flex,
  SelectField,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import "../../../styles/delivery.scss";
import SelectIcon from "../../../components/icons/select-icon";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function DeliveryForm() {
  return (
    <>
      <Flex direction={"column"} className="delivery-container ">
        <View className="pc-view">
          <Text
            backgroundColor={"#F0F9F3"}
            color={"#1C8C6E"}
            width={"fit-content"}
            fontWeight={500}
            fontSize={14}
            lineHeight="20px"
            letterSpacing="0.17px"
            padding={"10px 20px"}
            borderRadius={10}
          >
            {"使用可能"}
          </Text>

          <Flex direction={"row"} marginTop={20}>
            <Text
              border={`1px solid #4365DE`}
              borderRadius={10}
              padding={"5px 20px"}
              color={"#4365DE"}
              width={"fit"}
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
            >
              {"掘削・整地"}
            </Text>
            <Text
              padding={"5px 20px"}
              width={"fit"}
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
            >
              {"高所-1"}
            </Text>
          </Flex>
        </View>

        {/*** This is a Mobile View  */}
        <View className="sp-view">
          <Flex direction={"row"} justifyContent={"space-between"}>
            <Text
              border={`1px solid #4365DE`}
              borderRadius={10}
              padding={"5px 20px"}
              color={"#4365DE"}
              width={"fit"}
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
            >
              掘削・整地
            </Text>
            <Text
              backgroundColor={"#F0F9F3"}
              color={"#1C8C6E"}
              width={"fit-content"}
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              letterSpacing="0.17px"
              padding={"5px 20px"}
              borderRadius={10}
              textAlign={"center"}
            >
              {"使用可能"}
            </Text>
          </Flex>
          <Text
            padding={"5px 20px 5px 0px"}
            width={"fit"}
            fontWeight={700}
            fontSize={20}
            margin={"10px 0px"}
            lineHeight="20px"
            letterSpacing="0.17px"
          >
            高所-1
          </Text>
        </View>

        <Flex direction={"column"}>
          <TextField
            label="使用予定日"
            width={"100%"}
            type="date"
            value="2024/03/31"
          />
          <TextField
            label="使用終了日（予定）"
            width={"100%"}
            type="date"
            value="2024/03/31"
          />

          <SelectField
            width={"100%"}
            label="使用担当者（予定）"
            icon={<SelectIcon />}
          >
            <option value="A山 A太郎">A山 A太郎</option>
          </SelectField>
          <SelectField
            width={"100%"}
            label="使用場所（予定）"
            icon={<SelectIcon />}
          >
            <option value="使用なし">使用なし</option>
          </SelectField>
        </Flex>
      </Flex>
      <Flex className="form-actions">
        <CustomButton
          {...defaultButtonProps}
          text="使用予定を更新"
          size="medium"
          width="medium"
          type="primary"
          borderRadius="large"
          //   click={() => navigate(`/eq/${equipmentId}`)}
        />
        <CustomButton
          {...defaultButtonProps}
          text="キャンセル"
          size="medium"
          width="medium"
          borderRadius="large"
          type="bordered-transparent"
          //   click={() => navigate(`/eq/${equipmentId}`)}
        />
      </Flex>

      {/* {isModalOpen && (
        <CommonModal
          isOpen={isModalOpen}
          icon="icon"
          textHeading="機材使用予定を登録しました"
        >
          <View margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="〇〇〇〇を確認"
              size="medium"
              width="medium"
              borderRadius="large"
              //   iconName={ disableButton ? 'loader' : '' }
              iconPosition="right"
              //   disabled={ disableButton }
              //   click={() => handleSubmit()}
            />
          </View>
        </CommonModal>
      )} */}

      {/* cancel modal */}
      {/* {isModalCancelOpen && (
        <CommonModal isOpen={isModalCancelOpen} textHeading="○○○○の情報を削除します">
          <Flex direction={"column"} justifyContent="center" margin={`0 auto`}>
            <Text>2024/03/31：機材「高所-1」</Text>
            <Text color={"red"}>
              この操作は取り消せません。本当に削除しますか？
            </Text>

            <Flex margin={`0 auto`}>
              <CustomButton
                {...defaultButtonProps}
                text="編集へ戻る"
                type="bordered-transparent"
                //   click={showCancelModal}
              />
              <CustomButton
                {...defaultButtonProps}
                text="破棄"
                size="xs"
                width="small"
                //   click={handleCancel}
              />
            </Flex>
          </Flex>
        </CommonModal>
      )} */}
    </>
  );
}
