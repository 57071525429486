import React from "react";
import {
  Flex,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableRow,
  View,
} from "@aws-amplify/ui-react";
import CustomButton from "../../../../components/custom-button";

import CommonModal from "../../../../components/common-modal";
import formatDate from "../../../../utils/formatDate";
const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function ScheduleDateModal({
  isModalDatesOpen,
  openDatesModal,
  typeOfSchedule,
  detailData,
}: {
  isModalDatesOpen: boolean;
  openDatesModal:  any;
  typeOfSchedule: string;
  detailData: any;
}) {
  return (
    <View className="schedule-dates-modal">
      <CommonModal
        isOpen={isModalDatesOpen}
        onClose={() => openDatesModal(false)}
      >
        <Flex className="schedule-dates-modal--contents">
          <View className="custom-table custom-table__eq">
            {typeOfSchedule === "schedule" && (
              <>
                <Heading className="schedule-dates-modal--title">
                  搬入スケジュール
                </Heading>
                <Table highlightOnHover={true}>
                  <TableBody>
                    <TableRow>
                      <TableCell as="th">日付</TableCell>
                      <TableCell as="th">時間</TableCell>
                      <TableCell as="th">確認者（所属）</TableCell>
                      <TableCell as="th">場所</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
            {typeOfSchedule === "return" && (
              <>
                <Heading className="schedule-dates-modal--title">
                  返却スケジュール
                </Heading>
                <Table highlightOnHover={true}>
                  <TableBody>
                    <TableRow>
                      <TableCell as="th">日付</TableCell>
                      <TableCell as="th">時間</TableCell>
                      <TableCell as="th">確認者（所属）</TableCell>
                      <TableCell as="th">場所</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
            {typeOfSchedule === "inventories" && (
              <>
                <Heading className="schedule-dates-modal--title">
                  棚卸履歴
                </Heading>
                <Table highlightOnHover={true}>
                  <TableBody>
                    <TableRow>
                      <TableCell as="th">日付</TableCell>
                      <TableCell as="th">時間</TableCell>
                      <TableCell as="th">確認者（所属）</TableCell>
                      <TableCell as="th">場所</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {formatDate("dot", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDate("time", detailData?.inventory_datetime)}
                      </TableCell>
                      <TableCell>建設　太郎（Z建設）</TableCell>
                      <TableCell>東館1F</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </View>
          <CustomButton
            {...defaultButtonProps}
            text="閉じる"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={() => openDatesModal(false)}
          />
        </Flex>
      </CommonModal>
    </View>
  );
}
