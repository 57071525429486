import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

// custom components
import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  // SelectField,
  IconsProvider,
  Heading
} from "@aws-amplify/ui-react";

// types
import { IAssignList } from "../../../types/ISiteList";

// icons
// import SelectIcon from "../../../components/icons/select-icon";
import ArrowLeftIcon from "../../../components/icons/arrow-left";
import ArrowRightIcon from "../../../components/icons/arrow-right";

// utils
import { assignList } from "../../../utils/testSiteAssign";

export default function SiteAssignPage() {
  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/site/list', label: '現場管理' },
    { href: '', label: '現場一覧' },
    { href: '', label: '現場詳細' },
  ]
  const tableData: IAssignList = {
    list: [ ...assignList ]
  }
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataList, setDataList] = useState(tableData.list)
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPage = 10;
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const siteId = localStorage.getItem("siteId");
  const navigate = useNavigate()

  const indexOfLastUser = currentPageIndex * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentItems = dataList.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  // const handleSetItems = (items: number) => {
  //   setItemsPerPage(items);

  //   const newTotalPages = Math.ceil(dataList.length / items);
  //   setTotalPages(newTotalPages);

  //   setCurrentPageIndex(1);
  // };

  useEffect(() => {
    const data = tableData.list

    setDataList(data)
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  // eslint-disable-next-line
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View
        className="custom-table"
      >
        <Heading fontWeight={700} level={4} marginBottom={20}>
          配属ユーザー一覧
        </Heading>
        <Table highlightOnHover={true}>
          <TableBody>
            <TableRow backgroundColor='#0000001F'>
              <TableCell as="th">ユーザーID</TableCell>
              <TableCell as="th">所属法人</TableCell>
              <TableCell as="th">名前</TableCell>
              <TableCell as="th">権限グループ</TableCell>
              <TableCell as="th"></TableCell>
            </TableRow>
            {
              currentItems?.map((item, index) => {
                return (
                  <TableRow key={ index }>
                    <TableCell width={150}>
                      <Link to={`/company/${ item.id }`} className="table-hover">{item.id}</Link>
                    </TableCell>
                    <TableCell width={150}>{item.corporateAffiliation}</TableCell>
                    <TableCell width={150}>{item.name}</TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Flex
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            fontWeight={400}
            fontSize={12}
            lineHeight="20px"
            letterSpacing={0.4}
          >
            <IconsProvider
              icons={{
                pagination: {
                  next: <ArrowRightIcon />,
                  previous: <ArrowLeftIcon />,
                },
              }}
            >
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                // @ts-ignore
                onChange={handleOnChange}
                className="custom-pagination"
              />
            </IconsProvider>
          </Flex>
          <Flex
            justifyContent={{
                base: 'center',
                medium: 'flex-start'
            }}
          >
            <CustomButton
              text='配属ユーザー編集'
              type='primary'
              iconPosition='left'
              iconName=''
              size=''
              disabled={false}
              click={() => navigate(`/site/assign/user/${siteId}`)}
            />
          </Flex>
      </View>
    </PageContent>
  );
}