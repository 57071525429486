import React, { useContext, useEffect, useState } from "react";
import { default as ReactSelect, components } from "react-select";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Divider,
  Text,
  Heading,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

// icons
import ErrorSmallIcon from "../../components/icons/error-small-icon";
import SelectIcon from "../../components/icons/select-icon";

// types
import { IUserCreate } from "../../types/IUsers";

// api
import { UserApi } from "../../api/user";
import { CompanyApi } from "../../api/company";
import { AuthApi } from "../../api/auth";
import { SiteApi } from "../../api/site";

// utils
import validateEmail from "../../utils/validateEmail";
import { AuthorityContext } from "../../App";

type Company = {
  id: number;
  company_id: number;
  name: string;
};

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function UserCreatePage() {
  const { site, company, authority}: any = useContext(AuthorityContext);
  const api = UserApi();
  const authApi = AuthApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const paswd = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [affiliationError, setAffiliationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [companyList, setCompanyList] = useState([] as any);
  const [authGroupList, setAuthGroupList] = useState([] as any);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [mismatchError, setMismatchError] = useState(false);
  const [passwordValueError, setPasswordValueError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [siteListOptions, setSiteListOptions] = useState([] as any[]);
  const [termsError, seTermsError] = useState<boolean>(false);
  const [payload, setPayload] = useState<IUserCreate>({
    name: "",
    affiliation: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    note: "",
    site: [],
    terms: true,
    authGroup: {},
  });
  

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/user/list", label: "ユーザー一覧" },
    { href: "", label: "新規ユーザー登録" },
  ];

  const openModal = () => {
    let errorMessageToSet = [];
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setNameError(() => {
      return payload.name === "" ? true : false;
    });
    setAffiliationError(() => {
      return payload.affiliation === "" ? true : false;
    });
    setEmailError(() => {
      if (payload.emailAddress === "") {
        return true;
      } else if (!validateEmail(payload.emailAddress)) {
        return true;
      } else {
        return false;
      }
    });

    seTermsError(!payload.terms)
    
    setPasswordError(() => {
      return payload.password === "" ? true : false;
    });

    setConfirmPasswordError(() => {
      return payload.confirmPassword === "" ? true : false;
    });

    setPasswordValueError(() => {
      if (payload.password.match(paswd)) {
        return false;
      } else {
        return true;
      }
    });

    setMismatchError(() => {
      if (payload.password === payload.confirmPassword) {
        return false;
      } else {
        return true;
      }
    });
    if (
      payload.name === "" ||
      payload.affiliation === "" ||
      payload.emailAddress === "" ||
      payload.password === "" ||
      //payload.terms ||
      payload.confirmPassword === ""
    ) {
      errorMessageToSet.push("必須項目を入力してください。");
    }

    if (payload.password !== "" && payload.confirmPassword !== "") {
      if (payload.password === payload.confirmPassword) {
        if (payload.password.match(paswd)) {
        } else {
          errorMessageToSet.push(
            "英大文字、英小文字、数字を混ぜた８文字以上の文字列で入力してください"
          );
        }
      } else {
        errorMessageToSet.push("パスワードが一致しませんでした。");
      }
    }

    const phoneRegex =
      /^(0[1-9]{1,3}-?[1-9]\d{3}-?\d{4}|0[789]0-?\d{4}-?\d{4}|050-?\d{4}-?\d{4})$/;
    const isPhoneValid =
      payload.phoneNumber !== "" ? phoneRegex.test(payload.phoneNumber) : true;
    if (payload.phoneNumber !== "") {
      setPhoneError(!phoneRegex.test(payload.phoneNumber));
      if(!phoneRegex.test(payload.phoneNumber)){
        errorMessageToSet.push("電話番号が不正です。");
      }
    } else {
      setPhoneError(false);
    }
    if(!validateEmail(payload.emailAddress)){
      errorMessageToSet.push("メールアドレスが不正です");
    }
    if (payload.site.length < 1) {
      errorMessageToSet.push("1つ以上の現場と権限グループを入力してください。");
    }

    let siteAuthMatchFlag = true;
    let payloadValidAuthLength = 0;
    payload.site.forEach((e) => {
      if (payload.authGroup[e] && payload.authGroup[e] !== "") {
        payloadValidAuthLength += 1;
      } else {
        siteAuthMatchFlag = false;
      }
    });
    if (payload.site.length !== payloadValidAuthLength || !siteAuthMatchFlag) {
      errorMessageToSet.push("現場と権限グループはペアで入力してください。");
    }
    setErrorMessage(errorMessageToSet.join("\n"));

    if (
      payload.name !== "" &&
      payload.affiliation !== "" &&
      payload.emailAddress !== "" &&
      payload.password !== "" &&
      payload.confirmPassword !== "" &&
      payload.password === payload.confirmPassword &&
      payload.password.match(paswd) &&
      isPhoneValid &&
      validateEmail(payload.emailAddress) &&
      payload.site.length > 0 && 
      payload.terms &&
      payload.site.length === payloadValidAuthLength &&
      siteAuthMatchFlag
    ) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };
  const handleCheckboxChange = (value: boolean, id: string) => {
    const siteIds = [...payload.site];
    if (value) {
      siteIds.push(id);
      setPayload({ ...payload, site: siteIds });
    } else {
      const filteredId = siteIds.filter((siteId) => siteId !== id);
      setPayload({
        ...payload,
        site: filteredId,
        authGroup: { ...payload.authGroup, [id]: "" },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    setPayload({
      ...payload,
      authGroup: {
        ...payload.authGroup,
        [e.siteId]: e.value,
      },
    });
  };

  const handleChange = (item: any, value: any) => {
    let payloadData = { ...payload, [item]: value };
    setPayload(payloadData);
    if (item === "note") {
      setNoteLength(value.length);
    }
  };

  // company filtering start
  const getUserManageLists = (): number[] => {
    // get user_manage_record to list
    const allUserManageLists = Object.values(authority).flatMap(
      (data: any) => data.record_control.user_manage
    );
  
    // Remove duplicates
    const uniqueUserManageList = Array.from(new Set(allUserManageLists));
    console.log(uniqueUserManageList)
  
    return uniqueUserManageList;
  };

  const filterCompanyList = (companyList: Company[], validCompanyIds: number[]): Company[] => {
    return companyList.filter(company => validCompanyIds.includes(company.company_id));
  };
  // company filtering end

  useEffect(() => {
    const authSelectedListCandidate: { [key: string]: any } = {};
    siteListOptions?.forEach((siteItem: any) => {
      authSelectedListCandidate[siteItem.value] = "";
    });
    setPayload({
      ...payload,
      authGroup: { ...authSelectedListCandidate, ...payload.authGroup },
    });
  }, [authGroupList, siteListOptions]);

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");
    const authGroupPayload = Object.fromEntries(
      Object.entries(payload.authGroup).filter(([key, value]) => value !== "")
    );
    const createPayload = {
      company_code: payload.affiliation,
      username: payload.emailAddress,
      password: payload.confirmPassword,
      email: payload.emailAddress,
      name: payload.name,
      phone_number: payload.phoneNumber,
      remarks: payload.note,
      agreement: payload.terms,
      site_auth_group: authGroupPayload,
    };

    try {
      // @ts-ignore
      const { data } = await api.createUser(createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/user/list`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  useEffect(() => {
    const getList = async () => {
      // setLoader(true);
      const { data, status } = await companyApi.getList();
      if (status === 200) {
        setCompanyList([...data?.data]);
        if(!company?.admin_flag){
          // Get user_manage list with duplicates eliminated
         const userManageLists = getUserManageLists();
         // Filter companyList
         const filteredCompanies = filterCompanyList(data?.data, userManageLists);
         setCompanyList(filteredCompanies);
       };
      }
    };

    const getAuthGroupList = async () => {
      const { data, status } = await authApi.getList();
      if (status === 200) {
        const mappedList = data?.data.map(
          (authgroup: {
            site_id: any;
            authority_group_id: any;
            authority_groups_name: any;
          }) => ({
            value: authgroup.authority_group_id,
            label: authgroup.authority_groups_name,
            siteId: authgroup.site_id,
          })
        );
        setAuthGroupList(mappedList);
      }
    };

    const getSiteList = async () => {
      if (company?.admin_flag) {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );
          setSiteListOptions(mappedList);
        }
      } else {
        const mappedList = site?.map((item: any) => {
          return {
            value: item.site_id,
            label: item.site_name,
          };
        });
        setSiteListOptions(mappedList);
      }
    };
    
    getList();
    getAuthGroupList();
    getSiteList(); 
    // eslint-disable-next-line
  }, [site, company]);

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <View className="main-content">
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          <Heading level={5} marginBottom={'30px'}>
            ユーザー情報
          </Heading>
          <Heading level={6} className="form-note">
            ※ ユーザーIDは自動で生成されます
          </Heading>
          <Text className="form-required-note">
            必須項目 <span>＊</span>
          </Text>

          {errorMessage !== "" || hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              {errorMessage?.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </Flex>
          ) : null}
          <TextField
            label="名前"
            placeholder="お名前を入力してください"
            value={payload.name}
            onChange={(event) => handleChange("name", event.target.value)}
            isRequired
            className={nameError ? `required input-error` : "required"}
          />
          <SelectField
            label="所属法人"
            placeholder="所属法人を選択してください"
            value={payload.affiliation}
            onChange={(event) =>
              handleChange("affiliation", event.target.value)
            }
            isRequired
            className={`required ${affiliationError ? 'input-error' : ''} ${payload.affiliation === '' ? 'no-value' : ''}`}
            icon={<SelectIcon />}
          >
            {companyList?.map((item: any, index: any) => {
              return (
                <option value={item?.company_id} key={item?.company_id}>
                  {item?.company_name}
                </option>
              );
            })}
          </SelectField>
          <TextField
            label="電話番号"
            placeholder="電話番号を入力してください"
            value={payload.phoneNumber}
            onChange={(event) =>
              handleChange("phoneNumber", event.target.value)
            }
            //isRequired
            //className={phoneError ? `required input-error` : "required"}
          />
          <TextField
            label="メールアドレス"
            placeholder="example@diace.co.jp"
            value={payload.emailAddress}
            onChange={(event) =>
              handleChange("emailAddress", event.target.value)
            }
            type="email"
            isRequired
            className={emailError ? `required input-error` : "required"}
          />
          <Divider className="form-divider" />
          <View
            as="p"
            marginBottom={"15px"}
            color={"#ACB5BB"}
            fontSize={"16px"}
            fontWeight={"bold"}
          >
            <span className="header-required">現場･権限設定</span>
          </View>
          <View as="div">
            <Flex direction={{ base: "column", medium: "column" }}>
              {siteListOptions?.map((siteItem) => {
                const filteredAuth = authGroupList.filter(
                  (auth: { siteId: any }) => auth.siteId === siteItem.value
                );

                return (
                  <Flex
                    direction={{ base: "column", medium: "row" }}
                    alignItems={{ base: "flex-start", medium: "center" }}
                    alignContent="flex-start"
                    key={siteItem.value}
                  >
                    <View as="div" flex={"0 20%"}>
                      <Flex direction={{ base: "column", medium: "row" }}>
                        <CheckboxField
                          label={siteItem.label}
                          name={siteItem.label}
                          checked={payload.site.includes(siteItem.value)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.checked,
                              siteItem.value
                            )
                          }
                          className="user-checkbox"
                        />
                      </Flex>
                    </View>

                    <View as="div" 
                      flex={{ base: "100%", medium: "0 40%" }}
                      width={{ base: "100%", medium: "auto" }}
                    >
                      <ReactSelect //SelectFieldに直しても良いかも
                        options={filteredAuth}
                        onChange={(event) => handleSelectChange(event)}
                        value={
                          authGroupList.find(
                            (auth: { value: any }) =>
                              payload.authGroup[siteItem.value] === auth.value
                          ) ?? ""
                        }
                        isSearchable={false}
                        placeholder="権限グループ選択"
                        isDisabled={!payload.site.includes(siteItem.value)}
                        className="react-custom-select"
                      />
                    </View>
                  </Flex>
                );
              })}
            </Flex>
          </View>
          <Divider className="form-divider" />
          <TextField
            label="パスワード"
            placeholder="8文字以上 英大小文字数字"
            value={payload.password}
            onChange={(event) => handleChange("password", event.target.value)}
            type="password"
            className={
              mismatchError || passwordError || passwordValueError
                ? `required input-with-note input-error`
                : "required input-with-note"
            }
          />
          <Text className="input-note">
            英大文字、英小文字、数字を混ぜた８文字以上の文字列で入力してください
          </Text>
          <TextField
            label="パスワード確認"
            placeholder="再度パスワードを入力してください"
            value={payload.confirmPassword}
            onChange={(event) =>
              handleChange("confirmPassword", event.target.value)
            }
            type="password"
            className={
              mismatchError || confirmPasswordError || passwordValueError
                ? `required input-error`
                : "required"
            }
          />
          <TextAreaField
            label="備考"
            placeholder="備考を入力してください"
            value={payload.note}
            onChange={(event) => handleChange("note", event.target.value)}
            maxLength={100}
          />
          <Text className="textarea-length" as="span">
            {noteLength}/100
          </Text>
          {/*<CheckboxField
            label="利用規約に同意しています"
            name="terms"
            // checked={payload.terms}
            onChange={(event) => handleChange('terms', event.target.checked)}
            className={ !termsError ? `required input-error` : 'required' }
            hasError={termsError}
          />*/}
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="登録"
              size="medium"
              width="medium"
              borderRadius="large"
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={showCancelModal}
            />
            {/* submit modal */}
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              icon="icon"
              textHeading={`ユーザー「${payload.name}」を作成します。よろしいですか？`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="ユーザーを作成する"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleSubmit()}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄して詳細に戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="xs"
                  width="small"
                  click={() => navigate("/user/list")}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
