import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import { AuthorityContext } from "../../App";

// amplify ui
import {
  Flex,
  View,
  // Table,
  // TableCell,
  // TableRow,
  // TableBody,
  Loader,
  Text,
  Heading,
} from "@aws-amplify/ui-react";

// types
import { IUserDetail } from "../../types/IUsers";

// api
import { UserApi } from "../../api/user";

export default function UserListPage() {
  const { company, settings }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const { userId } = useParams();
  const username = localStorage.getItem("username") || "null";
  const api = UserApi();
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [settingsList, setSettingsList] = useState([] as string[]);
  const [tableData, setTableData] = useState<IUserDetail>({
    id: "",
    affiliation: "",
    phoneNumber: "",
    emailAddress: "",
    group: "",
    note: "",
    userName: "",
  });
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/user/list", label: "ユーザー管理" },
    { href: "/user/list", label: "ユーザー一覧" },
    { href: "", label: "ユーザー詳細" },
  ];

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    deleteUser(userId);
  };

  const deleteUser = async (id: any) => {
    try {
      const { data } = await api.deleteUser(id);
      if (data?.success) {
        navigate("/user/list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate("/user/list");
  };

  useEffect(() => {
    const getDetail = async () => {
      setLoader(true);
      // @ts-ignore
      const { data, status } = await api.getDetail(userId);
      if (status === 200) {
        setLoader(false);
        setFetchError(false);
        const authorityGroupToSet = {} as { [key: string]: string };
        data?.data?.user_affiliated_authority_groups?.forEach((e: any) => {
          if (e?.authority_group_id && e?.site_id) {
            authorityGroupToSet[e?.site_id] = e?.authority_groups_name;
          }
        });
        //const siteToSet = [] as string[];
        //data?.data?.user_affiliated_sites?.forEach((e: any) => {
        //  if (e?.site_id) {
        //    siteToSet.push(e?.site_id);
        //  }
        //});
        setTableData({
          id: data?.data?.user_id,
          userName: data?.data?.user_name,
          affiliation: data?.data?.company_name,
          phoneNumber: data?.data?.user_phone_number,
          emailAddress: data?.data?.user_email,
          group: Object.values(authorityGroupToSet).join(", "),
          note: data?.data?.remarks,
        });
      } else {
        setFetchError(true);
      }
    };
    getDetail();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const retSettingsList = [];
    if (settings) {
      for (let site_id in settings) {
        const setting = settings[site_id];
        if (setting.page_control) {
          retSettingsList.push(...setting.page_control);
        }
      }
      setSettingsList(retSettingsList);
    }
  }, [settings]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={tableData.userName ?? pageTitle}
    >
      <View className="custom-table table-v2">
        <Heading level={5} className="table-title">ユーザー詳細</Heading>
        {!loader ? (
          <>
            <View className="table-body">
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">ユーザーID</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.id}</Text>
              </View>
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">所属法人</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.affiliation}</Text>
              </View>
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">電話番号</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.phoneNumber}</Text>
              </View>
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">メールアドレス</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.emailAddress}</Text>
              </View>
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">権限グループ</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.group}</Text>
              </View>
              <View as="fieldset" className="custom-fieldset">
                <View as="legend">備考</View>
                <Text color={'#0d1926'} fontSize={'1rem'}>{tableData.note}</Text>
              </View>
            </View>
            <Flex 
              marginTop="20px" 
              className="hide-from-print" 
              justifyContent={'flex-end'}
              direction={{ base: 'column', medium: 'row' }}
              alignItems={`center`}
              // width={{ base: '43%', medium: 'auto' }}
              // marginLeft={{ base: 'auto', medium: '0' }}
            >
              {(company?.admin_flag === true ||
                settingsList.includes("user_edit")) && (
                <CustomButton
                  {...defaultButtonProps}
                  text="編集"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  click={() => navigate(`/user/edit/${userId}`)}
                />
              )}
              {(company?.admin_flag === true ||
                settingsList.includes("user_delete")) &&
                username !== tableData.id.toString() && (
                  <CustomButton
                    {...defaultButtonProps}
                    text="削除"
                    type="bordered-transparent"
                    click={() => openModal()}
                  />
                )}
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
        <CommonModal
          isOpen={isModalOpen}
          textHeading={`ユーザー「${tableData.userName}」の情報を削除します。よろしいですか？`}
          textOperation="この操作は取り消せません。本当に削除しますか？"
          onClose={() =>  setIsModalOpen(false)}
        >
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={handleCancel}
          />
          <CustomButton
            {...defaultButtonProps}
            text="ユーザー情報を削除"
            type="warning"
            iconName=""
            size="large"
            click={() => handleDelete()}
          />
        </CommonModal>
      </View>
    </PageContent>
  );
}
