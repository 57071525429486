import React from "react";

export default function EquipementIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 2V5" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 2V5" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.5 9.08984H20.5" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.211 15.7703L15.671 19.3103C15.531 19.4503 15.401 19.7103 15.371 19.9003L15.181 21.2503C15.111 21.7403 15.451 22.0803 15.941 22.0103L17.291 21.8203C17.481 21.7903 17.751 21.6603 17.881 21.5203L21.421 17.9803C22.031 17.3703 22.321 16.6603 21.421 15.7603C20.531 14.8703 19.821 15.1603 19.211 15.7703Z" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.6992 16.2803C18.9992 17.3603 19.8392 18.2003 20.9192 18.5003" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 13.08V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5V12" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 22H8C4.5 22 3 20 3 17" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9945 13.7002H12.0035" stroke="#393A94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.29529 13.7002H8.30427" stroke="#393A94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.29529 16.7002H8.30427" stroke="#393A94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
