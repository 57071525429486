import { Text, Heading } from "@aws-amplify/ui-react";
import React from "react";
import { Section } from "types/ITermOfUse";
import _ from "lodash";
import "../../styles/term-of-use.scss";

function SubSection({ sections }: any) {
  return _.isString(sections) ? (
    <Text className="section">{sections}</Text>
  ) : (
    <>
      <Text paddingLeft={40} marginBottom={10}>
        {sections.title}
      </Text>
      {sections.subSections.map((data: any) => (
        <Text paddingLeft={60} marginBottom={10}>
          {data}
        </Text>
      ))}
    </>
  );
}

export default function Sections({ sections }: { sections: Section[] }) {
  return sections.map(
    ({ title, content, subContent, sections: sectionList }, index) => (
      <React.Fragment key={index}>
        <Heading level={5} fontSize={16} textAlign={'left'} className="section-title">
          {title}
        </Heading>
        {!_.isEmpty(content) && <Text className="last-content">{content}</Text>}
        {!_.isEmpty(sectionList) && (
          <div className="last-content">
            {sectionList?.map((sectionData: any) => (
              <SubSection sections={sectionData} />
            ))}
          </div>
        )}

        {!_.isEmpty(subContent) &&
          subContent?.map((contentData, sbIndex) => (
            <Text
              className={
                sbIndex === subContent.length - 1
                  ? "last-sub-content"
                  : "sub-content"
              }
            >
              {contentData}
            </Text>
          ))}
      </React.Fragment>
    )
  );
}
