import React, { useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import { TextField, View, Flex, Text, Heading } from "@aws-amplify/ui-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { IAuthEdit } from "../../types/IAuth";
import { AuthApi } from "../../api/auth";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function AuthEditPage() {
  const api = AuthApi();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();
  const { state } = location;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [payload, setPayload] = useState<IAuthEdit>({
    authority_groups_name: state?.pageTitle,
  });

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/auth/list", label: "権限管理" },
    { href: "", label: "権限グループ編集" },
  ];

  const openModal = () => {
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setNameError(() => {
      return payload.authority_groups_name === "" ? true : false;
    });
    if (payload.authority_groups_name !== "") {
      setIsModalOpen(true);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false);
    }
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
    closeActions()
  };

  const handleSubmit = async () => {
    const createPayload = {
      authority_groups_name: payload.authority_groups_name,
    };

    setDisableButton(true);
    setErrorMessage("");

    try {
      const { data } = await api.updateAuth(groupId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/auth/list`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message);
      }
    } catch (err) {
      return err;
    }
    closeActions()
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  };

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={state?.pageTitle || pageTitle}
    >
      <View className="main-content">
        <View as="form" onSubmit={() => handleSubmit()} className="custom-form">
          <Heading level={5} marginBottom={'30px'}>新規登録</Heading>
          {hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          ) : null}
          <TextField
            label="権限グループ名"
            placeholder=""
            value={payload.authority_groups_name}
            onChange={(event) =>
              handleChange("authority_groups_name", event.target.value)
            }
            isRequired
            className={nameError ? `required input-error` : "required"}
          />
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="権限グループ情報を更新"
              size="medium"
              width="medium"
              borderRadius="large"
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={showCancelModal}
            />
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              icon="icon"
              textHeading={`権限グループ「${payload.authority_groups_name}」の情報を更新しました`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="権限グループ一覧へ"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleSubmit()}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄して一覧へ戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="xs"
                  width="small"
                  click={() => navigate(`/auth/list`)}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
