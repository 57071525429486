import FetchHeader from "../../utils/fetchHeader";

export const NoticeApi = () => {
  const getList = async (
    limit: number = 20,
    page: number = 1,
    site_id: number
  ) => {
    const url = `/notice/list?limit=${limit}&page=${page}&site_id=${site_id}`;
    const response = await FetchHeader(url, "GET");
    return response;
  };

  return {
    getList,
  };
};
