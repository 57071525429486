import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from '../../components/common-modal';

// amplify ui
import {
  Flex,
  Text,
  View,
  Table,
  Loader,
  TableCell,
  TableBody,
  TableRow,
} from "@aws-amplify/ui-react";

// types
import { ISiteDetails } from "../../types/ISiteList";

// api
import { SiteApi } from "../../api/site";

import getDeviceType from "../../utils/getDeviceType";

export default function SitePage() {
  const defaultButtonProps = {
    text: '',
    type: 'primary',
    iconPosition: 'left',
    iconName: '',
    size: '',
    disabled: false,
    click: () => { return '' }
  }

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/site/list', label: '現場管理' },
    { href: '/site/list', label: '現場一覧' },
    { href: '', label: '現場詳細' },
  ]

  const [tableData, setTableData] = useState<ISiteDetails>({
    site_name: "",
    primeContractor: "",
    rentalCompany: "",
    otherCorporation: "",
    constructionArea: "",
    inventoryLocation: "",
    remarks: "",
  });

  // const { name } = useParams()
  const isMobile = getDeviceType() === 'mobile'
  const { siteId = '' } = useParams();
  const api = SiteApi()
  const navigate = useNavigate();
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const [siteStatus, setSiteStatus] = useState(false)

  const geDetails = async () => {
    setLoader(true);
    const { data, status } = await api.getDetail(siteId);
    if (status === 200) {
      setLoader(false);
      setFetchError(false);
      const siteData = data.data;
      const primeCompanies = siteData.site_prime_companies.filter((company: { company: any; }) => company.company);
      const rentalCompanies = siteData.site_rental_companies.filter((company: { company: any; }) => company.company);
      const otherCompanies = siteData.site_other_companies.filter((company: { company: any; }) => company.company);
      
      setTableData({
        site_name: siteData.site_name || '',
        primeContractor: primeCompanies.length > 0 ? primeCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name).join(', ') : '',
        rentalCompany: rentalCompanies.length > 0 ? rentalCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name).join(', ') : '',
        otherCorporation: otherCompanies.length > 0 ? otherCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name).join(', ') : '',
        constructionArea: siteData.site_construction_locations.length > 0 ? siteData.site_construction_locations.map((location: { name: any; }) => location.name).join(', ') : '',
        inventoryLocation: siteData.site_inventory_locations.length > 0 ? siteData.site_inventory_locations.map((location: { name: any; }) => location.name).join(', ') : '',
        remarks: siteData.remarks || ''
      });
      setSiteStatus(siteData.status)
    } else {
      setFetchError(true);
    }
  };

  const openModal: () => {} = () => {
    setIsModalOpen(true)
    setIsSuccess(false)
    return {};
  };

  const handleDelete = async () => {
    const editPayload = {
      status: false,
    }
    try {
    const { data } = await api.editSite(siteId, editPayload);
      if (data?.success) {
        geDetails()
        setIsModalOpen(false)
        setIsSuccess(true)
      } 
    } catch (err) {
      console.log(err);
    }
  };


  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate('/site/list')
  };

  const toSiteEdit = () => {
    navigate(`/site/edit/${siteId}`)
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = 'auto';
  }

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  useEffect(() => {
    geDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={tableData.site_name}
    >
      {isSuccess && <View as="p" padding={'12px 16px'} fontSize={'16px'} backgroundColor={`#F0F9F3`} color={`#1C8C6E`} textAlign={'center'} fontWeight={'bold'} borderRadius={'8px'}>
        {`現場 「${tableData.site_name}」をクローズしました。`}
      </View>}
      <View
        className="custom-table"
      >
        {!loader ? (
          <>
            <Table>
              <TableBody>
                {/*<TableRow>
                  <TableCell fontWeight={700} width="50%" border="0">元請け会社</TableCell>
                  <TableCell border="0">
                    {Array.isArray(tableData.primeContractor) ? (
                      tableData.primeContractor
                        .filter(company => company && company.company)
                        .map((company, index) => (
                          <View key={index}>{company.company.company_name}</View>
                        ))
                    ) : (
                      tableData.primeContractor
                    )}
                  </TableCell>
                </TableRow>*/}
                {/*<TableRow>
                  <TableCell fontWeight={700} width="50%">レンタル会社</TableCell>
                  <TableCell>
                    {Array.isArray(tableData.rentalCompany) ? (
                      tableData.rentalCompany
                        .filter(company => company && company.company)
                        .map((company, index) => (
                          <View key={index}>{company.company.company_name}</View>
                        ))
                    ) : (
                      tableData.rentalCompany
                    )}
                </TableCell>
                </TableRow>*/}
                {/*<TableRow>
                  <TableCell fontWeight={700} width="50%">その他法人</TableCell>
                  <TableCell>{tableData.otherCorporation}</TableCell>
                  </TableRow>*/}
                <TableRow>
                  <TableCell fontWeight={700} width="50%">工区</TableCell>
                  <TableCell>{tableData.constructionArea}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">棚卸し場所（所在）</TableCell>
                  <TableCell>{tableData.inventoryLocation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">備考</TableCell>
                  <TableCell>{tableData.remarks}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            
            { siteStatus && (
            <>
              <Flex
                marginTop="20px"
                justifyContent='flex-end'
                className="hide-from-print"
              >
                <CustomButton
                  {...defaultButtonProps}
                  text='編集'
                  type="primary"
                  click={() => toSiteEdit()}
                />
                {/*<CustomButton
                  {...defaultButtonProps}
                  text='配属ユーザー編集'
                  type={isMobile ? 'transparent' : 'primary'}
                  width=''
                  iconPosition={isMobile ? '' : 'left'}
                  iconName={isMobile ? '' : 'users'}
                  click={() => navigate(`/site/assign/user/${siteId}`)}
              />*/}
                <CustomButton
                    {...defaultButtonProps}
                    text='現場をクローズする'
                    type={'bordered-gray'}
                    click={openModal}
                  />
              </Flex>
            </>)}
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading={`現場「${tableData.site_name}」をクローズします。よろしいですか？`}
          textOperation="この操作は取り消せません。本当にクローズしますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='キャンセル'
              type='bordered-transparent'
              click={handleCancel}
            />
            { siteStatus && (
              <CustomButton
                { ...defaultButtonProps }
                text='現場をクローズする'
                click={handleDelete}
              />
            )}
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
