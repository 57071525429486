import React from "react";

type Props = {
  color: string,
  opacity: string
};

export default function CameraIcon(props : Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3751 9.61536C10.4862 9.61536 8.14429 12.25 8.14429 15.5C8.14429 18.75 10.4862 21.3846 13.3751 21.3846C16.2639 21.3846 18.6058 18.75 18.6058 15.5C18.6058 12.25 16.2639 9.61536 13.3751 9.61536ZM10.6058 15.5C10.6058 13.7794 11.8457 12.3846 13.3751 12.3846C14.9045 12.3846 16.1443 13.7794 16.1443 15.5C16.1443 17.2206 14.9045 18.6154 13.3751 18.6154C11.8457 18.6154 10.6058 17.2206 10.6058 15.5Z"
        fill={props.color || "black"}
        fillOpacity={props.opacity || "0.56"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96316 1.13364C9.52877 0.793308 10.1603 0.615283 10.8017 0.615356H15.9482C16.5896 0.615282 17.2213 0.793307 17.787 1.13364C18.3526 1.47401 18.8348 1.96619 19.1909 2.56651L19.1916 2.5678L20.2735 4.39651C20.4047 4.61736 20.5826 4.79915 20.7908 4.92441C20.9992 5.04981 21.2319 5.1154 21.4682 5.11536L22.7084 5.11536C23.7421 5.11536 24.7334 5.57731 25.4643 6.39958C26.1952 7.22186 26.6058 8.3371 26.6058 9.49997V23C26.6058 24.1628 26.1952 25.2781 25.4643 26.1004C24.7334 26.9226 23.7421 27.3846 22.7084 27.3846H4.04172C3.00806 27.3846 2.01673 26.9226 1.28582 26.1004C0.554909 25.2781 0.144287 24.1628 0.144287 23V9.49997C0.144287 8.3371 0.554908 7.22186 1.28582 6.39958C2.01673 5.57731 3.00806 5.11536 4.04172 5.11536H5.28172C5.51806 5.1154 5.75094 5.04981 5.95934 4.92441C6.16761 4.79909 6.34519 4.61791 6.47633 4.39692L7.55924 2.56651C7.91527 1.96619 8.3975 1.47401 8.96316 1.13364ZM10.1241 3.57553C10.3325 3.45014 10.5652 3.38455 10.8015 3.38459H15.9484C16.1847 3.38455 16.4176 3.45014 16.626 3.57553C16.8342 3.7008 17.0117 3.88187 17.1428 4.10273L18.2259 5.93343C18.5819 6.53376 19.0642 7.02594 19.6298 7.3663C20.1954 7.70664 20.827 7.88466 21.4684 7.88459H22.7084C23.0892 7.88459 23.4544 8.05478 23.7237 8.35772C23.993 8.66067 24.1443 9.07155 24.1443 9.49997V23C24.1443 23.4284 23.993 23.8393 23.7237 24.1422C23.4544 24.4452 23.0892 24.6154 22.7084 24.6154H4.04172C3.6609 24.6154 3.29567 24.4452 3.02639 24.1422C2.75711 23.8393 2.60583 23.4284 2.60583 23V9.49997C2.60583 9.07154 2.75711 8.66067 3.02639 8.35772C3.29567 8.05478 3.6609 7.88459 4.04172 7.88459H5.28154C5.92296 7.88466 6.55468 7.70664 7.12028 7.3663C7.68594 7.02594 8.16818 6.53376 8.52421 5.93343L9.60687 4.10343C9.73802 3.88244 9.91583 3.70085 10.1241 3.57553Z"
        fill={props.color || "black"}
        fillOpacity={props.opacity || "0.56"}
      />
    </svg>
  );
}
