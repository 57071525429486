import React from "react";
import { useEffect, useState } from "react";
import { PostsApi } from "../../api/test/post";

// interfaces
import { CreatePost, UpdatePost, PatchPost } from "types/test";

export default function GetApi() {
  const postApi = PostsApi();
  const [list, setList] = useState([]);
  const [post, setPost] = useState({});

  const createPayload: CreatePost = {
    title: "foo",
    body: "bar",
    userId: 1,
  };

  const updatePayload: UpdatePost = {
    id: 1,
    title: "foo",
    body: "bar",
    userId: 1,
  };

  const patchPayload: PatchPost = {
    title: "foo",
  };

  const createPost = async () => {
    return postApi.createPost(createPayload);
  };

  const updatePost = async () => {
    return postApi.updatePost(1, updatePayload);
  };

  const patchPost = async () => {
    return postApi.patchPost(1, patchPayload);
  };

  const deletePost = async () => {
    return postApi.deletePost(1);
  };

  const getEq = async () => {
    return postApi.getList();
  };

  useEffect(() => {
    const getList = async () => {
      const list = await postApi.getList();
      setList(list);
    };

    const getPost = async () => {
      const postDetail = await postApi.getPost(1);
      setPost(postDetail);
    };

    getList();
    getPost();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <button onClick={getEq}>get eq list</button>
      <button onClick={createPost}>create post</button>
      <button onClick={updatePost}>update post</button>
      <button onClick={patchPost}>patch post</button>
      <button onClick={deletePost}>delete post</button>
      <pre>{JSON.stringify(post, null, 2)}</pre>
      <pre>{JSON.stringify(list, null, 2)}</pre>
    </div>
  );
}
