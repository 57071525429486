import React from "react";
import { Text, Heading } from "@aws-amplify/ui-react";
import "../../../styles/privacy-policy.scss";
import { infoUsagePurposeData } from "./constants";

//* Second Section
export default function InfoUsagePurpose() {
  return (
    <>
      <Heading level={5} className="heading-title" textDecoration={"underline"}>
        {infoUsagePurposeData.title}
      </Heading>
      <Text className="content">{infoUsagePurposeData.content}</Text>
      {infoUsagePurposeData.subContent?.map((data, index) => (
        <Text
          className={
            index === infoUsagePurposeData.subContent.length - 1
              ? "last-sub-content"
              : "sub-content"
          }
        >
          {data.content}
        </Text>
      ))}
      {infoUsagePurposeData.otherContent?.map((data: string, index: number) => (
        <Text
          className={
            index === infoUsagePurposeData.otherContent.length - 1
              ? "last-content"
              : "other-content"
          }
        >
          {data}
        </Text>
      ))}
      {infoUsagePurposeData.inquiryDetails?.map((data: string, index: number) => (
        <Text
          className={
            index === infoUsagePurposeData.inquiryDetails.length - 1
              ? "last-content"
              : "other-content"
          }
        >
          {data}
        </Text>
      ))}
    </>
  );
}
