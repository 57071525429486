import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";　

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import { AuthorityContext } from "../../App";
import getDeviceType from "../../utils/getDeviceType";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  IconsProvider,
  Loader,
  Text,
  CheckboxField,
  TableHead,
  SearchField,
} from "@aws-amplify/ui-react";

// icons
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// types
import { IUserList } from "../../types/IUsers";

// api
import { UserApi } from "../../api/user";
import { SiteApi } from "../../api/site";

type SiteSelect = {
  value: string;
  label: string;
};

export default function UserListPage() {
  const isMobile = getDeviceType() === 'mobile'
  const { site, company, settings }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const username = localStorage.getItem("username") || "null";
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [dataList, setDataList] = useState([] as any);
  const itemsLimit = 20;
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [name, setName] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userToDeleteId, setUserToDeleteId] = useState("");
  const [isSettingsEmpty, setIsSettingsEmpty] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const [settingsList, setSettingsList] = useState([] as string[]);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | number>('')
  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)
  const [siteId, setSiteId] = useState("");
  const [siteSelections, setSiteSelections] = useState<
  SiteSelect[] | undefined
>([]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";
  const [freewordValue, setFreewordValue] = useState("" as string);

  // const indexOfLastUser = currentPageIndex * itemsLimit;
  // const indexOfFirstUser = (indexOfLastUser - itemsLimit) + 1;
  // const currentItems = dataList?.slice(indexOfFirstUser, indexOfLastUser);
  const api = UserApi();
  const siteApi = SiteApi();

  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "", label: "ユーザー一覧" },
  ];

  const settingsButton = async () => {
    setShowInactiveUsers(!showInactiveUsers);
    setCurrentPageIndex(currentPageIndex);
    setIsFirstLoad(false);
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const editUser = async (name: string, id: string | number) => {
    setIsActionsModalOpen(true)
    setSelectedUser(id)
    setName(name);
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: "smooth" });
    // id === username
    //   ? navigate(`/account/edit/email/${id}`)
    //   : navigate(`/user/edit/${id}`);
  };

  const openModal = (name: string, id: any) => {
    setName(name);
    setUserToDeleteId(id);
    setIsModalOpen(true);
    setIsActionsModalOpen(false)
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleDelete = () => {
    deleteUser(selectedUser);
  };

  const handleRemoveBodyHidden = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  }

  const handleCancel = () => {
    // @ts-ignore
    handleRemoveBodyHidden()
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsModalOpen(false);
  };

  const getList = async (page: number, flag: boolean = false, limit:number = itemsLimit) => {
    if (isFetching) return;
  
    setIsFetching(true);
    setLoader(true);
  
    try {
      const { data, status } = await api.getList(page, showInactiveUsers, limit, +siteId, freewordValue);
  
  
      if (status === 200) {
         // checker
          if (currentPageIndex > data.data.totalPages) {
            if(data.data.totalPages != 0){
              setCurrentPageIndex(data.data.totalPages);
            }else{
              setCurrentPageIndex(1);
              setLoader(false);
              setDataList([]);
            }
            return;
          }
        const mappedList: IUserList = data?.data?.users?.map((item: any) => {
          return {
            id: item?.user_id,
            company_name: item?.company_name,
            name: item?.user_name,
            //@ts-ignore
            site: item?.user_affiliated_sites.map(site => site.site_name).join(' '),
            //@ts-ignore
            group: item?.user_affiliated_authority_groups.map(authority => authority.authority_groups_name).join(' '),
            site_id: item?.site_id,
            deleted: item?.delete_flag,
          };
        });
        setDataList(mappedList);
        setTotalPages(data?.data?.totalPages);
        setTotalCount(data?.data?.totalCount);
        setLoader(false);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    showInactiveUsers
      ? getList(currentPageIndex + 1, showInactiveUsers)
      : getList(currentPageIndex + 1);
    setIsFirstLoad(false);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    showInactiveUsers
      ? getList(currentPageIndex - 1, showInactiveUsers)
      : getList(currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const deleteUser = async (id: any) => {
    setDisableButton(true);
    setErrorMessage("");
    try {
      const { data } = await api.deleteUser(id);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(false);
        setErrorMessage("");
        setCurrentPageIndex(1);
        getList(currentPageIndex);

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(true);
        setErrorMessage(
          data?.error?.message ?? data?.message ?? "Error deleting user!"
        );

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      }

      // @ts-ignore
      handleRemoveBodyHidden()
    } catch (err) {
      console.log(err);
    }
  };

  const handleCellClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => {
    const target = e.target as HTMLTableCellElement;
    if (target.className === "amplify-table__td") {
      navigate(`/user/${id}`);
    }
  };

  const handleChange = (value: string) => {
    setFreewordValue(value);
  };

  const closeActions = () => {
    // @ts-ignore
    handleRemoveBodyHidden()
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsActionsModalOpen(false);
  };

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setFreewordValue("");
    setCurrentPageIndex(1);
    setSiteId(value);
  };

  useEffect(() => {
    const retSettingsList = [];
    if (settings) {
      for (let site_id in settings) {
        const setting = settings[site_id];
        if (setting.page_control) {
          retSettingsList.push(...setting.page_control);
        }
      }
      setSettingsList(retSettingsList);
      setIsSettingsEmpty(false)
    }
  }, []);

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            setSiteId(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteId(mappedSiteList[0].value)
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        setSiteId(initialSelectedSiteId)
        setSiteSelections(mappedSiteList);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteId(mappedSiteList[0].value);
      }
    };
 
    // eslint-disable-next-line
  }, [site, company]);

  useEffect(() => {
    if (settings && !isSettingsEmpty && siteId !== "") {
      setFreewordValue("");
      getList(currentPageIndex);
    }
    // eslint-disable-next-line
  }, [isSettingsEmpty, currentPageIndex, siteId]);
  
  useEffect(() => {
    if(!isFirstLoad) {
      getList(currentPageIndex, showInactiveUsers);
    }
  }, [showInactiveUsers])

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent 
      breadcrumbs={breadCrumbsItems} 
      title={pageTitle}
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue={siteId}
      changeEvent={setSiteForFilter}
    >
      <Flex className="hide-from-print hide-from-print-v2" alignItems={"center"}>
        {(company?.admin_flag === true ||
          settingsList.includes("user_regist")) && (
          <CustomButton
            {...defaultButtonProps}
            size="medium"
            width="medium"
            borderRadius="large"
            text="新規ユーザー登録"
            click={() => navigate("/user/create")}
          />
        )}
        <SearchField
          placeholder="検索"
          label="search"
          value={freewordValue}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          onSubmit={() => {
            setCurrentPageIndex(1);
            getList(currentPageIndex);
          }}
          className="search-eq search-sp"
        />
        {(company?.admin_flag === true ||
          settingsList.includes("inactive_user_display")) && (
          <Flex flex={1} justifyContent={"flex-end"} maxHeight={"30px"}>
            <CheckboxField
              label="非アクティブユーザーを表示"
              name="a-construction"
              value=""
              onChange={() => settingsButton()}
            />
          </Flex>
        )}
      </Flex>
      <View className="custom-table custom-table-2">
        {hasError ? (
          <Flex className="signin-error-message">
            <View className="error-icon">
              <ErrorSmallIcon fill="#D32F2F" />
            </View>
            <Text>{errorMessage}</Text>
          </Flex>
        ) : null}
        {!loader ? (
          <>
            <View as="div" className="scrollable-pc">
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow className="custom-table-head" backgroundColor="#edf0f8">
                    <TableCell as="th" width={"200px"}>
                      ユーザーID
                    </TableCell>
                    <TableCell as="th" width={"300px"}>
                      所属法人
                    </TableCell>
                    <TableCell as="th" width={"300px"}>
                      現場名
                    </TableCell>
                    <TableCell as="th" width={"300px"}>
                      名前
                    </TableCell>
                    <TableCell as="th" width={"300px"}>権限グループ</TableCell>
                    {/* {(company?.admin_flag === true ||
                      settingsList.includes("user_edit")) && ( */}
                      <TableCell
                        className="hide-from-print"
                        as="th"
                        width={"150px"}
                        textAlign={"center"}
                      >
                        編集・削除
                      </TableCell>
                    {/* )} */}
                    {/* {(company?.admin_flag === true ||
                      settingsList.includes("user_delete")) && (
                      <TableCell
                        className="hide-from-print"
                        as="th"
                        width={"58px"}
                        textAlign={"center"}
                      >
                        削除
                      </TableCell>
                    )} */}
                  </TableRow>
                </TableHead>
                <TableBody className="vertical-scroll">
                  {dataList?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10} textAlign={"center"}>
                        <Text>No data found.</Text>
                      </TableCell>
                    </TableRow>
                  )}
                  {dataList?.map((item: any, index: number) => {
                    return (
                      <TableRow
                        onClick={(e) => handleCellClick(e, item.id)}
                        key={index}
                        className={`table-row table-no-title ${
                          item.deleted ? "inactive-row" : ""
                        }`}
                      >
                        <TableCell width={"200px"}>
                          <View as="div"><View as="p" className="table-title">ユーザーID</View></View>
                          <span>{item.id}</span>
                        </TableCell>
                        <TableCell width={"300px"}>
                          <View as="div"><View as="p" className="table-title">所属法人</View></View>
                          <span>{item.company_name}</span>
                        </TableCell>
                        <TableCell width={"300px"}>
                          <View as="div"><View as="p" className="table-title">現場名</View></View>
                          {item.site}
                          </TableCell>
                        <TableCell width={"300px"}>
                          <View as="div"><View as="p" className="table-title">名前</View></View>
                          {item.name}
                        </TableCell>
                        <TableCell width={"300px"}>
                          <View as="div"><View as="p" className="table-title">権限グループ</View></View>
                          {item.group}
                        </TableCell>
                        {/* {company?.admin_flag === true && ( */}
                          <TableCell
                            className="hide-from-print table-actions-v2 user-list-tb"
                            textAlign={"center"}
                            width={"150px"}
                          >
                            <CustomButton
                              {...defaultButtonProps}
                              type="icon"
                              iconName="menu"
                              click={() => editUser(item.name, item.id)}
                            />
                          </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </View>
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => handleCancel()}
              textHeading={`ユーザー「${name}」の情報を削除します。よろしいですか？`}
              textOperation="この操作は取り消せません。本当に削除しますか？"
            >
              <Flex gap={{'base': '12px', 'medium': '32px'}} justifyContent={'center'} width={'100%'}>
                <CustomButton
                  {...defaultButtonProps}
                  text="キャンセル"
                  type="default"
                  iconName="close"
                  iconPosition="left"
                  size="large"
                  click={() => handleCancel()}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="ユーザー情報を削除"
                  type="warning"
                  size="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleDelete()}
                />
              </Flex>
            </CommonModal>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  totalPages={totalPages}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className={`custom-pagination ${
                    currentPageIndex === totalPages ? "disabled" : ""
                  }`}
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
      </View>
      <View className="company-action-modal">
          <CommonModal
            isOpen={isActionsModalOpen}
            onClose={() => closeActions()}
            textHeading=""
            textOperation=""
          >
            {isMobile ? (
              <View as="ul" width='100%' style={{'listStyleType': 'none', padding: '0'}}>
                {(company?.admin_flag === true || settingsList.includes("user_edit")) && (
                  <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                    <Link to={selectedUser === username ? `/account/edit/email/${selectedUser}` : `/user/edit/${selectedUser}`} onClick={() => handleRemoveBodyHidden()}>編集</Link>
                  </View>
                )}
                {(company?.admin_flag === true || settingsList.includes("user_delete")) && (
                  <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                    <View as="a" onClick={() => openModal(name, selectedUser)} style={{ cursor: 'pointer' }}>削除</View>
                  </View>
                )}
              </View>
            ) : (
              <>
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="編集"
                  type="primary"
                  iconName="pen"
                  iconPosition="left"
                  size="large"
                  click={() => {
                    handleRemoveBodyHidden()
                    const path = selectedUser === username 
                      ? `/account/edit/email/${selectedUser}` 
                      : `/user/edit/${selectedUser}`;
                    navigate(path)
                  }}
                />
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="削除"
                  type="default"
                  iconName="trash"
                  iconPosition="left"
                  size="large"
                  click={() => openModal(name, selectedUser)}
                />
              </>
            )}
          </CommonModal>
        </View>
    </PageContent>
  );
}
