import React, { useContext, useEffect, useState } from "react";
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
import { useLocation, useNavigate } from "react-router-dom";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import { AuthApi } from "../../api/auth";
import { CompanyApi } from "../../api/company";
import { SiteApi } from "../../api/site";

import {
  View,
  Flex,
  CheckboxField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SelectField,
  Text,
  Loader,
} from "@aws-amplify/ui-react";
import SelectIcon from "../../components/icons/select-icon";
// utils
import { pageControlList } from "../../utils/pageControlList";
import { AuthorityContext } from "../../App";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};
// const animatedComponents = makeAnimated();

type SiteSelect = {
  value: string;
  label: string;
};

interface SelectFieldOption {
  value: string;
  label: string;
  pageControlList: [];
  recordControlList: {
    equipments_manage: [];
    equipments_schedule_manage: [];
    user_manage: [];
  };
  siteId: string;
}

interface CompanyList {
  category_id: string;
  value: string;
  label: string;
}

export default function AuthEditPermissions() {
  const api = AuthApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const navigate = useNavigate();
  const { company, site }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const [activeItem, setActiveItem] = useState<SelectFieldOption>({
    value: "",
    label: "",
    pageControlList: [],
    recordControlList: {
      equipments_manage: [],
      equipments_schedule_manage: [],
      user_manage: [],
    },
    siteId: "",
  });
  const [authGroupSelectFieldOptions, setAuthGroupSelectFieldOptions] =
    useState<SelectFieldOption[]>([]);
  const [
    filteredAuthGroupSelectFieldOptions,
    setFilteredAuthGroupSelectFieldOptions,
  ] = useState<SelectFieldOption[]>([]);
  const [tableData, setTableData] = useState(pageControlList);
  const [loader, setLoader] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recordControlModal, setRecordControlModal] = useState(false);
  const [controlIndex, setControlIndex] = useState("");
  const [companyList, setCompanyList] = useState<CompanyList[]>([]);
  const [rentalList, setRentalList] = useState<CompanyList[]>([]);
  const [recordListPayload, setRecordListPayload] = useState({
    first: [],
    second: [],
    third: [],
  });
  const [recordControlData] = useState([
    {
      id: "rentalListFirst",
      text: "機材情報管理",
      fields: [
        {
          name: "field 1",
          type: "select",
          options: [
            { value: "A", label: "Aレンタル会社" },
            { value: "B", label: "Bレンタル建機" },
            { value: "C", label: "Cレンタル社" },
          ],
        },
      ],
    },
    {
      id: "rentalListSecond",
      text: "機材スケジュール",
      fields: [
        {
          name: "field 1",
          type: "select",
          options: [
            { value: "A", label: "Aレンタル会社" },
            { value: "B", label: "Bレンタル建機" },
            { value: "C", label: "Cレンタル社" },
          ],
        },
      ],
    },
    {
      id: "companyList",
      text: "ユーザー管理",
      fields: [
        {
          name: "field 1",
          type: "select",
          options: [
            { value: "A", label: "Aレンタル会社" },
            { value: "B", label: "Bレンタル建機" },
            { value: "C", label: "Cレンタル社" },
          ],
        },
      ],
    },
  ]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";
  const [siteId, setSiteId] = useState(initialSelectedSiteId) ?? "";
  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);
  // const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  // const [payload, setPayload] = useState<IAuthCreate>({
  //   groupName: ''
  // })

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/auth/list", label: "権限管理" },
    { href: "", label: "権限編集" },
  ];

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const openModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setIsModalOpen(true);
  };

  const openRecordModal = (control: string) => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0 });
    setControlIndex(control);
    setRecordControlModal(true);
  };

  const handleRemoveBodyHidden = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  }

  const closeRecordModal = () => {
    // @ts-ignore
    handleRemoveBodyHidden()
    // document.querySelector(".main-body").style.overflow = "auto";
    setRecordControlModal(false);
  };

  const showCancelModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: "smooth" });
    setIsCancelModal(true);
  };

  const closeModal = () => {
    // @ts-ignore
    handleRemoveBodyHidden()
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsModalOpen(false);
    setIsCancelModal(false);
  };

  const handleCheckboxChange = (key: string, flag: boolean) => {
    const newData = tableData.map((item) => {
      const newSubMenu = item.subMenu.map((sub: any) => {
        if (sub.key === key) {
          return { ...sub, value: flag };
        }
        if (sub.components) {
          const newComponents = sub.components.map((comp: any) => {
            if (comp.key === key) {
              return { ...comp, value: flag };
            }
            return comp;
          });
          return { ...sub, components: newComponents };
        }
        return sub;
      });
      return { ...item, subMenu: newSubMenu };
    });
    setTableData(newData);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedText = event.target.options[selectedIndex].text;
    const selectedList =
      filteredAuthGroupSelectFieldOptions[selectedIndex].pageControlList;
    const selectedControlList =
      filteredAuthGroupSelectFieldOptions[selectedIndex].recordControlList;
    setActiveItem({
      value: event.target.value,
      label: selectedText,
      pageControlList: selectedList,
      recordControlList: selectedControlList,
      siteId: siteId,
    });
    const newData = selectedList;
    // console.log(newData);
    // @ts-ignore
    selectedList ? setTableData(newData) : setTableData(pageControlList);
  };

  const handleRecordChange = (type: string, value: any, checked: boolean) => {
    const { first, second, third } = recordListPayload;
    if (type === "rentalListFirst") {
      if (checked) {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          first: [...first, value],
        });
      } else {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          first: first.filter((e) => e !== value),
        });
      }
    }

    if (type === "rentalListSecond") {
      if (checked) {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          second: [...second, value],
        });
      } else {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          second: second.filter((e) => e !== value),
        });
      }
    }

    if (type === "companyList") {
      if (checked) {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          third: [...third, value],
        });
      } else {
        setRecordListPayload({
          ...recordListPayload,
          // @ts-ignore
          third: third.filter((e) => e !== value),
        });
      }
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");
    const payload = {
      page_control: tableData,
      record_control: {
        equipments_manage: recordListPayload.first,
        equipments_schedule_manage: recordListPayload.second,
        user_manage: recordListPayload.third,
      },
    };

    try {
      const { data } = await api.updateSettings(activeItem.value, payload);
      if (data?.success) {
        //window.location.reload();
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/auth/list`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }

      // @ts-ignore
      handleRemoveBodyHidden()
      // document.querySelector(".main-body").style.overflow = "auto";
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const getList = async () => {
    setLoader(true);
    try {
      const { data, status } = await api.getList();
      if (status === 200) {
        setLoader(false);
        const mappedList: SelectFieldOption[] = data?.data?.map(
          (item: any, index: number) => {
            return {
              value: item?.authority_group_id,
              label: item?.authority_groups_name,
              pageControlList: item?.authority_groups_value?.page_control,
              recordControlList: item?.authority_groups_value?.record_control,
              siteId: item?.site_id,
            };
          }
        );
        setAuthGroupSelectFieldOptions(mappedList);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCompanyList = async () => {
    try {
      const { data } = await companyApi.getList();
      if (data?.success) {
        const compList: CompanyList[] = data?.data?.filter((item:any)=>{
          return !(item.admin_flag ?? false);
        }).map((item: any) => {
          return {
            category_id: item.company_category_id,
            value: item.company_id,
            label: item.company_name,
          };
        });
        const rentList: CompanyList[] = compList.filter(
          (item: any) => item.category_id === "200"
        );
        setCompanyList(compList);
        setRentalList(rentList);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const setSiteForFilter = async (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteId(value);
  };

  useEffect(() => {
    getList();
    getCompanyList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    activeItem.pageControlList === undefined
      ? // @ts-ignore
        setTableData(pageControlList)
      : // @ts-ignore
        setTableData(activeItem.pageControlList);
    if (activeItem.recordControlList) {
      setRecordListPayload((prevState) => ({
        ...prevState,
        first: activeItem.recordControlList.equipments_manage,
        second: activeItem.recordControlList.equipments_schedule_manage,
        third: activeItem.recordControlList.user_manage,
      }));
    }
  }, [activeItem]);

  useEffect(() => {
    if(authGroupSelectFieldOptions?.length <= 0){
      return;
    }
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            setSiteId(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteId(mappedSiteList[0].value)
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        setSiteId(initialSelectedSiteId)
        setSiteSelections(mappedSiteList);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteId(mappedSiteList[0].value);
      }
    };
 
    // eslint-disable-next-line
  }, [site, company, authGroupSelectFieldOptions]);

  useEffect(() => {
    if (authGroupSelectFieldOptions.length > 0) {
      setFilteredAuthGroupSelectFieldOptions(
        authGroupSelectFieldOptions.filter((e) => e.siteId === siteId)
      );
      setActiveItem(
        authGroupSelectFieldOptions.filter((e) => e.siteId === siteId)
          .length > 0
          ? authGroupSelectFieldOptions.filter(
              (e) => e.siteId === siteId
            )[0]
          : authGroupSelectFieldOptions[0]
      );
    }
  }, [siteId, authGroupSelectFieldOptions]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue = {siteId}
      changeEvent={setSiteForFilter}
    >
      {!loader ? (
        <View className="main-content">
          {hasFormError && (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          )}
          <Flex justifyContent={"flex-end"} marginBottom={"20px"}>
            <SelectField
              label=""
              value={activeItem.value}
              inputStyles={{
                backgroundColor: "#fff ",
                border: `1px solid #bcbcbc`,
              }}
              labelHidden
              width={{
                small: "220px",
                xl: "179px",
              }}
              icon={<SelectIcon color={"#000"} />}
              onChange={(event) => handleSelectChange(event)}
            >
              {authGroupSelectFieldOptions.filter(
                (e) => e.siteId === siteId
              ).length > 0 &&
                authGroupSelectFieldOptions
                  .filter((e) => e.siteId === siteId)
                  .map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
            </SelectField>
          </Flex>
          {/* <Table className="auth-table auth-table-head">
            <TableHead>
              <TableRow textAlign={"center"}>
                <TableCell as="th" minHeight={"35px"} width={"50%"}>
                  {" "}
                  項目{" "}
                </TableCell>
                <TableCell
                  as="th"
                  minHeight={"35px"}
                  width={"50%"}
                  whiteSpace={"nowrap"}
                >
                  {" "}
                  {activeItem.label}{" "}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table> */}
          <View className="table-scroll">
          <Table width="100%" className="auth-table">
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={4}
                  fontSize={'1rem'}
                  fontWeight={700}
                  backgroundColor={"#4365DE80"}
                  color={"#fff"}
                  padding={"12px 15px"}
                  textAlign={"left"}
                  width={"100%"}
                  className="has-border-b"
                >
                  機能制御
                </TableCell>
              </TableRow>
              {tableData
                ?.filter((item) => {
                  //TODO remove filter when auth control is fully implemented.
                  return !["equipment_schedule"].includes(item.settingsId);
                })
                .map((item, index) => {
                  return (
                    <TableRow
                      key={`td-${item.text}-${index}`}
                      className={`${index === 0 ? "no-border-t" : ""}`}
                    >
                      <TableCell
                        width={'200px'}
                        backgroundColor={"#EDF0F8"}
                        border={"1px solid #D9E4FD"}
                      >
                        <View as="span" className="cell-span">
                          {item.text}
                        </View>
                      </TableCell>
                      <TableCell 
                        width={'245px'} 
                        textAlign={"center"}
                      >
                        {item.subMenu.length > 0 &&
                          item.subMenu
                            .filter((sub: any) => {
                              //TODO remove filter when auth control is fully implemented.
                              return !["権限編集"].includes(sub.text);
                            })
                            .map((sub: any, idx: any) => (
                              <View
                                key={`${sub.text}-${idx}`}
                                className={`cell-span ${
                                  idx !== 0 ? "has-border-t" : ""
                                }`}
                                backgroundColor={"#EDF0F8"}
                                border={"1px solid #D9E4FD"}
                                fontWeight={500}
                                height={`calc(54px*${
                                  sub?.components && sub.components.length > 1
                                    ? sub?.components.filter((comp: any) => {
                                        return ![
                                          "model",
                                          "charge_ordering",
                                          "site_of_use",
                                          "onsite_manage_no",
                                          "rental_company",
                                          "quantity",
                                          "total_operating_hour",
                                          "operating_time_at_the_start_of_use",
                                          "delivery_schedule_date",
                                          "delivery_schedule_time",
                                          "result_delivery_schedule_date",
                                          "result_delivery_schedule_time",
                                          "receiving_person",
                                          "scheduled_return_date",
                                          "scheduled_return_time",
                                          "return_date",
                                          "return_time",
                                          "person_in_charge_of_return",
                                          "remarks",
                                        ].includes(comp.key);
                                      }).length
                                    : 1
                                })`}
                                as="span"
                              >
                                {sub.text}
                              </View>
                            ))}
                      </TableCell>
                      {/* <TableCell
                        colSpan={1}
                        textAlign={"center"}
                        border={"none"}
                      >
                        {item.subMenu &&
                          item.subMenu?.length > 0 &&
                          item.subMenu
                            .filter((subm: any) => {
                              //TODO remove filter when auth control is fully implemented.
                              return !["権限編集"].includes(subm.text);
                            })
                            .map((subm: any) => {
                              if (
                                subm?.components &&
                                subm?.components?.length > 0
                              ) {
                                return subm.components
                                  .filter((comp: any) => {
                                    //TODO remove filter when auth control is fully implemented.
                                    return ![
                                      "model",
                                      "charge_ordering",
                                      "site_of_use",
                                      "onsite_manage_no",
                                      "rental_company",
                                      "quantity",
                                      "total_operating_hour",
                                      "operating_time_at_the_start_of_use",
                                      "delivery_schedule_date",
                                      "delivery_schedule_time",
                                      "result_delivery_schedule_date",
                                      "result_delivery_schedule_time",
                                      "receiving_person",
                                      "scheduled_return_date",
                                      "scheduled_return_time",
                                      "return_date",
                                      "return_time",
                                      "person_in_charge_of_return",
                                      "remarks",
                                    ].includes(comp.key);
                                  })
                                  .map((comp: any) => (
                                    <View
                                      key={`${comp.text}-${comp.key}-${index}`}
                                      className="cell-span gray-border"
                                      backgroundColor={"#fff"}
                                      as="span"
                                      fontWeight={500}
                                    >
                                      {comp.text}
                                    </View>
                                  ));
                              }
                              return (
                                <View
                                  key={`empty-${subm.text}-${index}`}
                                  className="cell-span gray-border"
                                  backgroundColor={"#fff"}
                                  as="span"
                                  fontWeight={500}
                                >
                                  -
                                </View>
                              );
                            })}
                      </TableCell> */}
                      <TableCell
                        textAlign={"center"}
                        border={"none"}
                      >
                        {item.subMenu &&
                          item.subMenu?.length > 0 &&
                          item.subMenu
                            .filter((subm: any) => {
                              //TODO remove filter when auth control is fully implemented.
                              return !["権限編集"].includes(subm.text);
                            })
                            .map((subm: any) => {
                              if (subm?.key) {
                                return (
                                  <Flex
                                    key={`${subm.key}-${index}`}
                                    className="gray-border-checkbox"
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                  >
                                    <CheckboxField
                                      label=""
                                      name={subm.key}
                                      checked={subm.value}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          subm.key,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Flex>
                                );
                              }
                              if (subm?.components) {
                                return subm.components
                                  .filter((comp: any) => {
                                    //TODO remove filter when auth control is fully implemented.
                                    return ![
                                      "model",
                                      "charge_ordering",
                                      "site_of_use",
                                      "onsite_manage_no",
                                      "rental_company",
                                      "quantity",
                                      "total_operating_hour",
                                      "operating_time_at_the_start_of_use",
                                      "delivery_schedule_date",
                                      "delivery_schedule_time",
                                      "result_delivery_schedule_date",
                                      "result_delivery_schedule_time",
                                      "receiving_person",
                                      "scheduled_return_date",
                                      "scheduled_return_time",
                                      "return_date",
                                      "return_time",
                                      "person_in_charge_of_return",
                                      "remarks",
                                    ].includes(comp.key);
                                  })
                                  .map((comp: any) => (
                                    <Flex
                                      key={`${comp.key}-${index}`}
                                      className="gray-border-checkbox"
                                      justifyContent={"center"}
                                      alignContent={"center"}
                                    >
                                      <CheckboxField
                                        label=""
                                        name={comp.key}
                                        checked={comp.value}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            comp.key,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </Flex>
                                  ));
                              }
                              return null;
                            })}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          </View>

          <View as="div" className="table-scroll">
            <Table width="100%" className="auth-table">
              <TableHead>
                <TableRow textAlign={"center"}>
                  <TableCell
                    fontSize={'1rem'}
                    fontWeight={700}
                    backgroundColor={"#4365DE80"}
                    color={"#fff"}
                    padding={"12px 15px"}
                    textAlign={"left"}
                    width={"100%"}
                    className="has-border-b"
                  >
                    レコード制御
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recordControlData.map((record, index) => {
                  return (
                    <TableRow
                      key={`td-${record.text}-${index}`}
                      className={`${index === 0 ? "no-border-t" : ""}`}
                    >
                      <TableCell
                        backgroundColor={"#EDF0F8"}
                        border={"1px solid #D9E4FD"}
                        width={{
                          base: '200px',
                          medium: '407px !important'
                        }}
                      >
                        <View as="span" className="cell-span">
                          {record.text}
                        </View>
                      </TableCell>
                      {record.fields.map((field, idx) => {
                        if (field?.options && field?.options?.length > 0) {
                          return (
                            <TableCell
                              key={`tc-${field.options}-${idx}`}
                              className="record-control-buttons"
                              style={{'verticalAlign': 'middle'}}
                            >
                              {/* <View className="cell-span">
                                <Select
                                  components={animatedComponents}
                                  isMulti
                                  // @ts-ignore
                                  onChange={(event) => handleRecordChange(event, index)}
                                  options={
                                    record.id === 'rentalList' ? rentalList : companyList
                                  }
                                />
                              </View> */}
                              <CustomButton
                                {...defaultButtonProps}
                                text="設定する"
                                size="small"
                                click={() => openRecordModal(record.id)}
                              />
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={`tc-${field.options}-${idx}`}
                            backgroundColor={`${idx === 0 ? "#EDF0F8" : ""}`}
                            border={"1px solid #D9E4FD"}
                            colSpan={1}
                          >
                            <View as="span" className="cell-span">
                              {field.name}
                            </View>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </View>
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="登録"
              size="medium"
              width="medium"
              borderRadius="large"
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={() => showCancelModal()}
            />
          </Flex>
        </View>
      ) : (
        <View className="table-loader">
          <Loader width="5rem" height="5rem" filledColor="#2196F3" />
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
      <View className="control-list-modal">
        <CommonModal
          isOpen={recordControlModal}
          onClose={() => closeRecordModal()}
        >
          <View className="control-list-container">
            {controlIndex === "companyList" &&
              companyList.map((item: any, index: number) => {
                return (
                  <CheckboxField
                    key={index}
                    label={item.label}
                    name={item.value}
                    // @ts-ignore
                    checked={recordListPayload.third.includes(item.value)}
                    onChange={(event: { target: { checked: boolean } }) =>
                      handleRecordChange(
                        "companyList",
                        item.value,
                        event.target.checked
                      )
                    }
                    className="control-check"
                  />
                );
              })}
            {controlIndex === "rentalListFirst" &&
              rentalList.map((item: any, index: number) => {
                return (
                  <CheckboxField
                    key={index}
                    label={item.label}
                    name={item.value}
                    // @ts-ignore
                    checked={recordListPayload.first.includes(item.value)}
                    onChange={(event: { target: { checked: boolean } }) =>
                      handleRecordChange(
                        "rentalListFirst",
                        item.value,
                        event.target.checked
                      )
                    }
                    className="control-check"
                  />
                );
              })}
            {controlIndex === "rentalListSecond" &&
              companyList.map((item: any, index: number) => {
                return (
                  <CheckboxField
                    key={index}
                    label={item.label}
                    name={item.value}
                    // @ts-ignore
                    checked={recordListPayload.second.includes(item.value)}
                    onChange={(event: { target: { checked: boolean } }) =>
                      handleRecordChange(
                        "rentalListSecond",
                        item.value,
                        event.target.checked
                      )
                    }
                    className="control-check"
                  />
                );
              })}
          </View>
        </CommonModal>
      </View>
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => closeModal()}
        icon="icon"
        textHeading="権限グループの更新をします。よろしいですか？"
      >
        <View margin={`0 auto`}>
          <CustomButton
            {...defaultButtonProps}
            text="権限グループを更新する"
            size="medium"
            width="medium"
            borderRadius="large"
            iconName={disableButton ? "loader" : ""}
            iconPosition="right"
            disabled={disableButton}
            click={() => handleSubmit()}
          />
        </View>
      </CommonModal>

      {/* cancel modal */}
      <CommonModal
        isOpen={isCancelModal}
        onClose={() => closeModal()}
        textHeading="入力内容を破棄して一覧へ戻りますか？"
      >
        <Flex justifyContent="center" margin={`0 auto`}>
          <CustomButton
            {...defaultButtonProps}
            text="編集へ戻る"
            type="bordered-transparent"
            click={() => closeModal()}
          />
          <CustomButton
            {...defaultButtonProps}
            text="破棄"
            size="xs"
            width="small"
            click={() => {
                handleRemoveBodyHidden()
                navigate("/auth/list")
              }
            }
          />
        </Flex>
      </CommonModal>
    </PageContent>
  );
}
