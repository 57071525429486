import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

// amplify ui
import {
  Flex,
  View,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  IconsProvider,
  Text,
  Loader,
  TableHead,
} from "@aws-amplify/ui-react";

// types
import { ISiteList } from "../../types/ISiteList";

// api
import { SiteApi } from "../../api/site";

// icons
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import getDeviceType from "../../utils/getDeviceType";


export default function SiteListPage() {
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/site/0000001", label: "現場管理" },
    { href: "", label: "現場一覧" },
  ];
  const isMobile = getDeviceType() === 'mobile'
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [dataList, setDataList] = useState([] as any);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsLimit = 20;
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const api = SiteApi();
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteDeleteId, setSiteDeleteId] = useState('')
  const [siteDeleteName, setSiteDeleteName] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [idSelected, setIdSelected] = useState('');
  const [nameSelected, setNameSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState(false);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  const editSite = async (id: string | number) => {
    navigate(`/site/edit/${id}`);
  };

  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)

  // const indexOfLastUser = currentPageIndex * itemsLimit;
  // const indexOfFirstUser = (indexOfLastUser - itemsLimit) + 1;
  // const currentItems = dataList.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    getList(itemsLimit, currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    getList(itemsLimit, currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const openModal = (id: any, name: any) => {
    setIsModalOpen(true);
    setSiteDeleteId(id)
    setSiteDeleteName(name)
  };

  const handleDelete = () => {
    deleteSite(siteDeleteId)
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsActionsModalOpen(false);
  };

  const getList = async (
    limit: number = itemsLimit,
    page: number = 1
  ) => {
      setLoader(true);
      const { data, status } = await api.getList(true, page);
      if (status === 200) {
        const mappedList: ISiteList = data?.data?.map((item: any) => {
          const contractorNames = item.site_prime_companies
            ?.map((company: any) => company.company?.company_name)
            .filter((name: any) => name)
            .join(", ");
          return {
            id: item?.site_id,
            name: item?.site_name,
            contractor: contractorNames,
            status: item?.status,
          };
        });
        setDataList(mappedList);
        setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
        setTotalCount(data?.data?.totalCount || data?.meta?.totalCount)
        setLoader(false);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
  };

  const deleteSite = async (id: any) => {
    setDisableButton(true)
    setErrorMessage('')
    try {
      const { data } = await api.deleteSite(id)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(false)
        setErrorMessage('')
        getList(itemsLimit, currentPageIndex)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCellClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: number) => {
    const target = e.target as HTMLTableCellElement;
    if (target.className === 'amplify-table__td' || target.className === 'amplify-badge') {
      navigate(`/site/${id}`);
    }
  };

  const handleActions = (id: any, name: string, status: boolean) => {
    setIdSelected(id);
    setNameSelected(name);
    setStatusSelected(status)
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: "smooth" });
    setIsActionsModalOpen(true);
  };

  useEffect(() => {
    getList(itemsLimit, currentPageIndex)
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <Flex
        justifyContent={{
            base: 'center',
            medium: 'flex-start'
        }}
        className="hide-from-print"
      >
        <CustomButton
          {...defaultButtonProps}
          text="新規現場登録"
          size='medium'
          width='medium'
          borderRadius='large'
          disabled={false}
          click={() => navigate("/site/create")}
        />
      </Flex>
      { !isMobile ? (<View className="custom-table">
        { hasError ? 
          (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
              <Text>{ errorMessage }</Text>
            </Flex>
          ) : null
        }
        {!loader ? (
          <>
            <View as="div">
              <Table className="site-list-table" highlightOnHover={true}>
                <TableHead className={dataList.length < 8 ? 'no-padding' : ''}>
                  <TableRow backgroundColor="#edf0f8">
                    <TableCell as="th"  width={'40%'}>
                      現場名
                    </TableCell>
                    <TableCell as="th" width={"20%"} textAlign={'center'}>
                      ステータス
                    </TableCell>
                    {/* <TableCell className="hide-from-print" as="th" width={"97px"} textAlign={"center"}>
                      ユーザー
                    </TableCell> */}
                    <TableCell className="hide-from-print" as="th" width={"30%"} textAlign={"center"}>
                      詳細・編集
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="vertical-scroll">
                  {dataList?.map((item: any, index: number) => {
                    return (
                      <TableRow onClick={(e) => handleCellClick(e, item.id)} key={index} className="table-row">
                        <TableCell width={'40%'}>
                          {item.name}
                        </TableCell>
                        <TableCell width={"20%"} textAlign={'center'}>
                          <View as="span" backgroundColor={!item.status ? "#EEEEEE" : "#F0F9F3"} color={!item.status ? "#616161" : "#1C8C6E"} display='inline-block' fontSize='12px' fontWeight='700' padding='5px 0' borderRadius='6px' height='27px' minWidth='96px' textAlign='center'>{item.status ? "進行中" : "クローズ"}</View>
                        </TableCell>
                        {/* <TableCell className="hide-from-print" textAlign={"center"}>
                          <CustomButton
                            {...defaultButtonProps}
                            type="icon"
                            iconName="user"
                            click={() => navigate(`/site/assign/user/${item.id}`)}
                          />
                        </TableCell> */}
                        <TableCell className="hide-from-print" textAlign={"center"} width={"30%"}>
                          {/* <CustomButton
                            {...defaultButtonProps}
                            type="icon"
                            iconName="edit"
                            click={ () => editSite(item.id) }
                          /> */}
                          <CustomButton
                            {...defaultButtonProps}
                            type="icon"
                            iconName="menu"
                            className="no-min"
                            click={() => handleActions(item.id, item.name, item.status)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </View>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className={`custom-pagination ${
                    currentPageIndex === totalPages ? "disabled" : ""
                  }`}
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
      </View>) : (
        <View padding={'40px 20px'} overflow='scroll'>
          { hasError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          <Flex direction='column'>
            {dataList?.map((item: any, index: any) => {
              return (
                <View key={`sp-${item.id}-${index}`} as="div" border='1px solid #DCE4E8' borderRadius='10px' padding='16px 16px'>
                  <Flex justifyContent='space-between' marginBottom='10px'>
                    <View as="p" fontWeight='700' fontSize='14px' margin='0'>{item.name}</View>
                    <View as="div">
                      <CustomButton
                        {...defaultButtonProps}
                        type="icon"
                        iconName="menu"
                        className="no-min"
                        click={() => handleActions(item.id, item.name, item.status)}
                      />
                    </View>
                  </Flex>
                  <Flex gap='12px' justifyContent='space-between'>
                    <View as="p" flex='1' fontSize='12px' margin='0' color='#6C7278'>{item.contractor}</View>
                    <Flex flex='0 96px'>
                      <View as="span" backgroundColor={item.status ? '#F0F9F3' : '#EEEEEE'} color={item.status ? '#1C8C6E' : '#616161'} display='block' fontSize='12px' fontWeight='700' padding='5px 0' borderRadius='6px' height='27px' width='100%' textAlign='center'>{item.status ? "進行中" : "クローズ"}</View>
                    </Flex>
                  </Flex>
                </View>
                )
              })
            }
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            fontWeight={400}
            fontSize={12}
            letterSpacing={0.4}
            className="hide-from-print"
            direction={'column'}
          >
            <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
            <IconsProvider
              icons={{
                pagination: {
                  next: <ArrowRightIcon />,
                  previous: <ArrowLeftIcon />,
                },
              }}
            >
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                // @ts-ignore
                onChange={handleOnChange}
                className={`custom-pagination ${
                  currentPageIndex === totalPages ? "disabled" : ""
                }`}
              />
            </IconsProvider>
          </Flex>
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        textHeading={`法人「${ siteDeleteName }」の情報を削除します。よろしいですか？`}
        textOperation="この操作は取り消せません。本当に削除しますか？"
      >
        <Flex justifyContent="center" margin={`0 auto`}>
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            type='bordered-transparent'
            click={() => handleCancel()}
          />
          <CustomButton
            {...defaultButtonProps}
            text="ユーザー情報を削除"
            size='large'
            iconName={ disableButton ? 'loader' : '' }
            disabled={ disableButton }
            click={() => handleDelete()}
          />
        </Flex>
      </CommonModal>
      <View className="company-action-modal">
          <CommonModal
            isOpen={isActionsModalOpen}
            onClose={() => closeActions()}
            textHeading=""
            textOperation=""
          >
            {isMobile ? (<View as="ul" width='100%' style={{'listStyleType': 'none', padding: '0'}}>
              <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                <Link to={`/site/${idSelected}`}>現場詳細</Link>
              </View>
              {/*<View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                <Link to={`/user/list`}>ユーザーの一覧</Link>
              </View>*/}
              {statusSelected && (<View as="li" width='100%' fontSize='16px'>
                <Link to={`/site/edit/${idSelected}`}>現場情報編集</Link>
              </View>)}
            </View>) : (
              <>
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="現場詳細"
                  type="primary"
                  size="medium"
                  click={() => navigate(`/site/${idSelected}`)}
                />
                {/*<CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="ユーザーの一覧"
                  type="primary"
                  size="medium"
                  click={() => navigate('/site/list')}
                />*/}
                {statusSelected && (<CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="現場情報編集"
                  type="primary"
                  iconName="pen"
                  iconPosition="left"
                  size="medium"
                  click={() => navigate(`/site/edit/${idSelected}`)}
                />)}
              </>
            )}
          </CommonModal>
        </View>
    </PageContent>
  );
}
