import React, { useEffect, useState } from "react";
import { View, Text, Button, Flex } from "@aws-amplify/ui-react";
import "src/styles/modal.scss";

import CircleCheckIcon from "../components/icons/circle-check";
import WarningIcon from "../components/icons/warning-icon";
import getDeviceType from "../utils/getDeviceType";

interface CommonModalProps {
  isOpen: boolean;
  textHeading?: string;
  icon?: string;
  textsmall?: string;
  text?: string;
  periodUse?: string;
  user?: string;
  users?: string;
  location?: string;
  textOperation?: string;
  textOperationAlign?: string;
  onClose?: () => void;
  children: React.ReactNode;
  isEqModal?: boolean;
  textAlign?: string;
  type?: string;
}

export default function CommonModal({
  isOpen: propIsOpen,
  textHeading,
  icon,
  textOperation,
  textOperationAlign,
  textsmall,
  periodUse,
  user,
  users,
  location,
  onClose,
  children,
  isEqModal = false,
  textAlign = "center",
  type,
}: CommonModalProps) {
  const [isOpen, setIsOpen] = useState(propIsOpen);
  const isMobile = getDeviceType() === "mobile";

  const modalHeight = isMobile ? (isEqModal ? "260vh" : "200vh") : "100%";
  //? This is for mobile modal view to look like dis
  const mobileStyle =
    type !== "desktop"
      ? {
          top: "50%",
          height: "auto",
        }
      : {
          height: "auto",
          width: "auto",
          borderRadius: "12px",
          padding: "50px 30px",
          top: "50%",
        };
  useEffect(() => {
    setIsOpen(propIsOpen);
  }, [propIsOpen]);

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      document.querySelector('body')?.classList.add('hidden');
    }
  }, [isOpen]);

  const handleClose = () => {
    // @ts-ignore
    setIsOpen(false);
    if (typeof onClose === "function") {
      onClose();
      }
      
    // @ts-ignore
    if (document.querySelector('body')?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector('body')?.classList.remove('hidden');
    }

    if (document.querySelector('.main-body')?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector('.main-body')?.classList.remove('hidden');
    }
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'visible';
  //   }
  //   return () => {
  //     document.body.style.overflow = 'visible';
  //   };
  // }, [isOpen]);

  // const handleClose = () => {
  //   setIsOpen(false);
  //   if (typeof onClose === 'function') {
  //     onClose();
  //   }
  // };

  return (
    <View
      className={`modal ${isOpen ? "open blur-bg" : ""}  `}
      style={
        // Set modal overlay style for fixed
        { height: "100%", position: "fixed" }
      }
    >
      <View className="modal__overlay"></View>
      <View className="modal-content" style={isMobile ? mobileStyle : {}}>
        {icon && (
          <View textAlign={`center`}>
            <CircleCheckIcon />
          </View>
        )}
        <Button border={`0`} className="close" onClick={handleClose}></Button>
        {textHeading !== "" && (
          <Text
            fontWeight={700}
            fontSize={16}
            lineHeight="20px"
            marginTop={0}
            textAlign={textAlign}
            marginBottom={textAlign === "left" ? "30px" : "60px"}
          >
            {textHeading}
          </Text>
        )}
        {textsmall !== "" && (
          <Text
            fontWeight={500}
            fontSize={16}
            lineHeight="24px"
            marginTop={10}
            letterSpacing="0.15px"
          >
            {textsmall}
          </Text>
        )}
        {location !== "" && (
          <Text
            fontWeight={500}
            fontSize={16}
            lineHeight="24px"
            letterSpacing="0.15px"
            marginBlockStart={"10px"}
          >
            {location}
          </Text>
        )}
        {periodUse !== "" && (
          <Text
            fontWeight={500}
            fontSize={16}
            lineHeight="24px"
            marginTop={0}
            letterSpacing="0.15px"
          >
            {periodUse}
          </Text>
        )}
        {user !== "" && (
          <Text
            fontWeight={500}
            fontSize={16}
            lineHeight="24px"
            letterSpacing="0.15px"
          >
            {user}
          </Text>
        )}
        {textOperation && (
          <Flex marginTop={-30} justifyContent={`center`}>
            <WarningIcon />
            <Text
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#EB5757"
              marginBottom={50}
              textAlign={textOperationAlign || 'center'}
              marginLeft={-10}
              whiteSpace={'break-spaces'}
            >
              {textOperation}
            </Text>
          </Flex>
        )}
        <Flex gap={32} marginTop={20}>
          {children}
        </Flex>
      </View>
    </View>
  );
}
