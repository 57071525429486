import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

// custom components
import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

// amplify ui
import {
  Flex,
  View,
  CheckboxField,
  Heading,
  Accordion,
  Loader,
  Text
} from "@aws-amplify/ui-react";

// icons
import SelectIcon from "../../../components/icons/select-icon";

// api
import { SiteApi } from "../../../api/site";

import getDeviceType from "../../../utils/getDeviceType"

export default function SiteAssignPage() {
  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/site/list', label: '現場管理' },
    { href: '', label: '現場一覧' },
    { href: '', label: '現場詳細' },
  ]

  const defaultButtonProps = {
    text: '',
    type: 'primary',
    iconPosition: '',
    iconName: '',
    size: '',
    disabled: false,
    click: () => { return '' }
  }

  const isMobile = getDeviceType() === 'mobile'
  const api = SiteApi();
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const { siteId = '' } = useParams();
  const navigate = useNavigate()
  const [accordionOpen, setAccordionOpen] = useState(Array.from({ length: 5 }, () => false));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [loader, setLoader] = useState<boolean>()
  const [fetchError, setFetchError] = useState(false);
  const [accordionData, setAccordionData] = useState([] as any)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [payload, setPayload] = useState({
    assign_users: [] as any
  })

  const handleAccordionToggle = (index: number) => {
    setAccordionOpen(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const openModal = () => {
    setIsModalOpen(true)
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleChange = (item: number, checked: boolean) => {
    const { assign_users } = payload
    if(checked) {
      setPayload({
        assign_users: [...assign_users, Number(item)]
      })
    } else {
      setPayload({
        assign_users: assign_users.filter(
          (e: any) => e !== Number(item)
        ),
      })
    }
  }

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')

    try {
      const { data } = await api.updateSiteUserList(siteId, payload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(false)
        setErrorMessage('')
        navigate(`/site/${ siteId }`)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch(err) {
      console.log(err)
    }
  };

  const getList = async () => {
    setLoader(true)
    try {
      const { data } = await api.getSiteUserList(siteId)
      if(data?.success) {
        let arr:any = []
        const mappedList = data?.data.map((item: any) => {
          item?.content?.map((item: any) => {
            if(item.checked) arr.push(Number(item?.id))
            return item
          })
          setPayload({
            ...payload.assign_users,
            assign_users: arr
          })
          return {
            id: item?.company_id,
            label: item?.company_name,
            content: item?.content
          }
        })
        setAccordionData(mappedList)
        setLoader(false)
        setFetchError(false)
      } else {
        setFetchError(true)
      }
    } catch(err) {
      return err
    }
  }

  useEffect(() => {
    getList()
  // eslint-disable-next-line
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View
        className="custom-table"
      >
        <Heading fontWeight={700} level={4} marginBottom={20}>
          配属ユーザー設定
        </Heading>
        { hasError ? 
          (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
              <Text>{ errorMessage }</Text>
            </Flex>
          ) : null
        }
        { hasError ?
          (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
              <Text>{ errorMessage }</Text>
            </Flex>
          ) : null
        }
        {!loader ? (
          <>
            <Accordion.Container marginBottom={20}>
              {accordionData.map((accordion: any, index: number) => (
                <Accordion.Item key={index} value={`Accordion-item-${index}`}>
                  <Accordion.Trigger onClick={() => handleAccordionToggle(index)}>
                    <CheckboxField
                      label={accordion.label}
                      name={`construction-${index}`}
                    />
                    <div className={accordionOpen[index] ? 'accordion-arrow' : ''}>
                      <SelectIcon />
                    </div>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <Flex gap={40} wrap="wrap" marginTop={10}>
                      {accordion.content.map((item: any, itemIndex: number) => (
                        <CheckboxField
                          key={itemIndex}
                          label={item.name}
                          name={`content-${index}-${itemIndex}`}
                          checked={payload?.assign_users?.includes(Number(item.id))}
                          onChange={(event: { target: { checked: boolean; } }) => handleChange(Number(item.id), event.target.checked)}
                        />
                      ))}
                    </Flex>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Container>
            <Flex
                  justifyContent="flex-end"
                  marginTop="1rem"
                  alignItems={`center`}
                  direction={{
                    base: 'column',
                    medium: 'row'
                }}
              >
              <CustomButton
                {...defaultButtonProps}
                text='登録'
                size='medium'
                width='medium'
                borderRadius='large'
                click={openModal}
              />
              <CustomButton
                {...defaultButtonProps}
                text='キャンセル'
                type='bordered-transparent'
                click={showCancelModal}
              />
              {/* cancel modal */}
              <CommonModal
                isOpen={isCancelModal}
                textHeading="入力内容を破棄して一覧へ戻りますか？"
              >
                <Flex justifyContent="center" margin={`0 auto`}>
                  <CustomButton
                    { ...defaultButtonProps }
                    text='編集へ戻る'
                    type='bordered-transparent'
                    click={showCancelModal}
                  />
                  <CustomButton
                    { ...defaultButtonProps }
                    text='破棄'
                    size='xs'
                    width='small'
                    click={() => navigate(`/site/${siteId}`)}
                  />
                </Flex>
              </CommonModal>
              <CommonModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                icon={isMobile ? 'icon' : ''}
                textHeading="現場「〇〇区〇〇プロジェクト」の配属ユーザー情報を更新しました"
                textAlign={isMobile ? "center" : "left"}
              >
                <View margin={isMobile ? '0 auto' : '0'}>
                  <CustomButton
                    { ...defaultButtonProps }
                    text='配属ユーザー情報を確認'
                    size='medium'
                    width={isMobile ? 'medium' : ''}
                    borderRadius={isMobile ? 'large' : 'small'}
                    iconName={ disableButton ? 'loader' : 'arrow-right' }
                    iconPosition='right'
                    disabled={ disableButton }
                    click={() => handleSubmit()}
                  />
                </View>
              </CommonModal>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
      </View>
    </PageContent>
  );
}
