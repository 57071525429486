export default function formatDate(format: string | null, value: string) {
  if(value === null || value === undefined) return ''
  if(format === 'dot') {
    const sliceDate = value?.slice(0,10)
    const finalDate = sliceDate.replaceAll('-', '.')
    return finalDate
  } else if(format === 'time') {
    var date = new Date(value.replaceAll('-','/'))
    if(Number.isNaN(date.getTime())){//iPhone・ios対策
      date = new Date(value)
    }
    // const newDate = new Date(date).toISOString().slice(0, -1)
    const getTime = new Date(date).toLocaleTimeString().slice(0,8)
    const getPeriod = new Date(date).toLocaleTimeString().split(' ')

    let [hours, minutes, seconds] = getTime.split(":");
    if (getPeriod[1] === "PM" && hours !== "12") {
      hours = String(Number(hours) + 12);
    }
    if (getPeriod[1] === "AM" && hours === "12") {
      hours = "00";
    }
    
    const time = `${hours.length === 1 ? '0' + hours : hours}:${minutes}:${seconds}`
    return time.replaceAll(' ', '');
  } else {
    const sliceDate = value?.slice(0,10)
    return sliceDate
  }
}