import React, { useEffect, useState,useContext } from "react";
import PageContent from "../../components/pageContent";
// amplify ui components
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  View,
  Loader,
  Text,
  Flex,
  IconsProvider,
  Pagination,
} from "@aws-amplify/ui-react";

// icons
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";

import { ILogs } from "../../types/ILogs";
import { LogsApi } from "../../api/logs";
import { SiteApi } from "../../api/site";
import formatDate from "../../utils/formatDate";
import checkFeature from "../../utils/checkFeature";
import { AuthorityContext } from "../../App";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "", label: "更新履歴" },
];

type SiteSelect = {
  value: string;
  label: string;
};


export default function LogsPage() {
  const api = LogsApi();
  const siteApi = SiteApi();
  const { site, company}: any = useContext(AuthorityContext);
  const [dataList, setDataList] = useState<ILogs[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [siteId, setSiteId] = useState("");
  const itemsLimit = 20;
  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)
  const [siteSelections, setSiteSelections] = useState<
  SiteSelect[] | undefined
>([]);
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ??  "";

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    getList(itemsLimit, currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    getList(itemsLimit, currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const isJSON = (value: string) => {
    try {
      return JSON.parse(value) && !!value;
    } catch (e) {
      return false;
    }
  };

  const checkNote = (value: string, toSearch: string) => {
    const translated = checkFeature(toSearch) as string;
    return value.replace(toSearch, translated);
  };

  const getList = async (
    limit: number = itemsLimit,
    page: number = currentPageIndex
  ) => {
    setLoader(true);
    try {
      const { data } = await api.getList(limit, page);
      if (data?.success) {
        const mappedList: ILogs[] = data?.data?.map((item: any) => {
          return {
            date: formatDate(null, item.created_at),
            type: item.type,
            feature: isJSON(item.description)
              ? checkFeature(JSON.parse(item.description).table)
              : item.description,
            note: isJSON(item.description)
              ? checkNote(
                  JSON.parse(item.description).note,
                  JSON.parse(item.description).table
                )
              : item.description,
            userId: item.user_id,
          };
        });

        setDataList(mappedList);
        setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
        setTotalCount(data?.data?.totalCount || data?.meta?.totalCount);
        setLoader(false);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteId(value);
  };

  useEffect(() => {
    const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
      return {
        value: item.site_id,
        label: item.site_name,
      };
    });

    setSiteSelections(mappedSiteList);
    mappedSiteList?.length > 0 && setSiteId(mappedSiteList[0].value);
  }, [site]);

  useEffect(() => {
    setSiteId(initialSelectedSiteId);
  }, [siteSelections]);

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);
          } else {
            setSiteSelections(mappedSiteList);
          }
          setSiteId(mappedSiteList[0].value)
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId) {
        setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);
      } else {
        setSiteSelections(mappedSiteList);
      }
      setSiteId(mappedSiteList[0].value)
    }
 
    // eslint-disable-next-line
  }, [site, company]);


  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [siteId]);

  useEffect(() => {}, [dataList]);

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={"更新履歴"}
      //showSelectField={true}
      //selectFieldOptions={siteSelections}
      //changeEvent={setSiteForFilter}
    >
      <View className="custom-table">
        {!loader ? (
          <>
            <Table highlightOnHover={true}>
              <TableBody>
                {dataList?.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{'wordBreak': 'break-all'}}>
                        {item.date} {item.userId} {item.note}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              lineHeight="20px"
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className={`custom-pagination ${
                    currentPageIndex === totalPages ? "disabled" : ""
                  }`}
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
      </View>
    </PageContent>
  );
}
