/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import PageContent from "../../components/pageContent";
import { IEqNewDetail } from "types/IEq";
import "../../styles/eq-result-return.scss";
import { EqApi } from "../../api/eq";
import { useNavigate, useParams } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import ReturnResultForm from "./components/result-return-form";
import convertStatus from "../../utils/geStatus";
import { getResultReturn } from "../../api/eq";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "/eq/list", label: "機材一覧" },
  { href: "", label: "機材詳細" },
  { href: "", label: "返却登録" },
];

export default function ResulReturnPage() {
  const api = EqApi();
  const { equipmentId } = useParams();
  const navigate = useNavigate();
  const defaultDate = new Date();

  const [fetchError, setFetchError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);

  const [detail, setDetail] = useState<IEqNewDetail>({
    schedule: "",
    schedule_time: "",
    user_id: "",
    equipment_common_name: "",
    equipment_name: "",
    category_name: "",
    hasSchedule: false,
    return_schedule: "",
    status: "",
  });

  const fetchData = async () => {
    setLoader(true);
    const responseData = await getResultReturn(equipmentId ?? "");

    if (responseData?.success) {
      const {
        result_return_schedule,
        result_return_user_id,
        equipment_common_name,
        equipment_name,
        equipment_category1_name,
        return_schedule,
        status,
      } = responseData;

      setLoader(false);
      setFetchError(false);

      const convert = defaultDate.toLocaleDateString("ja-JP");
      const format = convert?.split("/");
      const formattedTime = defaultDate.toLocaleTimeString("en-US");

      const checkMonth = format[1].length === 1 ? "0" + format[1] : format[1];
      const checkDate = format[2].length === 1 ? "0" + format[2] : format[2];

      setDetail({
        schedule:
          formatDate(null, result_return_schedule) ||
          format[0] + "-" + checkMonth + "-" + checkDate,
        schedule_time:
          formatDate("time", result_return_schedule) ||
          formatDate(
            "time",
            format[0] + "-" + checkMonth + "-" + checkDate + " " + formattedTime
          ),
        return_schedule:  formatDate(null, return_schedule) ||
        format[0] + "-" + checkMonth + "-" + checkDate,
        user_id: result_return_user_id,
        equipment_common_name,
        equipment_name,
        category_name: equipment_category1_name,

        hasSchedule:
          result_return_schedule === null || result_return_schedule === ""
            ? false
            : true,
        status: convertStatus(status),
      });
    }
  };
  console.log({ detail });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (item: any, value: any) => {
    setDetail({ ...detail, [item]: value });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  
  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");
    const createPayload = {
      // result_return_user_id: payload.user_id,
      result_return_schedule: detail.schedule,
      result_return_schedule_time: detail.schedule_time,
    };

    try {
      // @ts-ignore
      const { data } = await api.updateEq(equipmentId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        // navigate(`/eq/${ equipmentId }`)
        openModal();
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }

      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains('hidden')) {
        document.querySelector(".main-body")?.classList.remove('hidden');
      }
      // document.querySelector(".main-body").style.overflow = "auto";
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate("/eq/schedule/:equipmentId");
  };

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={detail.equipment_common_name || detail.equipment_name}
    >
      <ReturnResultForm
        handleChange={handleChange}
        detail={detail}
        error={fetchError}
        loader={loader}
      />
    </PageContent>
  );
}
