import React from "react";
import { Text, Heading } from "@aws-amplify/ui-react";
import "../../../styles/privacy-policy.scss";
import { thirdPartyInfoProvisionData } from "./constants";

export default function ThirdPartyInfoProvision() {
  return (
    <>
      <Heading level={5} className="heading-title" textDecoration={"underline"}>
        {thirdPartyInfoProvisionData.title}
      </Heading>

      {thirdPartyInfoProvisionData.otherContent?.map((data: string, index: number) => (
        <Text
          className={
            index === thirdPartyInfoProvisionData.otherContent.length - 1
              ? "last-content"
              : "other-content"
          }
        >
          {data}
        </Text>
      ))}
    </>
  );
}
