import React from "react";
import PageContent from "../../components/pageContent";
// amplify ui components
import { Text, Heading } from "@aws-amplify/ui-react";
import { termOfUseData } from "./constants";
import Sections from "./sections";
import _ from "lodash";
import "../../styles/term-of-use.scss";

export default function LogsPage() {
  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "", label: "利用規約" },
  ];
  const pageTitle = localStorage.getItem("currentPage") ?? "";

  const { title, otherContent, sections } = termOfUseData;
  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <Heading level={1} className="title">
        {title}
      </Heading>
      {!_.isEmpty(otherContent) &&
        otherContent.map((data, index) => (
          <Text
            className={
              index === otherContent.length - 1
                ? "last-content"
                : "other-content"
            }
          >
            {data}
          </Text>
        ))}

      {!_.isEmpty(sections) && <Sections sections={sections} />}
    </PageContent>
  );
}
