import React from "react";

type Props = {
  active: boolean;
};

export default function EquipementIcon2(props: Props) {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3321 5.29289C14.7226 5.68342 14.7226 6.31658 14.3321 6.70711L10.3321 10.7071C9.94158 11.0976 9.30842 11.0976 8.91789 10.7071L6.91789 8.70711C6.52737 8.31658 6.52737 7.68342 6.91789 7.29289C7.30842 6.90237 7.94158 6.90237 8.33211 7.29289L9.625 8.58579L12.9179 5.29289C13.3084 4.90237 13.9416 4.90237 14.3321 5.29289Z" fill={ props.active ?  "#4365DE" : '#9BA1A8'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.625 3C0.625 1.34315 1.96815 0 3.625 0H17.625C19.2819 0 20.625 1.34315 20.625 3V19C20.625 20.6569 19.2819 22 17.625 22H3.625C1.96815 22 0.625 20.6569 0.625 19V3ZM2.625 16V19C2.625 19.5523 3.07272 20 3.625 20H17.625C18.1773 20 18.625 19.5523 18.625 19V16H2.625ZM18.625 14H2.625V3C2.625 2.44772 3.07272 2 3.625 2H17.625C18.1773 2 18.625 2.44772 18.625 3V14Z" fill={ props.active ?  "#4365DE" : '#9BA1A8'}/>
    </svg>
  );
}
