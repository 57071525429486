import React, { useMemo, useState, memo, useCallback } from "react";
import moment from "moment";
import { getMonthDays } from "./dateUtils";
import { Flex } from "@aws-amplify/ui-react";
import { CalendarMonth } from "./calendar-month";
const now = new Date();

const Calendar: React.FC<{
  type: string;
  eventList: any[];
  handleEventClick: (
    hasEvent: boolean,
    defaultDate: string,
    company_name: any,
    company_id: any
  ) => any;
}> = ({ type, eventList, handleEventClick }) => {
  const [currentDate, setCurrentDate] = useState({
    day: moment(now).day(),
    month: moment(now).month(),
    year: moment(now).year(),
  });

  const monthDays: MonthData = useMemo(() => {
    // Generate calendar data memoized based on year and month
    return getMonthDays(currentDate.month, currentDate.year);
  }, [currentDate]);

  const handleSetCurrentDate = useCallback((newDate: any) => {
    setCurrentDate((prevDate) => ({
      ...prevDate,
      ...newDate,
    }));
  }, []);

  return (
    <Flex className="calendar">
      {type === "calendar-month" && (
        <CalendarMonth
          toolbar={true}
          monthDays={monthDays}
          setCurrentDate={handleSetCurrentDate}
          eventList={eventList}
          handleEventClick={handleEventClick}
        />
      )}
    </Flex>
  );
};
export default memo(Calendar);
