import React, { useEffect, useState, useRef } from "react";

// Amplify UI
import { Text, View, Flex } from "@aws-amplify/ui-react";
// Icons
import ArrowIcon from "./icons/arrow-icon";
import EquipementIcon2 from "./icons/v2/equipment-icon-2";
import HomeIcon2 from "./icons/v2/home-icon-2";
import NotificationIcon2 from "./icons/v2/notification-icon-2";
import MenuIcon from "./icons/v2/menu-icon";
import ScanIcon from "./icons/v2/scan-icon";

// custom css
import "../styles/footer.scss";

import { isSafari } from "../utils/getDeviceType";
import { useNavigate } from "react-router-dom";

type Props = {
  handleShowMenu: (show: boolean) => void;
  handleSetUsername: (username: boolean) => void;
  isAuthenticated: boolean;
  showMenu: boolean;
};

const Footer: React.FC<Props> = ({ handleShowMenu, handleSetUsername, isAuthenticated, showMenu }) => {
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const userName = localStorage.getItem("username") || 'null';
  const [openedByMenu, setOpenedByMenu] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const scrollToTop = () => {
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleMenuClick = () => {
    handleShowMenu(!showMenu);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const sidebar = sidebarRef.current;
      if (sidebar && !sidebar.contains(event.target as Node) && handleShowMenu && openedByMenu) {
        handleShowMenu(false);
        setOpenedByMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleShowMenu, openedByMenu]);

  useEffect(() => {
    const hasUser = !!localStorage.getItem("username")
    handleSetUsername(hasUser)
  }, [userName, handleSetUsername])

  return (
    <View as="footer" textAlign={'center'} className={`main-footer ${userName === 'null' ? "not-authenticated" : ""} ${isSafari() === true ? 'safari-only': ''}`}>
      <Text
        color={"#00000099"}
        fontSize='12px'
        display={'block'}
      >
        © 2024 Diamond Construction Equipment Corp.
      </Text>
      <View
        className="arrow-up"
        onClick={scrollToTop}
      >
        <ArrowIcon />
      </View>
      <View
        as="nav"
        className="footer-menu"
        display={{
          base: 'block',
          medium: 'none',
        }}
      >
        <Flex as="ul" flex={1} gap={0}>
          <View as="li" flex={1}>
            <Flex 
              as="a" 
              href="/" 
              direction="column" 
              gap="0" 
              justifyContent="center"
              className={ `footer-link-item ${ pageTitle === 'ホーム' ? 'footer-link-active' : '' }` }
            >
              <View as="span" className="footer-link-icon">
                <HomeIcon2 active={ pageTitle === 'ホーム' ? true : false } />
              </View>
              <View as="span" className="footer-link-text">
                ホーム
              </View>
            </Flex>
          </View>
          <View as="li" flex={1}>
            <View onClick={()=>{navigate("/eq/list/")}}>
              <Flex
                //"a"の方法だとrouter上でブラウザバック・フォワード扱いになり検索条件が誤って復元されるのでViewからnavigateを使って遷移
                //as="a"
                //href="/eq/list"
                direction="column"
                gap="0"
                justifyContent="center"
                className={ `footer-link-item ${ pageTitle === '機材一覧' ? 'footer-link-active' : '' }` }
              >
                <View as="span" className="footer-link-icon">
                  <EquipementIcon2 active={ pageTitle === '機材一覧' ? true : false }  />
                </View>
                <View as="span" className="footer-link-text">機材管理</View>
              </Flex>
            </View>
          </View>
          <View as="li" flex={1} className="footer-scan-icon">
            <Flex 
              as="a"
              href={localStorage.getItem("skipQrConfirmation") === "true" ? "/qrcode/scanner" : "/qrcode"}
              direction="column" 
              gap="0" 
              justifyContent="center"
              className="footer-link-item"
            >
              <View as="span" className="footer-link-icon">
                <ScanIcon />
              </View>
            </Flex>
          </View>
          <View as="li" flex={1}>
            <Flex 
              as="a" 
              href="/notice" 
              direction="column" 
              gap="0" 
              justifyContent="center"
              className={ `footer-link-item ${ pageTitle === '通知一覧画面' ? 'footer-link-active' : '' }` }
            >
              <View as="span" className="footer-link-icon">
                <NotificationIcon2 hasNotification={false} active={ pageTitle === '通知一覧画面' ? true : false }  />
              </View>
              <View as="span" className="footer-link-text">お知らせ</View>
            </Flex>
          </View>
          <View as="li" flex={1}>
            <Flex 
              direction="column" 
              gap="0" 
              justifyContent="center"
              className="footer-link-item"
            >
              <View onClick={handleMenuClick} className="footer-link-icon footer-menu-icon">
                <MenuIcon />
              </View>
              <View as="span" className="footer-link-text">メニュー</View>
            </Flex>
          </View>
        </Flex>
      </View>
    </View>
  );
};

export default Footer;
