import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { CreatePost, PatchPost, UpdateSiteUsers } from "types/test"

export const SiteApi = () => {
  const getList = async (hasLimit: boolean = false, page: number = 1) => {
    const response = hasLimit ? await FetchHeader(`/site/list?limit=${'20'}&page=${page}`, "GET") : await FetchHeader(`/site/list`, "GET");
    return response;
  };

  const getDetail = async (id: string | number) => {
    const response = await FetchHeader(`/site/detail/${id}`, "GET");
    return response;
  };

  const editSite = async (id: string | number, payload: PatchPost) => {
    return await FetchHeader(`/site/edit/${id}`, "POST", payload);
  };

  const createSite = async (payload: CreatePost) => {
    return await FetchHeader(`/site/create`, "POST", payload);
  };

  const getPreCreateList = async () => {
    const response = await FetchHeader("/site/pre_create", "GET");
    return response;
  };

  const deleteSite = async (id: string | number) => {
    return await FetchHeader(`/site/delete/${id}`, "POST");
  };

  const getSiteUserList = async (id: string | number | undefined) => {
    const response = await FetchHeader(`/site/assign/user/list/${ id }`, "GET");
    return response;
  };

  const updateSiteUserList = async (id: string | number | undefined, payload: UpdateSiteUsers) => {
    const response = await FetchHeader(`/site/assign/user/edit/${ id }`, "POST", payload);
    return response;
  };

  return {
    getList,
    getDetail,
    createSite,
    editSite,
    getPreCreateList,
    deleteSite,
    getSiteUserList,
    updateSiteUserList
  };
};
