import React, { memo } from "react";
import { TableRow, TableHead } from "@aws-amplify/ui-react";
import "../../../../../styles/custom-calendar.scss";
import moment from "moment";
import { translateDayToJapanese } from "./dateUtils";

export default memo(function CalendarHeader() {
  const daysOfWeek = moment.weekdays(); // Array of days of the week

  return (
    <>
      <TableHead>
        <TableRow>
          {daysOfWeek.map((day) => (
            <th key={day}>{translateDayToJapanese(day)}</th>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
});
export const CalendarHeaderSP = memo(function CalendarHeaderSP() {
  const daysOfWeek = moment.weekdays(); // Array of days of the week

  return (
    <>
      <TableHead style={{ position: "relative", right: "20px !important" }}>
        <TableRow className="header-row">
          {daysOfWeek.map((day) => (
            <th key={day}>{translateDayToJapanese(day)}</th>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
});
