import {
  IEqCsv,
  IEqUpdate,
  IEqCreate,
  IEqDeliverySchedule,
  IEqStart,
  IEqDeliveryScheduleDelete,
  IEqRegistInventory,
  IEqQrParams,
  IReturnBulk
} from "types/IEq";
import FetchHeader from "../../utils/fetchHeader";
import { CompanyApi } from "../company";
import { SiteApi } from "../site";
import convertStatus from "../../utils/geStatus";

export const EqApi = () => {
  const getList = async (limit: number = 20, page: number = 1) => {
    const response = await FetchHeader(
      `/eq/list?limit=${limit}&page=${page}`,
      "GET"
    );
    return response;
  };

  const createCsv = async (payload: IEqCsv) => {
    return await FetchHeader("/eq/csvlists", "POST", payload);
  };

  const getDetail = async (id: string | number) => {
    const response = await FetchHeader(`/eq/detail/${id}`, "GET");
    return response;
  };

  const updateEq = async (id: string | number, payload: IEqUpdate) => {
    return await FetchHeader(`/eq/edit/${id}`, "POST", payload);
  };

  const createEq = async (payload: IEqCreate) => {
    return await FetchHeader("/eq/create", "POST", payload);
  };

  const deleteEq = async (id: string | number) => {
    return await FetchHeader(`/eq/delete/${id}`, "POST");
  };

  const getCategoryList = async (site_id: string = '') => {
    let url = `/eq/categories/list`
    url += site_id != '' ? `?site_id=${site_id}` : '';
    const response = await FetchHeader(url, "GET");
    return response;
  };

  const getEqSchedule = async (siteId: string, startDate: string, endDate: string, payload: string | {}) => {
    const filteredData = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value !== "")
    );
    const queryString = new URLSearchParams(filteredData).toString();
    const response = await FetchHeader(
      `/eq/schedule?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}${queryString ? '&'+ queryString: '' }`,
      "GET"
    );
    return response;
  };

  const getEqScheduleDetail = async (id: string, date: string) => {
    const response = await FetchHeader(
      `/eq/schedule/detail/${id}?month=${date}`,
      "GET"
    );
    return response;
  };

  const registerSchedule = async (
    id: string | number,
    payload: IEqDeliverySchedule
  ) => {
    const response = await FetchHeader(
      `/eq/schedule/regist/${id}`,
      "POST",
      payload
    );
    return response;
  };

  const dateTimeRegister = async (id: string | number, payload: IEqStart) => {
    const response = await FetchHeader(
      `/eq/datetime/regist/${id}`,
      "POST",
      payload
    );
    return response;
  };

  const dateTimeEdit = async (id: string | number, payload: IEqStart) => {
    const response = await FetchHeader(
      `/eq/datetime/edit/${id}`,
      "POST",
      payload
    );
    return response;
  };

  const dateTimeDelete = async (equipment_id: string | number, payload:IEqDeliveryScheduleDelete ) => {
    return await FetchHeader(`/eq/datetime/delete/${equipment_id}`, "POST", payload);
  };

  const getEqStatus = async (id: string | number) => {
    const response = await FetchHeader(`/eq/status/${id}`, "GET");
    return response;
  };

  const registerEqInventory = async (
    id: string | number,
    payload: IEqRegistInventory
  ) => {
    const response = await FetchHeader(`/eq/inventory/${id}`, "POST", payload);
    return response;
  };

  const registerEqInventoryBulk = async (payload: IEqRegistInventory) => {
    const response = await FetchHeader(`/eq/schedule/inventory`, "POST", payload);
    return response;
  };

  const registerEqReturnBulk = async (payload: IReturnBulk) => {
    const response = await FetchHeader(`/eq/schedule/return`, "POST", payload);
    return response;
  };

  const getQrCode = async (payload: IEqQrParams) => {
    const response = await FetchHeader(
      `/eq/datetime?company_id=${payload.company_id}&type=${payload.type}&identification_tag=${payload.identification_tag}&site_id=${payload.site_id}`,
      "GET"
    );
    return response;
  };

  const getEqCommonUserInformation = async (id: string | number) => {
    const response = await FetchHeader(
      `/eq/common/user/information/${id}`,
      "GET"
    );
    return response;
  };

  const getEqInventoryHistory = async (id: string | number) => {
    const response = await FetchHeader(
      `/eq/history/inventory/${id}`,
      "GET"
    );
    return response;
  };

  const searchEqAdvance = async (
    type: string,
    payload: string | {},
    limit: number = 20,
    page: number = 1
  ) => {
    const filteredData = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value !== "")
    );
    const queryString = new URLSearchParams(filteredData).toString();
    const response =
      type === "freeword"
        ? await FetchHeader(
            `/eq/advance/search?type=${type}&${queryString}&limit=${limit}&page=${page}`,
            "GET"
          )
        : await FetchHeader(
            `/eq/advance/search?${queryString}&limit=${limit}&page=${page}`,
            "GET"
          );
    return response;
  };

  return {
    getList,
    getDetail,
    createCsv,
    updateEq,
    deleteEq,
    getCategoryList,
    createEq,
    getEqScheduleDetail,
    getEqSchedule,
    registerSchedule,
    dateTimeRegister,
    dateTimeEdit,
    dateTimeDelete,
    getEqStatus,
    registerEqInventory,
    registerEqInventoryBulk,
    registerEqReturnBulk,
    getQrCode,
    getEqCommonUserInformation,
    searchEqAdvance,
    getEqInventoryHistory
  };
};

export const getEquipUsageRegister = async (
  equipmentId: string = "",
  siteId: string
) => {
  const api = EqApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const [getEqStatusResponse, getCompanyListResponse, getListResponse] =
    //!
    await Promise.all([
      api.getEqStatus(equipmentId),

      siteApi.getDetail(siteId),
      companyApi.getList(),
    ]);

  return {
    getEqStatusData: getEqStatusResponse?.data,
    getCompanyListData: {
      data: getListResponse.data,
      status: getListResponse.status,
    },
    getLocations: getCompanyListResponse?.data,
  };
};

export const getResultReturn = async (equipmentId: string) => {
  const api = EqApi();
  try {
    const [statusResponse, detailResponse] = await Promise.all([
      api.getDetail(equipmentId ?? ""),
      api.getEqStatus(equipmentId ?? ""),
    ]);

    const { data: statusData } = await statusResponse;
    const { data: detailData } = await detailResponse;

    if (statusData?.success && detailData?.success) {
      const {
        result_return_schedule,
        result_return_user_id,
        equipment_common_name,
        equipment_name,
        equipment_category1_name,
        return_schedule,
      } = statusData?.data;
      const { status } = detailData?.data;

      return {
        result_return_schedule,
        result_return_user_id,
        equipment_common_name,
        equipment_name,
        equipment_category1_name,
        return_schedule,
        status,
        success: true,
      };
    }
    return { success: false };
  } catch (error) {
    return { success: false, error };
  }
};

export const getEquipScheduleDetails = async (
  equipmentId: string,
  date: string
) => {
  try {
    const api = EqApi();

    const [getEqStatusResponse, getEqScheduleDetailResponse] =
      await Promise.all([
        api.getEqStatus(equipmentId),
        api.getEqScheduleDetail(equipmentId, date),
      ]);

    const { data: statusData } = await getEqStatusResponse;
    const { data: detailData } = await getEqScheduleDetailResponse;

    if (statusData?.success && detailData?.success) {
      const stat = convertStatus(statusData?.data?.status);
      const { equipment_status } = detailData?.data;
      const equipments_status = equipment_status;

      return {
        success: true,
        message:'',
        stat,
        equipments_status,
        detailData: detailData?.data,
      };
    }
    return { success: false, message: detailData?.data?.message };
  } catch (error) {
    return { success: false };
  }
};
