import React from "react";

import "../../../styles/custom-icon.scss";

type Props = {
  hasNotification: boolean;
  active: boolean
};

export default function NotificationIcon2(props: Props) {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.375 1C8.25327 1 6.21844 1.84285 4.71815 3.34315C3.21785 4.84344 2.375 6.87827 2.375 9V11.9939C2.34638 14.2833 1.7058 16.5235 0.519644 18.482C0.332625 18.7908 0.326563 19.1764 0.503782 19.4909C0.681002 19.8054 1.01399 20 1.375 20H19.375C19.736 20 20.069 19.8054 20.2462 19.4909C20.4234 19.1764 20.4174 18.7908 20.2304 18.482C19.0442 16.5235 18.4036 14.2833 18.375 11.9939V9C18.375 6.87827 17.5321 4.84344 16.0319 3.34315C14.5316 1.84285 12.4967 1 10.375 1ZM6.13236 4.75736C7.25758 3.63214 8.7837 3 10.375 3C11.9663 3 13.4924 3.63214 14.6176 4.75736C15.7429 5.88258 16.375 7.4087 16.375 9L16.3749 12L16.3751 12.012C16.4 14.086 16.8595 16.125 17.7165 18H3.03352C3.89051 16.125 4.35003 14.086 4.375 12.012V9C4.375 7.4087 5.00714 5.88258 6.13236 4.75736Z" fill={ props.active ?  "#4365DE" : '#9BA1A8'}/>
      <path d="M8.53945 22.4497C8.00234 22.0916 7.59559 21.5852 7.375 21H13.375C13.1544 21.5852 12.7477 22.0916 12.2105 22.4497C11.6734 22.8078 11.0323 23 10.375 23C9.71773 23 9.07657 22.8078 8.53945 22.4497Z" fill={ props.active ?  "#4365DE" : '#9BA1A8'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.875 9C20.3603 9 22.375 6.98528 22.375 4.5C22.375 2.01472 20.3603 0 17.875 0C15.3897 0 13.375 2.01472 13.375 4.5C13.375 6.98528 15.3897 9 17.875 9Z" fill={ props.hasNotification ? '#EF4E4E' : '' }/>
    </svg>
  );
}
