import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

// amplify ui
import {
  Flex,
  View,
  Divider,
  Heading,
  CheckboxField
} from "@aws-amplify/ui-react";


export default function QrCodePage() {
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/site/0000001", label: "現場管理" },
    { href: "", label: "現場一覧" },
  ];

  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [checkboxToggle, setCheckboxToggle] = useState(false)


  // const openModal = () => {
  //   // @ts-ignore
  //   document.querySelector(".main-body").style.overflow = 'hidden';
  //   document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
  //   setIsModalOpen(true)
  // };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const closeModal = () => {
    // @ts-ignore
    // document.querySelector(".main-body").style.overflow = 'auto';
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false)
  };
  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  useEffect(() => {}, [checkboxToggle])

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <Heading
        level={4}
        marginBottom={15}
      >
        ご確認ください
      </Heading>
      <Divider />
      <ul className="qr-list">
        <li>
          機材の稼働状態、身の回りの安全を必ず確認した上で読み取りをお願いいたします。
        </li>
        <li>新規に現場に搬入された機材のQRコードを、当該機材を登録できます。</li>
        <li>
          既に機材一覧に登録されている機材のQRコードを読み取ると、当該機材のステータスを変更できます。
        </li>
        <li>連携レンタル会社の機材に貼付されたQRコード以外を読み取った場合は、エラーが出力されます。連携レンタル会社一覧はこちらから確認できます。</li>
      </ul>
      <View marginTop={20} marginBottom={40}>
        <CheckboxField
          fontSize={14}
          label="今後は表示しない"
          name="agree"
          checked={ checkboxToggle }
          onChange={() => setCheckboxToggle(!checkboxToggle)}
        />
      </View>
      <Flex
          justifyContent="flex-end"
          marginTop="1rem"
          alignItems={`center`}
          direction={{
            base: 'column',
            medium: 'row'
        }}
      >
        <CustomButton
          {...defaultButtonProps}
          text='カメラを起動'
          size='medium'
          width='medium'
          borderRadius='small'
          iconPosition='left'
          iconName='camera'
          click={() => {
            if(checkboxToggle){
              localStorage.setItem("skipQrConfirmation","true")
            }
            navigate(`/qrcode/scanner`);
          }}
        />

        <View as="div" width={'160px'}>
          <CustomButton
            {...defaultButtonProps}
            text='キャンセル'
            size='medium'
            width='full'
            type='light-gray'
            borderRadius='small'
            iconPosition='left'
            iconName='close'
            click={() => showCancelModal()}
          />
        </View>
        {/* submit modal */}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          icon="icon"
          textHeading="機材情報を登録します。よろしいですか？"
        >
          <View margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='機材情報を登録する'
              size='medium'
              width='medium'
              borderRadius='large'
              iconPosition='right'
              // click={() => ()}
            />
          </View>
        </CommonModal>

        {/* cancel modal */}
        <CommonModal
          isOpen={isCancelModal}
          textHeading="破棄して機材一覧に戻りますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='編集へ戻る'
              type='bordered-transparent'
              click={showCancelModal}
            />
            <CustomButton
              { ...defaultButtonProps }
              text='破棄'
              size='medium'
              width='small'
              click={() => navigate('/eq/list')}
            />
          </Flex>
        </CommonModal>
      </Flex>
    </PageContent>
  );
}
