import React from "react";
import { Card, Flex, Heading, Text, View } from "@aws-amplify/ui-react";
import CustomButton from "../../../../components/custom-button";
import NavigateIcon from "../../../../components/icons/navigate-icon";
import formatDate from "../../../../utils/formatDate";

const defaultButtonProps = {
  text: "",
  type: "icon",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EquipScheduleDate({
  cardDetails,
}: {
  cardDetails?: any[];
}) {
  return (
    <Flex className="eq-schedule-cards">
      {cardDetails?.map((detail, index) => (
        <Card style={{ gap: 4 }} key={index} onClick={detail?.onClick}>
          <Flex direction={"row"} justifyContent={"center"}>
            <Heading level={6}>{detail?.header}</Heading>
            {detail?.icon}
          </Flex>
          <Text textAlign={"center"}>{detail?.value}</Text>
        </Card>
      ))}
    </Flex>
  );
}

export const EquipScheduleDateRefactor = ({
  detailData,
}: {
  detailData: any[];
}) => {
  return (
    <View className="eq-schedule-card-container">
      {/* <Flex className="eq-schedule-card-wrapper"> */}
        <Flex
          className="schedule-card "
          // style={{ transform: `translateX(-${currentSlide * 377}px)` }}
        >
          {detailData?.map((detail, index) => (
            <Card
              style={{
                gap: 4,
              }}
              minWidth={377}
              key={index}
              className={`schedule-card-item `}
            >
              <Flex
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text textAlign={"center"} color={"black"}>
                  {detail?.header}
                </Text>
                <CustomButton
                  {...defaultButtonProps}
                  type="icon"
                  iconName="duplicate"
                  className="custom-button"
                />
              </Flex>
              <Heading level={6} color={"#4365DE"} marginTop={5}>
                {detail?.value}
              </Heading>
            </Card>
          ))}
        </Flex>
      {/* </Flex> */}
    </View>
  );
};
