import React from "react";

export default function MenuIcon() {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.125 1C0.125 0.447715 0.572715 0 1.125 0H19.125C19.6773 0 20.125 0.447715 20.125 1C20.125 1.55228 19.6773 2 19.125 2H1.125C0.572715 2 0.125 1.55228 0.125 1Z" fill="#9BA1A8"/>
      <path d="M0.125 7C0.125 6.44772 0.572715 6 1.125 6H19.125C19.6773 6 20.125 6.44772 20.125 7C20.125 7.55228 19.6773 8 19.125 8H1.125C0.572715 8 0.125 7.55228 0.125 7Z" fill="#9BA1A8"/>
      <path d="M1.125 12C0.572715 12 0.125 12.4477 0.125 13C0.125 13.5523 0.572715 14 1.125 14H19.125C19.6773 14 20.125 13.5523 20.125 13C20.125 12.4477 19.6773 12 19.125 12H1.125Z" fill="#9BA1A8"/>
    </svg>
  );
}
