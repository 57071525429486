import React, { useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Text,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

// api
import { CompanyApi } from "../../api/company";
import { ICompanyCreate } from "../../types/ICompany";

// utils
import validateEmail from "../../utils/validateEmail";
import getDeviceType from "../../utils/getDeviceType";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function CompanyCreatePage() {
  const isMobile = getDeviceType() === 'mobile'
  const api = CompanyApi()
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  // const [invoiceError, setInvoiceError] = useState(false);
  const [contactError, seContactError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  // const [cinetError, setCinetError] = useState(false);
  const [termsError, seTermsError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [noteLength, setNoteLength] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  const [payload, setPayload] = useState<ICompanyCreate>({
    name: '',
    invoice: '',
    cinet: '',
    contact_person: '',
    category: '',
    note: '',
    terms: false,
    contact: ''
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/company/list', label: '法人管理' },
    { href: '', label: '新規法人登録' },
  ]

  const selectOptions = [
    { name: '元請け', value: 100 },
    { name: 'レンタル会社', value: 200 },
    { name: 'その他の会社', value: 300 },
  ]

  const openModal = () => {
    let errorMessageSet = [];
    setNameError(() => {
      return payload.name === '' ? true : false
    })
    // setInvoiceError(() => {
    //   return payload.invoice === '' ? true : false
    // })
    seContactError(() => {
      if(payload.contact_person === '') {
        return true
      } else if(!validateEmail(payload.contact_person)) {
        return true
      } else {
        return false
      }
    })
    seTermsError(!payload.terms)

    setCategoryError(payload.category === '')

    const cinet = payload.cinet.toString()
    const cinetRegex = /^\d*$/;
    // setCinetError(() => {
    //   return !cinetRegex.test(cinet) || cinet.length !== 6 || cinet === ''
    // })
    if(payload.name ===""){
      errorMessageSet.push("法人名を入力してください")
    }
    if(payload.contact_person === ""){
      errorMessageSet.push("法人担当者のメールアドレスを入力してください")
    }
    if(!validateEmail(payload.contact_person)){
      errorMessageSet.push("メールアドレスが不正です")
    }
    if(payload.category ===""){
      errorMessageSet.push("法人カテゴリーを　選択してください")
    }
    if(!payload.terms){
      errorMessageSet.push("利用規約に同意されていません")
    }
    setErrorMessage(errorMessageSet.join("\n"));

    if(payload.name !== '' && 
      // payload.invoice !== '' && 
      payload.contact_person !== '' && 
      payload.terms && 
      validateEmail(payload.contact_person) &&
      payload.category !== ''
      // (cinetRegex.test(cinet) && cinet.length === 6 && cinet !== '')
    ) {
      setIsModalOpen(true)
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false)
    }
  };

  const handleChange = (item:any, value: any) => {
    if(item === 'note') {
      setNoteLength(value.length)
    }

    setPayload({ ...payload, [item]: value })
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    const createPayload = {
      company_category_id: payload.category,
      company_name: payload.name,
      business_registration_number: payload.invoice,
      ci_net_number: payload.cinet,
      company_email: payload.contact_person,
      company_phone_number: '',
      remarks: payload.note,
      terms: payload.terms
    }

    try {
      // @ts-ignore
      const { data } = await api.createCompany(createPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        navigate(`/company/list`)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch(err) {
      console.log(err)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { errorMessage !== "" || hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                {errorMessage?.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
              </Flex>
            ) : null
          }
          <TextField
            label="法人名"
            placeholder="法人名を入力してください"
            value={payload.name}
            onChange={(event) => handleChange('name', event.target.value)}
            isRequired
            className={ nameError ? `required input-error` : 'required' }
          />
          <TextField
            label="インボイス番号"
            placeholder="0000000000000"
            value={payload.invoice}
            onChange={(event) => handleChange('invoice', event.target.value)}
          />
          <TextField
            label="CI-NET番号"
            placeholder="000000"
            value={payload.cinet}
            onChange={(event) => handleChange('cinet', event.target.value)}
          />
          <TextField
            label="メールアドレス"
            placeholder="法人担当者のメールアドレスを入力してください"
            value={payload.contact_person}
            onChange={(event) => handleChange('contact_person', event.target.value)}
            className={ contactError ? `required input-error` : 'required' }
          />
          <SelectField
            label="法人カテゴリー"
            placeholder="選択してください"
            value={payload.category}
            onChange={(event) => handleChange('category', event.target.value)}
            icon={<SelectIcon />}
            className={`required ${categoryError ? 'input-error' : ''} ${payload.category === '' ? 'no-value' : ''}`}
          >
            { 
              selectOptions.map((_item, index) => {
                return (
                  <option value={_item.value} key={index}>{ _item.name }</option>
                )
              }) 
            }
          </SelectField>
          <TextAreaField
            label="備考"
            placeholder="備考を入力してください"
            value={ payload.note }
            onChange={(event) => handleChange('note', event.target.value)}
            maxLength={100}
          />
          <Text className='textarea-length' as='span'>{ noteLength }/100</Text>
          <CheckboxField
            label="利用規約に同意しています"
            name="terms"
            checked={payload.terms}
            onChange={(event) => handleChange('terms', event.target.checked)}
            // className={ !termsError ? `required input-error` : 'required' }
            hasError={termsError}
          />
          <Flex
              justifyContent='flex-end'
              marginTop="1rem"
              alignItems={`center`}
              direction={{
                base: 'column',
                medium: 'row'
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text='登録'
              size='medium'
              width={'medium'}
              borderRadius={'large'}
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text='キャンセル'
              borderRadius={'large'}
              type={'transparent'}
              size='medium'
              click={showCancelModal}
            />
          </Flex>
        </View>
        {/* submit modal */}
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        icon="icon"
        textHeading="新規法人を登録します。よろしいですか？"
      >
        <View margin={`0 auto`}>
          <CustomButton
            { ...defaultButtonProps }
            text='新規法人を登録する'
            size='medium'
            width='medium'
            borderRadius='large'
            iconName={ disableButton ? 'loader' : '' }
            iconPosition='right'
            disabled={ disableButton }
            click={handleSubmit}
          />
        </View>
      </CommonModal>

      {/* cancel modal */}
      <CommonModal
        isOpen={isCancelModal}
        textHeading="入力内容を破棄して一覧へ戻りますか？"
      >
        <Flex justifyContent="center" margin={`0 auto`}>
          <CustomButton
            { ...defaultButtonProps }
            text='編集へ戻る'
            type='bordered-transparent'
            click={showCancelModal}
          />
          <CustomButton
            { ...defaultButtonProps }
            text='破棄'
            size='xs'
            width='small'
            click={() => navigate('/company/list')}
          />
        </Flex>
      </CommonModal>
      </View>
    </PageContent>
  );
}
