import React from "react";

export default function ScanIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1236_25145)">
      <path d="M12.5415 13.2136H4.26781C3.89815 13.2136 3.5957 12.9111 3.5957 12.5415V4.26781C3.5957 3.89815 3.89815 3.5957 4.26781 3.5957H12.5415C12.9111 3.5957 13.2136 3.89815 13.2136 4.26781V12.5415C13.2136 12.9111 12.9111 13.2136 12.5415 13.2136ZM4.93992 11.8693H11.8693V4.93992H4.93992V11.8693Z" fill="white"/>
      <path d="M12.5415 24.404H4.26781C3.89815 24.404 3.5957 24.1015 3.5957 23.7319V15.4582C3.5957 15.0886 3.89815 14.7861 4.26781 14.7861H12.5415C12.9111 14.7861 13.2136 15.0886 13.2136 15.4582V23.7319C13.2136 24.1015 12.9111 24.404 12.5415 24.404ZM4.93992 23.0598H11.8693V16.1303H4.93992V23.0598Z" fill="white"/>
      <path d="M23.7329 13.2136H15.4592C15.0896 13.2136 14.7871 12.9111 14.7871 12.5415V4.26781C14.7871 3.89815 15.0896 3.5957 15.4592 3.5957H23.7329C24.1025 3.5957 24.405 3.89815 24.405 4.26781V12.5415C24.405 12.9111 24.1025 13.2136 23.7329 13.2136ZM16.1313 11.8693H23.0608V4.93992H16.1313V11.8693Z" fill="white"/>
      <path d="M0.672108 6.24388C0.302448 6.24388 0 5.94143 0 5.57177V0.672108C0 0.302448 0.302448 0 0.672108 0H5.57177C5.94143 0 6.24388 0.302448 6.24388 0.672108C6.24388 1.04177 5.94143 1.34422 5.57177 1.34422H1.34422V5.57177C1.34422 5.94143 1.04177 6.24388 0.672108 6.24388Z" fill="white"/>
      <path d="M27.3276 6.24388C26.958 6.24388 26.6555 5.94143 26.6555 5.57177V1.34422H22.428C22.0583 1.34422 21.7559 1.04177 21.7559 0.672108C21.7559 0.302448 22.0583 0 22.428 0H27.3276C27.6973 0 27.9997 0.302448 27.9997 0.672108V5.57177C27.9997 5.94143 27.6973 6.24388 27.3276 6.24388Z" fill="white"/>
      <path d="M0.672108 6.24388C0.302448 6.24388 0 5.94143 0 5.57177V0.672108C0 0.302448 0.302448 0 0.672108 0H5.57177C5.94143 0 6.24388 0.302448 6.24388 0.672108C6.24388 1.04177 5.94143 1.34422 5.57177 1.34422H1.34422V5.57177C1.34422 5.94143 1.04177 6.24388 0.672108 6.24388Z" fill="white"/>
      <path d="M27.3276 6.24388C26.958 6.24388 26.6555 5.94143 26.6555 5.57177V1.34422H22.428C22.0583 1.34422 21.7559 1.04177 21.7559 0.672108C21.7559 0.302448 22.0583 0 22.428 0H27.3276C27.6973 0 27.9997 0.302448 27.9997 0.672108V5.57177C27.9997 5.94143 27.6973 6.24388 27.3276 6.24388Z" fill="white"/>
      <path d="M5.57177 27.9997H0.672108C0.302448 27.9997 0 27.6973 0 27.3276V22.428C0 22.0583 0.302448 21.7559 0.672108 21.7559C1.04177 21.7559 1.34422 22.0583 1.34422 22.428V26.6555H5.57177C5.94143 26.6555 6.24388 26.958 6.24388 27.3276C6.24388 27.6973 5.94143 27.9997 5.57177 27.9997Z" fill="white"/>
      <path d="M27.3276 27.9997H22.428C22.0583 27.9997 21.7559 27.6973 21.7559 27.3276C21.7559 26.958 22.0583 26.6555 22.428 26.6555H26.6555V22.428C26.6555 22.0583 26.958 21.7559 27.3276 21.7559C27.6973 21.7559 27.9997 22.0583 27.9997 22.428V27.3276C27.9997 27.6973 27.6973 27.9997 27.3276 27.9997Z" fill="white"/>
      <path d="M9.07273 7.77637H7.72852V9.12058H9.07273V7.77637Z" fill="white"/>
      <path d="M10.3977 10.4377H6.41211V6.45215H10.3977V10.4377ZM7.75632 9.09353H9.05349V7.79636H7.75632V9.09353Z" fill="white"/>
      <path d="M20.27 7.77637H18.9258V9.12058H20.27V7.77637Z" fill="white"/>
      <path d="M21.5872 10.4377H17.6016V6.45215H21.5872V10.4377ZM18.9458 9.09353H20.2429V7.79636H18.9458V9.09353Z" fill="white"/>
      <path d="M9.07273 18.9268H7.72852V20.271H9.07273V18.9268Z" fill="white"/>
      <path d="M10.3977 21.5881H6.41211V17.6025H10.3977V21.5881ZM7.75632 20.2439H9.05349V18.9468H7.75632V20.2439Z" fill="white"/>
      <path d="M20.2719 18.9258H18.9277V20.27H20.2719V18.9258Z" fill="white"/>
      <path d="M21.5891 21.5881H17.6035V17.6025H21.5891V21.5881ZM18.9477 20.2439H20.2449V18.9468H18.9477V20.2439Z" fill="white"/>
      <path d="M22.2541 22.9252H16.9377C16.5681 22.9252 16.2656 22.6228 16.2656 22.2531V16.9368C16.2656 16.5671 16.5681 16.2646 16.9377 16.2646H22.2541C22.6238 16.2646 22.9262 16.5671 22.9262 16.9368V22.2531C22.9262 22.6228 22.6238 22.9252 22.2541 22.9252ZM17.6098 21.581H21.582V17.6089H17.6098V21.581Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1236_25145">
      <rect width="28" height="28" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}