import React from "react";

export default function DocumentIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 8.83268V12.9993C18.3332 17.166 16.6665 18.8327 12.4998 18.8327H7.49984C3.33317 18.8327 1.6665 17.166 1.6665 12.9993V7.99935C1.6665 3.83268 3.33317 2.16602 7.49984 2.16602H11.6665"
        stroke="#1A1C1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 8.83268H14.9998C12.4998 8.83268 11.6665 7.99935 11.6665 5.49935V2.16602L18.3332 8.83268Z"
        stroke="#1A1C1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 11.334H10.8335"
        stroke="#1A1C1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 14.666H9.16683"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
