import React from "react";

export default function MenuDotIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99805 19C9.99805 20.1 10.898 21 11.998 21C13.098 21 13.998 20.1 13.998 19C13.998 17.9 13.098 17 11.998 17C10.898 17 9.99805 17.9 9.99805 19Z" stroke="#6C7278" strokeWidth="1.5"/>
      <path d="M9.99805 4.99805C9.99805 6.09805 10.898 6.99805 11.998 6.99805C13.098 6.99805 13.998 6.09805 13.998 4.99805C13.998 3.89805 13.098 2.99805 11.998 2.99805C10.898 2.99805 9.99805 3.89805 9.99805 4.99805Z" stroke="#6C7278" strokeWidth="1.5"/>
      <path d="M9.99805 12.002C9.99805 13.102 10.898 14.002 11.998 14.002C13.098 14.002 13.998 13.102 13.998 12.002C13.998 10.902 13.098 10.002 11.998 10.002C10.898 10.002 9.99805 10.902 9.99805 12.002Z" stroke="#6C7278" strokeWidth="1.5"/>
    </svg>
  )
}