import React from "react";
import { Divider, Flex, Heading, Text, View } from "@aws-amplify/ui-react";
import CustomButton from "../../../../components/custom-button";
export default function EquipTabMenu() {
  return (
    <View
      //   style={{ justifySelf: "flex-end" }}
      role="tablist"
      className="amplify-tabs__list"
    >
      <button
        role="tab"
        id="Tab1-tab"
        aria-selected="false"
        aria-controls="Tab1-panel"
        className="amplify-tabs__item amplify-tabs__item--active"
      >
        機材詳細
      </button>
      <button
        role="tab"
        id="Tab2-tab"
        aria-selected="true"
        aria-controls="Tab2-panel"
        className="amplify-tabs__item"

        // onClick={() => navigateToEqSchedule()}
      >
        <Flex direction={"column"}>
          <Text style={{ color: "#ACB5BB" }}>スケジュール</Text>
          <Text style={{ color: "#ACB5BB" }}>（予定・実績）</Text>
        </Flex>
      </button>
    </View>
  );
}

const defaultButtonProps = {
  text: "",
  type: "icon",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};
export const EquipTabMenuSP = () => {
  return (
    <Flex direction={"column"} margin={"20px -20px"}>
      <Divider className="form-divider" />
      <button className="modified-tab">
        <Flex
          direction={"row"}
          className="add-eq-details"
          justifyContent={"space-between"}
        >
          <Heading level={3} textAlign={"left"} paddingLeft={20}>
            機材詳細
          </Heading>
          <CustomButton
            {...defaultButtonProps}
            type="icon"
            iconName="add-default"
            className="custom-button"
            // click={() => navigate("/help")}
          />
        </Flex>
      </button>
      <Divider className="form-divider" />
      <button className="modified-tab">
        <Flex direction={"row"}>
          <Heading level={4} textAlign={"left"} paddingLeft={20}>
            スケジュール
          </Heading>
          <Heading
            level={6}
            textAlign={"left"}
            paddingLeft={20}
            alignSelf={"center"}
          >
            （予定・実績）
          </Heading>
        </Flex>
      </button>
      <Divider className="form-divider" />
    </Flex>
  );
};
