import React from "react";

type Props = {
  active: boolean;
};

export default function HomeIcon2(props: Props) {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4089 0.182931C11.0949 -0.060977 10.6551 -0.060977 10.3411 0.182931L1.2107 7.27504C0.998889 7.43956 0.875 7.69254 0.875 7.96053V19.1053C0.875 19.873 1.18038 20.6093 1.72397 21.1522C2.26755 21.695 3.00481 22 3.77355 22H6.38225C6.94254 22 7.39674 21.5464 7.39674 20.9868V12.7368C7.39674 12.2572 7.78606 11.8684 8.2663 11.8684H13.4837C13.9639 11.8684 14.3533 12.2572 14.3533 12.7368V20.9868C14.3533 21.5464 14.8075 22 15.3678 22H17.9764C18.7452 22 19.4825 21.695 20.026 21.1522C20.5696 20.6093 20.875 19.873 20.875 19.1053V7.96053C20.875 7.69254 20.7511 7.43956 20.5393 7.27504L11.4089 0.182931Z" fill={ props.active ?  "#4365DE" : '#9BA1A8'}/>
    </svg>
  );
}
