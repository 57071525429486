import React from "react";
import {
  Breadcrumbs,
  ThemeProvider,
  createTheme,
  View,
  Heading,
  SelectField,
  Flex,
  Menu,
  MenuItem,
  Text
} from "@aws-amplify/ui-react";

// Amplify Auth
import { signOut } from "aws-amplify/auth";

// icons
import SelectIcon from "../components/icons/select-icon";
import AvatarIcon from "./icons/v2/avatar-icon";

// interfaces
import { IPageContent } from "../types/IPageContent";
import CustomButton from "./custom-button";
import { useLocation, useNavigate } from "react-router-dom";

interface IPageContentWithSelectFieldProps extends IPageContent {
  showSelectField?: boolean;
  selectFieldLabel?: string;
  initialValue?: string;
  selectFieldOptions?: { value: string; label: string }[];
  changeEvent?: (event: any) => void
}

const defaultButtonProps = {
  text: '',
  type: 'icon',
  iconPosition: 'left',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function PageContent({
  breadcrumbs,
  title,
  children,
  showSelectField = false,
  selectFieldLabel = "〇〇プロジェクト",
  initialValue = "",
  selectFieldOptions = [
    { value: "01", label: "〇〇プロジェクト" },
    { value: "02", label: "〇〇プロジェクト" },
  ],
  changeEvent= (event: any) => { return event.target.value }
}: IPageContentWithSelectFieldProps) {
  const userName = localStorage.getItem("username") || 'null';
  const userFname = localStorage.getItem("userFname") || '';
  const navigate = useNavigate()
  const { pathname } = useLocation();
  
  const theme = createTheme({
    name: 'breadcrumbs-theme',
    tokens: {
      components: {
        heading: {
          1: {
            fontSize: { value: '32px' },
            fontWeight: { value: '600' }
          }
        },
        divider: {
          borderColor: { value: 'rgba(0, 0, 0, 0.12)' },
          borderWidth: { value: '1px' },
        },
        breadcrumbs: {
          gap: { value: '0' },
          separator: {
            paddingInline: { value: '10px' }
          },
          link: {
            fontSize: { value: '12px' },
            paddingInline: { value: '0' },
            color: { value: '#ffffff' },
            fontWeight: { value: '300'}
          },
        },
      },
    },
  });

  async function handleSignOut() {
    try {
      localStorage.setItem("lastUserVisit", pathname);
      await signOut();
      localStorage.setItem("username", String(null));
      localStorage.removeItem("currentSiteId")
      sessionStorage.clear()
      navigate('/login')
      window.location.reload()
    } catch (error) {
      return error;
    }
  }
  
  return (
    <ThemeProvider theme={theme}>
      <View 
        className="main-banner"
        padding={{
          base: '20px',
          medium: '20px 40px'
        }}
      >
        <Flex 
          justifyContent="space-between" 
          alignItems="center"
          display={{
            base: 'none',
            medium: 'flex',
          }}
        >
          <View className="hide-from-print">
            <Breadcrumbs items={breadcrumbs}/>
          </View>
          <View className="banner-select hide-from-print">
            {showSelectField && (
              <SelectField
                label={selectFieldLabel}
                inputStyles={{
                  backgroundColor: '#f5f5f5 ',
                  border: `1px solid #bcbcbc`,
                }}
                labelHidden
                width={{
                  small: '220px',
                  xl: '179px'
                }}
                icon={<SelectIcon color={"#fff"} />}
                onChange={(event: any) => changeEvent(event.target.value)}
                value={initialValue}
              >
                {selectFieldOptions.map((option)  => (
                  <option key={option.value}  value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
            )}
          </View>
        </Flex>
        {
            userName !== 'null' ?
            <Flex
              display={{
                base: 'flex',
                medium: 'none',
              }}
            >
              <Menu
                menuAlign="end"
                size="small"
                className="account-menu"
                trigger={
                  <Flex 
                    className="account-icon"
                    marginLeft={{
                      base: '0',
                    }}
                  >
                    <AvatarIcon />
                    <Text className="header-username">
                      { userFname }
                    </Text>
                    <SelectIcon />
                  </Flex>
                }
              >
                <MenuItem padding={'0 15px'} style={{'borderBottom': '2px solid rgba(0,0,0,.3)'}} onClick={() => navigate(`/account/${userName}`)}>アカウント情報</MenuItem>
                <MenuItem onClick={() => handleSignOut()}>ログアウト</MenuItem>
              </Menu>
              <View className="help-button">
                <CustomButton 
                  {...defaultButtonProps}
                  type="icon"
                  iconName="info"
                  click={() => navigate('/help')}
                />
              </View>
            </Flex>
            : null
          }
        <View as="div" position={'relative'}>
          <Heading
            className="page-header"
            fontWeight={700}
          >
            {title}
          </Heading>
          <View as="div" className="sp-header-select">
            {showSelectField && (
              <SelectField
                label={selectFieldLabel}
                inputStyles={{
                  backgroundColor: '#f5f5f5 ',
                  border: `1px solid #bcbcbc`,
                }}
                labelHidden
                width={{
                  small: '220px',
                  xl: '179px'
                }}
                icon={<SelectIcon color={"#fff"} />}
                onChange={(event: any) => changeEvent(event.target.value)}
                value={initialValue}
              >
                {selectFieldOptions.map((option)  => (
                  <option key={option.value}  value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
            )}
          </View>
        </View>
      </View>
      <View
        as='main'
        className='page-content-body'
      >
        <View className="main-container">
          {children}
        </View>
      </View>
    </ThemeProvider>
  );
}
