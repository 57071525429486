import React, { useEffect, useContext, useState } from "react";
// header custom css
import "../styles/header.scss";

// custom components
import CustomButton from "../components/custom-button";

// Amplify UI
import {
  Flex,
  Heading,
  Text,
  View,
  Menu,
  MenuItem,
  Button,
} from "@aws-amplify/ui-react";

// Amplify Auth
import { signOut } from "aws-amplify/auth";

// Icons
import NotificationIcon from "./icons/v2/notification-icon";
import AvatarIcon from "./icons/v2/avatar-icon";
import { getCurrentUser } from "aws-amplify/auth";

import { routes } from "../routes";
import { 
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthorityContext } from '../App'
import ReactToPrint from 'react-to-print';
import PrintIcon from "../components/icons/v2/print-icon";
import { UserApi } from "../api/user";

type Props = {
  handleSetUsername: (username: boolean) => void;
  printRef: React.RefObject<HTMLDivElement>;
};

const defaultButtonProps = {
  text: '',
  type: 'icon',
  iconPosition: 'left',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

const Header: React.FC<Props> = ({ handleSetUsername, printRef }) => {
  const navigate = useNavigate()
  const api = UserApi();
  const {authority}: any = useContext(AuthorityContext);
  const userName = localStorage.getItem("username") || 'null';
  const hasNotification = true;
  const paths = routes.props.children;
  const location = useLocation();
  const pageName = process.env.REACT_APP_NAME
  const [name, setName] = useState('')

  const usePathPattern = () => {
    const obj = paths.find((route: any) =>
      matchPath(route.props.path, location.pathname)
    );
    localStorage.setItem("currentPage", obj.props.name);
  };

  const pages = paths.map((item: any, index: number) => {
    return {
      path: item.props.path ?? "",
      code: item.props.code ?? "",
    };
  });

  usePathPattern();

  async function handleSignOut() {
    try {
      localStorage.setItem("lastUserVisit", location.pathname);
      await signOut();
      localStorage.setItem("username", String(null));
      localStorage.removeItem('check_info')
      localStorage.removeItem('userFname')
      handleSetUsername(false);
      localStorage.removeItem("currentSiteId")
      sessionStorage.clear()
      navigate('/login')
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    async function getUserName() {
      try {
        const { signInDetails } = await getCurrentUser();
        if (signInDetails?.loginId) {
          try {
            const { data, status } = await api.getDetail(signInDetails.loginId);
            if (status === 200) {
              setName(data.data.user_name)
              localStorage.setItem('userFname', data.data.user_name)
            } else {
              setName('')
            }
          } catch(e) {
            console.error(e)
            localStorage.setItem("username", String(null));
            return e
          }
        }
        localStorage.setItem("username", String(signInDetails?.loginId));
      } catch (err) {
        console.error(err)
      }
    }
    if (userName !== 'null') getUserName();
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const except = [
      '/403-forbidden',
      '/404-not-found',
      '/login',
      '/',
      '/eq/list',
      '/categories/list',
      '/help',
      '/terms-of-use',
      '/privacy-policy',
      '/list',
      '/notice',
      '/eq/create',
      '/eq/csv/upload'
    ]

    if (!except.includes(location.pathname) && !location.pathname.includes('eq/edit') && !location.pathname.includes('/account/')) {
      const currentRoute = pages.filter((page: { path: string; }) => page.path === location.pathname)[0]

      if(typeof authority === 'object' && Object.entries(authority).length === 0) {
        navigate('/403-forbidden')
        return
      }
      if(authority && currentRoute?.code) {
        if (typeof authority[currentRoute.code] === 'boolean' && authority[currentRoute.code]) {
          navigate(location.pathname)
        }
      }
    }
    
    if(location.pathname === '/login' && userName !== 'null') {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [location.pathname, authority]);

  return (
    // <ThemeProvider theme={theme}>
      <View 
        as="header" 
        className="main-header"
        display={{
          medium: 'block',
        }}
      >
        <Flex>
          <Heading
            className="header-title"
            fontWeight={'600'}
            fontSize={'28px'}
            textAlign='left'
            style={{'cursor': 'pointer'}}
            onClick={() => navigate('/')}
          >
            { pageName }
          </Heading>
          {
            userName !== 'null' ?
            <Flex
              className="header-right-menu"
            >
              <CustomButton 
                {...defaultButtonProps}
                type="icon"
                iconName="info"
                click={() => navigate('/help')}
              />
              <ReactToPrint
                trigger={() => (
                  <button style={{'background': 'transparent', 'border': 'none', 'cursor': 'pointer'}}>
                    <PrintIcon />
                  </button>
                )}
                content={() => printRef.current}
              />
              <Button 
                className="notif-button"
                onClick={() => navigate('/notice')}
              > 
                <NotificationIcon hasNotification={hasNotification} /> 
              </Button>
              <Menu
                menuAlign="end"
                size="small"
                className="account-menu"
                trigger={
                  <Flex className="account-icon" gap={10}>
                    <AvatarIcon />
                    <Text
                      display={{
                        base: "none",
                        medium: "block",
                      }}
                      className="header-username"
                    >
                      { name }
                    </Text>
                  </Flex>
                }
              >
                <MenuItem padding={'0 15px'} style={{'borderBottom': '2px solid rgba(0,0,0,.3)'}} onClick={() => navigate(`/account/${userName}`)}>アカウント情報</MenuItem>
                <MenuItem padding={'0 15px'} onClick={() => handleSignOut()}>ログアウト</MenuItem>
              </Menu>
            </Flex>
            : null
          }
        </Flex>
      </View>
  );
}

export default Header;
