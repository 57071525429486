import { View, Heading, TextField, Flex, Text } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import "../../styles/forgot-password.scss";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import validateEmail from "../../utils/validateEmail";
import { PasswordApi } from "../../api/password";
import { useNavigate } from "react-router-dom";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

const defaultButtonProps = {
  text: "",
  type: "icon",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: (): void => {},
};

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const api = PasswordApi();

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const openModal = () => {
    setDisableButton(false);
    setErrorMessage("");

    setUsernameError(() => {
      if (username === "") {
        return true;
      } else if (!validateEmail(username)) {
        return true;
      } else {
        return false;
      }
    });

    if (username !== "") {
      if (validateEmail(username)) setIsModalOpen(true);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false);
    }
  };

  async function handleResetPassword(username: string) {
    setDisableButton(true);
    setErrorMessage("");
    const res: any = await api.doForgotPassword({ email: username });

    if (res?.status === 200) {
      setDisableButton(false);
      setUsernameError(false);
      localStorage.setItem("email", username);
      navigate("/password/reset", { state: { email: username } });
    } else {
      setDisableButton(false);
      setIsModalOpen(false);
      setUsernameError(true);
      if (res?.status === 409) {
        setErrorMessage("入力したEmailアドレスは無効です。");
      }
    }
  }
  return (
    <>
      <View className="forgot-password-form">
        <Heading level={4}>
          ご入力いただいたメールアドレスに、
          <br />
          パスワード再設定用の認証コードを送信します。
        </Heading>
        {errorMessage !== "" ? (
          <Flex className="signin-error-message">
            <View className="error-icon">
              <ErrorSmallIcon fill="#D32F2F" />
            </View>
            <Text>{errorMessage}</Text>
          </Flex>
        ) : null}
        <TextField
          value={username}
          placeholder="user@example.com"
          onChange={(e) => setUsername(e.target.value)}
          label=""
          className={usernameError ? `input-error` : ""}
        />
        <CustomButton
          {...defaultButtonProps}
          text="送信"
          type="primary"
          iconPosition=""
          click={() => openModal()}
        />
      </View>
      {isModalOpen && (
        <CommonModal
          isOpen={isModalOpen}
          icon={""}
          onClose={() => setIsModalOpen(false)}
          textHeading="メールを送信しました"
          textsmall={`ご入力いただいたメールアドレスにパスワード再設定用認証コードを送信しました`}
        >
          <CustomButton
            {...defaultButtonProps}
            text="パスワード再設定"
            type="primary"
            borderRadius="large"
            width="full"
            iconPosition=""
            disabled={disableButton}
            click={() => handleResetPassword(username)}
          />
        </CommonModal>
      )}
    </>
  );
}
