import React from "react";

export default function WarningIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.99997 7.0909C6.40163 7.0909 6.72724 6.85955 6.72724 6.57416L7.09088 2.99999C7.09088 2.48325 6.72724 2.18181 5.99997 2.18181C5.27269 2.18181 4.90906 2.48325 4.90906 2.99999L5.27269 6.57416C5.27269 6.85955 5.59831 7.0909 5.99997 7.0909ZM5.99454 9.81819C5.39504 9.81819 4.90906 9.32977 4.90906 8.72728C4.90906 8.12479 5.39504 7.63637 5.99454 7.63637H6.00539C6.60489 7.63637 7.09088 8.12479 7.09088 8.72728C7.09088 9.32977 6.60489 9.81819 6.00539 9.81819H5.99454Z" 
        fill="#EF4E4E"
      />
    </svg>
  );
}
