// src/utils/DateUtils.ts
export const getFormattedDate = (date: Date): string => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}`;
  };
  
  export const getDateRange = (daysBefore: number, daysAfter: number): string[] => {
    const result: string[] = [];
    const today = new Date();
  
    for (let i = -daysBefore; i <= daysAfter; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      result.push(getFormattedDate(date));
    }
  
    return result;
  };
  