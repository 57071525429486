import React, { useEffect, useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import { TextField, View, Flex, Text, Heading } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";

import { IAuthCreate } from "../../types/IAuth";
import { AuthApi } from "../../api/auth";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function AuthCreatePage() {
  const api = AuthApi();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [authNames, setAuthNames] = useState<[]>([]);
  const selectedSiteId: string = state?.siteId ?? "";
  const selectedSiteName: string = state?.siteName ?? "";
  const [payload, setPayload] = useState<IAuthCreate>({
    authority_groups_name: "",
    site_id: selectedSiteId,
  });

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/auth/list", label: "権限管理" },
    { href: "", label: "権限グループ編集" },
  ];

  const openModal = () => {
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setNameError(() => {
      // @ts-ignore
      return payload.authority_groups_name === "" ||
        // @ts-ignore
        authNames.includes(payload.authority_groups_name)
        ? true
        : false;
    });

    if(payload.authority_groups_name === ""){
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("権限名を入力してください");
      setIsModalOpen(false);
       // @ts-ignore
    }else if(authNames.includes(payload.authority_groups_name)){
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("入力された権限名は既に使用されています");
      setIsModalOpen(false);
    }else{
      setIsModalOpen(true);
    }
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const getList = async () => {
    try {
      const { data } = await api.getList(true, selectedSiteId);
      if (data?.success) {
        const names = data?.data?.map((item: any) => {
          return item.authority_groups_name;
        });
        setAuthNames(names);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const handleSubmit = async () => {
    const createPayload = {
      authority_groups_name: payload.authority_groups_name,
      site_id: payload.site_id,
    };

    setDisableButton(true);
    setErrorMessage("");

    try {
      const { data } = await api.createAuth(createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/auth/list`, {
          state: {
            siteId: selectedSiteId,
          },
        });
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedSiteId || !selectedSiteName) {
      navigate(`/auth/list`);
    }
  }, []);

  useEffect(() => { }, [authNames])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={state?.pageTitle || pageTitle}
      showSelectField={true}
      selectFieldOptions={[
        // fixed one option
        {
          value: selectedSiteId,
          label: selectedSiteName,
        },
      ]}
    >
      <View className="main-content">
        <View as="form" onSubmit={() => handleSubmit()} className="custom-form">
          <Heading level={5} marginBottom={'30px'}>新規登録</Heading>
          {errorMessage !== "" || hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          ) : null}
          <TextField
            label="権限グループ名"
            placeholder=""
            value={payload.authority_groups_name}
            onChange={(event) =>
              handleChange("authority_groups_name", event.target.value)
            }
            isRequired
            className={nameError ? `required input-error` : "required"}
          />
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="登録"
              size="medium"
              width="medium"
              borderRadius="large"
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={showCancelModal}
            />
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              icon="icon"
              textHeading={`現場「${selectedSiteName}」に権限グループ「${payload.authority_groups_name}」を作成します。よろしいですか？`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="権限グループを作成する"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleSubmit()}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄して一覧へ戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="xs"
                  width="small"
                  click={() => navigate(`/auth/list`)}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
