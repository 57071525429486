import React, { useContext, useEffect, useState } from "react";
// import { NavLink, useNavigate } from 'react-router-dom';

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

// icons
// import SelectIcon from "../../components/icons/select-icon";
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";
import ErrorSmallIcon from "../../components/icons/error-small-icon";
import { AuthorityContext } from "../../App";
import getDeviceType from "../../utils/getDeviceType";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  // SelectField,
  IconsProvider,
  Loader,
  Text,
  TableHead,
  // Link
} from "@aws-amplify/ui-react";

import { IAuthList } from "../../types/IAuth";
import { AuthApi } from "../../api/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SiteApi } from "../../api/site";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "/", label: "権限管理" },
  { href: "", label: "権限グループ一覧" },
];

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};
type SiteSelect = {
  value: string;
  label: string;
};

export default function AuthListPage() {
  const { company, settings, site }: any = useContext(AuthorityContext);
  const isMobile = getDeviceType() === 'mobile'
  const api = AuthApi();
  const siteApi = SiteApi();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [dataList, setDataList] = useState([] as any);
  const [filteredDataList, setFilteredDataList] = useState([] as any);
  const itemsLimit = 20
  //const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsUndeletableModalOpen, setIsUndeletableModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [deleteId, setDeleteId] = useState<string | number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [deleteName, setDeleteName] = useState<string | number>("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [selectedAuth, setSelectedAuth] = useState<string | number>('')
  const [selectedAuthName, setSelectedAuthName] = useState<string | number>('')
  const [selectedAuthCount, setSelectedAuthCount] = useState<number>(0)
  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)
  // const indexOfLastUser = currentPageIndex * itemsLimit;
  // const indexOfFirstUser = (indexOfLastUser - itemsLimit) + 1;
  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);
  const { state } = useLocation();
  const initialSelectedSiteId: string = localStorage.getItem("currentSiteId") ?? state?.siteId ?? "";
  const navigate = useNavigate();

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const openModal = (id: number | string, name: number | string, affiliated_user_count: number) => {
    if (affiliated_user_count === 0 ) {
      setDeleteName(name);
      setDeleteId(id);
      setIsModalOpen(true);
    } else {
      setIsUndeletableModalOpen(true);
    }
    setIsActionsModalOpen(false)
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleDelete = () => {
    deleteCompany(deleteId);
  };

  const deleteCompany = async (id: any) => {
    setDisableButton(true);
    setErrorMessage("");
    try {
      const { data } = await api.deleteAuth(id);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(false);
        setErrorMessage("");
        getList();
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }
      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains('hidden')) {
        // @ts-ignore
        document.querySelector(".main-body")?.classList.remove('hidden');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteName("");
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  };

  const editAuthorityGroup = async (groupId: string | number, name: string, count: number) => {
    setSelectedAuth(groupId)
    setSelectedAuthName(name)
    setSelectedAuthCount(count)
    setIsActionsModalOpen(true)
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
  };

  const indexOfLastAuthorityGroup = currentPageIndex * itemsLimit;
  const indexOfFirstAuthorityGroup = indexOfLastAuthorityGroup - itemsLimit;
  const currentItems = filteredDataList.slice(
    indexOfFirstAuthorityGroup,
    indexOfLastAuthorityGroup
  );

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const getList = async (site: string | number = selectedSiteId, limit: number = itemsLimit, page: number = currentPageIndex) => {
    setLoader(true);

    const { data, status } = await api.getList(true, site, itemsLimit, currentPageIndex);
    if (status === 200) {
      const mappedList: IAuthList = data?.data?.map((item: any) => {
        return {
          id: item?.authority_group_id,
          name: item?.authority_groups_name,
          site_id: item?.site_id,
          affiliated_user_count: Number(item?.affiliated_user_count),
        };
      });
      setDataList(mappedList);
      setLoader(false);
      setFetchError(false);
      setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      setTotalCount(data?.data?.totalCount || data?.meta?.totalCount)
    } else {
      setFetchError(true);
    }
  };
  const setSiteForFilter = async (value: any) => {
    setSelectedSiteId(value);
    localStorage.setItem("currentSiteId", value)
    setCurrentPageIndex(1);
    //getList(selectedSiteId)
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsActionsModalOpen(false);
  };

  useEffect(() => {
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
         if (initialSelectedSiteId) {
            {/*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/}
            setSelectedSiteId(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSelectedSiteId(mappedSiteList[0].value)
            setSiteSelections(mappedSiteList);
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        {/*setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/}
          setSelectedSiteId(initialSelectedSiteId);
          setSiteSelections(mappedSiteList);
      } else {
        setSelectedSiteId(mappedSiteList[0].value);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteSelections(mappedSiteList);
      }
    }
  }, [site, company]);

  /*useEffect(() => {
    setSelectedSiteId(
      siteSelections && siteSelections.length ? siteSelections[0]?.value : ""
    );
  }, [siteSelections]);*/

  useEffect(() => {
    if(selectedSiteId !==""){
      getList();
      // eslint-disable-next-line
    }
  }, [selectedSiteId, currentPageIndex]);

  // useEffect(() => {
  //   setCurrentPageIndex(1);
  //   const filteredDataListToSet = dataList.filter((e: any) => {
  //     return e.site_id === selectedSiteId;
  //   });
  //   setFilteredDataList(filteredDataListToSet);
  //   setTotalPages(Math.ceil(filteredDataListToSet.length / itemsLimit));
  // }, [dataList, selectedSiteId]);

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue={selectedSiteId}
      changeEvent={setSiteForFilter}
    >
      <Flex alignItems={"center"} className="hide-from-print auth-list-actions">
        <CustomButton
          {...defaultButtonProps}
          text="新規権限グループ登録"
          size="medium"
          width="medium"
          borderRadius="large"
          disabled={false}
          click={() => {
            if (
              selectedSiteId &&
              siteSelections?.filter((e) => e.value === selectedSiteId)
            ) {
              navigate("/auth/create", {
                state: {
                  siteId: selectedSiteId,
                  siteName: siteSelections?.filter(
                    (e) => e.value === selectedSiteId
                  )[0].label,
                },
              });
            }
          }}
        />
        <CustomButton
          text="権限編集"
          type="lightblue"
          iconPosition="left"
          borderRadius="large"
          iconName="settings"
          size="medium"
          disabled={dataList.length === 0}
          click={() =>
            navigate("/auth/settings")
          }
        />
      </Flex>
      <View className="custom-table custom-table-2">
        {hasError ? (
          <Flex className="signin-error-message">
            <View className="error-icon">
              <ErrorSmallIcon fill="#D32F2F" />
            </View>
            <Text>{errorMessage}</Text>
          </Flex>
        ) : null}
        {!loader ? (
          <>
            <View as="div">
              <Table highlightOnHover={true}>
                <TableHead className={dataList.length < 8 ? 'no-padding' : ''}>
                  <TableRow className="custom-table-head" backgroundColor="#edf0f8">
                    <TableCell as="th" padding={'12px 16px'}>権限グループ名</TableCell>
                    <TableCell className="hide-from-print" as="th"></TableCell>
                    {(company?.admin_flag === true ||
                      (settings &&
                        settings[selectedSiteId]?.page_control?.includes(
                          "edit_permission_group"
                        ))) && (
                      <TableCell
                        className="hide-from-print"
                        as="th"
                        width={"150px"}
                        textAlign={"center"}
                        padding={'12px 16px'}
                      >
                        編集・削除
                      </TableCell>
                    )}
                    {/* {(company?.admin_flag === true ||
                      (settings &&
                        settings[selectedSiteId]?.page_control?.includes(
                          "delete_permission_group"
                        ))) && ( */}
                      {/* <TableCell
                        className="hide-from-print"
                        as="th"
                        width={"58px"}
                        textAlign={"center"}
                      >
                        削除
                      </TableCell> */}
                    {/* )} */}
                  </TableRow>
                </TableHead>
                <TableBody className="vertical-scroll">
                  {dataList?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10} textAlign={"center"}>
                        <Text>No data found.</Text>
                      </TableCell>
                    </TableRow>
                  )}
                  {dataList?.map((item: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell data-title={'権限グループ名'} padding={{'base':'20px 38px 20px 15px', 'medium': '15px 16px'}}>
                          {item.name}
                          {/* <NavLink to={`/auth/${ item.id }`} className="table-hover">{ item.name }</NavLink> */}
                        </TableCell> 
                        <TableCell padding={'15px 16px'} display={{'base': 'none', 'medium': 'table-cell'}}></TableCell>
                        <TableCell
                          className="hide-from-print table-actions-v2 auth-actions"
                          textAlign={"center"}
                          width={"150px"}
                        >
                          {(company?.admin_flag === true ||
                          (settings &&
                            settings[selectedSiteId]?.page_control?.includes(
                              "edit_permission_group"
                            ))) && (
                            <CustomButton
                              text=""
                              type="icon"
                              iconPosition="left"
                              iconName="menu"
                              size=""
                              disabled={false}
                              click={() => editAuthorityGroup(item.id, item.name, Number(item.affiliated_user_count))}
                            />
                          )}
                        </TableCell>
                        {/* {(company?.admin_flag === true ||
                          (settings &&
                            settings[selectedSiteId]?.page_control?.includes(
                              "delete_permission_group"
                            ))) && ( */}
                          {/* <TableCell
                            className="hide-from-print"
                            textAlign={"center"}
                          >
                            <CustomButton
                              {...defaultButtonProps}
                              type="icon"
                              iconName="trash"
                              click={() => openModal(item.id, item.name, item.affiliated_user_count)}
                            />
                          </TableCell> */}
                        {/* )} */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </View>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className="custom-pagination"
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}

        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading={`権限グループ「${deleteName}」の情報を削除します`}
          textOperation="この操作は取り消せません。本当に削除しますか？"
        >
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={() => handleCancel()}
          />
          <CustomButton
            {...defaultButtonProps}
            text="権限グループを削除"
            type="warning"
            iconName={disableButton ? "loader" : ""}
            iconPosition="right"
            disabled={disableButton}
            size="large"
            click={() => handleDelete()}
          />
        </CommonModal>
        <CommonModal
          isOpen={IsUndeletableModalOpen}
          onClose={() => setIsUndeletableModalOpen(false)}
          textHeading={`権限グループ削除不可`}
          textOperation="権限グループに紐づくユーザーが存在するため削除できません"
        >
        <View
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CustomButton
            {...defaultButtonProps}
            text="閉じる"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={() => setIsUndeletableModalOpen(false)}
          />
        </View>
        </CommonModal>
      </View>
      <View className="company-action-modal">
        <CommonModal
          isOpen={isActionsModalOpen}
          onClose={() => closeActions()}
          textHeading=""
          textOperation=""
        >
          {isMobile ? (
          <View as="ul" width='100%' style={{'listStyleType': 'none', padding: '0'}}>
            {/* {(company?.admin_flag === true || settings[selectedSiteId]?.page_control?.includes("user_edit")) && ( */}
              <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                <View as="a" onClick={() => {
                  closeActions()
                  navigate(`/auth/edit/${selectedAuth}`, { state: { pageTitle: selectedAuthName } });
                }}>編集</View>
              </View>
            {/* )} */}
            {/* {(company?.admin_flag === true || settings[selectedSiteId]?.page_control?.includes("user_delete")) && ( */}
              <View as="li" width='100%' fontSize='16px' >
                <View as="a" 
                  // @ts-ignore
                  onClick={() => openModal(selectedAuth, selectedAuthName, selectedAuthCount)} 
                  style={{ cursor: 'pointer' }}>削除</View>
              </View>
            {/* )} */}
          </View>
          ) : (
            <>
              <CustomButton
                {...defaultButtonProps}
                width="full"
                text="編集"
                type="primary"
                iconName="pen"
                iconPosition="left"
                size="large"
                click={() => {
                  closeActions()
                  navigate(`/auth/edit/${selectedAuth}`, { state: { pageTitle: selectedAuthName } });
                }}
              />
              <CustomButton
                {...defaultButtonProps}
                width="full"
                text="削除"
                type="default"
                iconName="trash"
                iconPosition="left"
                size="large"
                click={() => openModal(selectedAuth, selectedAuthName, selectedAuthCount)}
              />
            </>
          )}
        </CommonModal>
      </View>
    </PageContent>
  );
}
