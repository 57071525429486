import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { IAuthEdit } from "../../types/IAuth";

export const AuthApi = () => {
  const getList = async (filter: boolean = false, site: number | string = 10, limit: number = 20, page: number) => {
    const response = filter ? await FetchHeader(`/auth/group/list?site_id=${site}&limit=${limit}&page=${page}`, "GET") : await FetchHeader(`/auth/group/list`, "GET");
    return response;
  };

  const getDetail = async (id: string | number) => {
    const response = await FetchHeader(`/auth/group/detail/${ id }`, "GET");
    return response;
  };

  const createAuth = async (payload: IAuthEdit) => {
    const response = await FetchHeader(`/auth/group/create`, "POST", payload);
    return response;
  };

  const updateAuth = async (id: string | number | undefined, payload: IAuthEdit) => {
    const response = await FetchHeader(`/auth/group/edit/${ id }`, "POST", payload);
    return response;
  };

  const deleteAuth = async (id: string | number | undefined) => {
    const response = await FetchHeader(`/auth/group/delete/${ id }`, "POST");
    return response;
  };

  const updateSettings = async(id: string | null | undefined, payload: {}) => {
    const response = await FetchHeader(`/auth/group/settings/edit/${ id }`, "POST", payload);
    return response;
  }

  return {
    getList,
    getDetail,
    createAuth,
    updateAuth,
    deleteAuth,
    updateSettings
  };
};


// old format
// {
// 	"dashbord" : false,
// 	"equipment_regist" : true,
// 	"equipment_csv_regist" : true,
// 	"equipment_name" : true,
// 	"equipment_category" : true,
// 	"model" : true,
// 	"charge_oordering" : true,
// 	"site_of_use" : true,
// 	"onsite_manage_no" : true,
// 	"rental_company" : true,
// 	"quantity" : true,
// 	"total_operating_hour" : true,
// 	"operating_time_at_the_start_of_use" : true,
// 	"delivery_schedule_date" : true,
// 	"delivery_schedule_time" : true,
// 	"result_delivery_schedule_date" : true,
// 	"result_delivery_schedule_time" : true,
// 	"receiving_person" : true,
// 	"scheduled_return_date" : true,
// 	"scheduled_return_time" : true,
// 	"return_date" : true,
// 	"return_time" : true,
// 	"person_in_charge_of_return" : true,
// 	"last_inventory_date" : true,
// 	"last_inventory_time" : true,
// 	"inventory_checker" : true,
// 	"construction_location" : true,
// 	"remarks" : true,
// 	"equipment_delete" : true,
// 	"inventory_regist" : true,
// 	"equ_service" : true,
// 	"delivery_schedule_regist" : true,
// 	"person_in_charge_who_is_scheduled_to_use" : true,
// 	"scheduled_location_of_use" : true,
// 	"delete_scheduled_use" : true,
// 	"start_use_regist" : true,
// 	"end_use_regist" : true,
// 	"used_site" : true,
// 	"charge_of_equ" : true,
// 	"site_service" : true,
// 	"companies_service" : true,
// 	"auth_service" : true,
// 	"edit_permission_group" : true,
// 	"delete_permission_group" : true,
// 	"auth_dashboard" : true,
// 	"auth_equipment_information_management" : false,
// 	"auth_equipment_schedule" : true,
// 	"auth_on_site_management" : true,
// 	"auth_companise_entity_management" : true,
// 	"auth_authority_management" : true,
// 	"auth_user_management" : true,
// 	"auth_update_history" : true,
// 	"users_service" : true,
// 	"inactive_user_display" : true,
// 	"user_regist" : true,
// 	"user_edit" : true,
// 	"user_delete" : true,
// 	"logs" : true,
//     "record_controll": {
// 		"equipments_manage": [
// 			"100",
// 			"102"
//         ],
//         "equipments_schedule_manege": [
//             "100",
//             "102"
//         ],
//         "user_manage": [
// 	        "100",
//             "102"
//         ]
// 	}
// }
