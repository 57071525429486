import React from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
type IEquipmentMainDetails = {
  status: string;
  name: string;
  category: string;
};
export default function EquipHeaderDetails({
  status,
  name,
  category,
}: IEquipmentMainDetails) {
  return (
    <>
      <Flex direction={"column"} className="pc-view eq-header-details">
        <Text className="status">{status}</Text>
        <Flex direction={"row"}>
          <Text className="category">{category}</Text>
          <Text className="name">{name}</Text>
        </Flex>
      </Flex>
      <Flex direction={"column"} className="sp-view eq-header-details">
        <Flex direction={"row"} justifyContent={"space-between"}>
          <Text className="category">{category}</Text>
          <Text className="status">{status}</Text>
        </Flex>
        <Text className="name">{name}</Text>
      </Flex>
    </>
  );
}
