import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthorityContext } from "./App";

export default function PrivateRoute({ token, pageId, children }) {
  const navigate = useNavigate();
  const { settings, company } = useContext(AuthorityContext);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    async function checkAuthState() {
      try {
        const response = await fetchAuthSession();
        if (response?.tokens === undefined) {
          navigate("/login");
        }
      } catch (err) {
        navigate("/login");
        return err;
      }
    }

    checkAuthState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const outsideSettings = [
      "home",
      "eq-list",
      "category-list",
      "user-index",
      "auth-create",
      "auth-edit",
      "auth-settings",
      "eq-index",
      "schedule",
      "company-create",
      "company-edit",
      "company-index",
      "site-create",
      "site-index",
      "site-edit",
      "site-assign",
      "site-assign-user",
      "eq-schedule-regist",
      "eq-schedule",
      "eq-use-regist",
      "eq-schedule-ondate",
      "privacy-policy",
      "terms-of-use",
      "qr-code-alert",
      "qr-code-scanner",
      "qrcode",
      "notice",
      "account",
      "account-edit",
      "help",
    ];
    if (settings) {
      const menuList = outsideSettings;
      for (let site_id in settings) {
        const setting = settings[site_id];
        //if (settings?.length > 0) {
        if (setting.page_control) {
          if (setting.page_control.length > 0) {
            // it does not care about duplication.
            menuList.push(...setting.page_control.map((item) => item));
          }
        }
      }
      //menuList.push(...outsideSettings);

      if (!menuList.includes(pageId) && company.admin_flag !== true) {
        navigate("/403-forbidden");
      }
      setIsAllowed(true);
    }
  }, [navigate, pageId, settings]);

  return isAllowed ? children : <></>;
}
