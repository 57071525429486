import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { ICompanyEdit, ICompanyCreate } from "types/ICompany";

export const CompanyApi = () => {
  const getList = async (hasLimit: boolean = false, page: number = 1) => {
    const response = hasLimit ? await FetchHeader(`/company/list?limit=${'20'}&page=${page}`, "GET") : await FetchHeader(`/company/list`, "GET");
    return response;
  };

  const getDetail = async (id: string | number) => {
    const response = await FetchHeader(`/company/detail/${id}`, "GET");
    return response;
  };

  const editCompany = async (id: string | number, payload: ICompanyEdit) => {
    return await FetchHeader(`/company/edit/${id}`, "POST", payload);
  };

  const createCompany = async (payload: ICompanyCreate) => {
    return await FetchHeader(`/company/create`, "POST", payload);
  };

  const deleteCompany = async (id: string | number) => {
    return await FetchHeader(`/company/delete/${id}`, "POST");
  };

  return {
    getList,
    getDetail,
    editCompany,
    createCompany,
    deleteCompany
  };
};
