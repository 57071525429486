export const assignList = [
  {
    id: "A工業",
    corporateAffiliation: "A山 A太郎",
    name: "A山 グループB",
    autorizationGroup: "",
  },
  {
    id: "A工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループB",
    autorizationGroup: "",
  },
  {
    id: "A工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループB",
    autorizationGroup: "",
  },
  {
    id: "B工業",
    corporateAffiliation: "B田 B次郎",
    name: "グループB",
    autorizationGroup: "",
  },
  {
    id: "B工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループB",
    autorizationGroup: "",
  },
  {
    id: "B工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループB",
    autorizationGroup: "",
  },
  {
    id: "E工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループC",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  }, {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
  {
    id: "D工業",
    corporateAffiliation: "〇〇 〇〇",
    name: "グループA",
    autorizationGroup: "",
  },
];
