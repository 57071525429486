import React, { useState, useEffect } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  TextAreaField,
  CheckboxField,
  View,
  Flex,
  Text,
  Heading,
  Loader
} from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";

import { ISiteEdit } from "../../types/ISiteList";

//aspi
import { SiteApi } from "../../api/site";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function SiteEditPage() {
  const { siteId = '' } = useParams();
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const navigate = useNavigate();
  const api = SiteApi()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [noteLength, setNoteLength] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  const [editList, setEditList] = useState({} as any)
  const [payload, setPayload] = useState<ISiteEdit>({
    site_name: '',
    status: false,
    site_prime_companies: [],
    site_rental_companies: [],
    site_other_companies: [],
    site_construction_locations: [],
    site_inventory_locations: [],
    remarks: ''
  });

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/site/list', label: '現場管理' },
    { href: '/site/list', label: '現場一覧' },
    { href: '', label: '現場情報編集' },
  ]

  useEffect(() => {
    if (isModalOpen) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.add('hidden');
    }
  }, [isModalOpen]);

  const openModal = () => {
    setNameError(() => {
      return payload.site_name === '' ? true : false
    })

    if(payload.site_name !== '') {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.add('hidden');
      // document.querySelector(".main-body").style.overflow = 'hidden';
      document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
      setIsModalOpen(true)
    } else {
      document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
      setIsModalOpen(false)
    }
  };

  const closeModal = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = 'auto';
    setIsModalOpen(false)
  };

  const handleChange = (item: string, value: any) => {
    if (item === 'site_construction_locations' || item === 'site_inventory_locations') {
      const locationsArray = value.includes(',') ? value.split(',') : [value];
      setPayload(prevState => ({
        ...prevState,
        [item]: locationsArray
      }));
    } else {
      setPayload(prevState => ({
        ...prevState,
        [item]: value
      }));
    }
    if(item === 'remarks') {
      setNoteLength(value.length)
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage('');
    const editPayload = {
      site_name: payload.site_name,
      remarks: payload.remarks,
      status: true,
      site_prime_companies: payload.site_prime_companies.map(companyName => {
        const company = editList.site_prime_companies.find((c: { company_name: number; }) => c.company_name === companyName);
        return company ? company.company_id : null;
      }).filter(Boolean),
      site_rental_companies: payload.site_rental_companies.map(companyName => {
        const company = editList.site_rental_companies.find((c: { company_name: number; }) => c.company_name === companyName);
        return company ? company.company_id : null;
      }).filter(Boolean),
      site_other_companies: payload.site_other_companies.map(companyName => {
        const company = editList.site_other_companies.find((c: { company_name: number; }) => c.company_name === companyName);
        return company ? company.company_id : null;
      }).filter(Boolean),
      site_construction_locations: Array.isArray(payload.site_construction_locations) ? payload.site_construction_locations : [],
      site_inventory_locations: Array.isArray(payload?.site_inventory_locations) ? payload.site_inventory_locations : [],
    };
    
    try {
      // @ts-ignore
      const { data } = await api.editSite(siteId, editPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage('');
        navigate(`/site/${siteId}`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
     // @ts-ignore
     document.querySelector(".main-body")?.classList.add('hidden');
    //  document.querySelector(".main-body").style.overflow = 'hidden';
    document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
     setIsCancelModal(!isCancelModal)
  }

  const showReturnModal = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = 'auto';
   setIsCancelModal(!isCancelModal)
 }

  const handleCheckboxChange = (type: string, name: any, checked: boolean) => {
    const { site_prime_companies, site_rental_companies, site_other_companies } = payload
    if (type === 'site_prime_companies') {
      if(checked) {
        setPayload({
          ...payload,
          site_prime_companies: [...site_prime_companies, name]
        })
      } else {
        setPayload({
          ...payload,
          site_prime_companies: site_prime_companies.filter(
            (e) => e !== name
          ),
        })
      }
    } else if (type === 'site_rental_companies') {
      if(checked) {
        setPayload({
          ...payload,
          site_rental_companies: [...site_rental_companies, name]
        })
      } else {
        setPayload({
          ...payload,
          site_rental_companies: site_rental_companies.filter(
            (e) => e !== name
          ),
        })
      }
    } else if (type === 'site_other_companies') {
      if(checked) {
        setPayload({
          ...payload,
          site_other_companies: [...site_other_companies, name]
        })
      } else {
        setPayload({
          ...payload,
          site_other_companies: site_other_companies.filter(
            (e) => e !== name
          ),
        })
      }
    }
  };

  const toSiteDetails = () => {
    navigate(`/site/${siteId}`)
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = 'auto';
  }

  useEffect(() => {
    const geDetails = async () => {
      setLoader(true);
      try {
        const { data, status } = await api.getDetail(siteId);
        if (status === 200 && data && data.data) {
          setLoader(false);
          setFetchError(false);
          const siteData = data.data;
          const payloadUpdate: Partial<ISiteEdit> = {
            site_name: siteData.site_name || '',
            site_construction_locations: siteData.site_construction_locations ? siteData.site_construction_locations.map((location: { name: any; }) => location.name) : [],
            site_inventory_locations: siteData.site_inventory_locations ? siteData.site_inventory_locations.map((location: { name: any; }) => location.name) : [],
            remarks: siteData.remarks || '',
          };

          if (siteData.site_prime_companies && Array.isArray(siteData.site_prime_companies)) {
            const primeCompanies = siteData.site_prime_companies.filter((company: { company: any; }) => company.company);
            if (primeCompanies.length > 0) {
              payloadUpdate.site_prime_companies = primeCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name);
            }
          }

          if (siteData.site_rental_companies && Array.isArray(siteData.site_rental_companies)) {
            const rentalCompanies = siteData.site_rental_companies.filter((company: { company: any; }) => company.company);
            if (rentalCompanies.length > 0) {
              payloadUpdate.site_rental_companies = rentalCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name);
            }
          }

          if (siteData.site_other_companies && Array.isArray(siteData.site_other_companies)) {
            const otherCompanies = siteData.site_other_companies.filter((company: { company: any; }) => company.company);
            if (otherCompanies.length > 0) {
              payloadUpdate.site_other_companies = otherCompanies.map((company: { company: { company_name: any; }; }) => company.company.company_name);
            }
          }

          setPayload(prevState => ({ ...prevState, ...payloadUpdate }));
        } else {
          setFetchError(true);
        }
      } catch (error) {
        setFetchError(true);
      }
    };
    geDetails();

    // eslint-disable-next-line
  }, [siteId]);


  useEffect(() => {
    const getList = async () => {
      const { data } = await api.getPreCreateList();
      setEditList(data?.data)
    };
    getList()
  // eslint-disable-next-line
  }, [])


  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { hasFormError ?
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          {!loader ? (
            <>
            <TextField
              label="現場名"
              placeholder="お名前を入力してください"
              value={payload.site_name}
              onChange={(event: { target: { value: any; }; }) => handleChange('site_name', event.target.value)}
              isRequired
              className={ nameError ? `required input-error` : 'required' }
            />
            {/* <View>
              <Heading level={6} fontWeight={700}>元請け会社*</Heading>
              <Flex marginTop={10} className="site-checkboxes">
                {
                  editList?.site_prime_companies?.map((item : any, index : number) => {
                    return (
                      <CheckboxField
                      key={index}
                      label={item.company_name}
                      name={item.company_name}
                      value={item.company_id}
                      checked={payload.site_prime_companies.includes(item.company_name)}
                      onChange={(event: { target: { checked: boolean; }; }) => handleCheckboxChange('site_prime_companies', item.company_name, event.target.checked)}
                      />
                    )
                  })
                }
              </Flex>
            </View> */}
            {/* <View>
              <Heading level={6} fontWeight={700}>レンタル会社</Heading>
              <Flex marginTop={10} className="site-checkboxes">
                {
                  editList?.site_rental_companies?.map((item: any, index : number) => {
                    return (
                      <CheckboxField
                        key={index}
                        label={item.company_name}
                        name={item.company_name}
                        value={item.company_id}
                        checked={ payload.site_rental_companies.includes(item.company_name) }
                        onChange={(event: { target: { checked: boolean; }; }) => handleCheckboxChange('site_rental_companies', item.company_name, event.target.checked)}
                      />
                    )
                  })
                }
              </Flex>
            </View> */}
            {/* <View>
              <Heading level={6} fontWeight={700}>その他法人</Heading>
              <Flex marginTop={10} className="site-checkboxes">
                {
                  editList?.site_other_companies?.map((item: any, index : number) => {
                    return (
                      <CheckboxField
                        key={index}
                        label={item.company_name}
                        name={item.company_name}
                        value={item.company_id}
                        checked={ payload.site_other_companies.includes(item.company_name) }
                        onChange={(event: { target: { checked: boolean; }; }) => handleCheckboxChange('site_other_companies', item.company_name, event.target.checked)}
                      />
                    )
                  })
                }
            </Flex>
            </View> */}

            <TextField
              label="工区"
              placeholder="工区A,工区B,工区C"
              value={payload.site_construction_locations}
              onChange={(event: { target: { value: any; }; }) => handleChange('site_construction_locations', event.target.value)}
            />
            <Text
              color='#666666'
              fontWeight={400}
              fontSize={12}
              lineHeight='20px'
              marginBottom={20}
              marginTop='-20px'
              marginLeft={15}
            >
              工区が複数ある場合は、,（カンマ）で区切って入力してください。
            </Text>
            <TextField
              label="棚卸し場所（所在）"
              placeholder="機材の所在"
              value={payload.site_inventory_locations}
              onChange={(event: { target: { value: any; }; }) => handleChange('site_inventory_locations', event.target.value)}
            />
            <Text
              color='#666666'
              fontWeight={400}
              fontSize={12}
              lineHeight='20px'
              marginBottom={20}
              marginTop='-20px'
              marginLeft={15}
            >
              所在が複数ある場合は、,（カンマ）で区切って入力してください。
            </Text>
            <TextAreaField
              label="備考"
              placeholder="工期や住所など任意で記入してください"
              value={payload.remarks}
              onChange={(event: { target: { value: any; }; }) => handleChange('remarks', event.target.value)}
              maxLength={100}
            />
            <Text className='textarea-length' as='span'>{ noteLength }/100</Text>
            <Flex
              justifyContent="flex-end"
              marginTop="1rem"
              alignItems={`center`}
              direction={{
                base: 'column',
                medium: 'row'
              }}
            >
              <CustomButton
                {...defaultButtonProps}
                text='更新'
                size='medium'
                width='medium'
                borderRadius='large'
                click={openModal}
              />
              <CustomButton
                {...defaultButtonProps}
                text='キャンセル'
                type='bordered-transparent'
                click={showCancelModal}
              />
            </Flex>
          </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
          </View>
           {/* submit modal */}
           <CommonModal
             isOpen={isModalOpen}
             onClose={() => closeModal()}
             icon="icon"
             textHeading="現場情報を更新します。よろしいですか？"
           >
             <View margin={`0 auto`}>
               <CustomButton
                 { ...defaultButtonProps }
                 text='現場情報を更新する'
                 size='medium'
                 width='medium'
                 borderRadius='large'
                 iconName={ disableButton ? 'loader' : '' }
                 iconPosition='right'
                 disabled={ disableButton }
                 click={handleSubmit}
               />
             </View>
           </CommonModal>
            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              onClose={() => closeModal()}
              textHeading="入力内容を破棄して一覧へ戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='編集へ戻る'
                  iconPosition='left'
                  iconName='arrow-left'
                  click={showReturnModal}
                />
                <CustomButton
                  { ...defaultButtonProps }
                  type="warning"
                  text='破棄'
                  size='xs'
                  width='xsmall'
                  click={() => toSiteDetails()}
                />
              </Flex>
          </CommonModal>
      </View>
    </PageContent>
  );
}
