import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthorityContext } from "../../App";
import ImageGallery from "react-image-gallery";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Badge,
  Heading,
  Loader,
  Text,
} from "@aws-amplify/ui-react";

// types
import { IEqDetails } from "../../types/IEq";

// api
import { EqApi } from "../../api/eq";

// icons
// import ImageIcon from '../../components/icons/image-icon';

// utils
import formatDate from "../../utils/formatDate";
import convertStatus from "../../utils/geStatus";
import getBadgeColor from "../../utils/getBadgeColor";

// css
import "../../styles/eq.scss";

export default function EqPage() {
  const { company, settings }: any = useContext(AuthorityContext);
  const { equipmentId }: any | undefined = useParams();
  const navigate = useNavigate();
  const api = EqApi();
  const imageUrl =
    process.env.REACT_APP_ASSETS_S3BUCKET_URL +
    "equipment_images/" +
    equipmentId +
    "/";
  // const pageTitle = localStorage.getItem('currentPage') ?? ''
  const tdRef = useRef<HTMLTableCellElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [tableData, setTableData] = useState({} as IEqDetails);
  const [disableButton, setDisableButton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [siteId, setSiteId] = useState("");
  const [status, setStatus] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [tdHeight, setTdHeight] = useState(0);
  const [isScheduleRegisteredToday, SetIsScheduleRegisteredToday] =
    useState<boolean>(false);
  const [isAlreadyUseScheduleEnd, SetIsAlreadyUseScheduleEnd] =
    useState<boolean>(false);
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "/eq/list", label: "機材一覧" },
    { href: "", label: "機材詳細" },
  ];

  useEffect(() => {
    if (isModalOpen) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.add("hidden");
    }
  }, [isModalOpen]);

  const openModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add("hidden");
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setIsModalOpen(true);
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains("hidden")) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove("hidden");
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    deleteEq(equipmentId);
  };

  const deleteEq = async (id: any) => {
    setDisableButton(true);
    setErrorMessage("");
    try {
      const { data } = await api.deleteEq(id);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(false);
        setErrorMessage("");
        navigate("/eq/list");

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasError(true);
        setErrorMessage(data?.error?.message ?? data?.message);

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      }

      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains("hidden")) {
        document.querySelector(".main-body")?.classList.remove("hidden");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
  };

  const navigateToEqSchedule = () => {
    navigate(`/eq/schedule/${equipmentId}`, { state: { status: status } });
  };

  const getStatus = async () => {
    const { data } = await api.getEqStatus(equipmentId ?? "");
    try {
      if (data?.success) {
        const stat = convertStatus(data?.data?.status);
        setStatus(stat);
      } else {
        const stat = convertStatus("in_use");
        setStatus(stat);
      }
    } catch (err) {
      return err;
    }
  };

  const getDetail = async () => {
    setLoader(true);
    // @ts-ignore
    const { data } = await api.getDetail(equipmentId);
    if (data?.success) {
      setLoader(false);
      setFetchError(false);
      setTableData({
        name: data?.data?.equipment_name ?? "-",
        equipment_common_name: data?.data?.equipment_common_name ?? "",
        category: data?.data?.equipment_category1_name ?? "-",
        person_in_charge: data?.data?.charge_ordering != "" ? data?.data?.charge_ordering : "-",
        management_no: data?.data?.onsite_manage_no ?? "-",
        company: data?.data?.company_name ?? "-",
        quantity: data?.data?.quantity ?? "-",
        use_company: data?.data?.actual_company_name ?? "-",
        actual_use_company: data?.data?.actual_company_id ?? "-",
        rental_company: data?.data?.rental_company ?? "-",
        location: data?.data?.last_use_actual_use_location ?? "-",
        site_name: data?.data?.use_location,
        return_schedule: formatDate(null, data?.data?.return_schedule),
        condition: convertStatus(data?.data?.status) ?? "-",
        total_hours: data?.data?.total_operating_hours ?? "-",
        start_hours: data?.data?.operation_time_at_start_use ?? "-",
        note: data?.data?.remarks != "" ? data?.data?.remarks : "-",
        inventory_update: formatDate(null, data?.data?.inventory_datetime),
        last_update: formatDate(null, data?.data?.updated_at)
      });

      if (data?.data?.use_delivery_schedule && data?.data?.use_return_schedule) {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        SetIsScheduleRegisteredToday(
          new Date(data?.data?.use_delivery_schedule) <= today &&
            new Date(data?.data?.use_return_schedule) >= today
        );
        SetIsAlreadyUseScheduleEnd(data?.data?.actual_return !== null);
      }

      setSiteId(data?.data?.site_id);
      const listOfImages: any[] = [];
      if (data?.data?.urls?.length > 0) {
        data?.data?.urls?.map((item: any) => {
          const list = {
            original:item.images.original,
              //"https://assets.dev.dcerm.com/equipment_images/no-image.png", //item.original.replace('.png', '_original.png')
            thumbnail: null//item.sizes.thumbnail,
          };
          listOfImages.push(list);
          return item;
        });

        setImages(listOfImages);
      } else {
        setImages([]);
      }
    } else {
      setFetchError(true);
    }
  };


  useEffect(() => {
    getDetail();
    getStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tdRef.current) {
      setTdHeight(tdRef.current.clientHeight);
    }
  }, [tableData]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={tableData.equipment_common_name || tableData.name || ""}
    >
      <Flex direction={{ base: "column-reverse", medium: "column" }}>
        <Flex
          alignItems={"center"}
          direction={{ base: "column", medium: "row" }}
          className="eq-buttons hide-from-print"
        >
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes("accept_regist"))) && (
            <>
              {status === "受入前" && (
                <CustomButton
                  {...defaultButtonProps}
                  text="受入登録"
                  disabled={loader}
                  click={() => navigate(`/eq/delivery/${equipmentId}`,{state: {site_id: siteId}})}
                />
              )}
            </>
          )}
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes("delivery_schedule_regist") 
              /*&&
              settings[siteId]["record_control-equipments_schedule_manage"] &&
              settings[siteId][
                "record_control-equipments_schedule_manage"
              ].includes(tableData.rental_company)*/
            )) && (
            <>
              {(status === "受入前" ||
                status === "受入済" || status === "予約中" ||
                status === "使用中") && (
                <CustomButton
                  {...defaultButtonProps}
                  text="使用予定登録"
                  disabled={loader}
                  click={() =>
                    navigate(`/eq/delivery-schedule/${equipmentId}`, {
                      state: {
                        pageTitle:
                          tableData.equipment_common_name || tableData.name,
                        category: tableData.category,
                        onsite_manage_no: tableData.management_no,
                        siteId: siteId,
                      },
                    })
                  }
                />
              )}
            </>
          )}
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes("start_use_regist") &&
              settings[siteId] 
              /*&&
              settings[siteId]["record_control-equipments_schedule_manage"] &&
              settings[siteId][
                "record_control-equipments_schedule_manage"
              ].includes(tableData.use_company)*/
            )) && (
            <>
              {status === "受入済" || status === "予約中" &&
                isScheduleRegisteredToday &&
                !isAlreadyUseScheduleEnd && (
                  <CustomButton
                    {...defaultButtonProps}
                    text="使用開始登録"
                    click={() =>
                      navigate(`/eq/use/start/${equipmentId}`, {
                        state: {
                          pageTitle:
                            tableData.equipment_common_name || tableData.name,
                        },
                      })
                    }
                  />
                )}
            </>
          )}
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes("end_use_regist") &&
              settings[siteId] 
              /*settings[siteId]["record_control-equipments_schedule_manage"] &&
              settings[siteId][
                "record_control-equipments_schedule_manage"
              ].includes(tableData.actual_use_company)*/
            )) && (
            <>
              {status === "使用中" && (
                <CustomButton
                  {...defaultButtonProps}
                  text="使用終了登録"
                  click={() =>
                    navigate(`/eq/use/end/${equipmentId}`, {
                      state: {
                        pageTitle:
                          tableData.equipment_common_name || tableData.name,
                      },
                    })
                  }
                />
              )}
            </>
          )}
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes(
                "inventory_regist"
              ))) && (
            <>
              {(status === "受入済" || status === "予約中" || status === "使用中") && (
                <CustomButton
                  {...defaultButtonProps}
                  text="棚卸登録"
                  disabled={loader}
                  click={() =>
                    navigate(`/eq/regist/inventory/${equipmentId}`, {
                      state: {
                        pageTitle:
                          tableData.equipment_common_name || tableData.name,
                        category: tableData.category,
                        siteId: siteId,
                      },
                    })
                  }
                />
              )}
            </>
          )}
          {(company?.admin_flag === true ||
            (settings &&
              settings[siteId]?.page_control?.includes("return_regist"))) && (
            <>
              {(status === "受入済" || status === "予約中") && (
                <CustomButton
                  {...defaultButtonProps}
                  text="返却登録"
                  disabled={loader}
                  click={() => navigate(`/eq/result-return/${equipmentId}`)}
                />
              )}
            </>
          )}
        </Flex>
        <View className="custom-table">
          {hasError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          ) : null}
          {!loader ? (
            <>
              <Flex gap={{ base: "0", medium: "15px" }} direction={{ base: "column", medium: "row" }}>
                <Table
                  width={{
                    base: "100%",
                    medium: "60%",
                  }}
                >
                  <TableBody>
                    <TableRow className="eq-index--header">
                      <TableCell ref={tdRef} colSpan={2}>
                        <Flex wrap={{'base': 'wrap', 'medium': 'no-wrap'}} alignItems={'center'}>
                          <View as="div" width={{'base': '100%', 'medium': '118px'}}>
                            <View as="span" className="eq-index--sub-title" display={'inline-block'}>
                              {tableData.category}
                            </View>
                          </View>
                          <Heading level={5} flex={1}>
                              {tableData.management_no}
                          </Heading>
                          <View minWidth={'110px'}>
                            <Badge
                              backgroundColor={getBadgeColor(status).bg}
                              color={getBadgeColor(status).color}
                              minWidth={"70px"}
                              textAlign={"center"}
                              display={'inline-block'}
                            >
                              {status}
                            </Badge>
                          </View>
                        </Flex>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%" border="0">
                        最終使用場所
                      </TableCell>
                      <TableCell border="0">
                        {/*tableData.person_in_charge*/}
                        {tableData.location}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        使用業者
                      </TableCell>
                      <TableCell>
                      {status === "使用中"
                            ? tableData.use_company
                            : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        レンタル会社
                      </TableCell>
                      <TableCell>{tableData.company}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        レンタル会社商品名
                      </TableCell>
                      <TableCell>{tableData.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        数量
                      </TableCell>
                      <TableCell>{tableData.quantity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        返却予定日
                      </TableCell>
                      <TableCell>{tableData.return_schedule}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        発注担当者
                      </TableCell>
                      <TableCell>{tableData.person_in_charge}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        稼働状況
                      </TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        総稼働時間(受入時稼働時間)
                      </TableCell>
                      <TableCell>{tableData.total_hours}({tableData.start_hours})時間</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        最終棚卸日
                      </TableCell>
                      <TableCell>{tableData.inventory_update}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        備考
                      </TableCell>
                      <TableCell>{tableData.note}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={700} width="50%">
                        最終更新日
                      </TableCell>
                      <TableCell>{tableData.last_update}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <View
                  width={{
                    base: "100%",
                    medium: "40%",
                  }}
                >
                  <View
                    role="tablist"
                    className="amplify-tabs__list eq-amplify-tabs__list"
                    height={`${tdHeight}px`}
                  >
                    <button
                      role="tab"
                      id="Tab1-tab"
                      aria-selected="true"
                      aria-controls="Tab1-panel"
                      className="amplify-tabs__item amplify-tabs__item--active"
                    >
                      機材情報
                    </button>
                    <button
                      role="tab"
                      id="Tab2-tab"
                      aria-selected="false"
                      aria-controls="Tab2-panel"
                      className="amplify-tabs__item"
                      onClick={() => navigateToEqSchedule()}
                    >
                      スケジュール<br />（予定/実績）
                    </button>
                  </View>
                  <div
                    role="tabpanel"
                    id="Tab1-panel"
                    aria-labelledby="Tab1-tab"
                    className="amplify-tabs__panel amplify-tabs__panel--active"
                  >
                    <div className="amplify-card eq-index--carousel-item">
                      {/* { images.map((item: any, index: number) => {
                        return (
                          <img src={ imageUrl + item.original } alt={ item } key={index} />
                        )
                      }) } */}
                      <ImageGallery
                        items={images}
                        showBullets
                        showNav={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                      />
                    </div>
                  </div>
                </View>
              </Flex>
              <Flex marginTop="20px" className="hide-from-print">
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[siteId]?.page_control?.includes(
                      "equipment_edit"
                    ))) && (
                  <CustomButton
                    {...defaultButtonProps}
                    text="編集"
                    type="primary"
                    iconName="pen"
                    click={() => navigate(`/eq/edit/${equipmentId}`)}
                  />
                )}
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[siteId]?.page_control?.includes(
                      "equipment_delete"
                    ))) && (
                  <CustomButton
                    {...defaultButtonProps}
                    text="削除"
                    type="default"
                    iconName="delete"
                    click={openModal}
                  />
                )}
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
      </Flex>
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => closeActions()}
        textHeading={`法人「${tableData.name}」の情報を削除します。よろしいですか？`}
        textOperation="この操作は取り消せません。本当に削除しますか？"
      >
        <Flex justifyContent="center" margin={`0 auto`}>
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            type="bordered-transparent"
            click={() => handleCancel()}
          />
          <CustomButton
            {...defaultButtonProps}
            text="削除"
            size="xs"
            width="small"
            iconName={disableButton ? "loader" : ""}
            iconPosition="right"
            disabled={disableButton}
            click={() => handleDelete()}
          />
        </Flex>
      </CommonModal>
    </PageContent>
  );
}
