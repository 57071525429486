import React from "react";

export default function PlusIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H36V20C36 27.5425 36 31.3137 33.6569 33.6569C31.3137 36 27.5425 36 20 36H0V0Z" fill="#848DA3"/>
      <path d="M12 18H24" stroke="#DCE4E8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18 24V12" stroke="#DCE4E8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  );
}
