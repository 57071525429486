import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import SelectIcon from "../../../components/icons/select-icon";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  SelectField,
  Text
} from "@aws-amplify/ui-react";

import { EqApi } from "../../../api/eq";
import { SiteApi } from "../../../api/site";
import { IReturnBulk } from "../../../types/IEq";
import convertStatus from "../../../utils/geStatus";
import formatDate from "../../../utils/formatDate";
import getBadgeColor from "../../../utils/getBadgeColor";
import { AuthorityContext } from '../../../App'

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function ReturnBulk() {
  const api = EqApi();
  const siteApi = SiteApi()
  const navigate = useNavigate();
  const { company, username }: any = useContext(AuthorityContext)
  const { equipmentId } = useParams()
  const location = useLocation();
  const { state } = location
  const pageTitle = state?.pageTitle || ''
  const userId = localStorage.getItem('username') || ''
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [status, setStatus] = useState('')
  const currentDate = new Date().toISOString().replace('T',' ')
  const [disableButton, setDisableButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasFormError, setHasFormError] = useState(false)
  const [locationList, setLocationList] = useState([] as any)
  const [eqConsLoc, setEqConsLoc] = useState('')
  const [payload, setPayload] = useState<IReturnBulk>({
    result_return_schedule: formatDate(null, currentDate),
    result_return_schedule_time: formatDate('time',currentDate),
    result_return_user_id: userId,
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/eq/list', label: '機材管理' },
    { href: '/eq/schedule', label: '一括登録' },
    { href: '', label: '返却登録' },
  ]

  const openModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = 'hidden';
    document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
    setIsModalOpen(true)
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    
    const createPayload = {
      ...payload,
      equipment_id: state?.equipmentList?.map((element: any) => element.equipment_id),
      result_return_schedule: payload.result_return_schedule,
      result_return_schedule_time: formatDate('time', payload.result_return_schedule),
      result_return_user_id: userId
    }
    
    try {
      const { data } = await api.registerEqReturnBulk(createPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        openModal()
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch(err) {
      console.log(err)
    }

    closeActions()
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleCancel = () => {
    closeActions()
    setIsModalOpen(false);
    navigate(`/eq/schedule`)
  };

  // const getStatus = async () => {
  //   const { data } = await api.getEqStatus(equipmentId ?? '')
  //   try {
  //     if(data?.success) {
  //       const stat = convertStatus(data?.data?.status)
  //       setStatus(stat)
  //     } else {
  //       const stat = convertStatus('in_use')
  //       setStatus(stat)
  //     }
  //   } catch(err) {
  //     return err
  //   }   
  // }

  // const getLocationList = async (id: any) => {
  //   // setLoader(true);
  //   const { data } = await siteApi.getDetail(id);
  //   try {
  //     if (data?.success) {
  //       setLocationList([...data?.data?.site_inventory_locations])
  //     } else {
  //       setLocationList([])
  //     }
  //   } catch(err) {
  //     return err
  //   }
  // };

  // const getSiteDetail = async () => {
  //   // @ts-ignore
  //   const { data } = await api.getDetail(equipmentId);
  //   if (data?.success) {
  //     setEqConsLoc(data?.data?.site_construction_location_name)
  //   }
  //   console.log(data?.data)
  // };

  useEffect(() => {
    // getLocationList(state?.siteId)
    // getSiteDetail()
    // getStatus()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(!eqConsLoc && locationList?.length > 0){
      handleChange('site_construction_location_name', locationList[0].name);
    }
  }, [locationList,eqConsLoc])

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  
  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          <Flex direction={"column"} gap={10}>
            {state?.equipmentList.map((item: any, idx: number) => {
              return (
                <Flex key={`${item.equipment_category1_name}-${idx}`} justifyContent={"space-between"} alignItems={"end"}>
                  <View>
                    <Flex wrap="wrap">
                      <View
                        border={`1px solid #757575`}
                        padding={"5px 20px"}
                        width={"fit"}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight="20px"
                        letterSpacing="0.17px"
                      >
                        {item.equipment_category1_name}
                      </View>
                      <View fontWeight={700} fontSize={24} lineHeight="29px">
                        {item.equipment_name}
                      </View>
                      {/* <View fontWeight={700} fontSize={24} lineHeight="29px">
                  <Badge
                    backgroundColor={
                      status === "受入前" ? "#2196F3" : "hsl(210, 5%, 94%)"
                    }
                    color={status === "受入前" ? "#ffffff" : "#000000"}
                  >
                    {status}
                  </Badge>
                </View> */}
                    </Flex>
                  </View>
                  <View
                    fontWeight={500}
                    fontSize={14}
                    lineHeight="20px"
                    letterSpacing="0.17px"
                  >
                    現場管理No.：{item.onsite_manage_no}
                  </View>
                </Flex>
              );
            })}
          </Flex>
          <Divider orientation="horizontal"  marginBottom={20} marginTop={15} />
          { errorMessage !== '' || hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          <Flex>
            <TextField
              label="返却日"
              type="date"
              value={payload.result_return_schedule}
              disabled
              //onChange={(event) => handleChange('inventory_datetime', event.target.value)}
            />
            <TextField
              label="返却時間"
              type="time"
              value={payload.result_return_schedule_time}
              disabled
              //onChange={(event) => handleChange('schedule_time', event.target.value)}
            />
          </Flex>
          <TextField
            label="返却担当者"
            value={username || ''}
            disabled
          />
          <Flex
              justifyContent="flex-end"
              marginTop="1rem"
              alignItems={`center`}
              direction={{
                base: 'column',
                medium: 'row'
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text='返却登録'
              size='medium'
              width='medium'
              borderRadius='large'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={() => handleSubmit()}
            />
            <CustomButton
              {...defaultButtonProps}
              text='キャンセル'
              type='bordered-transparent'
              click={showCancelModal}
            />
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => {
                closeActions();
                navigate(`/eq/schedule`);
              }}
              icon="icon"
              textHeading="棚卸しを確定しました"
              textsmall="機材の使用状況を「返却済」に更新しました。"
              periodUse={`返却日時: ${payload.result_return_schedule} ${payload.result_return_schedule_time}`}
              user={`返却担当者: ${username || ''}`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='返却登録'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  click={() => {
                    closeActions();
                    navigate(`/eq/schedule`);
                  }}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄してスケジュールに戻りますか?"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='編集へ戻る'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
                <CustomButton
                  { ...defaultButtonProps }
                  text='破棄'
                  size='xs'
                  width='small'
                  click={handleCancel}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}