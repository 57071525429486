import FetchHeader from "../../utils/fetchHeader";

export const HelpApi = () => {
  const downloadManual = async () => {
    const response = await FetchHeader("/manual/download?filename=ConPath-D_manual_v1.0.pdf", "GET");
    return response;
  };

  return {
    downloadManual,
  };
};
