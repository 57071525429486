import React, { useEffect, useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  Text,
  View,
  Flex,
  Loader
} from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";

// api
import { UserApi } from "../../api/user";

import { IAccountEdit } from "../../types/IAccount";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function AccountEditEmailPage() {
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const { userId } = useParams()
  const api = UserApi();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [emailError, seEmailError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [payload, setPayload] = useState<IAccountEdit>({
    company_id: '',
    remarks: '',
    user_name: '',
    user_phone_number: '',
    user_email: ''
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/account/0001', label: 'アカウント' },
    { href: '', label: 'アカウント編集' },
  ]

  const openModal = () => {
    setNameError(() => {
      return payload.user_name === '' ? true : false
    })
    setNumberError(() => {
      return payload.user_phone_number === '' ? true : false
    })
    seEmailError(() => {
      return payload.user_email === '' ? true : false
    })

    if(payload.user_name !== '' && payload.user_phone_number !== '' && payload.user_email !== '') {
      setIsModalOpen(true)
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false)
    }
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    try {
      // @ts-ignore
      const { data } = await api.updateUser(userId, payload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        navigate(`/account/${ userId }`)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch(err) {
      console.log(err)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  useEffect(() => {
    const getDetail = async () => {
      setLoader(true);
      // @ts-ignore
      const { data, status } = await api.getDetail(userId);
      if (status === 200) {
        setLoader(false);
        setFetchError(false);
        setPayload({
          company_id: data?.data?.company_id,
          remarks: data?.data?.remarks,
          user_name: data?.data?.user_name,
          user_phone_number: data?.data?.user_phone_number,
          user_email: data?.data?.user_email,
        });
      } else {
        setFetchError(true);
      }
    };
    getDetail();
    // eslint-disable-next-line
  }, []);


  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={userId ?? pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { errorMessage !== '' || hasFormError ?
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          {!loader ? (
            <>
              <View as="p" fontWeight={'500'}>※ユーザーID、所属法人、所属現場は変更できません。</View>
              <View as="p" fontWeight={'500'} marginBottom={'20px'}>※パスワードを変更したい場合は、ログイン画面「パスワードを忘れた方はこちら」より変更をお願いします。</View>
              <TextField
                label="名前"
                placeholder="お名前を入力してください"
                value={payload.user_name}
                onChange={(event) => handleChange('user_name', event.target.value)}
                isRequired
                className={ nameError ? `required input-error` : 'required' }
              />
              <TextField
                label="電話番号"
                placeholder="電話番号を入力してください"
                value={payload.user_phone_number}
                onChange={(event) => handleChange('user_phone_number', event.target.value)}
                className={ numberError ? `required input-error` : 'required' }
                isRequired
              />
              <TextField
                label="メールアドレス"
                placeholder="example@diace.co.jp"
                value={payload.user_email}
                // onChange={(event) => handleChange('user_email', event.target.value)}
                type="email"
                isRequired
                // className={ emailError ? `required input-error` : 'required' }
                disabled
              />
              <Flex
                  justifyContent="flex-end"
                  marginTop="1rem"
                  alignItems={`center`}
                  direction={{
                    base: 'column',
                    medium: 'row'
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text='アカウント情報を更新'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  click={openModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text='キャンセル'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
        <CommonModal
          isOpen={isModalOpen}
          icon="icon"
          onClose={() => setIsModalOpen(false)}
          textHeading="アカウント情報を更新します。よろしいですか？"
        >
          <View margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='アカウント情報を更新する'
              size='medium'
              width='medium'
              borderRadius='large'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={() => handleSubmit()}
            />
          </View>
        </CommonModal>

        {/* cancel modal */}
        <CommonModal
          isOpen={isCancelModal}
          textHeading="入力内容を破棄して詳細に戻りますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='編集へ戻る'
              type='bordered-transparent'
              click={showCancelModal}
            />
            <CustomButton
              { ...defaultButtonProps }
              text='破棄'
              size='xs'
              width='small'
              click={() => navigate(`/account/${userId}`)}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
