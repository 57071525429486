import React, { useEffect, useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Text,
  Loader
} from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

import { ICompanyEdit } from "../../types/ICompany";

// api
import { CompanyApi } from "../../api/company";

// utils
import validateEmail from "../../utils/validateEmail";
import getDeviceType from "../../utils/getDeviceType";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function CompanyEditPage() {
  const isMobile = getDeviceType() === 'mobile'
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const { companyId } = useParams()
  const navigate = useNavigate();
  const api = CompanyApi()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [invoiceError, setInvoiceError] = useState(false);
  const [contactError, seContactError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [hasFormError, setHasFormError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [noteLength, setNoteLength] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [payload, setPayload] = useState<ICompanyEdit>({
    name: '',
    invoice: "",
    cinet: "",
    contact_person: "",
    category: "",
    note: ""
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/company/list', label: '法人一覧' },
    { href: `/company/${ companyId }`, label: '法人詳細' },
    { href: '', label: '法人情報編集' },
  ]

  const selectOptions = [
    { name: '元請け', value: 100 },
    { name: 'レンタル会社', value: 200 },
    { name: 'その他の会社', value: 300 },
  ]

  const openModal = () => {
    let errorMessageSet = [];
    setDisableButton(false)
    setErrorMessage('')
    
    setNameError(() => {
      return payload.name === '' ? true : false
    })
    setInvoiceError(() => {
       return payload.invoice === '' ? true : false
     })
    seContactError(() => {
      if(payload.contact_person === '') {
        return true
      } else if(!validateEmail(payload.contact_person)) {
        return true
      } else {
        return false
      }
      // return payload.contact_person === '' ? true : false
    })

    setCategoryError(payload.category === '')
    if(payload.name ===""){
      errorMessageSet.push("法人名を入力してください")
    }
    if(payload.contact_person === ""){
      errorMessageSet.push("法人担当者のメールアドレスを入力してください")
    }
    if(!validateEmail(payload.contact_person)){
      errorMessageSet.push("メールアドレスが不正です")
    }
    if(payload.category ===""){
      errorMessageSet.push("法人カテゴリーを選択してください")
    }
    setErrorMessage(errorMessageSet.join("\n"));

    if(payload.name !== '' && 
      //payload.invoice !== '' && 
      payload.contact_person !== '' && 
      validateEmail(payload.contact_person) &&
      payload.category !== ''
    ) {
      setIsModalOpen(true)
    } else {
      document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
      setIsModalOpen(false)
    }
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
    if(item === 'note') {
      setNoteLength(value.length)
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    const editPayload = {
      company_category_id: payload.category,
      company_name: payload.name,
      business_registration_number: payload.invoice,
      ci_net_number: payload.cinet,
      company_email: payload.contact_person,
      remarks: payload.note
    }

    try {
      // @ts-ignore
      const { data } = await api.editCompany(companyId, editPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        navigate(`/company/${ companyId }`)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message)
      }
    } catch(err) {
      console.log(err)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  useEffect(() => {
    const geDetails = async () => {
      setLoader(true);
      // @ts-ignore
      const { data, status } = await api.getDetail(companyId);
      if (status === 200) {
        setLoader(false);
        setFetchError(false);
        setCompanyName(data?.data?.company_name)
        setPayload({
          name: data?.data?.company_name,
          invoice: data?.data?.business_registration_number,
          cinet: data?.data?.ci_net_number,
          contact_person: data?.data?.company_email,
          category: data?.data?.company_category_id,
          note: data?.data?.remarks
        });
        setNoteLength(data?.data?.remarks?.length)
      } else {
        setFetchError(true);
      }
    };
    geDetails();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={companyName ?? pageTitle}
    >
      <View className="main-content">
        <View 
          as='form' 
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { errorMessage !== "" || hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                {errorMessage?.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
              </Flex>
            ) : null
          }
          {!loader ? (
            <>
              <TextField
                label="法人名"
                placeholder="法人名を入力してください"
                value={payload.name}
                onChange={(event) => handleChange('name', event.target.value)}
                isRequired
                className={ nameError ? `required input-error` : 'required' }
              />
              <TextField
                label="インボイス番号"
                placeholder="0000000000000"
                value={payload.invoice}
                onChange={(event) => handleChange('invoice', event.target.value)}
                isRequired
                //disabled
                //className={ invoiceError ? `required input-error` : 'required' }
              />
              <TextField
                label="CI-NET番号"
                placeholder="000000"
                value={payload.cinet}
                onChange={(event) => handleChange('cinet', event.target.value)}
                isRequired
              />
              <TextField
                label="メールアドレス"
                placeholder="法人担当者のメールアドレスを入力してください"
                value={payload.contact_person}
                onChange={(event) => handleChange('contact_person', event.target.value)}
                className={ contactError ? `required input-error` : 'required' }
              />
              <SelectField
                label="法人カテゴリー"
                placeholder="選択してください"
                value={payload.category}
                onChange={(event) => handleChange('category', event.target.value)}
                icon={<SelectIcon />}
                className={`required ${categoryError ? 'input-error' : ''} ${payload.category === '' ? 'no-value' : ''}`}
              >
                { 
                  selectOptions.map((_item, index) => {
                    return (
                      <option value={_item.value} key={index}>{ _item.name }</option>
                    )
                  }) 
                }
              </SelectField>
              <TextAreaField
                label="備考"
                placeholder="備考を入力してください"
                value={ payload.note }
                onChange={(event) => handleChange('note', event.target.value)}
                maxLength={100}
              />
              <Text className='textarea-length' as='span'>{ noteLength }/100</Text>
              <Flex
                justifyContent='flex-end'
                marginTop="1rem"
                alignItems={`center`}
                direction={{
                  base: 'column',
                  medium: 'row'
              }}
            >
              <CustomButton
                {...defaultButtonProps}
                text='法人情報を更新'
                size='medium'
                width={'medium'}
                borderRadius={'large'}
                click={openModal}
              />
              <CustomButton
                {...defaultButtonProps}
                text='キャンセル'
                borderRadius={'large'}
                type={'transparent'}
                size='medium'
                click={showCancelModal}
              />
            </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
        {/* submit modal */}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          icon="icon"
          textHeading="法人情報を更新します。よろしいですか？"
        >
          <View margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='法人情報を更新する'
              size='medium'
              width='medium'
              borderRadius='large'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={handleSubmit}
            />
          </View>
        </CommonModal>

        {/* cancel modal */}
        <CommonModal
          isOpen={isCancelModal}
          onClose={() => setIsModalOpen(false)}
          textHeading="入力内容を破棄して一覧へ戻りますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='編集へ戻る'
              type='bordered-transparent'
              click={showCancelModal}
            />
            <CustomButton
              { ...defaultButtonProps }
              text='破棄'
              size='xs'
              width='small'
              click={() => navigate(`/company/${ companyId }`)}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
