import React, { memo } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import CustomButton from "../../../../../components/custom-button";

const defaultButtonProps = {
  text: "",
  type: "icon",
  iconPosition: "left",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default memo(function CalendarToolBar({
  goToBack,
  goToNext,
  label,
}: {
  goToBack: () => any;
  goToNext: () => any;
  label: string;
}) {
  return (
    <Flex className="custom-toolbar">
      <CustomButton
        {...defaultButtonProps}
        type="icon"
        iconName="arrow-left-default"
        className="custom-button"
        click={goToBack}
      />
      <Text className="toolbar-label">{`${label} `}</Text>
      <CustomButton
        {...defaultButtonProps}
        type="icon"
        iconName="arrow-right-default"
        click={goToNext}
      />
    </Flex>
  );
});
