import React, { useContext, useEffect, useState } from "react";
import PageContent from "../../../components/pageContent";
import ScheduleRegisterForm from "./component/schedule-register-form";
import { useLocation, useParams } from "react-router-dom";
import { getEquipUsageRegister } from "../../../api/eq";
import { IEqRegister } from "../../../types/IEq";
import { AuthorityContext } from "../../../App";
import convertStatus from "../../../utils/geStatus";

const breadCrumbsItems = [
  { href: "/", label: "ホーム" },
  { href: "", label: "機材一覧" },
  { href: "", label: "機材詳細" },
];

export default function ScheduleRegisterPage() {
  const location = useLocation();
  const { state } = location;
  const { equipmentId } = useParams();
  const { company }: any = useContext(AuthorityContext);
  const stateDefaultDate = state?.defaultDate;

  const pageTitle = localStorage.getItem("currentPage") ?? "";

  const [usageRegState, setUsageRegState] = useState({
    status: "",
    locationList: [] as any,
    companyList: [] as any,
  });

  const [payload, setPayload] = useState<IEqRegister>({
    start_date_use: "2024/01/01",
    end_date_use: "2024/01/01",
    user_id: "A山 A太郎",
    user_name: "A山 A太郎",
    use_location: "",
    company_id: "",
  });
  console.log({ state });

  const fetchData = async () => {
    try {
      console.log(state);
      const { getEqStatusData, getLocations, getCompanyListData } =
        await getEquipUsageRegister(equipmentId, state?.siteId);
      // console.log({ getEqStatusData, getCompanyListData, getLocations });
      const isGettingDataSuccess =
        getEqStatusData?.success &&
        getCompanyListData?.status === 200 &&
        getLocations?.success;
      console.log({ getEqStatusData, getLocations, getCompanyListData });
      let convertedStatus;
      if (isGettingDataSuccess)
        convertedStatus = convertStatus(getEqStatusData?.data?.status);
      else {
        convertedStatus = convertStatus("in_use");
      }

      // setUsageRegState({
      //   status: convertedStatus,
      //   locationList: [...getListData?.data],
      //   companyList: [...getDetailData?.data],
      // });
    } catch (error) {
      return error;
    }
  };

  const setPayloadData = () => {
    const convert = stateDefaultDate
      ?.replace("年", "/")
      .replace("月", "/")
      .replace("日", "");
    const format = convert?.split("/");
    const checkMonth = format[1].length === 1 ? "0" + format[1] : format[1];
    const checkDate = format[2].length === 1 ? "0" + format[2] : format[2];
    setPayload({
      ...payload,
      start_date_use: format[0] + "-" + checkMonth + "-" + checkDate,
      end_date_use: format[0] + "-" + checkMonth + "-" + checkDate,
    });
  };

  useEffect(() => {
    // setPayloadData();
    fetchData();
  }, []);

  useEffect(() => {
    setPayload((prevState) => ({
      ...prevState,
      company_id: company?.company_id,
    }));
  }, [company]);

  useEffect(() => {}, [state]);
  console.log({ stateDefaultDate });
  // console.log({ usageRegState, payload });
  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={state?.pageTitle || pageTitle}
    >
      <ScheduleRegisterForm />
    </PageContent>
  );
}
