import React, { useEffect, useState, useContext } from "react";

import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  SelectField,
} from "@aws-amplify/ui-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SelectIcon from "../../../components/icons/select-icon";

import { IEqRegister } from "../../../types/IEq";
import convertStatus from "../../../utils/geStatus";
// api
import { EqApi } from "../../../api/eq";
import { CompanyApi } from "../../../api/company";
import { SiteApi } from "../../../api/site";
import { AuthorityContext } from "../../../App";
import { UserApi } from "../../../api/user";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EqScheduleBulkRegisterPage() {
  const api = EqApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const userApi = UserApi();
  //const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  //const { equipmentIds } = useParams();
  const location = useLocation();
  const { company, settings }: any = useContext(AuthorityContext);
  const { state } = location;
  const userName = localStorage.getItem("username") || "null";
  const equipmentList: any[] = state?.equipmentList;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  //  const [status, setStatus] = useState("");
  const [locationList, setLocationList] = useState([] as any);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState([] as any);
  const [hasFormError, setHasFormError] = useState(false);
  const [payload, setPayload] = useState<IEqRegister>({
    start_date_use: state?.defaultDateStart ?? "",
    end_date_use: state?.defaultDateEnd ?? "",
    user_id: "A山 A太郎",
    user_name: "A山 A太郎",
    use_location: "",
    company_id: company?.company_id,
  });
  const [companyList, setCompanyList] = useState([] as any);
  const [filteredCompanyList, setFilteredCompanyList] = useState([] as any);
  const [userList, setUserList] = useState([] as any);
  const [filteredUserList, setFilteredUserList] = useState([] as any);
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    null as string | null
  );

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "/eq/schedule", label: "一括登録" },
    { href: "", label: "使用予定登録" },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
  };

  const handleChangeAddMapToPayload = (mapToAdd: any) => {
    setPayload({ ...payload, ...mapToAdd });
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage([]);

    const createPayload = {
      equipment_id: equipmentList?.map((e) => e.equipment_id).join("___"),
      delivery_schedule: payload.start_date_use,
      return_schedule: payload.end_date_use,
      company_id: payload.company_id,
      user_id: payload.user_id,
      use_location: payload.use_location || (locationList.length > 0 ? locationList[0].name : null),
    };
    //local validation

    if (
      !createPayload.delivery_schedule ||
      !createPayload.return_schedule ||
      !createPayload.user_id ||
      !createPayload.company_id ||
      !createPayload.use_location
    ) {
      let errorMessage = "";
      errorMessage += createPayload.delivery_schedule ? "" : "使用開始日、";
      errorMessage += createPayload.return_schedule ? "" : "使用終了日、";
      errorMessage += createPayload.user_id ? "" : "使用担当者、";
      errorMessage += createPayload.company_id ? "" : "使用業者、";
      errorMessage += createPayload.use_location ? "" : "使用場所、";
      errorMessage = errorMessage.slice(0, -1);
      errorMessage += "を入力してください";
      setHasFormError(true);
      setErrorMessage([errorMessage]);
      setDisableButton(false);
      setIsModalOpen(false);
      return;
    }

    if (
      new Date(createPayload.return_schedule) <
      new Date(createPayload.delivery_schedule)
    ) {
      setHasFormError(true);
      setErrorMessage(["使用開始日は使用終了日以前の日付を設定してください"]);
      setDisableButton(false);
      setIsModalOpen(false);
      return;
    }
    try {
      // @ts-ignore
      const { data } = await api.registerSchedule(
        createPayload.equipment_id,
        createPayload
      );
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage([]);
        navigate(`/eq/schedule`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);

        const errors = data?.error?.errors;
        const toArray = Object.keys(errors).map((key) => [errors[key]]);
        setErrorMessage([...toArray]);
      }
    } catch (err) {
      console.log(err);
      setDisableButton(false);
      setIsModalOpen(false);
      setHasFormError(true);
      setErrorMessage([
        "予期しないエラーが発生しました。システム管理者に問い合わせてください",
      ]);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate("/eq/schedule");
  };

  //  const getStatus = async () => {
  //    const { data } = await api.getEqStatus(equipmentId ?? "");
  //    try {
  //      if (data?.success) {
  //        const stat = convertStatus(data?.data?.status);
  //        setStatus(stat);
  //      } else {
  //        const stat = convertStatus("in_use");
  //        setStatus(stat);
  //      }
  //    } catch (err) {
  //      return err;
  //    }
  //  };

  const getCompanyList = async () => {
    const { data, status } = await companyApi.getList();
    if (status === 200) {
      setCompanyList([...data?.data]);
    } else {
      console.log(data);
    }
  };

  const getLocationList = async () => {
    try {
      const { data } = await siteApi.getDetail(state?.siteId);
      if (data?.success) {
        setLocationList([...data?.data?.site_construction_locations]);
      } else {
        setLocationList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserList = async () => {
    const { data, status } = await userApi.getList(1, false, 10000, state?.siteId);
    if (status === 200) {
      const mappedList = data?.data?.users?.map((item: any) => {
        return {
          user_id: item?.user_id,
          user_name: item?.user_name,
          company_id: item?.company_id,
        };
      });
      setUserList(mappedList);
    } else {
      console.log("fetch error: usersList");
    }
  };

  useEffect(() => {
    if (!state || state.keys?.length) {
      navigate(`/eq/schedule`);
    }
    //TODO get from state
    //getStatus();
    getCompanyList();
    getLocationList();
    setSelectedCompanyId(payload.company_id);
    getUserList();
    // eslint-disable-next-line
  }, []);

  //useEffect(() => {
  //  setPayload((prevState) => ({
  //    ...prevState,
  //    company_id: company?.company_id,
  //  }));
  //}, [company]);

  useEffect(() => {
    let filteredCompanyListToSet: any[] = [];
    companyList?.forEach((element: any) => {
      if (
        company?.admin_flag === true ||
        (settings &&
          state?.siteId &&
          settings[state?.siteId] &&
          settings[state.siteId]["record_control-equipments_schedule_manage"] &&
          settings[state.siteId][
            "record_control-equipments_schedule_manage"
          ].includes(element.company_id))
      ) {
        filteredCompanyListToSet.push(element);
      }
    });
    filteredCompanyListToSet = [
      ...filteredCompanyListToSet.filter(
        (e) => e.company_id === company?.company_id
      ),
      ...filteredCompanyListToSet.filter(
        (e) => e.company_id !== company?.company_id
      ),
    ];
    setFilteredCompanyList(filteredCompanyListToSet);
    if (!filteredCompanyListToSet.length) {
      handleChangeAddMapToPayload({ company_id: null, company_name: null });
    } else {
      handleChangeAddMapToPayload({
        company_id: filteredCompanyListToSet[0].company_id,
        company_name: filteredCompanyListToSet[0].company_name,
      });
    }
  }, [companyList, settings, company]);

  useEffect(() => {
    let filteredUserListToSet: any[] = [];
    userList?.forEach((element: any) => {
      if (element.company_id === selectedCompanyId ?? payload.company_id) {
        filteredUserListToSet.push(element);
      }
    });
    filteredUserListToSet = [
      ...filteredUserListToSet.filter((e) => e.user_id === userName),
      ...filteredUserListToSet.filter((e) => e.user_id !== userName),
    ];
    setFilteredUserList(filteredUserListToSet);
    if (!filteredUserListToSet.length) {
      handleChangeAddMapToPayload({ user_id: null, user_name: null });
    } else {
      handleChangeAddMapToPayload({
        user_id: filteredUserListToSet[0].user_id,
        user_name: filteredUserListToSet[0].user_name,
      });
    }
  }, [userList, selectedCompanyId]);

  useEffect(() => {}, [state]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title="使用予定登録" //{state?.pageTitle || pageTitle}
    >
      <View className="main-content">
        {hasFormError ? (
          <Flex className="signin-error-message">
            <View className="error-icon">
              <ErrorSmallIcon fill="#D32F2F" />
            </View>

            {errorMessage.map((message: string) => (
              <View>{message}</View>
            ))}
          </Flex>
        ) : null}
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          <Flex direction={"column"} gap={10}>
            {equipmentList.map((item: any, idx: number) => {
              return (
                <Flex justifyContent={"space-between"} alignItems={"end"}>
                  <View>
                    <Flex wrap="wrap">
                      <View
                        border={`1px solid #757575`}
                        padding={"5px 20px"}
                        width={"fit"}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight="20px"
                        letterSpacing="0.17px"
                      >
                        {item.equipment_category1_name}
                      </View>
                      <View fontWeight={700} fontSize={24} lineHeight="29px">
                        {item.equipment_name}
                      </View>
                      {/* <View fontWeight={700} fontSize={24} lineHeight="29px">
                  <Badge
                    backgroundColor={
                      status === "受入前" ? "#2196F3" : "hsl(210, 5%, 94%)"
                    }
                    color={status === "受入前" ? "#ffffff" : "#000000"}
                  >
                    {status}
                  </Badge>
                </View> */}
                    </Flex>
                  </View>
                  <View
                    fontWeight={500}
                    fontSize={14}
                    lineHeight="20px"
                    letterSpacing="0.17px"
                  >
                    現場管理No.：{item.onsite_manage_no}
                  </View>
                </Flex>
              );
            })}
          </Flex>
          <Divider orientation="horizontal" marginBottom={20} marginTop={15} />
          <Flex>
            <TextField
              label="使用開始日（予定）"
              width={"50%"}
              type="date"
              value={payload.start_date_use}
              onChange={(event) =>
                handleChange("start_date_use", event.target.value)
              }
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
            <TextField
              label="使用終了日（予定）"
              width={"50%"}
              type="date"
              value={payload.end_date_use}
              onChange={(event) =>
                handleChange("end_date_use", event.target.value)
              }
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // InputProps={{
              //   inputProps: {
              //     min: '1000-01-01',
              //     max: '9999-12-31',
              //   }
              // }}
            />
          </Flex>
          <SelectField
            label="使用業者（予定）"
            placeholder={filteredCompanyList.length ? "" : "業者が存在しません"}
            disabled={filteredCompanyList.length ? false : true}
            onChange={(event) => {
              handleChangeAddMapToPayload({
                company_id: event.target.value,
                company_name: event.target.selectedOptions[0].label,
              });
              setSelectedCompanyId(event.target.value);
            }}
            icon={<SelectIcon />}
            //value={payload.company_id}
            value={payload.company_id}
          >
            {filteredCompanyList?.map((item: any, index: any) => {
              return (
                <option value={item?.company_id} key={index}>
                  {item?.company_name}
                </option>
              );
            })}
          </SelectField>
          {/*<SelectField
            label="使用業者（予定）"
            onChange={(event) => handleChange("company_id", event.target.value)}
            icon={<SelectIcon />}
            value={payload.company_id}
          >
            {companyList?.map((item: any, index: any) => {
              return (
                <option value={item?.company_id} key={index}>
                  {item?.company_name}
                </option>
              );
            })}
          </SelectField>*/}
          <SelectField
            label="使用担当者（予定）"
            placeholder={
              filteredUserList.length ? "" : "ユーザーが存在しません"
            }
            disabled={filteredUserList.length ? false : true}
            value={payload.user_id}
            onChange={(event) => {
              handleChangeAddMapToPayload({
                user_id: event.target.value,
                user_name: event.target.selectedOptions[0].label,
              });
            }}
            icon={<SelectIcon />}
          >
            {filteredUserList?.map((item: any, index: any) => {
              return (
                <option value={item?.user_id} key={index}>
                  {item?.user_name}
                </option>
              );
            })}
          </SelectField>

          <SelectField
            label="使用場所（予定）"
            value={payload.use_location}
            onChange={(event) =>
              handleChange("use_location", event.target.value)
            }
            icon={<SelectIcon color={"#929292"} />}
          >
            {locationList?.map((item: any, index: any) => {
              return (
                <option value={item?.name} key={index}>
                  {item?.name}
                </option>
              );
            })}
          </SelectField>
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="登録"
              size="medium"
              width="medium"
              borderRadius="large"
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={showCancelModal}
            />
            <CommonModal
              isOpen={isModalOpen}
              onClose={closeModal}
              icon="icon"
              textHeading="機材使用予定を登録します。よろしいですか？"
            >
              <View margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="機材スケジュールを登録する"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleSubmit()}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄してスケジュールに戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="xs"
                  width="small"
                  click={handleCancel}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
