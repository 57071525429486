import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import SelectIcon from "../../components/icons/select-icon";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  SelectField,
  Text
} from "@aws-amplify/ui-react";

import { EqApi } from "../../api/eq";
import { SiteApi } from "../../api/site";
import { IEqRegistInventory } from "../../types/IEq";
import convertStatus from "../../utils/geStatus";
import formatDate from "../../utils/formatDate";
import getBadgeColor from "../../utils/getBadgeColor";
import { AuthorityContext } from '../../App'
import { notDeepStrictEqual } from "assert";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function EqRegistInventoryPage() {
  const api = EqApi();
  const siteApi = SiteApi()
  const navigate = useNavigate();
  const { company, username }: any = useContext(AuthorityContext)
  const { equipmentId } = useParams()
  const location = useLocation();
  const { state } = location
  const pageTitle = state?.pageTitle || ''
  const userId = localStorage.getItem('username') || ''
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [status, setStatus] = useState('')
  const currentDate = new Date().toISOString().replace('T',' ')
  const [disableButton, setDisableButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasFormError, setHasFormError] = useState(false)
  const [locationList, setLocationList] = useState([] as any)
  //const [eqConsLoc, setEqConsLoc] = useState('')
  const [payload, setPayload] = useState<IEqRegistInventory>({
    inventory_datetime: formatDate(null, currentDate),
    user_company_id: '',
    user_id: userId,
    site_construction_location_name: ''
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/eq/list', label: '機材一覧' },
    { href: '', label: '機材詳細' },
    { href: '', label: '棚卸登録' },
  ]

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const openModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = 'hidden';
    document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
    setIsModalOpen(true)
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    const createPayload = {
      ...payload,
      inventory_datetime: payload.inventory_datetime + ' ' + formatDate('time', currentDate),
      equipment_id: equipmentId,
      user_company_id: company?.company_id,
      site_construction_location_name: payload.site_construction_location_name ?? null
    }
    if(!createPayload.site_construction_location_name){
      setErrorMessage("棚卸場所を設定してください。棚卸場所が選択できない場合には、現場管理者に棚卸場所の設定を依頼してください");
      setDisableButton(false)
      return;
    }
    console.log(createPayload)

    console.log(locationList)

      try {
        // @ts-ignore
        const { data } = await api.registerEqInventory(equipmentId, createPayload)
        if(data?.success) {
          setDisableButton(false)
          setIsModalOpen(false)
          setHasFormError(false)
          setErrorMessage('')
          openModal()
        } else {
          setDisableButton(false)
          setIsModalOpen(false)
          setHasFormError(true)
          setErrorMessage(data?.error?.message ?? data?.message)
        }
      } catch(err) {
        console.log(err)
      }
  
      closeActions()
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleCancel = () => {
    closeActions()
    setIsModalOpen(false);
    navigate(`/eq/schedule/${ equipmentId ?? '' }`)
  };

  const getStatus = async () => {
    const { data } = await api.getEqStatus(equipmentId ?? '')
    try {
      if(data?.success) {
        const stat = convertStatus(data?.data?.status)
        setStatus(stat)
      } else {
        const stat = convertStatus('in_use')
        setStatus(stat)
      }
    } catch(err) {
      return err
    }   
  }

  const getLocationList = async (id: any) => {
    // setLoader(true);
    const { data } = await siteApi.getDetail(id);
    try {
      if (data?.success) {
        setLocationList([...data?.data?.site_inventory_locations])
      } else {
        setLocationList([])
      }
    } catch(err) {
      return err
    }
  };

  const getSiteDetail = async () => {
    // @ts-ignore
    const { data } = await api.getDetail(equipmentId);
    if (data?.success) {
      //setEqConsLoc(data?.data?.site_construction_location_name)
      getLocationList(data?.data?.site_id)
    }
  };

  useEffect(() => {
    getSiteDetail()
    getStatus()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(locationList?.length > 0){
      handleChange('site_construction_location_name', locationList[0].name);
    }
  }, [locationList])


  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"end"}
          >
            <View>
              <Flex wrap="wrap">
                <View
                  border={`1px solid #757575`}
                  padding={"5px 20px"}
                  width={"fit"}
                  fontWeight={500}
                  fontSize={14}
                  lineHeight="20px"
                  letterSpacing="0.17px"
                >
                  { state?.category }
                </View>
                <View
                  fontWeight={700}
                  fontSize={24}
                  lineHeight="29px"
                >
                  { state?.pageTitle }
                </View>
                <View
                  fontWeight={700}
                  fontSize={24}
                  lineHeight="29px"
                >
                  <Badge
                    backgroundColor={getBadgeColor(status).bg}
                    color={getBadgeColor(status).color}
                  >
                    { status }
                  </Badge>
                </View>
              </Flex>
            </View>
          </Flex>
          <Divider orientation="horizontal"  marginBottom={20} marginTop={15} />
          { errorMessage !== '' || hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          <TextField
            label="棚卸日"
            type="date"
            value={payload.inventory_datetime}
            disabled
            //onChange={(event) => handleChange('inventory_datetime', event.target.value)}
          />
          <TextField
            label="所属法人"
            value={company?.company_name || ''}
            //onChange={(event) => handleChange('user_company_id', event.target.value)}
            disabled
          />
          <TextField
            label="確認者"
            value={username || ''}
            disabled
          />
          <SelectField
            label="棚卸場所"
            value={ payload.site_construction_location_name}
            onChange={(event) => handleChange('site_construction_location_name', event.target.value)}
            icon={<SelectIcon />}
          >
          {
            locationList?.map((item: any, index: any) => {
              return (
                <option value={item?.name} key={index}>{ item?.name.length > 0 ? item?.name : '-' }</option>
              )
            })
          }
          </SelectField>
          <Flex
              justifyContent="flex-end"
              marginTop="1rem"
              alignItems={`center`}
              direction={{
                base: 'column',
                medium: 'row'
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text='棚卸登録'
              size='medium'
              width='medium'
              borderRadius='large'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={() => handleSubmit()}
            />
            <CustomButton
              {...defaultButtonProps}
              text='キャンセル'
              type='bordered-transparent'
              click={showCancelModal}
            />
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => {
                closeActions();
                navigate(`/eq/schedule/${ equipmentId }`);
              }}
              icon="icon"
              textHeading="棚卸を確定しました"
              textsmall="機材の棚卸情報を更新しました。"
              periodUse={`棚卸日時：${formatDate('dot', payload.inventory_datetime)} ${formatDate('time', currentDate)}`}
              user={`確認者：${username || ''}`}
              location={`棚卸場所：${ payload.site_construction_location_name}`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='機材スケジュールを確認'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  click={() => {
                    closeActions();
                    navigate(`/eq/schedule/${ equipmentId }`);
                  }}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄してスケジュールに
              戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='編集へ戻る'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
                <CustomButton
                  { ...defaultButtonProps }
                  text='破棄'
                  size='xs'
                  width='small'
                  click={handleCancel}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
