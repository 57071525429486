
import React, { useEffect, useState } from "react";
// import {
//   signOut,
//   confirmResetPassword,
//   type ConfirmResetPasswordInput
// } from 'aws-amplify/auth';

// custom components
import CustomButton from "../../components/custom-button";

import { 
  // Authenticator,
  Text,
  Heading,
  View,
  Breadcrumbs,
  Divider,
  TextField,
  Flex
} from "@aws-amplify/ui-react";

// custom API call
import { PasswordApi } from "../../api/password";

// icons
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import '../../styles/forgot-password.scss'
import { useLocation, useNavigate } from "react-router-dom";

const defaultButtonProps = {
  text: '',
  type: 'icon',
  iconPosition: 'left',
  iconName: '',
  size: '',
  disabled: false,
  click: (): void => {}
}

export default function PasswordResetPage() {
  const navigate = useNavigate()
  const api = PasswordApi()
  const emailAddress = localStorage.getItem('email')
  const location = useLocation();
  const { state } = location;
  const [userError, setUserError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [mismatchError, setMismatchError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [hasError, setHasError] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [disableEmailButton, setDisableEmailButton] = useState(false)
  const [payload, setPayload] = useState({
    username: '',
    code: '',
    password: '',
    confirmPassword: ''
  })

  {/*const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '', label: 'パスワード再設定', current: true },
  ]*/}

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    setHasError(false)

    setUserError(() => {
      return payload.username === '' ? true : false
    })
    setCodeError(() => {
      return payload.code === '' ? true : false
    })
    setPasswordError(() => {
      return payload.password === '' ? true : false
    })
    setConfirmPasswordError(() => {
      return payload.confirmPassword === '' ? true : false
    })

    if(payload.code !== '' &&
      payload.password !== '' &&
      payload.confirmPassword !== '') {
        if(payload.password === payload.confirmPassword) {
          setMismatchError(false)
          
          const {data, status, success} = await api.doConfirmPassword({
            username: payload.username,
            code: payload.code,
            password: payload.confirmPassword
          })

          console.log(data)

          if(status === 200) {
            setDisableButton(false)
            setHasError(false)
            setErrorMessage('')
            navigate('/login')
          } else if(status === 401) {
            setDisableButton(false)
            setHasError(true)
            setErrorMessage('Unauthorized, please login.')
          } else if(status === 500){
            setDisableButton(false)
            setHasError(true)
            //ここからの処理を変えれば終わり。エラーバリデーションに応じてsetErrorMessageを出し分ける
            //'CodeMismatchException' → 認証コードが正しくありません
            //'ExpiredCodeException' → 認証コードの期限が切れています
            //'UserNotFoundException' → ユーザーIDが正しくありません
              console.log(data?.data?.message)
              if (data?.data?.message === "CodeMismatchException") {
                setErrorMessage('認証コードが正しくありません');
              } else if (data?.data?.message === "ExpiredCodeException") {
                setErrorMessage('ユーザーIDが正しくありません');
              } else if (data?.data?.message === "UserNotFoundException" || data?.data?.message === "InvalidParameterException") {
                setErrorMessage('ユーザーIDが正しくありません');
              } else if(data?.data?.message === "LimitExceededException") {
                setErrorMessage('規定回数を超えました。しばらくお待ちください');
              }
          }else {
            setDisableButton(false)
            setHasError(true)
            setErrorMessage("入力情報が間違っています")
          }
        } else {
          setDisableButton(false)
          setMismatchError(true)
        }
    } else {
      setDisableButton(false)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return null
    }
  };

  async function handleResetPassword(username: string) {
    setDisableEmailButton(true)
    setHasError(false)
    const res: any = await api.doForgotPassword({email: username})

    if(res?.status === 200) {
      setHasError(false)
      setDisableEmailButton(false)
      setErrorMessage('')
      setSuccessMessage('Emailを送信しました。確認してください')
      setTimeout(() => {
        setSuccessMessage('')
      }, 2000)
    } else {
      setHasError(true)
      setSuccessMessage('')
      setDisableEmailButton(false)
      if(res?.status === 409) {
        setErrorMessage('このEmailアドレスは存在しません')
      } else {
        setErrorMessage("入力情報が間違っています")
      }

      setTimeout(() => {
        setHasError(false)
        setErrorMessage('')
      }, 2000)
    }
  }

  {/*useEffect(() => {
    setPayload({
      ...payload,
      username: state.email || ''
    })
  }, [payload, state])*/}

  return (
    <>
      <View className='forgot-password'>
        {/* <Authenticator
          initialState="forgotPassword"
          formFields={formFields}
          components={components}
        /> */}
        <View className="forgot-pass--header">
          
          <Heading level={1}>パスワードを忘れた方へ</Heading>
          <Divider size="small" />
          <View className="forgot-pass--form-header">
            <Heading level={2}>新しいパスワードを入力してください</Heading>
            <Text margin={'20px 0'}>以下のフォームより新しいパスワードを入力し、再度ログイン画面よりログインを行なってください。</Text>
            <CustomButton
              { ...defaultButtonProps }
              text="認証コード再送"
              type="bordered-white"
              iconName={ disableEmailButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableEmailButton }
              // @ts-ignore
              click={() => handleResetPassword(state.email)}
            />
          </View>
        </View>

        <View className="main-content">
          <View as="form" className="custom-form">
            {
              successMessage !== '' ? (
                <View className="signin-error-success">
                  { successMessage }
                </View>
              ) : null
            }
            { mismatchError ? 
              (
                <Flex className="signin-error-message">
                  <View className="error-icon">
                    <ErrorSmallIcon fill='#D32F2F' />
                  </View>
                    <View>
                    パスワードが一致しませんでした。
                    </View>
                </Flex>
              ) : null
            }
            { hasError ? 
              (
                <Flex className="signin-error-message">
                  <View className="error-icon">
                    <ErrorSmallIcon fill='#D32F2F' />
                  </View>
                  <View>{ errorMessage }</View>
                </Flex>
              ) : null
            }
            <TextField 
              label="ユーザーID"
              placeholder="900000"
              value={payload.username}
              onChange={(event) => handleChange('username', event.target.value)}
              type="text"
              autoComplete = "off"
              isRequired
              className={ userError ? `required input-error` : 'required' }
            />
            <TextField 
              label="新しいパスワード"
              placeholder="8文字以上 英大小文字数字"
              value={payload.password}
              onChange={(event) => handleChange('password', event.target.value)}
              type="password"
              autoComplete="new-password"
              isRequired
              className={ passwordError ? `required input-with-note input-error` : 'required input-with-note' }
            />
            <Text className="input-note">英大文字と英小文字と数字を混ぜた８文字以上の文字列で入力してください</Text>
            <TextField 
              label="パスワード確認"
              placeholder="再度パスワードを入力してください"
              value={payload.confirmPassword}
              onChange={(event) => handleChange('confirmPassword', event.target.value)}
              type="password"
              isRequired
              autoComplete="new-password"
              className={ confirmPasswordError ? `required input-error` : 'required' }
            />
            <TextField 
              label="Code"
              placeholder=""
              value={payload.code}
              onChange={(event) => handleChange('code', event.target.value)}
              type="text"
              isRequired
              width={'300px'}
              maxWidth={'100%'}
              className={ codeError ? `required input-error` : 'required' }
            />
            <CustomButton
              { ...defaultButtonProps }
              text="パスワードを変更"
              type="primary"
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={() => handleSubmit()}
            />
          </View>
        </View>
      </View>
    </>
  );
}
