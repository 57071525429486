import React from "react";

export default function CorpIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 22H23" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.7793 22.0098V17.5498" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.7996 10.8896C18.5796 10.8896 17.5996 11.8697 17.5996 13.0897V15.3596C17.5996 16.5796 18.5796 17.5596 19.7996 17.5596C21.0196 17.5596 21.9996 16.5796 21.9996 15.3596V13.0897C21.9996 11.8697 21.0196 10.8896 19.7996 10.8896Z" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.09961 6.02002C2.09961 4.01002 3.09966 3 5.08966 3H11.3196C13.3096 3 14.2996 4.01002 14.2996 6.02002V22" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.09961 21.9998V10.0098" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.80078 8.25H10.7508" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.80078 12H10.7508" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.25 22V18.25" stroke="#393A94" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
