import React, { useContext, useEffect, useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Text,
  Heading,
  Loader,
  Divider,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

import { IUserEdit } from "../../types/IUsers";

// api
import { UserApi } from "../../api/user";
import { CompanyApi } from "../../api/company";
import { AuthApi } from "../../api/auth";
import { SiteApi } from "../../api/site";

// utils
import validateEmail from "../../utils/validateEmail";
import { AuthorityContext } from "../../App";
import ReactSelect from "react-select";
import { siteList } from "utils/testSiteList";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function UserEditPage() {
  const { site, company }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const api = UserApi();
  const authApi = AuthApi();
  const siteApi = SiteApi();
  const companyApi = CompanyApi();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [affiliationError, setAffiliationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [companyList, setCompanyList] = useState([] as any);
  const [authGroupList, setAuthGroupList] = useState([] as any);
  const [noteLength, setNoteLength] = useState(0);
  const [hasFormError, setHasFormError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [payload, setPayload] = useState<IUserEdit>({
    user_name: "",
    company_id: "",
    user_phone_number: "",
    user_email: "",
    remarks: "",
    site: [],
    authGroup: {},
  });
  const [siteListOptions, setSiteListOptions] = useState([] as any[]);

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/user/list", label: "ユーザー管理" },
    { href: "", label: "ユーザー編集" },
  ];

  const openModal = () => {
    let errorMessageToSet = [];
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setNameError(() => {
      return payload.user_name === "" ? true : false;
    });
    setAffiliationError(() => {
      return payload.company_id === "" ? true : false;
    });
    setEmailError(() => {
      if (payload.user_email === "") {
        return true;
      } else if (!validateEmail(payload.user_email)) {
        return true;
      } else {
        return false;
      }
    });
    if (
      payload.user_name === "" ||
      payload.company_id === "" ||
      payload.user_email === ""
    ) {
      errorMessageToSet.push("必須項目を入力してください。");
    }
    if (payload.site.length < 1) {
      errorMessageToSet.push("1つ以上の現場と権限グループを入力してください。");
    }

    let siteAuthMatchFlag = true;
    let payloadValidAuthLength = 0;
    payload.site.forEach((e) => {
      if (payload.authGroup[e] && payload.authGroup[e] !== "") {
        payloadValidAuthLength += 1;
      } else {
        siteAuthMatchFlag = false;
      }
    });
    if (payload.site.length !== payloadValidAuthLength || !siteAuthMatchFlag) {
      errorMessageToSet.push("現場と権限グループはペアで入力してください。");
    }
    setErrorMessage(errorMessageToSet.join("\n"));

    if (
      payload.user_name !== "" &&
      payload.company_id !== "" &&
      payload.user_email !== "" &&
      validateEmail(payload.user_email) &&
      payload.site.length > 0 &&
      payload.site.length === payloadValidAuthLength &&
      siteAuthMatchFlag
    ) {
      setIsModalOpen(true);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false);
    }
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
    if (item === "remarks") {
      setNoteLength(value?.length);
    }
  };
  const handleCheckboxChange = (value: boolean, id: string) => {
    const siteIds = [...payload.site];
    if (value) {
      siteIds.push(id);
      setPayload({ ...payload, site: siteIds });
    } else {
      const filteredId = siteIds.filter((siteId) => siteId !== id);
      setPayload({
        ...payload,
        site: filteredId,
        authGroup: { ...payload.authGroup, [id]: "" },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    setPayload({
      ...payload,
      authGroup: {
        ...payload.authGroup,
        [e.siteId]: e.value,
      },
    });
  };
  useEffect(() => {
    const authSelectedListCandidate: { [key: string]: any } = {};
    siteListOptions?.forEach((siteItem: any) => {
      authSelectedListCandidate[siteItem.value] = "";
    });
    setPayload({
      ...payload,
      authGroup: { ...authSelectedListCandidate, ...payload.authGroup },
    });
  }, [authGroupList, siteListOptions]);

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");

    const createPayload = {
      user_name: payload.user_name,
      user_phone_number: payload.user_phone_number,
      remarks: payload.remarks,
      site_auth_group: payload.authGroup,
    };
    try {
      // @ts-ignore
      const { data } = await api.updateUser(userId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/user/${userId}`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  useEffect(() => {
    const getDetail = async () => {
      setLoader(true);
      // @ts-ignore
      const { data, status } = await api.getDetail(userId);
      if (status === 200) {
        const authorityGroupToSet = {} as { [key: string]: string };
        data?.data?.user_affiliated_authority_groups?.forEach((e: any) => {
          if (e?.authority_group_id && e?.site_id) {
            authorityGroupToSet[e?.site_id] = e?.authority_group_id;
          }
        });
        const siteToSet = [] as string[];
        data?.data?.user_affiliated_sites?.forEach((e: any) => {
          if (e?.site_id) {
            siteToSet.push(e?.site_id);
          }
        });
        setLoader(false);
        setFetchError(false);
        setNoteLength(data?.data?.remarks?.length);
        setPayload({
          user_name: data?.data?.user_name,
          company_id: data?.data?.company_id,
          user_phone_number: data?.data?.user_phone_number,
          user_email: data?.data?.user_email,
          remarks: data?.data?.remarks,
          site: siteToSet,
          authGroup: authorityGroupToSet,
        });
      } else {
        setFetchError(true);
      }
    };

    const getCompanyList = async () => {
      // setLoader(true);
      const { data } = await companyApi.getList();
      if (data?.success) {
        setCompanyList([...data?.data]);
      }
    };

    const getAuthGroupList = async () => {
      const { data, status } = await authApi.getList();
      if (status === 200) {
        const mappedList = data?.data.map(
          (authgroup: {
            site_id: any;
            authority_group_id: any;
            authority_groups_name: any;
          }) => ({
            value: authgroup.authority_group_id,
            label: authgroup.authority_groups_name,
            siteId: authgroup.site_id,
          })
        );
        setAuthGroupList(mappedList);
      }
    };

    const getSiteList = async () => {
      if (company?.admin_flag) {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );
          setSiteListOptions(mappedList);
        }
      } else {
        const mappedList = site?.map((item: any) => {
          return {
            value: item.site_id,
            label: item.site_name,
          };
        });
        setSiteListOptions(mappedList);
      }
    };

    getSiteList();
    getCompanyList();
    getAuthGroupList();
    getDetail();
    // eslint-disable-next-line
  }, [site, company]);

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={userId ?? pageTitle}>
      <View className="main-content">
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          {errorMessage !== "" || hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              {errorMessage?.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </Flex>
          ) : null}
          {!loader ? (
            <>
              <Heading level={2} className="page-content-header">
                ユーザー情報
              </Heading>
              <Heading level={6} className="form-note">
                ※ユーザーIDは自動で生成されます。
              </Heading>
              <TextField
                label="名前"
                placeholder="お名前を入力してください"
                value={payload.user_name}
                onChange={(event) =>
                  handleChange("user_name", event.target.value)
                }
                isRequired
                className={nameError ? `required input-error` : "required"}
              />
              <SelectField
                label="所属法人"
                placeholder="XXXXX"
                value={payload.company_id}
                // onChange={(event) => handleChange('company_id', event.target.value)}
                disabled
                className={
                  affiliationError ? `required input-error` : "required"
                }
                icon={<SelectIcon />}
              >
                {companyList?.map((item: any, index: any) => {
                  return (
                    <option value={item?.company_id} key={index}>
                      {item?.company_name}
                    </option>
                  );
                })}
              </SelectField>
              <TextField
                label="電話番号"
                placeholder="電話番号を入力してください"
                value={payload.user_phone_number}
                onChange={(event) =>
                  handleChange("user_phone_number", event.target.value)
                }
                isRequired
              />
              <TextField
                label="メールアドレス"
                placeholder="example@diace.co.jp"
                value={payload.user_email}
                // onChange={(event) => handleChange('user_email', event.target.value)}
                type="email"
                isRequired
                className={emailError ? `required input-error` : "required"}
                disabled
              />
              <Divider className="form-divider" />

              <View
                as="p"
                marginBottom={"15px"}
                color={"#ACB5BB"}
                fontSize={"16px"}
                fontWeight={"bold"}
              >
                <span className="header-required">現場･権限設定</span>
              </View>
              <View as="div">
                <Flex direction={{ base: "column", medium: "column" }}>
                  {siteListOptions?.map((siteItem) => {
                    const filteredAuth = authGroupList.filter(
                      (auth: { siteId: any }) => auth.siteId === siteItem.value
                    );

                    return (
                      <Flex
                        direction={{ base: "column", medium: "row" }}
                        alignItems={{ base: "flex-start", medium: "center" }}
                        alignContent="flex-start"
                        key={siteItem.value}
                      >
                        <View as="div" flex={"0 20%"}>
                          <Flex direction={{ base: "column", medium: "row" }}>
                            <CheckboxField
                              label={siteItem.label}
                              name={siteItem.label}
                              checked={payload.site.includes(siteItem.value)}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e.target.checked,
                                  siteItem.value
                                )
                              }
                              className="user-checkbox"
                            />
                          </Flex>
                        </View>

                        <View as="div" 
                          flex={{ base: "100%", medium: "0 40%" }}
                          width={{ base: "100%", medium: "auto" }}
                        >
                          <ReactSelect //SelectFieldに直しても良いかも
                            options={filteredAuth}
                            onChange={(event) => handleSelectChange(event)}
                            value={
                              authGroupList.find(
                                (auth: { value: any }) =>
                                  payload.authGroup[siteItem.value] ===
                                  auth.value
                              ) ?? ""
                            }
                            isSearchable={false}
                            placeholder="権限グループ選択"
                            isDisabled={!payload.site.includes(siteItem.value)}
                            className="react-custom-select"
                          />
                        </View>
                      </Flex>
                    );
                  })}
                </Flex>
              </View>
              <Divider className="form-divider" />
              <TextAreaField
                label="備考"
                placeholder="備考を入力してください"
                value={payload.remarks}
                onChange={(event) =>
                  handleChange("remarks", event.target.value)
                }
                maxLength={100}
              />
              <Text className="textarea-length" as="span">
                {noteLength ?? 0}/100
              </Text>
              <Flex
                justifyContent="flex-end"
                marginTop="1rem"
                alignItems={`center`}
                direction={{
                  base: "column",
                  medium: "row",
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text="登録"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  click={openModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="キャンセル"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading="ユーザー情報を更新しますか？"
        >
          <View margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="ユーザー情報を更新"
              size="medium"
              width="medium"
              borderRadius="large"
              iconName={disableButton ? "loader" : ""}
              iconPosition="right"
              disabled={disableButton}
              click={() => handleSubmit()}
            />
          </View>
        </CommonModal>

        {/* cancel modal */}
        <CommonModal
          isOpen={isCancelModal}
          textHeading="入力内容を破棄して詳細に戻りますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="編集へ戻る"
              type="bordered-transparent"
              click={showCancelModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text="破棄"
              size="xs"
              width="small"
              click={() => navigate(`/user/${userId}`)}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
