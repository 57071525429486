import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../routes";

export default function SitemapPage() {
  const tableStyle: React.CSSProperties = {
    border: "1px solid #c1c1c1",
    borderCollapse: "collapse", // テーブルの線を一つにまとめる
    width: "80%", // テーブルの幅を親要素に合わせる
  };
  const theadStyle: React.CSSProperties = {
    background: "#c1c1c1",
    padding: "10px", // paddingを適切に設定
    margin: "0px",
  };
  const cellStyle: React.CSSProperties = {
    // thとtdの共通スタイル
    border: "1px solid #c1c1c1",
    padding: "10px", // セル内の余白
    textAlign: "left", // テキストを左揃え
  };

  const paths = routes.props.children;

  let pages = [];

  pages = paths.map((item: any, index: number) => {
    return {
      index: index + 1 ?? "",
      pageId: item.props.pageId ?? "",
      name: item.props.name ?? "",
      nameEn: item.props.nameEn ?? "",
      path: item.props.path ?? "",
      ui: item.props.ui ?? "",
      api: item.props.apiIntegration ?? "",
    };
  });

  return (
    <div>
      <h1>List</h1>
      <a
        href="https://docs.google.com/spreadsheets/d/154tKQOUSghRWsSb7QgmVthpzSmyE3GkqyDlL9ZAk8qM/edit#gid=1880355147"
        target="_blank"
        rel="noopener noreferrer"
      >
        Page list
      </a>

      <table style={tableStyle}>
        <thead style={theadStyle}>
          <tr style={cellStyle}>
            <th style={cellStyle}>no</th>
            <th style={cellStyle}>page ID</th>
            <th style={cellStyle}>name</th>
            <th style={cellStyle}>name (en)</th>
            <th style={cellStyle}>path</th>
            <th style={cellStyle}>ui dev</th>
            <th style={cellStyle}>api integration</th>
          </tr>
        </thead>
        <tbody>
          {pages.map((item: any) => (
            <tr style={cellStyle} key={item.index}>
              <td>{item.index}</td>
              <td>{item.pageId}</td>
              <td>{item.name}</td>
              <td>{item.nameEn}</td>
              <td>
                <Link to={item.path}>{item.path}</Link>
              </td>
              <td>{item.ui}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h1>Env variable</h1>
      <table style={tableStyle}>
        <thead style={theadStyle}>
          <tr style={cellStyle}>
            <th>no</th>
            <th>name</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <td>1</td>
            <td>REACT_APP_NAME</td>
            <td>{process.env.REACT_APP_NAME}</td>
          </tr>
          <tr style={cellStyle}>
            <td>2</td>
            <td>REACT_APP_ENV</td>
            <td>{process.env.REACT_APP_ENV}</td>
          </tr>
          <tr style={cellStyle}>
            <td>3</td>
            <td>REACT_APP_ENV</td>
            <td>{process.env.REACT_APP_ENV}</td>
          </tr>
          <tr style={cellStyle}>
            <td>4</td>
            <td>REACT_APP_URL</td>
            <td>{process.env.REACT_APP_URL}</td>
          </tr>
          <tr style={cellStyle}>
            <td>4</td>
            <td>REACT_APP_REGION</td>
            <td>{process.env.REACT_APP_REGION}</td>3
          </tr>
          <tr style={cellStyle}>
            <td>5</td>
            <td>REACT_APP_AUTH_USER_POOL_ID</td>
            <td>{process.env.REACT_APP_AUTH_USER_POOL_ID}</td>
          </tr>
          <tr style={cellStyle}>
            <td>6</td>
            <td>REACT_APP_API_BASE_URL</td>
            <td>{process.env.REACT_APP_API_BASE_URL}</td>
          </tr>
          <tr style={cellStyle}>
            <td>7</td>
            <td>REACT_APP_ACCESS_KEY_ID</td>
            <td>{process.env.REACT_APP_ACCESS_KEY_ID}</td>
          </tr>
          <tr style={cellStyle}>
            <td>8</td>
            <td>REACT_APP_SECRET_ACCESS_KEY</td>
            <td>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
          </tr>
          <tr style={cellStyle}>
            <td>9</td>
            <td>REACT_APP_AWS_SDK_LOAD_CONFIG</td>
            <td>{process.env.REACT_APP_AWS_SDK_LOAD_CONFIG}</td>
          </tr>
          <tr style={cellStyle}>
            <td>10</td>
            <td>REACT_APP_COGNITO_ID</td>
            <td>{process.env.REACT_APP_COGNITO_ID}</td>
          </tr>
        </tbody>
      </table>
      <br></br>
    </div>
  );
}
