import { View, Flex, Heading } from "@aws-amplify/ui-react";
import React from "react";
import "../../styles/login.scss";
import LoginForm from "./login-form";
import LoginIcon from "../../components/icons/login-icon";

function index() {
  return (
    <Flex className="login-light-container">
      <View className="login-light-main">
        <View className="login-headline">
          <Heading level={1} color={"white"}>
            ログインする
          </Heading>
          <Heading level={5} color={"white"}>
            Let’s login to your account
          </Heading>
        </View>
        <LoginIcon />
      </View>

      <LoginForm />
    </Flex>
  );
}

export default index;
