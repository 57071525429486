import React from "react";
import PageContent from "../../components/pageContent";
// amplify ui components
import {
  Card,
  Text,
  Flex,
  View,
  Heading,
  Divider,
} from "@aws-amplify/ui-react";
import DocumentIcon from "../../components/icons/document-icon";
import "../../styles/help.scss";

import { HelpApi } from "../../api/help";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function HelpPage() {
  const api = HelpApi();
  // const fileToDownload = API_BASE_URL + '/manual/download?filename=manual.pdf'
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "", label: "プライバシーポリシー" },
  ];

  const handleDownload = async () => {
    try {
      const { data, status } = await api.downloadManual();
      if (status === 200) window.open(data?.url, "_self");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <Flex direction={"column"} className="help-container" padding={'0px 20px'}>
        <Heading level={3}>操作マニュアル</Heading>
        <Divider className="form-divider" />
        <Card className="help-card">
          <span
            onClick={() => handleDownload()}
            className="downloadable-file"
          ></span>
          <Flex
            
            className="help-wrapper"
          >
            <Flex>
              <DocumentIcon />

              <Heading level={6}>ConPath-D_現場ユーザーマニュアル_v1.0</Heading>
            </Flex>
            <Text alignSelf={"end"}>PDF：2.4MB</Text>
          </Flex>
        </Card>
      </Flex>
    </PageContent>
  );
}
