import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Theme, ThemeProvider, Flex, Heading } from "@aws-amplify/ui-react";
import "../../styles/top.scss";
import {
  NavigationCard,
  NavigationCardHeader,
} from "./components/navigation-card";
import PageContent from "../../components/pageContent";
import NoticationCard from "./components/notification-card";
import { NoticeApi } from "../../api/notice";
import { SiteApi } from "../../api/site";
import { AuthorityContext } from "../../App";
import checkFeature from "../../utils/checkFeature";
import CommonModal from "../../components/common-modal";
import CustomButton from "../../components/custom-button";

import formatDate from "../../utils/formatDate";
import { ILogs } from "../../types/ILogs";
import _ from "lodash";
const breadCrumbsItems = [{ href: "", label: "ホーム" }];
const theme: Theme = {
  name: "card-theme",
  tokens: {
    components: {
      card: {
        backgroundColor: { value: "#fff" },
        borderRadius: { value: "10px" },
        padding: { value: "20px" },
        boxShadow: { value: "#000 0px 2px 6px -2px" },
      },
    },
  },
};
type SiteSelect = {
  value: string;
  label: string;
};

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function TopPage() {
  const api = NoticeApi();
  const siteApi = SiteApi();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const userName = localStorage.getItem("username") || "null";
  const checkInfo = localStorage.getItem("check_info");

  const { site, settings, company }: any = useContext(AuthorityContext);
  const [isCancelModal, setIsCancelModal] = useState(true);
  const initialSelectedSiteId: string =localStorage.getItem("currentSiteId") ?? "";
  const [siteId, setSiteId] = useState(initialSelectedSiteId);
  const [logsDataList, setLogsDataList] = useState<ILogs[]>([]);

  const portal_links = [
    {
      header: "機材管理",
      links: [
        { text: "機材一覧", url: "/eq/list", hide: false },
        { text: "カテゴリー", url: "/categories/list", hide: false },
        { 
          text: "新規機材登録", 
          url: "/eq/create", 
          hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('equipment_regist')) ? false : true 
        },
        { 
          text: "CSV登録・更新", 
          url: "/eq/csv/upload", 
          hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('equipment_csv_regist')) ? false : true 
        },
      ],
      hide: false
    },
    {
      header: "現場管理",
      links: [
        { text: "現場一覧", url: "/site/list" },
        { text: "新規現場登録", url: "/site/create" },
        //! No URL
        //{ text: "配属ユーザー編集", url: "" },
      ],
      hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('sites_service')) ? false : true
    },
    {
      header: "法人管理",
      links: [
        { text: "法人一覧", url: "/company/list" },
        { text: "新規法人登録", url: "/company/create" },
      ],
      hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('companies_service')) ? false : true
    },
    {
      header: "ユーザー管理",
      links: [
        { text: "ユーザー一覧", url: "/user/list", hide: false },
        { 
          text: "新規ユーザー登録", 
          url: "/user/create", 
          hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('user_regist')) ? false : true 
        },
      ],
      hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('users_service')) ? false : true
    },
    {
      header: "更新履歴",
      url: "/logs",
      hide: company?.admin_flag || (settings && settings[siteId]?.page_control?.includes('logs')) ? false : true
    },
    //! Dynamic route
    {
      header: "アカウント",
      url: `/account/${userName}`,
      hide: false
    },
    {
      header: "操作マニュアル",
      url: "/help",
      hide: false
    },
  ];

  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const toAccountUpdate = () => {
    navigate(`/account/edit/email/${userName}`);
    localStorage.removeItem("check_info");
  };

  const setSiteForFilter = (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setSiteId(value);
  };

  const isJSON = (value: string) => {
    try {
      return JSON.parse(value) && !!value;
    } catch (e) {
      return false;
    }
  };

  const checkNote = (value: string, toSearch: string) => {
    const translated = checkFeature(toSearch) as string;
    return value.replace(toSearch, translated);
  };

  const getList = async (limit: number = 3, page: number = 1) => {
    try {
      const { data } = await api.getList(limit, page, +siteId);
      if (data?.success) {
        const mappedList: ILogs[] = data?.data?.map((item: any) => {
          return {
            date: formatDate(null, item.created_at),
            type: item.type,
            feature: isJSON(item.description)
              ? checkFeature(JSON.parse(item.description).table)
              : item.description,
            note: isJSON(item.description)
              ? checkNote(
                  JSON.parse(item.description).note,
                  JSON.parse(item.description).table
                )
              : item.description,
            userId: item.user_id,
          };
        });

        setLogsDataList(mappedList);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    //getList();
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            {/*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/}
            setSiteSelections(mappedSiteList);
            setSiteId(initialSelectedSiteId)
          } else {
            setSiteSelections(mappedSiteList);
            setSiteId(mappedSiteList[0].value);
          };
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (initialSelectedSiteId && mappedSiteList.some(e => e.value === initialSelectedSiteId)) {
        {/*setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/}
        setSiteSelections(mappedSiteList);
        setSiteId(initialSelectedSiteId)
      } else {
        setSiteSelections(mappedSiteList);
        setSiteId(mappedSiteList[0].value)
        setSiteForFilter(mappedSiteList[0].value);
      }
    }
 
    // eslint-disable-next-line
  }, [site, company]);



  useEffect(() => {
    const lastVisit = localStorage.getItem("lastUserVisit") ?? "/";
    const justAfterLogin = localStorage.getItem("justAfterLogin") ?? "false";
    localStorage.removeItem("justAfterLogin");
    if (
      justAfterLogin === "true" &&
      lastVisit !== "/login/introduction" &&
      lastVisit !== "/"
    ) {
      setTimeout(() => {
        navigate(lastVisit);
      }, 50);
    }
    //getList();
    // eslint-disable-next-line
  }, [siteId]);

  useEffect(() => {
    // getList();
  }, [logsDataList, siteId, settings, site,company]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
      showSelectField={true}
      initialValue={siteId}
      selectFieldOptions={siteSelections}
      changeEvent={setSiteForFilter}
    >
      <ThemeProvider theme={theme} colorMode="light">
        <Flex className="navigation-main-wrapper ">
          {/*{!_.isEmpty(logsDataList) && (
            <NoticationCard notifications={logsDataList} />
          )}*/}
          <Flex className="navigation-card-main-container ">
            {portal_links.map(
              (portal, index) =>
                Number(portal?.links?.length) > 0 && !portal.hide && (
                  <Flex
                    className="navigation-card-container"
                    // style={{ flexBasis: "calc(25% - 5px)", flexGrow: 2 }}
                    key={`header-${portal.links?.at(0)?.url}-${index}`}
                  >
                    <NavigationCard
                      heading={portal.header}
                      links={portal.links}
                      hide={portal.hide}
                    />
                  </Flex>
                )
            )}
          </Flex>
          <Flex className="header-container">
            {portal_links.map(
              (portal, index) =>
                portal.hide !== true && !portal?.links && (
                  <Flex
                    className="header-card-container"
                    // style={{ flexBasis: "calc(25% - 5px)", flexGrow: 2 }}
                    key={index}
                  >
                    <NavigationCardHeader
                      heading={portal.header}
                      url={portal.url}
                      key={index}
                    />
                  </Flex>
                )
            )}
          </Flex>
        </Flex>
      </ThemeProvider>
      {checkInfo === "true" ? (
        <CommonModal
          isOpen={isCancelModal}
          textHeading="アカウント情報を確認してください"
          textsmall="登録されているアカウント情報を確認し、必要に応じて更新してください。"
        >
          <Flex justifyContent="center" margin={`0 auto`} className="top-modal">
            <CustomButton
              {...defaultButtonProps}
              text="あとで"
              type="bordered-transparent"
              click={showCancelModal}
              className="custom-button"
            />
            <CustomButton
              {...defaultButtonProps}
              text="アカウント情報を更新"
              iconName="arrow"
              iconPosition="right"
              size="medium"
              click={() => toAccountUpdate()}
              className="custom-button"
            />
          </Flex>
        </CommonModal>
      ) : null}
    </PageContent>
  );
}
