const PrivacyPolicyData = {
  title: "機材管理サービス“ConPath-D” プライバシーポリシー",
  content:
    "株式会社ダイヤモンド建機は、個人情報保護の重要性に鑑み、個人情報の保護に関する法令及び行政手続における特定の個人を識別するための番号の利用等に関する法令を遵守し、個人情報、匿名加工情報（特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたものをいい、加工方法等の情報を併せたものをいいます。）および個人関連情報（以下「個人情報等」）を以下の方針に基づいて適切に取り扱います。",
  subContent: [
    {
      title: "1. 個人情報保護体制の確立と継続的改善",
      content:
        "当社は、全社員に個人情報保護の重要性を認識させ、個人情報を適切に保護するための個人情報保護体制を確立し、継続的に改善します。",
    },
    {
      title: "2. 個人情報の取得・利用",
      content:
        "当社は、個人情報保護に係る社内規程を制定し、これに従って個人情報を適正かつ適切に取得、利用します。また、本人に通知もしくは公表した利用目的の達成に必要な範囲内に限定して個人情報を取り扱います。",
    },
    {
      title: "3. 本人への対応",
      content:
        "当社は、個人情報の開示、訂正等のお問い合わせに対し、法令に従い、速やかに対応します。",
    },
    {
      title: "4. 個人情報の正確性・安全性の確保",
      content:
        "当社は、個人情報の正確性および安全性を確保するため、安全対策を講じて、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏えい等の防止に努めます。",
    },
    {
      title: "5. 第三者提供に関する措置",
      content:
        "個人情報等を第三者に提供する際には、法令に従い必要な措置を講じます。",
    },
    {
      title: "6. 管理体制の維持・向上",
      content:
        "社内教育・啓発活動を実施して個人情報等の適正な取扱いについて周知徹底に努めるとともに、管理体制を継続的に見直します。",
    },
  ],
  otherContent: [
    `株式会社ダイヤモンド建機`,
    "代表取締役社長　宮沢 達朗",
    "〒105-0003 東京都港区西新橋一丁目4番14号 物産ビル6階",
  ],
};

const infoUsagePurposeData = {
  title: "個人情報の利用目的について",
  content: `当社は、他の方法でお知らせする場合を除き、ご提供いただいた個人情報を次の目的の達成に必要な範囲で取扱います。また、個人番号に関しては、法令に定める個人番号関係事務の範囲を超えて取扱うことはありません。`,
  subContent: [
    {
      content: "(1) お客様に機材管理サービス”ConPath-D”を提供するため。",
    },
    {
      content: "(2) サービス利用料金の徴収のため。",
    },
    {
      content: "(3) お客様の本人確認を行うため。",
    },
    {
      content: "(4) 各種お問い合わせ、資料請求等に対応するため。",
    },
    {
      content:
        "(5) お客様に、当社の商品、サービス等に関する情報を提供するため。",
    },
    {
      content: "(6) サービスの改善、新規サービスの開発・マーケティングのため。",
    },
    {
      content: `(7) 市場調査・顧客動向分析等、経営上必要な分析を行うための基礎データおよび統計的データの作成、または匿名加工情報の解析をするため。`,
    },
    {
      content: "(8)   新サービスに関する各種情報の連絡のため。",
    },
    {
      content: `(9) 利用規約等で禁じている各種行為の調査とそれに基づく詳細確認のため。`,
    },
    {
      content: "(10) 以上の各事項に付随する業務",
    },
  ],
  otherContent: [
    `当社は、個別にご了解を得た場合を除き、上記の利用目的の範囲を超えてお客様の個人情報を利用いたしません。利用目的が変更された場合には、速やかに通知、もしくは公表いたします。`,

    `なお、当社では上記利用目的を達成するため、提供いただいた個人情報を業務委託先に委託する場合がございます。その場合には、委託された個人情報等の安全管理が図られるよう、業務委託先に対する必要かつ適切な監督を行います。また、法令等に基づき公的機関より提供を求められた場合には、当該公的機関に提供することがございます。`,
  ],
  inquiryDetails: [
    `【個人情報の取り扱いに関するお問合せ】`,
    `株式会社ダイヤモンド建機　個人情報保護担当`,
    `電話：03-6205-7886`,
    `メール：info@diace.co.jp`,
  ],
};
const thirdPartyInfoProvisionData = {
  title: `個人情報の第三者提供について`,
  otherContent: [
    `当社は、取得した個人情報を、個人情報保護関連法令等で定める場合を除き、本人の同意なく第三者に提供することはありません。ただし、本人の同意を得た場合は、取得した個人情報を、第三者（外国にある第三者を含む）に提供する場合があります。`,

    `その際、個人情報の第三者提供にあたっては、個人情報保護法の定めに従い、その記録と保存を行います。個人情報を外国にある第三者に提供する場合には、同法の定めに従い、あらかじめ本人の同意を得たうえで提供します。`,
  ],
};

const disclosureProceduresData: SectionData = {
  title: `「開示等の求め」に応じる手続きについて`,
  content: `当社では「個人情報の保護に関する法律」に基づき、保有個人データに関する開示、訂正、利用停止等の求めに以下の通り対応させて頂きます。`,
  subContent: [
    {
      title: "1. 対象範囲",
      content: `ご本人の住所、氏名の他、特定の個人を識別できる個人情報であって、「個人情報の保護に関する法律」に基づく保有個人データに含まれ、当社が収集し、保有しているもののみとします。`,
    },
    {
      title: "2. 開示等のご請求先",
      content: ` 開示等のご請求は所定の請求書に必要書類および開示手数料（開示のご請求の場合のみ必要であり、訂正等・利用停止等のご請求の場合は不要）を同封の上、郵送にてお願いいたします。なお、郵送の際は封筒に朱書きで「開示等請求書類在中」とお書きください。`,
      otherContent: [
        "【郵送先】",
        "〒105-0003 東京都港区西新橋一丁目4番14号 物産ビル6階",
        "株式会社ダイヤモンド建機　個人情報保護担当",
        "【電話】　　03-6205-7886",
        "【E-Mail】　info@diace.co.jp",
        "【受付時間】9:00～17:00（ただし、土日祝祭日は除く）",
        "※直接ご来社頂いてのご請求はお受け致しかねますので、ご了承願います。",
        "※郵送料等につきましては、ご本人負担とさせていただきます。",
      ],
    },
    {
      title: "3. 開示等のご請求に際してご提出頂く書面（様式）等",
      content: `開示等のご請求を行う場合は、当社所定の請求書と本人確認のための書類が必要になります。当社所定の請求書（A）に所定の事項を全てご記入の上、本人確認のための書類（B）を同封しご郵送下さい。`,
      subContent: [
        {
          title: "A) 当社所定の請求書",
          otherContent: [
            "・開示請求書",
            "・訂正等請求書",
            "・利用停止等請求書",
          ],
        },
        {
          title: "B) 本人確認のための書類",
          otherContent: [
            "運転免許証、パスポート、保険証、年金手帳、住民票等の現住所が確認できる公的書類のコピー（現住所がわかるようにコピーをお願いします）１通",
            "※当社所定の請求書は当社よりお送り致しますので、ご希望の請求書を明らかにした上、上記連絡先にご一報下さい。",
            "※当社所定の申請書類以外では一切お受けできませんので、あらかじめご了承願います。",
            "※本人確認用書類として、運転免許証や住民票等を利用される場合は、当該「本籍地」を黒で塗りつぶすなどして読み取り不可の状態でご送付ください。",
          ],
        },
      ],
    },
    {
      title: "4. 代理人による開示等のご請求",
      content: `開示等のご請求をする方が、本人の法定代理人、または本人が委任した代理人である場合は、前項の書類に加えて以下の書類をご同封願います。`,
      subContent: [
        {
          title: "A)   法定代理人の場合",
          otherContent: [
            "法定代理人があることの理由を記載した書面（様式自由）１通",
            "法定代理権があることを確認するための書類（本人の戸籍抄本）１通",
            `代理人本人であることを確認するための書類　１通
                   （代理人の運転免許証、パスポート、保険証、年金手帳、住民票などの現住所が確認できる公的書類のコピー）`,
          ],
        },
        {
          title: "B) 委任による代理人の場合",
          otherContent: [
            "捺印済みの委任状　１通",
            "本人の印鑑証明書　１通",
            `
              代理人本人であることを確認するための書類　１通
                （代理人の運転免許証、パスポート、保険証、年金手帳、住民票などの現住所が確認できる公的書類のコピー）`,
          ],
        },
      ],
    },
    {
      title: "5. 開示等のご請求に関する手数料およびそのお支払い方法",
      content: `一回のご請求毎に、440円（簡易書留郵便を利用し書面にてご回答する実費）を頂戴いたしますので、440円分の郵便切手を申請書類に同封して下さい。なお、手数料は開示のご請求の場合のみ必要であり、訂正等・利用停止等のご請求の場合、手数料は不要です。`,
    },
    {
      title: "6. 開示等のご請求に対するご回答方法",
      content: `請求書記載住所宛に、簡易書留により書面でご回答申し上げます。なお、開示等のご請求に際してご提出頂く全ての書面等が到着し開示手続きが開始された時からご回答までは、少なくとも10営業日はお時間を頂きます。`,
    },
    {
      title: "7. 「保有個人データ」の不開示について",
      content:
        "次に定める場合は不開示とさせて頂きます。不開示を決定した場合は、その旨理由を付記してご通知申し上げます。なお、不開示の場合についても所定の手数料を頂きます。",
      otherSubContent: [
        `請求書に記載されている住所、本人確認のための書類に記載されている住所、当社の登録住所が一致しない場合等、ご本人からの請求であることが確認できない場合`,
        `代理人による申請に際して、代理権が確認できない場合`,
        `ご提出頂いた申請書類に不備があった場合`,
        `開示等のご請求の対象が「保有個人データ」に該当しない場合`,
        `本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合`,
        `当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合`,
        `他の法令に違反することとなる場合`,
      ],
    },
    {
      title: "8. 開示等のご請求に関して取得した個人情報の利用目的",
      content:
        "開示等のご請求に際してご提出頂いた請求書、本人確認書類等の個人情報は、開示等のご請求への対応に必要な範囲のみで取扱います。ご提出頂いた書類は、開示等のご請求に対するご回答が終了した後、一定期間保存しその後破棄させて頂きます。",
    },
  ],
};

const anonymizedInfoUsageData = {
  title: "匿名加工情報の利用について",
  otherContent: [
    "当社は、匿名加工情報を取扱う場合、法令等に定めに従い、適切に取扱います。また、当社は、保有している個人情報から匿名加工情報を作成した場合、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。",
    "また、当社は、匿名加工情報を第三者に提供する場合、提供しようとする匿名加工情報に含まれる個人に関する情報の項目と提供の方法を公表するとともに、提供先となる第三者に対して、提供する情報が匿名加工情報であることを明示します。",
  ],
};

export {
    PrivacyPolicyData,
    infoUsagePurposeData,
    thirdPartyInfoProvisionData,
    disclosureProceduresData,
    anonymizedInfoUsageData,
};
