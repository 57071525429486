import React, { memo } from "react";

import { Flex, View } from "@aws-amplify/ui-react";
import "../../../../../styles/custom-calendar.scss";
import moment from "moment";

const now = new Date();

export default memo(function CalendarEvents({
  day,
  eventList,
  handleEventClick,
}: {
  day: IDates;
  eventList: any[];
  handleEventClick: (
    hasEvent: boolean,
    defaultDate: string,
    company_name: any,
    company_id: any
  ) => any;
}) {
  const eventDetails = eventList.find((event) => {
    const dayDate = moment(day.date, "YYYY-MM-DD");
    const deliveryDate = moment(event.delivery_schedule, "YYYY-MM-DD");
    const returnDate = moment(event.return_schedule, "YYYY-MM-DD");

    // Check if both delivery and return dates are valid before comparison
    return (
      deliveryDate.isValid() &&
      returnDate.isValid() &&
      dayDate.isBetween(deliveryDate, returnDate, undefined, "[]") // '[]' includes endpoints
    );
  });

  const hasEvent = !!eventDetails;
  const companyName = hasEvent ? eventDetails.company_name : null;
  const companyId = hasEvent ? eventDetails.compay_id : null;

  const isToday =
    moment(now).format("YYYY-MM-DD") === moment(day.date).format("YYYY-MM-DD");

  const isCurrentMonth = day.isCurrentMonth;
  const eventClass = isCurrentMonth ? "range-event-day" : "off-range-event-day";
  const isOffRangeDay = isCurrentMonth ? "range-day" : "off-range-day";
  const isTodayHasEvent = hasEvent ? "today-event " : "current-day";
  const isDayDateHasEvent =
    isCurrentMonth && hasEvent ? "range-day-event" : isOffRangeDay;
  const formattedDate =
    day.date[0] + "年" + day.date[1] + "月" + day.date[2] + "日";

  return (
    <Flex
      direction={"column"}
      className="day-date"
      onClick={() => handleEventClick(hasEvent,formattedDate, companyName, companyId)}
      // aria-label={formattedDate}
    >
      <View className={isToday ? isTodayHasEvent : isDayDateHasEvent}>
        {day.date.split("-")[2]}
      </View>
      {hasEvent && <View className={eventClass}>{companyName}</View>}
      {/* {hasEvent && <View className={eventClass}>{companyName}</View>} */}
    </Flex>
  );
});
