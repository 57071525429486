import React from "react";

type Props = {
  color: string,
  opacity: number,
  width: string | number,
  height: string | number,
};

export default function TrashIcon(props : Props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.50008 13.8333C1.50008 14.75 2.47508 15.5 3.66675 15.5H12.3334C13.5251 15.5 14.5001 14.75 14.5001 13.8333V3.83333H1.50008V13.8333ZM15.5834 1.33333H11.7917L10.7084 0.5H5.29175L4.20841 1.33333H0.416748V3H15.5834V1.33333Z"
        fill={props.color}
        fillOpacity={props.opacity}
      />
    </svg>
  )
}