

export default function getBadgeColor(condition: string) {
    let res = {bg: '#ebf3ed', color:'#4c7b31'}
    switch(condition) {
      case '受入済':
        res = {bg: '#dae7fc', color:'#315e95'}
        break;
      case '予約中':
        res = {bg: '#4795ec', color:'#ffffff'}
        break;
      case '使用中':
        res = {bg: '#f7e3d8', color:'#b02318'}
        break;
      case '返却済':
        res = {bg: '#a6a6a6', color:'#ffffff'}
        break;
    }
    return res
  }