import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { IDashboard } from "types/IDashboard"

export const DashboardApi = () =>{
  const getDashboard = async (payload:IDashboard ) =>{
    const response = await FetchHeader('/dashboard', "POST", payload);
    return response;
  };
  return {
    getDashboard
  }
}
