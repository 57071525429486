import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from '../../components/common-modal';
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Loader,
  Text,
} from "@aws-amplify/ui-react";

// types
import { ICompanyDetail } from "../../types/ICompany";

// api
import { CompanyApi } from "../../api/company";

export default function CompanyPage() {
  const { companyId } = useParams()
  const api = CompanyApi()
  const defaultButtonProps = {
    text: '',
    type: 'primary',
    iconPosition: 'left',
    iconName: '',
    size: '',
    disabled: false,
    click: () => { return '' }
  }

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: `/company/list`, label: '法人一覧' },
    { href: '', label: '法人詳細' },
  ]

  const [tableData, setTableData] = useState<ICompanyDetail>({
    invoice: "",
    cinet: "",
    contact_person: "",
    category: "",
    note: ""
  });
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [disableButton, setDisableButton] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [companyName, setCompanyName] = useState('')

  const openModal: () => {} = () => {
    setIsModalOpen(true);
    return {};
  };

  const handleDelete = () => {
    deleteCompany(companyId)
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate('/company/list')
  };

  const deleteCompany = async (id: any) => {
    setDisableButton(true)
    setErrorMessage('')
    try {
      const { data } = await api.deleteCompany(id)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(false)
        setErrorMessage('')
        navigate('/company/list')
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const geDetails = async () => {
      setLoader(true);
      // @ts-ignore
      const { data } = await api.getDetail(companyId);
      if (data?.success) {
        setLoader(false);
        setFetchError(false);
        setCompanyName(data?.data?.company_name)
        setTableData({
          invoice: data?.data?.business_registration_number,
          cinet: data?.data?.ci_net_number,
          contact_person: data?.data?.company_email,
          category: data?.data?.company_category_name,
          note: data?.data?.remarks
        });
      } else {
        setFetchError(true);
        setErrorMessage(data?.error?.message)
      }
    };
    geDetails();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={companyName}
    >
      <View
        className="custom-table"
      >
        { hasError ? 
          (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
              <Text>{ errorMessage }</Text>
            </Flex>
          ) : null
        }
        {!loader ? (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell fontWeight={700} width="50%" border="0">インボイス番号</TableCell>
                  <TableCell border="0">{tableData.invoice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">CI-NET番号</TableCell>
                  <TableCell>{tableData.cinet}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">主担当者</TableCell>
                  <TableCell>{tableData.contact_person}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">法人カテゴリー</TableCell>
                  <TableCell>{tableData.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">備考</TableCell>
                  <TableCell>{tableData.note}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Flex
              marginTop="20px"
              justifyContent={`flex-end`}
              className="hide-from-print"
            >
              <CustomButton
                {...defaultButtonProps}
                text='編集'
                width='small'
                type="primary"
                iconPosition=""
                click={() => navigate(`/company/edit/${companyId}`)}
              />
              <CustomButton
                {...defaultButtonProps}
                text='削除'
                type="bordered-gray"
                iconPosition=""
                click={openModal}
              />
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">{ errorMessage }</Text>
        )}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading={`法人「${ companyName }」の情報を削除します。よろしいですか？`}
          textOperation="この操作は取り消せません。本当に削除しますか？"
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              { ...defaultButtonProps }
              text='キャンセル'
              type='bordered-transparent'
              click={() => handleCancel()}
            />
            <CustomButton
              { ...defaultButtonProps }
              text='法人情報を削除'
              size='large'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              click={() => handleDelete()}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
