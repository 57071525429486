import React from "react";

export default function AvatarIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M20.0007 37.1829C29.4815 37.1829 37.1673 29.4972 37.1673 20.0163C37.1673 10.5354 29.4815 2.84961 20.0007 2.84961C10.5198 2.84961 2.83398 10.5354 2.83398 20.0163C2.83398 29.4972 10.5198 37.1829 20.0007 37.1829Z" fill="#8EA9F5" stroke="white"/>
      <path d="M20 11.5664C16.55 11.5664 13.75 14.3664 13.75 17.8164C13.75 21.1997 16.4 23.9497 19.9167 24.0497C19.9667 24.0497 20.0333 24.0497 20.0667 24.0497C20.1 24.0497 20.15 24.0497 20.1833 24.0497C20.2 24.0497 20.2167 24.0497 20.2167 24.0497C23.5833 23.9331 26.2333 21.1997 26.25 17.8164C26.25 14.3664 23.45 11.5664 20 11.5664Z" fill="white"/>
      <path d="M31.2992 32.2671C28.3326 35.0004 24.3659 36.6837 19.9992 36.6837C15.6326 36.6837 11.6659 35.0004 8.69922 32.2671C9.09922 30.7504 10.1826 29.3671 11.7659 28.3004C16.3159 25.2671 23.7159 25.2671 28.2326 28.3004C29.8326 29.3671 30.8992 30.7504 31.2992 32.2671Z" fill="white"/>
    </svg>
  );
}
