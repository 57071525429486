import React from "react";

export default function UsersIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.36414C2 4.30955 3 3.2959 7 3.2959H17C21 3.2959 22 4.30955 22 8.36414V16.4733C22 20.5279 21 21.5416 17 21.5416H7C3 21.5416 2 20.5279 2 16.4733V12.8242" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 8.36426H19" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 12.4189H19" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 16.4736H19" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.50141 11.6987C9.50104 11.6987 10.3114 10.8773 10.3114 9.864C10.3114 8.85072 9.50104 8.0293 8.50141 8.0293C7.50177 8.0293 6.69141 8.85072 6.69141 9.864C6.69141 10.8773 7.50177 11.6987 8.50141 11.6987Z" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16.8078C11.86 15.338 10.71 14.1825 9.26 14.0507C8.76 14 8.25 14 7.74 14.0507C6.29 14.1926 5.14 15.338 5 16.8078" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
