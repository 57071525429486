import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { View } from "@aws-amplify/ui-react";

import "../styles/sidebar.scss";

// icons
import MenuArrowIcon from "./icons/v2/menu-arrow-icon";
import SidebarIcons from "./icons/sidebar-icons";
import SidebarColapseIcon from "./icons/v2/sidebar-colapse-icon";
import MenuDotIcon from "./icons/v2/menu-dot-icon";

import { AuthorityContext } from "../App";

import getDeviceType, { isSafari } from "../utils/getDeviceType";

type Props = {
  showMenu: boolean;
  handleShowMenu: (show: boolean) => void;
};

type submenu = {
  id?: number;
  path?: string;
  name?: string;
};

type Iitem = {
  id?: number;
  path?: string;
  name?: string;
  hasSubmenu?: boolean;
  menuOpen?: boolean;
  icon?: string;
  isActive?: boolean;
  submenu?: submenu[];
};

const Sidebar: React.FC<Props> = ({ showMenu, handleShowMenu }) => {
  const userName = localStorage.getItem("username") || "null";
  const isMobile = getDeviceType() === 'mobile';
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const [pointerEvent, setPointerEvent] = useState(false);
  const [menuList, setMenuList] = useState<Iitem[]>([]);
  const {company, settings }: any = useContext(AuthorityContext);
  // const windowWidth = window.innerWidth + 200 + "px";

  const menuAction = (index: number) => {
    setActiveMenuIndex((prevIndex: number) =>
      prevIndex === index ? 0 : index
    );
  };

  const doCollapse = () => {
    setCollapseSidebar(!collapseSidebar);
    setPointerEvent(true);
    setTimeout(() => {
      setPointerEvent(false);
    }, 1000);
  };

  const toScrollable = (index: number) => {
    // if(index !== 1) {
    //   setActiveMenuIndex(0)
    // }
    
    if (!isMobile) {
      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains('hidden')) {
        document.querySelector(".main-body")?.classList.remove('hidden');
      }
      // document.querySelector(".main-body").style.overflow = "auto";
    } else {
      handleShowMenu(false);
    }
  };

  useEffect(() => {
    if (settings) {
      //const menuList = settings.map((item: any) => item)
      const menuList = [];
      for (let site_id in settings) {
        const setting = settings[site_id];
        //if (settings?.length > 0) {
        if (setting?.page_control) {
          if (setting?.page_control?.length > 0) {
            // it does not care about duplication.
            menuList.push(...setting.page_control.map((item: any) => item));
          }
        }
      }

      const menu = [
        {
          id: 1,
          path: "/",
          name: "ホーム",
          hasSubmenu: false,
          menuOpen: false,
          icon: "home",
          isActive: false,
        },
      ];

      // dashboard
      if (
        company?.admin_flag === true ||
        menuList.includes("dashboard")) {
        menu.push({
          id: 2,
          path: "/dashboard",
          name: "ダッシュボード",
          hasSubmenu: false,
          menuOpen: false,
          icon: "dashboard",
          isActive: false,
        });
      }

      // equ_service
      const equService = {
        id: 3,
        path: `/eq/list`,
        name: "機材管理",
        hasSubmenu: true,
        menuOpen: false,
        icon: "equipment",
        isActive: false,
        submenu: [
          {
            id: 1,
            path: "/eq/list",
            name: "機材一覧",
          },
          {
            id: 2,
            path: "/categories/list",
            name: "カテゴリー",
          },
        ],
      };
      if (company?.admin_flag === true ||
        menuList.includes("equ_service")) {
        equService.submenu.push({
          id: 3,
          path: "/eq/schedule",
          name: "一括登録",
        });
      }
      menu.push(equService);

      // site_service
      if (company?.admin_flag === true ||
        menuList.includes("sites_service")) {
        menu.push({
          id: 4,
          path: "/site/list",
          name: "現場管理",
          hasSubmenu: false,
          menuOpen: false,
          icon: "onsite-management",
          isActive: false,
        });
      }

      // companies_service
      if (company?.admin_flag === true ||
        menuList.includes("companies_service")) {
        menu.push({
          id: 5,
          path: "/company/list",
          name: "法人管理",
          hasSubmenu: false,
          menuOpen: false,
          icon: "corp-management",
          isActive: false,
        });
      }

      // auth_service
      if (company?.admin_flag === true ||
        menuList.includes("auth_service")) {
        menu.push({
          id: 6,
          path: "/auth/list",
          name: "権限管理",
          hasSubmenu: false,
          menuOpen: false,
          icon: "auth-management",
          isActive: false,
        });
      }

      // users_service
      if (company?.admin_flag === true ||
        menuList.includes("users_service")) {
        menu.push({
          id: 7,
          path: "/user/list",
          name: "ユーザー管理",
          hasSubmenu: false,
          menuOpen: false,
          icon: "user-management",
          isActive: false,
        });
      }

      //logs
      if (company?.admin_flag === true ||
        menuList.includes("logs")) {
        menu.push({
          id: 8,
          path: "/logs",
          name: "更新履歴",
          hasSubmenu: false,
          menuOpen: false,
          icon: "history",
          isActive: false,
        });
      }

      menu.push({
        id: 9,
        path: `/account/${userName}`,
        name: "アカウント",
        hasSubmenu: false,
        menuOpen: false,
        icon: "account",
        isActive: false,
      });

      setMenuList(menu);
    }
    // eslint-disable-next-line
  }, [settings]);

  return (
    <>
      <View
        as="nav"
        className={`main-sidebar ${showMenu ? "active" : ""} ${
          collapseSidebar ? "collapse" : ""
        } ${pointerEvent ? "not-clickable" : ""}
        ${isSafari() === true ? 'safari-only': ''}
        `}
      >
        <View as="ul" className="sidebar-ul">
          {menuList.length > 0 &&
            menuList.map((item: Iitem, index) => {
              return (
                <View
                  as="li"
                  className={`sidebar-li ${
                    item.hasSubmenu ? "has-submenu" : ""
                  } ${activeMenuIndex === index ? "active" : ""}`}
                  key={index}
                >
                  <View className="sidebar-icon">
                    {item.icon && <SidebarIcons icon={item.icon} />}
                  </View>
                  {item.path && (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "sidebar-link link-active" : "sidebar-link"
                      }
                      to={item.path}
                      onClick={() => toScrollable(index)}
                    >
                      {item.name}
                    </NavLink>
                  )}
                  {item.hasSubmenu ? (
                    <>
                      <View
                        className="menu-arrow-icon"
                        onClick={() => {
                          menuAction(index);
                        }}
                      >
                        <MenuArrowIcon />
                      </View>
                      <View as="ul" className="sidebar-subul">
                        {item.hasSubmenu &&
                          item.submenu?.map((subItem, subIndex) => {
                            return (
                              <View
                                as="li"
                                className="sidebar-subli"
                                key={subIndex}
                              >
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? "sidebar-link link-active"
                                      : "sidebar-link"
                                  }
                                  to={subItem.path || ""}
                                  onClick={() => handleShowMenu(false)}
                                >
                                  {subItem.name}
                                </NavLink>
                              </View>
                            );
                          })}
                      </View>
                    </>
                  ) : null}
                </View>
              );
            })}
        </View>
        {collapseSidebar ? (
          <View className="sidebar-other-menu">
            <View className="menu-dot-icon" onClick={() => doCollapse()}>
              <MenuDotIcon />
            </View>
          </View>
        ) : (
          <View as="ul" className="sidebar-other-menu">
            <View as="li">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-other-link link-active"
                    : "sidebar-other-link"
                }
                to="/help"
                onClick={() => handleShowMenu(false)}
              >
                操作マニュアル
              </NavLink>
            </View>
            <View as="li">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-other-link link-active"
                    : "sidebar-other-link"
                }
                to="/terms-of-use"
                onClick={() => handleShowMenu(false)}
              >
                利用規約
              </NavLink>
            </View>
            <View as="li">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "sidebar-other-link link-active"
                    : "sidebar-other-link"
                }
                to="/privacy-policy"
                onClick={() => handleShowMenu(false)}
              >
                プライバシーポリシー
              </NavLink>
            </View>
            {/* <View as="li">
              <NavLink className={({ isActive }) =>
                    isActive ? "sidebar-other-link link-active" : "sidebar-other-link"
                  } to="/list">
                list (for develop)
              </NavLink>
            </View> */}
          </View>
        )}
      </View>
      <View
        className={`sidebar-collapse ${collapseSidebar ? "active" : ""}`}
        onClick={() => doCollapse()}
        display={{
          base: "none",
          medium: "block",
        }}
        top={{
          base: "15px",
          medium: "90px",
        }}
      >
        <SidebarColapseIcon />
      </View>
    </>
  );
};

export default Sidebar;
