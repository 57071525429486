import moment from "moment";

export const getMonthDays = (month: number, year: number) => {
  const startOfMonth = moment([year, month]).startOf("month");
  const endOfMonth = moment(startOfMonth).endOf("month");

  const startDayOfWeek = startOfMonth.day();
  const endDayOfWeek = endOfMonth.day();

  const startCalendar = moment(startOfMonth).subtract(startDayOfWeek, "days");
  const endCalendar = moment(endOfMonth).add(6 - endDayOfWeek, "days");
  console.log({ month, startCalendar });
  const dates = Array.from(
    { length: endCalendar.diff(startCalendar, "days") + 1 },
    (_, i) => {
      const currentDate = moment(startCalendar).add(i, "days");
      return {
        date: currentDate.format("YYYY-MM-DD"),
        dateOfWeek: currentDate.format("dddd"),
        isCurrentMonth: currentDate.month() === Number(month),
      };
    }
  );

  return {
    month: moment().month(month).format("MM"),
    year: moment().year(year).format("YYYY"),
    dates,
  };
};

export const translateDayToJapanese = (day: string): String => {
  const dayFormatted: String = day.toLowerCase();

  const daysInJapanese: { [key: string]: string } = {
    sunday: "土",
    monday: "日",
    tuesday: "火",
    wednesday: "水",
    thursday: "木",
    friday: "金",
    saturday: "土",
  };

  // Return the corresponding Japanese day, or a message if the input is invalid
  return daysInJapanese[dayFormatted] || "Invalid day";
};
