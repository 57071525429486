import React from "react";
import { useNavigate } from "react-router-dom";

import CustomButton from "../components/custom-button";
import {
  View,
  Heading
} from "@aws-amplify/ui-react";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}


export default function PageNotFound() {
  const navigate = useNavigate();

  const handGoback = () => {
    navigate('/')
  };

  return (
    <View
      textAlign={"center"}
      fontWeight={700}
      marginTop={{
        base: '150px',
        medium: '200px'
    }}
    >
      <Heading level={2} marginBottom={"50px"}>
        404<br/>Page Not Found
      </Heading>
      <CustomButton
         {...defaultButtonProps}
        text='Go back Home'
        size='medium'
        width='medium'
        borderRadius='large'
        click={handGoback}
      />
    </View>
  );
}