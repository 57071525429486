import React from "react";

export default function LocationIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.25 12.0693L10.75 13.5898L14.75 9.53516" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.98094 4.77085C10.3509 0.604758 18.8209 2.034 20.3809 9.03832C21.5309 14.1877 18.3709 18.5463 15.6009 21.2426C13.5909 23.2091 10.4109 23.2091 8.39094 21.2426C5.63094 18.5362 2.46094 14.1775 3.62094 9.02818" stroke="#393A94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
