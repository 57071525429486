import React, { useEffect, useState } from "react";
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import {
  TextField,
  TextAreaField,
  View,
  Flex,
  Text,
  Heading,
  CheckboxField
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { ISiteCreate } from "../../types/ISiteList";
import { SiteApi } from "../../api/site";

import ErrorSmallIcon from "../../components/icons/error-small-icon";

import getDeviceType from "../../utils/getDeviceType";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function UserCreatePage() {
  const isMobile = getDeviceType() === 'mobile'
  const api = SiteApi();
  const [nameError, setNameError] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFormError, setHasFormError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [noteLength, setNoteLength] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const [createList, setCreateList] = useState({} as any)

  const [payload, setPayload] = useState<ISiteCreate>({
    site_name: '',
    status: false,
    site_prime_companies: [],
    site_rental_companies: [],
    site_other_companies: [],
    site_construction_locations: [],
    site_inventory_locations: [],
    remarks: ''
  });

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/site/list', label: '現場管理' },
    { href: '', label: '新規現場登録' },
  ]

  const openModal = () => {
    document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
    setNameError(payload.site_name === '');
    if(payload.site_name !== '' ) {
      setIsModalOpen(true)
    } else {
      document.querySelector(".main-body")?.scroll({top:0,behavior:'smooth'});
      setErrorMessage('現場名を入力してください');
      setIsModalOpen(false)
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true)
    setErrorMessage('')
    const createPayload = {
      site_name: payload.site_name,
      remarks: payload.remarks,
      status: true,
      site_prime_companies: payload.site_prime_companies,
      site_rental_companies: payload.site_rental_companies,
      site_other_companies: payload.site_other_companies,
      site_construction_locations: Object.values(payload.site_construction_locations),
      site_inventory_locations: Object.values(payload.site_inventory_locations),
    };

    try {
      // @ts-ignore
      const { data } = await api.createSite(createPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        navigate(`/site/list`)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch(err) {
      console.log(err)
    }
  };


  const handleChange = (item: string, value: any) => {
    if (item === 'site_construction_locations' || item === 'site_inventory_locations') {
      const locationsArray = value.includes(',') ? value?.split(',') : [value];
      setPayload(prevState => ({
        ...prevState,
        [item]: locationsArray ?? '-'
      }));
    } else {
      setPayload(prevState => ({
        ...prevState,
        [item]: value
      }));
    }
    if(item === 'remarks') {
      setNoteLength(value.length)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleCheckboxChange = (type: string, name: any, checked: boolean) => {
    const { site_prime_companies, site_rental_companies, site_other_companies } = payload
    if (type === 'site_prime_companies') {
      if(checked) {
        setPayload({ 
          ...payload,
          site_prime_companies: [...site_prime_companies, name]
        })
      } else {
        setPayload({ 
          ...payload,
          site_prime_companies: site_prime_companies.filter(
            (e) => e !== name
          ),
        })
      }
    } else if (type === 'site_rental_companies') {
      if(checked) {
        setPayload({ 
          ...payload,
          site_rental_companies: [...site_rental_companies, name]
        })
      } else {
        setPayload({ 
          ...payload,
          site_rental_companies: site_rental_companies.filter(
            (e) => e !== name
          ),
        })
      }
    } else if (type === 'site_other_companies') {
      if(checked) {
        setPayload({ 
          ...payload,
          site_other_companies: [...site_other_companies, name]
        })
      } else {
        setPayload({ 
          ...payload,
          site_other_companies: site_other_companies.filter(
            (e) => e !== name
          ),
        })
      }
    }
  };
  
  useEffect(() => {
    const getList = async () => {
      const { data } = await api.getPreCreateList();
      setCreateList(data?.data)
    };
    getList()
  
    
  // eslint-disable-next-line
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          { errorMessage !== "" || hasFormError ?
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          <TextField
            label="現場名"
            placeholder=""
            value={payload.site_name}
            onChange={(event) => handleChange('site_name', event.target.value)}
            isRequired
            className={ nameError ? `required input-error` : 'required' }
          />
          {/*<View>
            <Heading level={6} fontWeight={700}>元請け会社*</Heading>
            <Flex marginTop={10} className="site-checkboxes" direction={{
              base: 'column',
              medium: 'row'
            }}>
              {
                createList?.site_prime_companies?.map((item : any, index : number) => {
                  return (
                    <CheckboxField
                      key={index}
                      label={item.company_name}
                      name={item.company_name}
                      value={item.company_id}
                      onChange={(event) => handleCheckboxChange('site_prime_companies', item.company_id, event.target.checked)}
                    />
                  )
                })
              }
            </Flex>
          </View>
          <View>
            <Heading level={6} fontWeight={700}>レンタル会社</Heading>
            <Flex marginTop={10} className="site-checkboxes" direction={{
              base: 'column',
              medium: 'row'
            }}>
              {
                createList?.site_rental_companies?.map((item: any, index : number) => {
                  return (
                    <CheckboxField
                      key={index}
                      label={item.company_name}
                      name={item.company_name}
                      value={item.company_id}
                      onChange={(event) => handleCheckboxChange('site_rental_companies', item.company_id, event.target.checked)}
                    />
                  )
                })
              }
            </Flex>
          </View>
          <View>
            <Heading level={6} fontWeight={700}>その他法人</Heading>
            <Flex marginTop={10} className="site-checkboxes" direction={{
              base: 'column',
              medium: 'row'
            }}>
              {
                createList?.site_other_companies?.map((item: any, index : number) => {
                  return (
                    <CheckboxField
                      key={index}
                      label={item.company_name}
                      name={item.company_name}
                      value={item.company_id}
                      onChange={(event) => handleCheckboxChange('site_other_companies', item.company_id, event.target.checked)}
                    />
                  )
                  
                })
              }
            </Flex>
          </View>*/}
           <TextField
           label="工区"
            placeholder="工区A,工区B,工区C"
            value={payload.site_construction_locations}
            onChange={(event) => handleChange('site_construction_locations', event.target.value)}
          />
          <Text
            color='#666666'
            fontWeight={400}
            fontSize={12}
            lineHeight='20px'
            marginBottom={20}
            marginTop='-20px'
            marginLeft={15}
          >
            工区が複数ある場合は、,（カンマ）で区切って入力してください。
          </Text>
           <TextField
            label="棚卸し場所（所在）"
            placeholder="機材の所在"
            value={payload.site_inventory_locations}
            onChange={(event) => handleChange('site_inventory_locations', event.target.value)}
          />
          <Text
            color='#666666'
            fontWeight={400}
            fontSize={12}
            lineHeight='20px'
            marginBottom={20}
            marginTop='-20px'
            marginLeft={15}
          >
            所在が複数ある場合は、,（カンマ）で区切って入力してください。
          </Text>
          <TextAreaField
            label="備考"
            placeholder="工期や住所など任意で記入してください"
            value={payload.remarks}
            onChange={(event) => handleChange('remarks', event.target.value)}
            maxLength={100}
          />
          <Text className='textarea-length' as='span'>{ noteLength }/100</Text>
          <Flex
            justifyContent={{
              base: 'flex-end',
              medium: 'space-between'
            }}
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: 'column',
              medium: 'row'
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text='登録'
              size='medium'
              width={isMobile ? 'medium' : 'xsmall'}
              borderRadius={isMobile ? 'large' : 'small'}
              click={openModal}
            />
            <CustomButton
              {...defaultButtonProps}
              text='キャンセル'
              borderRadius={isMobile ? 'large' : 'small'}
              type={isMobile ? 'transparent' : 'light-gray'}
              size='medium'
              click={showCancelModal}
            />
            {/* submit modal */}
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              icon="icon"
              textHeading={`現場「${ payload.site_name }」を追加します。よろしいですか？`}
            >
              <View margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='現場情報を登録する'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  iconName={ disableButton ? 'loader' : '' }
                  iconPosition='right'
                  // disabled={ disableButton }
                  click={handleSubmit}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄して詳細に戻りますか？"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  { ...defaultButtonProps }
                  text='編集へ戻る'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
                <CustomButton
                  { ...defaultButtonProps }
                  text='破棄'
                  size='xs'
                  width='small'
                  click={() => navigate('/site/list')}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}

