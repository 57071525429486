import React from "react";

export default function InfoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 11.5597V13.4297C2 16.4297 4 18.4297 7 18.4297H11L15.45 21.3897C16.11 21.8297 17 21.3598 17 20.5598V18.4297C20 18.4297 22 16.4297 22 13.4297V7.42969C22 4.42969 20 2.42969 17 2.42969H7C4 2.42969 2 4.42969 2 7.42969" stroke="#DCE4E8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9998 11.3604V11.1504C11.9998 10.4704 12.4198 10.1104 12.8398 9.82037C13.2498 9.54037 13.6598 9.18039 13.6598 8.52039C13.6598 7.60039 12.9198 6.86035 11.9998 6.86035C11.0798 6.86035 10.3398 7.60039 10.3398 8.52039" stroke="#DCE4E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9955 13.75H12.0045" stroke="#DCE4E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
