export const pageControlList = [
  {
    settingsId: 'dashboard',
    text: 'ダッシュボード',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'dashboard',
        value: true
      }
    ],
  },
  {
    settingsId: 'equipment',
    text: '機材情報管理',
    subMenu: [
      {
        text: '新規機材登録',
        key: 'equipment_regist',
        value: true
      },
      {
        text: 'CSV登録',
        key: 'equipment_csv_regist',
        value: true
      },
      {
        text: '機材情報編集',
        components: [
          {
            text: '-',
            key: 'equipment_edit',
            value: true,
          },
          {
            text: '型式',
            key: 'model',
            value: true,
          },
          {
            text: '発注担当者',
            key: 'charge_ordering',
            value: true,
          },
          {
            text: '使用現場',
            key: 'site_of_use',
            value: true,
          },
          {
            text: '現場管理No.',
            key: 'onsite_manage_no',
            value: true,
          },
          {
            text: 'レンタル会社',
            key: 'rental_company',
            value: true,
          },
          {
            text: '数量',
            key: 'quantity',
            value: true,
          },
          {
            text: '総稼働時間',
            key: 'total_operating_hour',
            value: true,
          },
          {
            text: '使用開始時稼働時間',
            key: 'operating_time_at_the_start_of_use',
            value: true,
          },
          {
            text: '受入予定日',
            key: 'delivery_schedule_date',
            value: true,
          },
          {
            text: '受入予定時間',
            key: 'delivery_schedule_time',
            value: true,
          },
          {
            text: '受入登録',
            key: 'result_delivery_schedule_date',
            value: true,
          },
          {
            text: '受入時間',
            key: 'result_delivery_schedule_time',
            value: true,
          },
          {
            text: '返却登録',
            key: 'receiving_person',
            value: true,
          },
          {
            text: '返却予定日',
            key: 'scheduled_return_date',
            value: true,
          },
          {
            text: '返却予定時間',
            key: 'scheduled_return_time',
            value: true,
          },
          {
            text: '返却日',
            key: 'return_date',
            value: true,
          },
          {
            text: '返却時間',
            key: 'return_time',
            value: true,
          },
          {
            text: '返却担当者',
            key: 'person_in_charge_of_return',
            value: true,
          },
          {
            text: '備考',
            key: 'remarks',
            value: true,
          },
        ]
      },
      {
        text: '機材情報削除',
        key: 'equipment_delete',
        value: true
      },
      {
        text: '受入登録',
        key: 'accept_regist',
        value: true
      },
      {
        text: '棚卸登録',
        key: 'inventory_regist',
        value: true
      },
      {
        text: '返却登録',
        key: 'return_regist',
        value: true
      }
    ]
  },
  {
    settingsId: 'equipment_schedule',
    text: '機材 スケジュール',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'equ_service',
        value: true
      },
      {
        text: '使用予定登録',
        key: 'delivery_schedule_regist',
        value: true
      },
      {
        text: '使用予定編集',
        components: [
          {
            text: '使用予定担当者',
            key: 'person_in_charge_who_is_scheduled_to_use',
            value: true
          },
          {
            text: '使用予定場所',
            key: 'scheduled_location_of_use',
            value: true
          }
        ]
      },
      {
        text: '使用予定削除',
        key: 'delete_scheduled_use',
        value: true
      },
      {
        text: '使用開始登録',
        key: 'start_use_regist',
        value: true
      },
      {
        text: '使用終了登録',
        key: 'end_use_regist',
        value: true
      },
      {
        text: '使用終了登録',
        components: [
          {
            text: '使用場所',
            key: 'used_site',
            value: true
          },
          {
            text: '使用担当者',
            key: 'charge_of_equ',
            value: true
          }
        ]
      },
    ]
  },
  {
    settingsId: 'sites',
    text: '現場管理',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'sites_service',
        value: true
      }
    ]
  },
  {
    settingsId: 'company',
    text: '法人管理',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'companies_service',
        value: true
      }
    ]
  },
  {
    settingsId: 'auth',
    text: '権限管理',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'auth_service',
        value: true
      },
      {
        text: '権限グループ編集',
        key: 'edit_permission_group',
        value: true
      },
      {
        text: '権限グループ削除',
        key: 'delete_permission_group',
        value: true
      },
      {
        text: '権限編集',
        components: [
          {
            text: 'ダッシュボード',
            key: 'auth_dashboard',
            value: true,
          },
          {
            text: '機材情報管理',
            key: 'auth_equipment_information_management',
            value: true,
          },
          {
            text: '機材スケジュール',
            key: 'auth_equipment_schedule',
            value: true,
          },
          {
            text: '現場管理',
            key: 'auth_on_site_management',
            value: true,
          },
          {
            text: '法人管理',
            key: 'auth_companise_entity_management',
            value: true,
          },
          {
            text: '権限管理',
            key: 'auth_authority_management',
            value: true,
          },
          {
            text: 'ユーザー管理',
            key: 'auth_user_management',
            value: true,
          },
          {
            text: '更新履歴',
            key: 'auth_update_history',
            value: true,
          }
        ]
      }
    ],
  },
  {
    settingsId: 'user',
    text: 'ユーザー管理',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'users_service',
        value: true,
      },
      {
        text: '非アクティブ ユーザー表示',
        key: 'inactive_user_display',
        value: true,
      },
      {
        text: '新規ユーザー登録',
        key: 'user_regist',
        value: true,
      },
      {
        text: 'ユーザー情報編集',
        key: 'user_edit',
        value: true,
      },
      {
        text: 'ユーザー削除',
        key: 'user_delete',
        value: true,
      }
    ]
  },
  {
    settingsId: 'logs',
    text: '更新履歴',
    subMenu: [
      {
        text: '基本サービス利用',
        key: 'logs',
        value: true,
      }
    ]
  }
]