import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { IUserCreate, IUserEdit } from "types/IUsers"

export const UserApi = () => {
  const getList = async (page: number, showDeleted:boolean = false, limit: number = -1, site_id:number = -1, freewordValue: string = '') => {
    let url = showDeleted ? `/user/list?page=${ page }&with_trashed=true` : `/user/list?page=${ page }`
    url += limit > 0 ? `&limit=${ limit }` : '';
    url += site_id > 0 ? `&site_id=${ site_id }` : '';
    url += freewordValue != '' ? `&freeword=${freewordValue}`: '';
    const response = await FetchHeader(url, "GET");
    return response;
  };

  const getDetail = async (id: string | number) => {
    const response = await FetchHeader(`/user/detail/${id}`, "GET");
    return response;
  };

  const createUser = async (payload: IUserCreate) => {
    return await FetchHeader(`/user/create`, "POST", payload);
  };

  const updateUser = async (id: string | number, payload: IUserEdit) => {
    return await FetchHeader(`/user/edit/${id}`, "POST", payload);
  };

  const deleteUser = async (id: string | number) => {
    return await FetchHeader(`/user/delete/${id}`, "POST");
  };

  return {
    getList,
    getDetail,
    createUser,
    updateUser,
    deleteUser
  };
};
