import React from "react";

export default function ArrowIcon() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5767 10.3204C19.3709 10.3204 19.165 10.2504 19.0025 10.1004L13.0009 4.56043L6.99919 10.1004C6.68503 10.3904 6.16503 10.3904 5.85086 10.1004C5.53669 9.81043 5.53669 9.33043 5.85086 9.04043L12.4267 2.97043C12.7409 2.68043 13.2609 2.68043 13.575 2.97043L20.1509 9.04043C20.465 9.33043 20.465 9.81043 20.1509 10.1004C19.9992 10.2504 19.7825 10.3204 19.5767 10.3204Z" fill="#DCE4E8"/>
      <path d="M13 21.2499C12.5558 21.2499 12.1875 20.9099 12.1875 20.4999V3.66992C12.1875 3.25992 12.5558 2.91992 13 2.91992C13.4442 2.91992 13.8125 3.25992 13.8125 3.66992V20.4999C13.8125 20.9099 13.4442 21.2499 13 21.2499Z" fill="#DCE4E8"/>
    </svg>
  );
}
