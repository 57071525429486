import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Loader,
  Text,
  Heading,
} from "@aws-amplify/ui-react";

// types
import { IAccountDetail } from "../../types/IAccount";

// api
import { UserApi } from "../../api/user";
import { AuthorityContext } from '../../App'

export default function AccountPage() {
  const { userName } = useParams();
  const api = UserApi();
  const { site }: any = useContext(AuthorityContext);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [tableData, setTableData] = useState<IAccountDetail>({
    company_id: '',
    user_name: '',
    user_phone_number: '',
    user_email: '',
    affiliation: ''
  });
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/user/list", label: "ユーザー管理" },
    { href: "", label: "ユーザー詳細" },
  ];

  const navigate = useNavigate();
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  // const userName = localStorage.getItem("username") ?? "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteName, setSiteName] = useState('')

  // const openModal: () => {} = () => {
  //   setIsModalOpen(true);
  //   return {};
  // };

  const handleDelete = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate("/user/list");
  };

  // useEffect(() => {
  //   const getList = async () => {
  //     setLoader(false);
  //     setTableData({
  //       name: 'A山 A太郎',
  //       affiliation: 'A工業',
  //       number: '0300000000',
  //       email: 'example@diace.co.jp',
  //       site: '〇〇区〇〇プロジェクト',
  //     });
  //     // @ts-ignore
  //     // const { data, status } = await api.getDetail(userId);
  //     // if (status === 200) {
  //     //   setLoader(false);
  //     //   setFetchError(false);
  //     //   setTableData({
  //     //     id: data?.data?.user_id,
  //     //     affiliation: data?.data?.company_name,
  //     //     phoneNumber: data?.data?.user_phone_number,
  //     //     emailAddress: data?.data?.user_email,
  //     //     group: "権限グループ",
  //     //     note: data?.data?.remarks,
  //     //   });
  //     // } else {
  //     //   setFetchError(true);
  //     // }
  //   };
  //   getList();

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    const getDetail = async () => {
      setLoader(true);
      // @ts-ignore
      const { data, status } = await api.getDetail(userName);
      if (status === 200) {
        setLoader(false);
        setFetchError(false);
        setTableData({
          user_name: data?.data?.user_name,
          company_id: data?.data?.company_id,
          user_phone_number: data?.data?.user_phone_number,
          user_email: data?.data?.user_email,
          affiliation: data?.data?.company_name
        });
      } else {
        setFetchError(true);
      }
    };

    // const getCompanyList = async () => {
    //   // setLoader(true);
    //   const { data } = await companyApi.getList();
    //   if (data?.success) {
    //     setCompanyList([...data?.data])
    //   }
    // };

    // getCompanyList()
    getDetail();
    site?.map((item: any) => {
      setSiteName(item?.site_name)
      return item
    })
    // eslint-disable-next-line
  }, [site]);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="custom-table">
        {!loader ? (
          <>
            <Heading level={2} fontSize={'24px'} fontWeight={'bold'}>{ userName }</Heading>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell fontWeight={700} width="50%" border="0">
                    名前
                  </TableCell>
                  <TableCell border="0">{tableData.user_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">
                    所属法人
                  </TableCell>
                  <TableCell>{tableData.affiliation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">
                    電話番号
                  </TableCell>
                  <TableCell>{tableData.user_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell fontWeight={700} width="50%">
                    メールアドレス
                  </TableCell>
                  <TableCell>{tableData.user_email}</TableCell>
                </TableRow>
               {/* <TableRow>
                  <TableCell fontWeight={700} width="50%">
                    所属現場
                  </TableCell>
                  <TableCell>{ siteName }</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
            <View as="p" fontWeight={'500'} marginTop={'20px'}>※パスワードを変更したい場合は、ログイン画面「パスワードを忘れた方はこちら」より変更をお願いします。</View>
            <Flex marginTop="20px" justifyContent={'flex-end'} className="hide-from-print">
              <CustomButton
                {...defaultButtonProps}
                text="アカウント情報の編集"
                size='medium'
                width='medium'
                borderRadius='large'
                click={() => navigate(`/account/edit/email/${userName}`)}
              />
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
        {fetchError && (
          <Text className="error-fetch-message">
            Error in fetching data. Please contact administrator.
          </Text>
        )}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading={`ユーザー「${tableData.user_name}」の情報を削除します。よろしいですか？`}
          textOperation="この操作は取り消せません。本当に削除しますか？"
        >
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={handleDelete}
          />
          <CustomButton
            {...defaultButtonProps}
            text="ユーザー情報を削除"
            type="warning"
            iconName=""
            size="large"
            click={handleCancel}
          />
        </CommonModal>
      </View>
    </PageContent>
  );
}
