import { TableCell, TableRow, TableBody } from "@aws-amplify/ui-react";
import React, { memo } from "react";
import CalendarEvents from "./calendar-events";
import "../../../../../styles/custom-calendar.scss";

export default memo(function CalendarBody({
  weeks,
  eventList,
  handleEventClick,
}: {
  weeks: any[];
  eventList: any[];
  handleEventClick: (
    hasEvent: boolean,
    defaultDate: string,
    company_name: any,
    company_id: any
  ) => any;
}) {
  return (
    <>
      <TableBody>
        {weeks.map((week, weekIndex) => (
          <TableRow key={weekIndex}>
            {(week as any).map((day: any, dayIndex: any) => (
              <TableCell
                key={dayIndex}
                className={day.isCurrentMonth ? "current-month" : "other-month"}
              >
                <CalendarEvents
                  handleEventClick={handleEventClick}
                  day={day}
                  eventList={eventList}
                />
                {/* Display the day number */}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
});

export const CalendarBodySP = memo(function CalendarBodySP({
  weeks,
  eventList,
  handleEventClick,
}: {
  weeks: any[];
  eventList: any[];
  handleEventClick: (
    hasEvent: boolean,
    defaultDate: string,
    company_name: any,
    company_id: any
  ) => any;
}) {
  return (
    <>
      <TableBody style={{ position: "relative", right: "20px" }}>
        {weeks.map((week, weekIndex) => (
          <TableRow key={weekIndex}>
            {(week as any).map((day: any, dayIndex: any) => (
              <TableCell
                key={dayIndex}
                className={day.isCurrentMonth ? "current-month" : "other-month"}
              >
                <CalendarEvents
                  handleEventClick={handleEventClick}
                  day={day}
                  eventList={eventList}
                />
                {/* Display the day number */}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
});
