import FetchHeader from "../../utils/fetchHeader";

// interfaces
import { CreatePost, UpdatePost, PatchPost } from "types/test";

export const PostsApi = () => {
  const getList = async () => {
    const response = await FetchHeader("/eq/list", "GET");
    return response;
  };

  const getPost = async (id: string | number) => {
    const response = await FetchHeader(`/eq/detail/${id}`, "GET");
    return response;
  };

  const createPost = async (payload: CreatePost) => {
    return await FetchHeader(`/eq/create`, "POST", payload);
  };

  const updatePost = async (id: string | number, payload: UpdatePost) => {
    return await FetchHeader(`/eq/${id}`, "PUT", payload);
  };

  const patchPost = async (id: string | number, payload: PatchPost) => {
    return await FetchHeader(`/eq/${id}`, "PATCH", payload);
  };

  const deletePost = async (id: string | number) => {
    return await FetchHeader(`/eq/${id}`, "DELETE");
  };

  return {
    getList,
    getPost,
    createPost,
    updatePost,
    patchPost,
    deletePost,
  };
};
