import React, { useEffect, useState } from "react";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Text,
  Heading,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

import { IEqCreate } from "../../types/IEq";

// api
import { EqApi } from "../../api/eq";
import { CompanyApi } from "../../api/company";
import { SiteApi } from "../../api/site";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EqCreatePage() {
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const navigate = useNavigate();
  const api = EqApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasFormError, setHasFormError] = useState(false);
  const [categoryList, setCategoryList] = useState([] as any);
  const [siteList, setSiteList] = useState([] as any);
  const [companyList, setCompanyList] = useState([] as any);
  const [categorySecondary, setCategorySecondary] = useState({} as any);
  const [eqNameError, setEqNameError] = useState<boolean | void>(false);
  const [rentalCompanyError, setRentalCompanyError] = useState<boolean | void>(false);
  const [categoryError, setCategoryError] = useState<boolean | void>(false);
  const [siteIdError, setSiteIdError] = useState<boolean | void>(false);
  const [payload, setPayload] = useState<IEqCreate>({
    site_manage_no: "",
    equipment_name: "",
    equipment_category_id: "",
    equipment_category_id_secondary: "",
    model: "",
    person_in_charge: "",
    site_id: "",
    rental_company: "",
    quantity: "",
    total_operating_hours: "",
    operation_time_at_start_use: "",
    delivery_schedule: "",
    delivery_schedule_time: "",
    return_schedule: "",
    return_schedule_time: "",
    remarks: "",
  });

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "", label: "新規登録" },
  ];

  const openModal = () => {
    let errorMessageSet = [];
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setEqNameError(() => {
      return payload.equipment_name === "" ? true : false;
    });
    setCategoryError(() => {
      return payload.equipment_category_id === "" ? true : false;
    });
    setSiteIdError(() => {
      return payload.site_id === "" ? true : false;
    });
    setRentalCompanyError(()=>{
      return payload.rental_company === "" ? true : false;
    });
    if(
      payload.equipment_name === "" 
    ){
      errorMessageSet.push("機材名を入力してください。")
    }
    if(
      payload.equipment_category_id === ""
    ){
      errorMessageSet.push("機材カテゴリーを選択してください。")
    }
    if(
      payload.site_id === ""
    ){
      errorMessageSet.push("使用場所を選択してください。")
    }
    if(
      payload.rental_company === ""
    ){
      errorMessageSet.push("レンタル会社を選択してください。")
    }
    //console.log(errorMessageSet)
    setErrorMessage(errorMessageSet.join("\n"));

    if (
      payload.equipment_name !== "" &&
      payload.equipment_category_id !== "" &&
      payload.site_id !== "" &&
      payload.rental_company !== ""
    ) {
      setIsModalOpen(true);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const closeModal = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsModalOpen(false);
  };

  const handleChange = (item: any, value: any) => {
    if (item === "equipment_category_id") {
      categoryList.map((item: any) => {
        if (value === item.equipment_category_id) {
          setCategorySecondary(item?.secondary_equipment_categories);
          return item;
        }
        return item;
      });
      setPayload({ ...payload, [item]: value, "equipment_category_id_secondary": value });
    }else{
      setPayload({ ...payload, [item]: value });
    }
  };
  const convertDateTimeInputToPayloadValue = (
    datePart: string,
    timePart: string
  ): string | null => {
    if (datePart === "") {
      return null;
    } else {
      if (timePart === "") {
        return datePart + " 00:00:00";
      } else {
        return datePart + " " + timePart;
      }
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");
    const createPayload = {
      site_manage_no: payload.site_manage_no,
      equipment_name: payload.equipment_name,
      equipment_category_id: payload.equipment_category_id_secondary,
      model: payload.model,
      person_in_charge: payload.person_in_charge,
      site_id: payload.site_id,
      rental_company: payload.rental_company,
      quantity: payload.quantity || 0,
      total_operating_hours: payload.total_operating_hours || 0,
      operation_time_at_start_use: payload.operation_time_at_start_use || 0,
      delivery_schedule: convertDateTimeInputToPayloadValue(
        payload.delivery_schedule,
        payload.delivery_schedule_time
      ),
      return_schedule: convertDateTimeInputToPayloadValue(
        payload.return_schedule,
        payload.return_schedule_time
      ),
      remarks: payload.remarks,
    };

    try {
      // @ts-ignore
      const { data } = await api.createEq(createPayload);
      if (data?.status === "success") {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/eq/list`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }

      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains('hidden')) {
        // @ts-ignore
        document.querySelector(".main-body")?.classList.remove('hidden');
      }
      // document.querySelector(".main-body").style.overflow = "auto";
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const getCompanyList = async () => {
    // setLoader(true);
    const { data, status } = await companyApi.getList();
    if (status === 200) {
      const mappedCompanyList = data?.data?.filter(
        (item: any) => item.company_category_id === "200"
      );
      setCompanyList([...mappedCompanyList]);
    }
  };

  const getSiteList = async () => {
    // setLoader(true);
    const { data, status } = await siteApi.getList();
    if (status === 200) {
      setSiteList([...data?.data]);
    }
  };

  const getCategoryList = async () => {
    // setLoader(true);
    const { data, status } = await api.getCategoryList();
    if (status === 200) {
      setCategoryList([...data?.data]);
    }
  };

  useEffect(() => {
    getCategoryList();
    getSiteList();
    getCompanyList();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContent breadcrumbs={breadCrumbsItems} title={pageTitle}>
      <View className="main-content">
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          <Heading level={6} className="form-note">
            ※機材IDは自動で生成されます。
          </Heading>
          {errorMessage !== "" || hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              {errorMessage?.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}              
            </Flex>
          ) : null}
          <TextField
            label="機材名"
            placeholder="機材名を入力してください"
            value={payload.equipment_name}
            isRequired
            className={eqNameError ? `required input-error` : "required"}
            onChange={(event) =>
              handleChange("equipment_name", event.target.value)
            }
          />

          <Flex>
            <SelectField
              label="機材カテゴリー(大)"
              placeholder="選択してください"
              value={payload.equipment_category_id}
              isRequired
              className={`required ${categoryError ? 'input-error' : ''} ${payload.equipment_category_id === '' ? 'no-value' : ''}`}
              onChange={(event) =>
                handleChange("equipment_category_id", event.target.value)
              }
              icon={<SelectIcon />}
            >
              {categoryList?.map((item: any, index: any) => {
                return (
                  <option value={item?.equipment_category_id} key={index}>
                    {item?.equipment_category1_name}
                  </option>
                );
              })}
            </SelectField>
            <SelectField
              label="機材カテゴリー(中)"
              placeholder={payload.equipment_category_id_secondary!=="" ? "" : "選択してください"}
              value={payload.equipment_category_id_secondary}
              isRequired
              className={`required ${categoryError ? 'input-error' : ''} ${payload.equipment_category_id_secondary === '' ? 'no-value' : ''}`}
              //className={categoryError ? `input-error` : ""}
              onChange={(event) =>
                handleChange("equipment_category_id_secondary", event.target.value)
              }
              icon={<SelectIcon />}
            >
              {Object.keys(categorySecondary??{})?.map((item: any, index: any) => {
                return (
                  <option value={item} key={index}>
                    {categorySecondary[item]}
                  </option>
                );
              })}
            </SelectField>
          </Flex>
          <TextField
            label="型式"
            placeholder="型式"
            value={payload.model}
            onChange={(event) => handleChange("model", event.target.value)}
            type="email"
          />
          <TextField
            label="発注担当者"
            placeholder="ダイヤモンド 太郎"
            value={payload.person_in_charge}
            onChange={(event) =>
              handleChange("person_in_charge", event.target.value)
            }
          />
          <SelectField
            label="使用現場"
            placeholder="現場名を選択してください"
            value={payload.site_id}
            isRequired
            className={`required ${siteIdError ? `required input-error` : 'required'} ${payload.site_id === '' ? 'no-value' : ''}`}
            onChange={(event) => handleChange("site_id", event.target.value)}
            icon={<SelectIcon />}
          >
              {siteList?.filter((item: any) => item.status === true).map((item: any, index: number) => (
                <option value={item.site_id} key={index}>
                  {item.site_name}
                </option>
              ))}

          </SelectField>
          <TextField
            className="input-with-note"
            label="現場管理No."
            placeholder="AAA-00001"
            value={payload.site_manage_no}
            onChange={(event) =>
              handleChange("site_manage_no", event.target.value)
            }
          />
          <Text className="input-note">
            レンタル会社管理No.ではなく、現場管理No.を入力してください。
          </Text>
          <SelectField
            label="レンタル会社"
            placeholder="選択してください"
            value={payload.rental_company}
            isRequired
            className={`${rentalCompanyError ? `required input-error` : "required"} ${payload.rental_company === '' ? 'no-value' : ''}`}
            onChange={(event) =>
              handleChange("rental_company", event.target.value)
            }
            icon={<SelectIcon />}
          >
            {companyList?.map((item: any, index: any) => {
              return (
                <option value={item?.company_id} key={index}>
                  {item?.company_name}
                </option>
              );
            })}
          </SelectField>
          <TextField
            label="数量"
            placeholder="0"
            value={payload.quantity}
            onChange={(event) => handleChange("quantity", event.target.value)}
          />
          <Flex>
            <TextField
              label="総稼働時間（hour）"
              placeholder="0"
              value={payload.total_operating_hours}
              onChange={(event) =>
                handleChange("total_operating_hours", event.target.value)
              }
            />
            <TextField
              label="使用開始時稼働時間（hour）"
              placeholder="0"
              value={payload.operation_time_at_start_use}
              onChange={(event) =>
                handleChange("operation_time_at_start_use", event.target.value)
              }
            />
          </Flex>
          <Flex width={"49%"} className="blocked-field">
            <TextField
              type="date"
              label="搬入予定日"
              placeholder="2024/01/01"
              style={{ color: `${!payload.delivery_schedule ? '#acb5bb' : '#0D1A1F'}` }}
              value={payload.delivery_schedule}
              onChange={(event) =>
                handleChange("delivery_schedule", event.target.value)
              }
            />
            <TextField
              type="time"
              label="搬入予定時間"
              placeholder="08:00"
              style={{ color: `${!payload.delivery_schedule_time ? '#acb5bb' : '#0D1A1F'}` }}
              value={payload.delivery_schedule_time}
              step={1}
              onChange={(event) =>
                handleChange("delivery_schedule_time", event.target.value)
              }
            />
          </Flex>
          <Flex width={"49%"} className="blocked-field">
            <TextField
              type="date"
              label="返却予定日"
              placeholder="2024/01/01"
              style={{ color: `${!payload.return_schedule ? '#acb5bb' : '#0D1A1F'}` }}
              value={payload.return_schedule}
              onChange={(event) =>
                handleChange("return_schedule", event.target.value)
              }
            />
            <TextField
              type="time"
              label="返却予定時間"
              placeholder="08:00"
              value={payload.return_schedule_time}
              style={{ color: `${!payload.return_schedule_time ? '#acb5bb' : '#0D1A1F'}` }}
              step={1}
              onChange={(event) =>
                handleChange("return_schedule_time", event.target.value)
              }
            />
          </Flex>
          <TextAreaField
            label="備考"
            placeholder="備考を入力してください"
            value={payload.remarks}
            onChange={(event) => handleChange("remarks", event.target.value)}
            maxLength={100}
          />
          <Text className="textarea-length" as="span">
            0/100
          </Text>
          <Flex
            justifyContent="flex-end"
            marginTop="1rem"
            alignItems={`center`}
            direction={{
              base: "column",
              medium: "row",
            }}
          >
            <CustomButton
              {...defaultButtonProps}
              text="登録"
              size="medium"
              width="medium"
              borderRadius="large"
              click={() => openModal()}
            />
            <CustomButton
              {...defaultButtonProps}
              text="キャンセル"
              type="bordered-transparent"
              click={() => showCancelModal()}
            />
            {/* submit modal */}
            <CommonModal
              isOpen={isModalOpen}
              onClose={() => closeModal()}
              icon="icon"
              textHeading="機材情報を登録します。よろしいですか？"
            >
              <View margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="機材情報を登録する"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  iconName={disableButton ? "loader" : ""}
                  iconPosition="right"
                  disabled={disableButton}
                  click={() => handleSubmit()}
                />
              </View>
            </CommonModal>

            {/* cancel modal */}
            <CommonModal
              isOpen={isCancelModal}
              textHeading="入力内容を破棄して一覧へ戻りますか？"
              type="desktop"
            >
              <Flex justifyContent="center" margin={`0 auto`}>
                <CustomButton
                  {...defaultButtonProps}
                  text="編集へ戻る"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="破棄"
                  size="medium"
                  width="small"
                  click={() => navigate("/eq/list")}
                />
              </Flex>
            </CommonModal>
          </Flex>
        </View>
      </View>
    </PageContent>
  );
}
