import React, { useContext, useEffect, useState } from "react";
import { AuthorityContext } from "../../App";

import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

import {
  TextField,
  SelectField,
  TextAreaField,
  View,
  Flex,
  Text,
  Heading,
  Loader,
  Divider,
} from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import SelectIcon from "../../components/icons/select-icon";

// types
import { IEqUpdate } from "../../types/IEq";

// api
import { EqApi } from "../../api/eq";
import { CompanyApi } from "../../api/company";

// utils
import formatDate from "../../utils/formatDate";
// import { timeMachRegex } from "../../utils/validateTime";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export default function EqEditPage() {
  const { company, settings }: any = useContext(AuthorityContext);
  // const pageTitle = localStorage.getItem('currentPage') ?? ''
  const navigate = useNavigate();
  const api = EqApi();
  const companyApi = CompanyApi();
  const { equipmentId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasFormError, setHasFormError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [deliceryUserName, setDeliveryUserName] = useState("");
  const [returnUserName, setReturnUserName] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [companyList, setCompanyList] = useState([] as any);
  const [disableButton, setDisableButton] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [payload, setPayload] = useState<IEqUpdate>({
    site_id: "",
    equipment_category_id: "",
    identification_tag: {},
    eq_name: "",
    category_1: "",
    category_2: "",
    model: "",
    person_in_charge: "",
    field_of_use: "",
    management_no: "",
    rental_company: "",
    volume: "",
    total_hours: "",
    operation_start_use: "",
    delivery_schedule: "",
    delivery_schedule_time: "",
    actual_delivery: "",
    actual_delivery_time: "",
    return_schedule: "",
    return_schedule_time: "",
    actual_return: "",
    actual_return_time: "",
    result_return_user_id: "",
    result_delivery_user_id: "",
    updated_at: "",
    updated_at_time: "",
    inventories_register_user_name: "",
    location: "",
    note: "",
  });

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: "/eq/list", label: "機材管理" },
    { href: "/eq/list", label: "機材一覧" },
    { href: "", label: "機材情報編集" },
  ];

  useEffect(() => {
    if (isModalOpen) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.add("hidden");
    }
  }, [isModalOpen]);

  const openModal = () => {
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add("hidden");
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains("hidden")) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.remove("hidden");
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsModalOpen(false);
  };

  const handleChange = (item: any, value: any) => {
    setPayload({ ...payload, [item]: value });
    if (item === "note") {
      setNoteLength(value?.length);
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    setErrorMessage("");
    let createPayload: { [key: string]: any } = {
      model: payload.model || null,
      charge_ordering: payload.person_in_charge || null,
      onsite_manage_no: payload.management_no || null,
      rental_company: payload.rental_company || null,
      quantity: payload.volume || null,
      total_operating_hours: payload.total_hours || null,
      operation_time_at_start_use: payload.operation_start_use || null,
      remarks: payload.note || "",
      delivery_schedule: payload.delivery_schedule || null,
      delivery_schedule_time: payload.delivery_schedule_time || null,
      result_delivery_schedule: payload.actual_delivery || null,
      result_delivery_schedule_time: payload.actual_delivery_time || null,
      return_schedule: payload.return_schedule || null,
      return_schedule_time: payload.return_schedule_time || null,
      result_return_schedule: payload.actual_return || null,
      result_return_schedule_time: payload.actual_return_time || null,
    };
    //remove data having null value.
    const submitCreatePayload: { [key: string]: any } = {};
    Object.keys(createPayload).forEach((key: string) => {
      if (createPayload[key] !== null) {
        submitCreatePayload[key] = createPayload[key];
      }
    });

    try {
      // @ts-ignore
      const { data } = await api.updateEq(equipmentId, createPayload);
      if (data?.success) {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(false);
        setErrorMessage("");
        navigate(`/eq/${equipmentId}`);
      } else {
        setDisableButton(false);
        setIsModalOpen(false);
        setHasFormError(true);
        const errors = JSON.stringify(data?.error?.errors);
        if (errors.includes("must_be_date")) {
          setErrorMessage(data?.error?.message + ": Invalid date format.");
        } else if (errors.includes("must_match_regex")) {
          setErrorMessage(data?.error?.message + ": Invalid time format.");
        } else {
          setErrorMessage(data?.error?.message);
        }
      }

      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains("hidden")) {
        document.querySelector(".main-body")?.classList.remove("hidden");
      }
      // document.querySelector(".main-body").style.overflow = "auto";
    } catch (err) {
      console.log(err);
    }
  };

  const showCancelModal = () => {
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setIsCancelModal(true);
  };

  useEffect(() => {
    const getDetail = async () => {
      setLoader(true);
      // @ts-ignore
      const { data } = await api.getDetail(equipmentId);
      if (data?.success) {
        setLoader(false);
        setFetchError(false);
        setNoteLength(data?.data?.remarks?.length);
        setSiteName(data?.data?.site_name);
        setPayload({
          site_id: data?.data?.site_id,
          equipment_category_id: data?.data?.equipment_category_id,
          identification_tag: data?.data?.identification_tag,
          eq_name: data?.data?.equipment_name,
          category_1: data?.data?.equipment_category1_name,
          category_2: data?.data?.equipment_category2_name,
          model: data?.data?.model,
          person_in_charge: data?.data?.charge_ordering,
          field_of_use: data?.data?.last_use_actual_use_location,
          management_no: data?.data?.onsite_manage_no,
          rental_company: data?.data?.rental_company,
          volume: data?.data?.quantity,
          total_hours: data?.data?.total_operating_hours,
          operation_start_use: data?.data?.operation_time_at_start_use,

          delivery_schedule: formatDate(null, data?.data?.delivery_schedule),
          delivery_schedule_time: formatDate(
            "time",
            data?.data?.delivery_schedule
          ),

          actual_delivery: formatDate(
            null,
            data?.data?.result_delivery_schedule
          ),
          actual_delivery_time: formatDate(
            "time",
            data?.data?.result_delivery_schedule
          ),

          return_schedule: formatDate(null, data?.data?.return_schedule),
          return_schedule_time: formatDate("time", data?.data?.return_schedule),

          actual_return: formatDate(null, data?.data?.result_return_schedule),
          actual_return_time: formatDate(
            "time",
            data?.data?.result_return_schedule
          ),
          result_delivery_user_id: data?.data?.result_delivery_user_id,
          result_return_user_id: data?.data?.result_return_user_id,
          updated_at: formatDate(null, data?.data?.inventory_datetime),
          updated_at_time: formatDate("time", data?.data?.inventory_datetime),
          inventories_register_user_name:
            data?.data?.inventories_register_user_name,
          location: data?.data?.site_construction_location_name,
          //location: data?.data?.site_construction_location_name.length > 0 ? data?.data?.site_construction_location_name.length : '-',
          note: data?.data?.remarks,
        });
        setDeliveryUserName(data?.data?.eq_tbl_result_delivery_user_name ?? "");
        setReturnUserName(data?.data?.eq_tbl_result_return_user_name ?? "");
      } else {
        setFetchError(true);
      }
    };

    const getCompanyList = async () => {
      const { data, status } = await companyApi.getList();
      if (status === 200) {
        const mappedCompanyList = data?.data?.filter(
          (item: any) => item.company_category_id === "200"
        );
        setCompanyList([...mappedCompanyList]);
      }
    };

    getCompanyList();
    getDetail();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={payload.equipment_common_name ?? payload.eq_name}
    >
      <View className="main-content">
        <View as="form" onSubmit={handleSubmit} className="custom-form">
          <Heading level={6} className="form-note">
            ※機材IDは自動で生成されます。
          </Heading>
          {errorMessage !== "" || hasFormError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          ) : null}
          {!loader ? (
            <>
              <TextField
                label="機材名"
                placeholder="------"
                value={payload.eq_name}
                onChange={(event) =>
                  handleChange("eq_name", event.target.value)
                }
                disabled
              />
              <Flex>
                <TextField
                  label="機材カテゴリー"
                  placeholder="------"
                  value={payload.category_1}
                  disabled
                />
                <TextField
                  label=""
                  placeholder="------"
                  value={payload.category_2}
                  disabled
                />
              </Flex>
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "model"
                  ))) && (
                <TextField
                  label="型式"
                  placeholder="------"
                  value={payload.model}
                  onChange={(event) =>
                    handleChange("model", event.target.value)
                  }
                  type="email"
                />
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "charge_ordering"
                  ))) && (
                <TextField
                  label="発注担当者"
                  placeholder="------"
                  value={payload.person_in_charge}
                  onChange={(event) =>
                    handleChange("person_in_charge", event.target.value)
                  }
                />
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "site_of_use"
                  ))) && (
                <TextField
                  label="使用現場"
                  placeholder="〇〇区〇〇プロジェクト"
                  disabled
                  value={siteName}
                  //onChange={(event) =>
                  //handleChange("field_of_use", event.target.value)
                  //}
                />
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "onsite_manage_no"
                  ))) && (
                <TextField
                  className="input-with-note"
                  label="現場管理No."
                  placeholder="AAA-00001"
                  value={payload.management_no}
                  //disabled
                  onChange={(event) =>
                    handleChange("management_no", event.target.value)
                  }
                />
              )}
              <Text className="input-note">
                レンタル会社管理No.ではなく、現場管理No.を入力してください。
              </Text>
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "rental_company"
                  ))) && (
                <SelectField
                  label="レンタル会社"
                  placeholder="選択してください"
                  value={payload.rental_company}
                  onChange={(event) =>
                    handleChange("rental_company", event.target.value)
                  }
                  icon={<SelectIcon />}
                >
                  {companyList?.map((item: any, index: any) => {
                    return (
                      <option value={item?.company_id} key={index}>
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "quantity"
                  ))) && (
                <TextField
                  label="数量"
                  placeholder="0"
                  value={payload.volume}
                  onChange={(event) =>
                    handleChange("volume", event.target.value)
                  }
                />
              )}
              <Flex>
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "total_operating_hour"
                    ))) && (
                  <TextField
                    label="総稼働時間（hour）"
                    placeholder="0"
                    value={payload.total_hours}
                    onChange={(event) =>
                      handleChange("total_hours", event.target.value)
                    }
                    className="input-with-note"
                  />
                )}
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "operating_time_at_the_start_of_use"
                    ))) && (
                  <TextField
                    label="使用開始時稼働時間（hour）"
                    placeholder="0"
                    value={payload.operation_start_use}
                    onChange={(event) =>
                      handleChange("operation_start_use", event.target.value)
                    }
                    className="input-with-note"
                  />
                )}
              </Flex>
              <Divider className="form-divider" />
              <Flex width={"49%"} className="blocked-field">
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "delivery_schedule_date"
                    ))) && (
                  <TextField
                    type="date"
                    label="受入予定日"
                    placeholder="2024/01/01"
                    value={payload.delivery_schedule}
                    onChange={(event) =>
                      handleChange("delivery_schedule", event.target.value)
                    }
                  />
                )}
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "delivery_schedule_time"
                    ))) && (
                  <TextField
                    type="time"
                    label="受入予定時間"
                    placeholder="08:00"
                    value={payload.delivery_schedule_time}
                    step={1}
                    onChange={(event) =>
                      handleChange("delivery_schedule_time", event.target.value)
                    }
                  />
                )}
              </Flex>
              <Flex className="blocked-field">
                <Flex width={"49%"}>
                  {(company?.admin_flag === true ||
                    (settings &&
                      settings[payload.site_id]?.page_control?.includes(
                        "result_delivery_schedule_date"
                      ))) && (
                    <TextField
                      type="date"
                      label="受入日"
                      placeholder="2024/01/01"
                      value={payload.actual_delivery}
                      onChange={(event) =>
                        handleChange("actual_delivery", event.target.value)
                      }
                    />
                  )}
                  {(company?.admin_flag === true ||
                    (settings &&
                      settings[payload.site_id]?.page_control?.includes(
                        "result_delivery_schedule_time"
                      ))) && (
                    <TextField
                      type="time"
                      label="受入時間"
                      placeholder="08:00"
                      value={payload.actual_delivery_time}
                      step={1}
                      onChange={(event) =>
                        handleChange("actual_delivery_time", event.target.value)
                      }
                    />
                  )}
                </Flex>
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "receiving_person"
                    ))) && (
                  <TextField
                    width={"49%"}
                    label="受入担当者"
                    placeholder="------"
                    value={deliceryUserName}
                    disabled
                  />
                )}
              </Flex>
              <Flex width={"49%"} className="blocked-field">
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "scheduled_return_date"
                    ))) && (
                  <TextField
                    type="date"
                    label="返却予定日"
                    placeholder="2024/01/01"
                    value={payload.return_schedule}
                    onChange={(event) =>
                      handleChange("return_schedule", event.target.value)
                    }
                  />
                )}
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "scheduled_return_time"
                    ))) && (
                  <TextField
                    type="time"
                    label="返却予定時間"
                    placeholder="08:00"
                    value={payload.return_schedule_time}
                    step={1}
                    onChange={(event) =>
                      handleChange("return_schedule_time", event.target.value)
                    }
                  />
                )}
              </Flex>
              <Flex className="blocked-field">
                <Flex width={"49%"}>
                  {(company?.admin_flag === true ||
                    (settings &&
                      settings[payload.site_id]?.page_control?.includes(
                        "return_date"
                      ))) && (
                    <TextField
                      type="date"
                      label="返却日"
                      placeholder="2024/01/01"
                      value={payload.actual_return}
                      onChange={(event) =>
                        handleChange("actual_return", event.target.value)
                      }
                    />
                  )}
                  {(company?.admin_flag === true ||
                    (settings &&
                      settings[payload.site_id]?.page_control?.includes(
                        "return_time"
                      ))) && (
                    <TextField
                      type="time"
                      label="返却時間"
                      placeholder="08:00"
                      value={payload.actual_return_time}
                      step={1}
                      onChange={(event) =>
                        handleChange("actual_return_time", event.target.value)
                      }
                    />
                  )}
                </Flex>
                {(company?.admin_flag === true ||
                  (settings &&
                    settings[payload.site_id]?.page_control?.includes(
                      "person_in_charge_of_return"
                    ))) && (
                  <TextField
                    width={"49%"}
                    label="返却担当者"
                    placeholder="------"
                    value={returnUserName}
                    disabled
                  />
                )}
              </Flex>
              <Flex className="blocked-field">
                <Flex width={"49%"}>
                  <TextField
                    type="date"
                    label="最終棚卸日"
                    placeholder="2024/01/01"
                    value={payload.updated_at}
                    disabled
                    className="input-with-note"
                  />
                  <TextField
                    type="time"
                    label="最終棚卸時間"
                    placeholder="08:00"
                    value={payload.updated_at_time}
                    step={1}
                    disabled
                    className="input-with-note"
                  />
                </Flex>
                <Flex width={"49%"}>
                  <TextField
                    label="確認者"
                    placeholder="------"
                    value={payload.inventories_register_user_name}
                    disabled
                    className="input-with-note"
                  />
                  <TextField
                    label="場所"
                    placeholder="------"
                    value={payload.location}
                    disabled
                    className="input-with-note"
                  />
                </Flex>
              </Flex>
              <Divider className="form-divider" />
              {(company?.admin_flag === true ||
                (settings &&
                  settings[payload.site_id]?.page_control?.includes(
                    "remarks"
                  ))) && (
                <TextAreaField
                  label="備考"
                  placeholder="機材の状態について特記事項があれば記入してください。"
                  value={payload.note}
                  onChange={(event) => handleChange("note", event.target.value)}
                  maxLength={100}
                />
              )}
              <Text className="textarea-length" as="span">
                {noteLength ?? 0}/100
              </Text>
              <Flex
                justifyContent="flex-end"
                marginTop="1rem"
                alignItems={`center`}
                direction={{
                  base: "column",
                  medium: "row",
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text="機材情報を更新"
                  size="medium"
                  width="medium"
                  borderRadius="large"
                  click={openModal}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text="キャンセル"
                  type="bordered-transparent"
                  click={showCancelModal}
                />
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
        {/* submit modal */}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          icon="icon"
          textHeading={`機材「${payload.eq_name}」の情報を更新します。よろしいですか？`}
        >
          <View margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="機材情報を更新する"
              size="medium"
              width="medium"
              borderRadius="large"
              iconName={disableButton ? "loader" : ""}
              click={() => handleSubmit()}
            />
          </View>
        </CommonModal>

        {/* cancel modal */}
        <CommonModal
          isOpen={isCancelModal}
          textHeading="入力内容を破棄して一覧へ戻りますか？"
          isEqModal
          onClose={() => setIsCancelModal(false)}
        >
          <Flex justifyContent="center" margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="編集へ戻る"
              type="bordered-transparent"
              click={() => setIsCancelModal(false)}
            />
            <CustomButton
              {...defaultButtonProps}
              text="破棄"
              size="xs"
              width="small"
              click={() => navigate("/eq/list")}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
