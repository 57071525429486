import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

// custom components
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
// import MenuDotIcon from "../../components/icons/v2/menu-dot-icon";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  // SelectField,
  // Button,
  IconsProvider,
  Badge,
  Loader,
  Text,
  TableHead
} from "@aws-amplify/ui-react";

// types
import { ICompanyList } from "../../types/ICompany";

// icons
// import SelectIcon from "../../components/icons/select-icon";
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// utils
// import { companyList } from "../../utils/testCompanyList";
import getDeviceType from "../../utils/getDeviceType";

// api
import { CompanyApi } from "../../api/company";

export default function CompanyListPage() {
  const defaultButtonProps = {
    text: '',
    type: 'primary',
    iconPosition: 'left',
    iconName: '',
    size: '',
    disabled: false,
    click: () => { return '' }
  }
  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/company/list', label: '法人一覧' },
  ]
  // const tableData: ICompanyList = {
  //   list: [ ...companyList ]
  // }
  const isMobile = getDeviceType() === 'mobile'
  const api = CompanyApi()
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [dataList, setDataList] = useState([] as any)
  const itemsLimit = 20;
  const pageTitle = localStorage.getItem('currentPage') ?? ''
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [companyDeleteName, setCompanyDeleteName] = useState('')
  const [companyDeleteId, setCompanyDeleteId] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [idSelected, setIdSelected] = useState('');
  const [nameSelected, setNameSelected] = useState('');
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  
  const navigate = useNavigate()

  const addUser = async () => {
    navigate(`/company/create`)
  }

  const editCompany = async (id: any) => {
    navigate(`/company/edit/${id}`)
  }

  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)

  // const indexOfLastUser = currentPageIndex * itemsPerPage;
  // const indexOfFirstUser = (indexOfLastUser - itemsPerPage) + 1;
  // const currentItems = dataList.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    getList(itemsLimit, currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    getList(itemsLimit, currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  // const handleSetItems = (items: number) => {
  //   setItemsPerPage(items);

  //   const newTotalPages = Math.ceil(dataList.length / items);
  //   setTotalPages(newTotalPages);

  //   setCurrentPageIndex(1);
  // };

  const openModal = (id: any, name: any) => {
    setIsActionsModalOpen(false)
    setIsModalOpen(true);
    setCompanyDeleteId(id)
    setCompanyDeleteName(name)
  };

  const handleDelete = () => {
    deleteCompany(companyDeleteId)
  };

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate('/company/list')
  };

  useEffect(() => {
    const mainBody = document.querySelector(".main-body");
  
    if (isModalOpen) {
      mainBody?.classList.add('hidden');  // モーダルが開いたときにクラスを追加
    } else {
      mainBody?.classList.remove('hidden');  // モーダルが閉じたときにクラスを削除
    }
  
    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      mainBody?.classList.remove('hidden');
    };
  }, [isModalOpen]);
  

  const handleActions = (id: any, name: string) => {
    setIdSelected(id);
    setNameSelected(name);
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add('hidden');
    // document.querySelector(".main-body").style.overflow = "hidden";
    document.querySelector(".main-body")?.scroll({ top: 0, behavior: "smooth" });
    setIsActionsModalOpen(true);
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsActionsModalOpen(false);
  };

  const getList = async (
    limit: number = itemsLimit,
    page: number = 1
  ) => {
    setLoader(true);
    const { data, status } = await api.getList(true, page);
    if (status === 200) {
      const mappedList: ICompanyList = data?.data?.map((item: any) => {
        const sites = item.site_affiliated_companies.length > 0 ? item.site_affiliated_companies.map((site: { site_name: any; }) => site.site_name).join(', ') : '-'
        return {
          id: item?.company_id,
          name: item?.company_name,
          site: sites,
          category: item?.company_category_name,
          category_id: item?.company_category_id
        };
      });
      setDataList(mappedList);
      setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      setTotalCount(data?.data?.totalCount || data?.meta?.totalCount)
      setLoader(false);
      setFetchError(false);
    } else {
      setFetchError(true);
    }
  };

  const deleteCompany = async (id: any) => {
    setDisableButton(true)
    setErrorMessage('')
    try {
      const { data } = await api.deleteCompany(id)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(false)
        setErrorMessage('')
        getList(itemsLimit, currentPageIndex)
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasError(true)
        setErrorMessage(data?.error?.message ?? data?.message)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCellClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: number) => {
    const target = e.target as HTMLTableCellElement;
    if (target.className === 'amplify-table__td' || target.className === 'amplify-badge') {
      navigate(`/company/${id}`);
    }
  };

  const getCategoryName = (id: string) => {
    let category = {
      text: 'レンタル会社',
      color: '#DBAA00'
    }
    switch(id) {
      case '100':
        category = {
          text: '元請け',
          color: '#BE3F4A'
        }
      break;
      case '300':
        category = {
          text: '下請け',
          color: '#BE3F4A'
        }
      break;
    }

    return category
  }

  useEffect(() => {
    getList(itemsLimit, currentPageIndex)
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent 
      breadcrumbs={breadCrumbsItems} 
      title={pageTitle}
    >
      <Flex
        justifyContent={{
            base: 'center',
            medium: 'flex-start'
        }}
        className="hide-from-print"
      >
        <CustomButton
          {...defaultButtonProps}
          text='新規法人登録'
          size='medium'
          width={'medium'}
          borderRadius={'large'}
          disabled={false}
          click={ () => addUser() }
        />
      </Flex>
      { !isMobile ? (<View
        className="custom-table"
      >
        { hasError ? 
          (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill='#D32F2F' />
              </View>
              <Text>{ errorMessage }</Text>
            </Flex>
          ) : null
        }
        {!loader ? (
          <>
            <View as="div">
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow backgroundColor="#edf0f8">
                    <TableCell as="th" width={'40%'}>法人名</TableCell>
                    {/*<TableCell as="th" >所属現場</TableCell>*/}
                    <TableCell as="th" width={'30%'}>法人カテゴリー</TableCell>
                    <TableCell className="hide-from-print" as="th" width={'30%'} textAlign={'center'}>編集・削除</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="vertical-scroll">
                  {
                    dataList?.map((item: any, index: any) => {
                      return (
                        <TableRow onClick={(e) => handleCellClick(e, item.id)} key={index} className="table-row">
                          <TableCell width={'40%'}>
                            { item.name }
                          </TableCell>
                          {/*<TableCell>{ item.site }</TableCell>*/}
                          <TableCell width={'30%'}>
                            {/* <Badge
                              backgroundColor=''
                              color='#000000'
                              padding="6px 11px"
                              border={'2px solid #ffffff'}
                            >
                              {item.category}
                            </Badge> */}
                            <View as="span" border={'1px solid #DCE4E8'} color={item.category === "元請け" ? "#1C8C6E" : "#BE3F4A"} display='inline-block' fontSize='12px' fontWeight='700' padding='5px 0' borderRadius='6px' height='27px' minWidth='100px' textAlign='center'>{item.category}</View>
                          </TableCell>
                          <TableCell className="hide-from-print" textAlign={'center'} width={'30%'}>
                            <CustomButton
                            {...defaultButtonProps}
                              type="icon"
                              iconName="menu"
                              className="no-min"
                              click={() => handleActions(item.id, item.name)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </View>
            <Flex
              justifyContent="center"
              alignItems="center"
              marginTop="20px"
              fontWeight={400}
              fontSize={12}
              letterSpacing={0.4}
              className="hide-from-print"
              direction={'column'}
            >
              <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
              <IconsProvider
                icons={{
                  pagination: {
                    next: <ArrowRightIcon />,
                    previous: <ArrowLeftIcon />,
                  },
                }}
              >
                <Pagination
                  currentPage={currentPageIndex}
                  totalPages={totalPages}
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  // @ts-ignore
                  onChange={handleOnChange}
                  className={`custom-pagination ${
                    currentPageIndex === totalPages ? "disabled" : ""
                  }`}
                />
              </IconsProvider>
            </Flex>
          </>
        ) : (
          <View className="table-loader">
            <Loader width="5rem" height="5rem" filledColor="#2196F3" />
          </View>
        )}
      </View>) : (
        <View padding={'40px 20px'} overflow='scroll'>
          { hasError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <Text>{ errorMessage }</Text>
              </Flex>
            ) : null
          }
          <Flex direction='column'>
            {dataList?.map((item: any, index: any) => {
              return (
                <View key={`sp-${item.id}-${index}`} as="div" border='1px solid #DCE4E8' borderRadius='10px' padding='16px 16px'>
                  <Flex justifyContent='space-between' marginBottom='10px'>
                    <View as="p" fontWeight='700' fontSize='14px' margin='0'>{item.name}</View>
                    <View as="div">
                      <CustomButton
                        {...defaultButtonProps}
                        type="icon"
                        iconName="menu"
                        className="no-min"
                        click={() => handleActions(item.id, item.name)}
                      />
                    </View>
                  </Flex>
                  <Flex gap='12px' justifyContent='space-between'>
                    {/* <View as="p" flex="1" fontSize='14px' margin='0'>{item.site}</View> */}
                    <Flex flex='0 96px'>
                      <View as="span" color={getCategoryName(item.category_id).color} display='block' fontSize='12px' fontWeight='700' border='1px solid #DCE4E8' padding='5px 0' borderRadius='6px' height='27px' width='100%' textAlign='center'>{getCategoryName(item.category_id).text}</View>
                    </Flex>
                  </Flex>
                </View>
                )
              })
            }
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            fontWeight={400}
            fontSize={12}
            letterSpacing={0.4}
            className="hide-from-print"
            direction={'column'}
          >
            <View>{ totalCount + '件中' + indexOfFirstUser + '-' + indexOfLastUser + '件' }</View>
            <IconsProvider
              icons={{
                pagination: {
                  next: <ArrowRightIcon />,
                  previous: <ArrowLeftIcon />,
                },
              }}
            >
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                // @ts-ignore
                onChange={handleOnChange}
                className={`custom-pagination ${
                  currentPageIndex === totalPages ? "disabled" : ""
                }`}
              />
            </IconsProvider>
          </Flex>
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          textHeading={`法人「${ companyDeleteName }」の情報を削除します`}
          textOperation="この操作は取り消せません。本当に削除しますか？"
        >
          <Flex gap={{'base': '12px', 'medium': '32px'}} justifyContent={'center'} width={'100%'}>
            <CustomButton
              { ...defaultButtonProps }
              text='キャンセル'
              type="default"
              iconName='close'
              iconPosition='left'
              size='large'
              click={() => handleCancel()}
            />
            <CustomButton
              { ...defaultButtonProps }
              text='法人情報を削除'
              type='warning'
              iconName={ disableButton ? 'loader' : '' }
              iconPosition='right'
              disabled={ disableButton }
              size='large'
              click={() => handleDelete()}
            />
          </Flex>
        </CommonModal>
        <View className="company-action-modal">
          <CommonModal
            isOpen={isActionsModalOpen}
            onClose={() => closeActions()}
            textHeading=""
            textOperation=""
          >
            {isMobile ? (<View as="ul" width='100%' style={{'listStyleType': 'none', padding: '0'}}>
              <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                <Link to={`/company/${idSelected}`}>法人情報</Link>
              </View>
              <View as="li" width='100%' fontSize='16px' marginBottom='20px' paddingBottom='20px' style={{'borderBottom': '1px solid #D9DBDEAD'}}>
                <Link to={`/company/edit/${idSelected}`}>編集</Link>
              </View>
              <View as="li" width='100%' fontSize='16px'>
                <View onClick={() => openModal(idSelected, nameSelected)}>削除</View>
              </View>
            </View>) :(
              <>
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="編集"
                  type="primary"
                  iconName="pen"
                  iconPosition="left"
                  size="large"
                  click={() => editCompany(idSelected)}
                />
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="削除"
                  type="default"
                  iconName="trash"
                  iconPosition="left"
                  size="large"
                  click={() => openModal(idSelected,  nameSelected)}
                />
              </>
            )}
          </CommonModal>
        </View>
    </PageContent>
  );
}