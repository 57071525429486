import React from "react";
import { Flex, SelectField, TextField, View } from "@aws-amplify/ui-react";
import CustomButton from "../../../components/custom-button";
import SelectIcon from "../../../components/icons/select-icon";
import CommonModal from "../../../components/common-modal";
import { useNavigate } from "react-router-dom";
import EquipHeaderDetails from "./details-form/equip-header";
import "../../../styles/eq-details.scss";

const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

function ResultReturnActions() {
  return (
    <>
      <View className="pc-view">
        <Flex direction="row" justifyContent={"end"} marginTop={20}>
          <CustomButton
            {...defaultButtonProps}
            text="使用予定を更新"
            size="medium"
            width="medium"
            type="primary"
            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            size="medium"
            width="medium"
            type="bordered-transparent"
            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
        </Flex>
        <Flex justifyContent={"end"}>
          <CustomButton
            {...defaultButtonProps}
            text="削除"
            size="medium"
            width="medium"
            type="bordered-gray"

            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
        </Flex>
      </View>
      <View className="sp-view">
        <Flex direction="row" justifyContent={"end"} margin={"10px 0px"}>
          <CustomButton
            {...defaultButtonProps}
            text="キャンセル"
            size="medium"
            width="medium"
            type="bordered-transparent"
            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
          <CustomButton
            {...defaultButtonProps}
            text="使用予定を更新"
            size="medium"
            width="medium"
            type="primary"
            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
        </Flex>
        <Flex justifyContent={"end"}>
          <CustomButton
            {...defaultButtonProps}
            text="削除"
            size="medium"
            width={"small"}
            type="bordered-gray"

            //   click={() => navigate(`/eq/${equipmentId}`)}
          />
        </Flex>
      </View>
    </>
  );
}

export default function ResultReturnForm({
  detail,
  error,
  loader,
  handleChange,
  isModalOpen,
  setIsModalOpen,
}: any) {
  const navigate = useNavigate();

  return (
    <Flex
      direction={"column"}
      // width={"80%"}
      className="eq-rr-container"
    >
      <View className="pc-view">
        <EquipHeaderDetails
          status={detail.status}
          name={detail.equipment_name}
          category={detail.category_name}
        />
      </View>

      {/*** This is a Mobile View  */}
      <View className="sp-view">
        <EquipHeaderDetails
          status={detail.status}
          name={detail.equipment_name}
          category={detail.category_name}
        />
      </View>

      <Flex direction={"column"}>
        <TextField
          label="使用予定日"
          width={"100%"}
          type="date"
          value={detail.schedule}
          onChange={(event) => handleChange("schedule", event.target.value)}
        />
        <TextField
          label="使用終了日（予定）"
          width={"100%"}
          type="date"
          value={detail.return_schedule}
          onChange={(event) =>
            handleChange("return_schedule", event.target.value)
          }
        />
        <TextField
          label="使用担当者（予定）"
          width={"100%"}
          value={"Person in charge of use"}
        />

        <SelectField width={"100%"} label="場所" icon={<SelectIcon />}>
          <option value="1週間">Place</option>
          <option value="1週間">1週間</option>
        </SelectField>
      </Flex>
      <ResultReturnActions />
      {isModalOpen && (
        <CommonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          icon="icon"
          textHeading="機材返却を確定しました"
          textsmall="機材の使用状況を「返却済」に更新しました。"
          periodUse={`返却日時: ${detail.schedule} ${detail.schedule_time}`}
          user={`返却担当者: ${detail.user_id}`}
        >
          <View margin={`0 auto`}>
            <CustomButton
              {...defaultButtonProps}
              text="機材情報を確認"
              size="medium"
              width="medium"
              borderRadius="large"
              click={() => navigate(`/eq/${detail.equipmentId}`)}
            />
          </View>
        </CommonModal>
      )}
    </Flex>
  );
}
