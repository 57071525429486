import React from "react";
import { Flex, Heading, Card, Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

import "../../../styles/top.scss";
import CustomButton from "../../../components/custom-button";
import _ from "lodash";
const defaultButtonProps = {
  text: "",
  type: "primary",
  iconPosition: "",
  iconName: "",
  size: "",
  disabled: false,
  click: () => {
    return "";
  },
};

export const NavigationCard = ({
  heading,
  links,
  hide
}: {
  heading: any;
  links: any;
  hide: boolean
}) => {
  return (
    !hide && (
      <Card
        className="navigation-card-container"
        style={{ boxShadow: "#000 0px 2px 6px -2px" }}
      >
        <Heading level={4} className="header-text" key={`head-lv4-${links[0].url}`}>
          {heading}
        </Heading>
        <Flex className="navigation-card-wrapper" marginTop={20}>
          {!_.isEmpty(links) &&
            links?.map((link: any, index: any) => (
              !link.hide && (
                <Link to={link.url} className="nav-item" key={`link-${link.url}-${index}`}>
                  <Flex key={`${link.url}-${index}`}>
                    <CustomButton
                      {...defaultButtonProps}
                      type="icon"
                      iconPosition="left"
                      disabled={false}
                      iconName="arrow-right-default"
                      className="icon-button"
                      width="xsmall"
                    />
                    <Heading level={6}>{link.text}</Heading>
                  </Flex>
                </Link>
              )
            ))}
        </Flex>
      </Card>
    )
  );
};

export const NavigationCardHeader = ({
  heading,
  url,
}: {
  heading: any;
  url: any;
}) => {
  return (
    <Link to={url} className="header-item">
      <Card
       className="header-card"
      >
      <Heading level={4} className="header-text">
          {heading}
        </Heading>
        <CustomButton
          {...defaultButtonProps}
          type="icon"
          iconPosition="right"
          disabled={false}
          iconName="arrow-next"
          className="icon-button"
          width="xsmall"
        />
      </Card>
    </Link>
  );
};
