import React, { useEffect, useState, createContext, useRef } from "react";
import "./App.css";
import "./styles/amplify-modified.scss";
import { EqApi } from "./api/eq";

// 正しいインポート方法に修正
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { routes } from "./routes";
import Header from "./components/header";
import Footer from "./components/footer";
import Sidebar from "./components/sidebar";
import ScrollToTop from "./utils/scrollToTop"

// Amplify UI
import { Flex, View } from "@aws-amplify/ui-react";

// Amplify Auth
import { getCurrentUser } from "aws-amplify/auth";

// Configure Amplify with awsExports
Amplify.configure(awsExports);

// Create a context
type AuthorityStateRaw = {
  [key: string]:
    | boolean
    | string
    | { [key: string]: boolean | string | string[] }
    | { [key: string]: boolean | string | string[] }[];
};
type AuthorityState = { [key: string]: AuthorityStateRaw };

type CompanyState = {
  [key: string]: boolean | string;
};

type SiteStateRaw = {
  [key: string]: boolean | string;
};
type SiteState = SiteStateRaw[];
type SettingsState = {
  [key: string]: { [key: string]: string[] };
  //  list: [];
};

type UserState = {
  [key: string]: boolean | string;
};

interface AuthorityContextType {
  authority: AuthorityState | undefined;
  company: CompanyState | undefined;
  site: SiteState | undefined;
  settings: SettingsState | undefined;
  username: UserState | undefined;
}

export const AuthorityContext = createContext<AuthorityContextType | undefined>(
  undefined
);

export default function App() {
  const api = EqApi();
  const printRef = useRef<HTMLDivElement>(null);
  const userName: any = localStorage.getItem("username") || "null";
  const [showMenu, setShowMenu] = useState(false);
  const [hasUsername, setHasUsername] = useState(true);
  const [authority, setAuthority] = useState<AuthorityState | undefined>(
    undefined
  );
  const [company, setCompany] = useState<CompanyState | undefined>(undefined);
  const [site, setSite] = useState<SiteState | undefined>(undefined);
  const [settings, setSettings] = useState<SettingsState | undefined>(
    undefined
  );
  const [username, setUsername] = useState<UserState | undefined>(undefined);

  // const menuAction = () => {
  //   setShowMenu(!showMenu);
  // };

  //* temporary condition for hide header in login and password/forget
  const isLoginPath = () => {
    const path = window.location.pathname
    return path === "/login" || path === "password/forget" ? true : false;
  }
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSetUsername = (
    flag: boolean | ((prevState: boolean) => boolean)
  ) => {
    setHasUsername(flag);
  };

  useEffect(() => {
    handleSetUsername(userName === "null" ? false : true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      const sidebar = document.querySelector(".main-sidebar");
      if (sidebar && !sidebar.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const getUserAuth = async () => {
      var succeeded = false;
      for ( var i=0; i<3; i++) {//3回トライする
        try {
          const { signInDetails } = await getCurrentUser();
          if (signInDetails?.loginId) {
            const { data } = await api.getEqCommonUserInformation(userName);
            if (data.data?.authority_group) {
              const auth: { [key: string]: any } = {};
              //console.log("authority_group:");
              //console.log(data.data?.authority_group);
              data.data?.authority_group.forEach((item: any) => {
                if (item.site_id) {
                  auth[item.site_id?.toString()] = item.authority_groups_value;
                }
              });
              setAuthority(auth);
            } else {
              setAuthority({});
            }
            if (data.data?.company) setCompany(data.data.company);
            if (data.data?.site) {
              setSite(data.data.site)
              //currentSiteIdがない場合はここで保存。リストの最初の現場を割り当て
              if((localStorage.getItem("currentSiteId") ?? "") === ""){
                localStorage.setItem("currentSiteId", data.data.site[0].site_id)
              }
            };
            if (data.data?.user) setUsername(data.data.user.user_name);
            succeeded =true;
            break;
          }
        } catch (e) {
          console.error(e);
          await new Promise(s => setTimeout(s, 500))
        }
      }
      if(!succeeded){//失敗した場合は再度ログインさせる
        localStorage.setItem("username", String(null));
        localStorage.removeItem('check_info')
        localStorage.removeItem('userFname')
        handleSetUsername(false);
        localStorage.removeItem("currentSiteId")
        window.location.href = "/"; // It is needed to refresh the app so that context values are updated.
      }


    };
    if (userName !== "null") {
      getUserAuth();
    }
    // eslint-disable-next-line
  }, [userName]);

  useEffect(() => {
    if (authority && JSON.stringify(authority) !== "{}") {
      const retSettings = {} as { [key: string]: { [key: string]: string[] } };
      for (let [site_id, authSettingsRaw] of Object.entries(authority!)) {
        const authSettings = authSettingsRaw.page_control; //has "page_control" and "record_control"
        const menuList: string[] = [];
        // @ts-ignore
        authSettings?.forEach((item: any) => {
          item.subMenu.forEach((menu: any) => {
            if (menu.value) {
              menuList.push(menu.key);
            }

            if (menu.components) {
              menu?.components?.forEach((component: any) => {
                if (component.value) {
                  menuList.push(component.key);
                }
              });
            }
          });
          retSettings[site_id] = {};
          retSettings[site_id]["page_control"] = menuList;
          const authSettingsRecordControl = authSettingsRaw.record_control as {
            [key: string]: string[];
          };
          retSettings[site_id]["record_control-".concat("equipments_manage")] =
            authSettingsRecordControl["equipments_manage"] ?? [];
          retSettings[site_id][
            "record_control-".concat("equipments_schedule_manage")
          ] = authSettingsRecordControl["equipments_schedule_manage"] ?? [];
          retSettings[site_id]["record_control-".concat("user_manage")] =
            authSettingsRecordControl["user_manage"] ?? [];
        });
      }
      //console.log(retSettings);
      setSettings(retSettings);
    }
  }, [authority]);

  return (
    <>
      <AuthorityContext.Provider value={{ authority, company, site, settings, username }}>
        <Router>
          <ScrollToTop/>
          <Header handleSetUsername={handleSetUsername} printRef={printRef} />

          <Flex className="main-content">
            {userName !== "null" && <Sidebar showMenu={showMenu} handleShowMenu={handleShowMenu} />}
            <View
              ref={printRef}
              className={`main-body ${showMenu ? "has-menu" : ""} ${isLoginPath() ? "is-login" : ""}`}
              padding={{
                base: "0",
                medium: `${
                  userName !== "null" || isLoginPath() ? "0" : "20px 0"
                }`,
              }}
              marginTop={{
                base: "0",
                medium: "80px",
              }}
            >
              <Routes>{routes}</Routes>
              {!isLoginPath() && (
                <Footer
                  handleShowMenu={handleShowMenu}
                  handleSetUsername={handleSetUsername}
                  isAuthenticated={hasUsername}
                  showMenu={showMenu}
                />
              )}
            </View>
          </Flex>
        </Router>
      </AuthorityContext.Provider>
    </>
  );
}
